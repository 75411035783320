export const MegaphoneDarkgreyOffIcon = () => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.25 12.9998C29.25 10.1181 27.9933 7.51812 26 5.74146V20.2365C27.9933 18.4815 29.25 15.8815 29.25 12.9998Z"
        fill="#DBDBDB"
        fillOpacity="1"
      />
      <path
        d="M4.33333 6.5C1.95 6.5 0 8.45 0 10.8333V15.1667C0 17.55 1.95 19.5 4.33333 19.5H6.5V28.1667H10.8333V19.5H13L23.8333 26V0L13 6.5H4.33333ZM15.2317 10.205L19.5 7.64833V18.3517L15.2317 15.795L14.1917 15.1667H4.33333V10.8333H14.1917L15.2317 10.205Z"
        fill="#DBDBDB"
        fillOpacity="1"
      />
    </svg>
  )
}
