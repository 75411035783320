import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Card } from './Card'
import { ToggableCard } from './ToggableCard'
import VerticalChart from '../Charts/Vertical'
import { useTranslation } from 'react-i18next'

interface Props {
  labels: string[]
  data: number[]
}
export const UsePerPeriod = ({ labels, data }: Props) => {
  const { t } = useTranslation()

  return (
    <ToggableCard viewkey="useperperiod">
      <Card
        sx={{
          minWidth: '460px',
          minHeight: '200px',
          width: '222px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          py: '16px',
          px: '32px',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.6);',
              whiteSpace: 'pre-wrap',
              mb: 1,
            }}
          >
            {t('painel.useperperiod')}
          </Typography>
          <VerticalChart labels={labels} data={data} />
        </Box>
      </Card>
    </ToggableCard>
  )
}
