import { FormControl, InputLabel, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const allCategories = [
  {
    id: '1',
    value: 'access_point',
    label: 'Pontos de Acesso',
  }, {
    id: '2',
    value: 'admin_user',
    label: 'Usuários',
  }, {
    id: '3',
    value: 'captive_portal',
    label: 'Portais',
  }, {
    id: '4',
    value: 'group',
    label: 'Grupo de Pontos',
  }, {
    id: '5',
    value: 'marketing_campaign',
    label: 'Campanhas',
  }, {
    id: '6',
    value: 'role',
    label: 'Papeis',
  }, {
    id: '7',
    value: 'reports',
    label: 'Relatórios',
  },
]

interface VideoSelectFormProps {
  control: any
  name?: string
  label?: string
  sx?: any
}

export const VideoSelectForm = (props: VideoSelectFormProps) => {
  const { t } = useTranslation()
  const { name, label, control, sx } = props
  const { field } = useController({ name, control, defaultValue: '' })

  return (
    <FormControl
      variant="outlined"
      sx={{
        '.MuiFormHelperText-root.Mui-error': {
          m: '5px 0 10px 0',
        },
        width: '100%',
        ...sx
      }}
      fullWidth
    >
      <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-select-label`}
        label={label}
        {...field}
      >
        {allCategories.map((category) => {
          return (
            <MenuItem key={category.id} value={category.value}>
              {t(`${category.label}`)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
