import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna'
import CancelIcon from '@mui/icons-material/Cancel'

interface Props {
  onClick: (v) => void
  isChecked: boolean
  mode: 'active' | 'inactive'
}

export const ActiveFilter = ({ onClick, mode, isChecked }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        backgroundColor: isChecked ? '#FF5E1E' : 'white',
        paddingX: '10px',
        paddingY: '3px',
        borderRadius: '16px',
        cursor: 'pointer',
        border: isChecked ? '1px solid #FF5E1E;' : '',
        ':hover': isChecked
          ? {}
          : {
              color: '#FF5E1E !important',
              backgroundColor: '#FF5E1E14',
              border: '1px solid #FF5E1E',
              '#anteninha': {
                color: '#FF5E1E !important',
              },
              '#textinho': {
                color: '#FF5E1E !important',
              },
            },
      }}
      onClick={onClick}
    >
      <SettingsInputAntennaIcon
        id="anteninha"
        sx={{
          color:
            mode === 'active'
              ? isChecked
                ? 'white'
                : '#FF5E1E'
              : isChecked
              ? 'white'
              : 'rgba(0, 0, 0, 0.6)',
          fontSize: '20px',
        }}
      />
      <Typography
        id="textinho"
        sx={{
          fontSize: '14px',
          fontFamily: 'Roboto',
          color: isChecked ? 'white' : 'rgba(0, 0, 0, 0.6)',
        }}
      >
        {t(
          mode === 'active'
            ? 'heatmap.filter.active'
            : 'heatmap.filter.inactive'
        )}
      </Typography>
      {isChecked && (
        <CancelIcon
          sx={{
            fontSize: '14px',
            color: isChecked ? 'white' : 'rgba(0, 0, 0, 0.6)',
          }}
        />
      )}
    </Box>
  )
}
