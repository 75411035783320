import AddIcon from '@mui/icons-material/Add'
import InsightsIcon from '@mui/icons-material/Insights'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna'
import { Box, Button, Container, Typography } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import ActivityHistory from 'src/components/SideMenuAudit/ActivityHistory'
import { ActivityContext } from 'src/context/ActivityContext'
import { AlertContext } from 'src/context/AlertContext'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { getAccessPointsAnalysis } from 'src/service/accesspointService'
import { getPermissions, getRole } from 'src/service/authService'
import { goToCreatePoint } from '../../routes/coordinator'
import { DashboardService } from '../Dashboard/DashboardService'
import PointTable from './components/Table/PointTable'

const Point = () => {
  useProtectedPage()
  const history = useNavigate()
  const { t } = useTranslation()
  const { openDrawer } = useContext(ActivityContext)
  const { createAlert } = useContext(AlertContext)
  const { isGlobalAdminSelected, selectedCompanyData } = useGlobalSelectedCompany()
  const isTecnoit = getRole() === 'TECNOIT'

  const permissions = JSON.parse(getPermissions())
  const canSeeAccessPointReport = permissions?.reports?.includes('reports:ap-analysis')

  const downloadReport = async () => {
    if (isGlobalAdminSelected() && isTecnoit) {
      createAlert('/', t('point.report.data.error'), 'error')
      return
    } else {
      const response = await getAccessPointsAnalysis({ params: isTecnoit ? { companyId: selectedCompanyData.id } : {} })
      const report = new DashboardService()
      report.generateAndDownloadFile(
        response.accessPoints,
        `itBuzz_access_points_analysis_${new Date().toLocaleDateString()}`,
        '.csv',
        ['id', 'apName', 'geoAddress', 'geoLatitude', 'geoLongitude', 'lastConnection', 'totalConnections']
      )
    }
  }

  return (
    <Container
      sx={{
        mt: '96px',
      }}
    >
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <SettingsInputAntennaIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: ' #FF5E1E',
              marginTop: '-4px',
            }}
          >
            {t('point.title')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {
            permissions?.auditory?.includes('auditory:list') && <ActivityHistory path="pontos" keySubtitle="apName" />
          }
        </Box>
      </Box>

      <Box
        sx={{
          mb: '40px',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
            xl: 'row',
          },
          gap: '16px',
          width: openDrawer ? 'calc(100% - 280px)' : '100%',
        }}
      >
        <Button
          onClick={() => goToCreatePoint(history)}
          disabled={!permissions?.accesspoint?.includes("accesspoint:create")}
          variant="outlined"
          sx={{
            gap: '6px',
            width: openDrawer ? '100%' : '49%',
            height: '36px',
            ':hover': {
              backgroundColor: '#FF5E1E',
              color: '#ffff',
            },
          }}
        >
          <AddIcon /> {t('point.button.create')}
        </Button>
        { canSeeAccessPointReport &&
          <Button
            onClick={downloadReport}
            variant="outlined"
            sx={{
              gap: '8px',
              width: openDrawer ? '100%' : '49%',
              height: '36px',
              color: 'rgba(0, 0, 0, 0.6)',
              borderColor: 'rgba(0, 0, 0, 0.6)',
              ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: '#ffff',
                borderColor: 'rgba(0, 0, 0, 0.6)',
              },
            }}
          >
            <InsightsIcon /> {t('point.report.data')}
          </Button>
        }
      </Box>

      <PointTable />
    </Container>
  )
}
export default Point
