import { Route, Routes } from 'react-router-dom'

import { CampaignResult } from 'src/pages/Campaigns/CampaignResult'
import CompanyList from 'src/pages/Company/CompanyList'
import CreateCompany from 'src/pages/Company/CreateCompany'
import { CreateEnrichment } from 'src/pages/Enrichment/CreateEnrichment'
import { EnrichmentProfile } from 'src/pages/Enrichment/EnrichmentList'
import { Error401 } from 'src/pages/Error/Error401'
import { Error403 } from 'src/pages/Error/Error403'
import { Error404 } from 'src/pages/Error/Error404'
import { ColorMap } from 'src/pages/HeatMap/HeatMap'
import { CreateNotification } from 'src/pages/Notifications/CreateNotification'
import { ViewNotification } from 'src/pages/Notifications/ViewNotification'
import CreatePoint from 'src/pages/Point/CreatePoint'
import Point from 'src/pages/Point/PointList'
import CreatePointGroups from 'src/pages/PointGroups/CreatePointGroups'
import PointGroups from 'src/pages/PointGroups/PointGroupsList'
import CreatePortal from 'src/pages/Portal/CreatePortal'
import { ViewProfilePage } from 'src/pages/Profile/ViewProfilePage'
import { CreateFAQ } from 'src/pages/Support/CreateFAQ'
import { CreateVideo } from 'src/pages/Support/CreateVideo'
import { FAQsList } from 'src/pages/Support/FaqsList'
import { VideoList } from 'src/pages/Support/VideoList'
import CreaterUserManager from 'src/pages/User/CreateUserManager'
import UserManager from 'src/pages/User/UserManager'
import { WithNav } from 'src/routes/RoutesLayout/WithNav'
import { WithoutNav } from 'src/routes/RoutesLayout/WithoutNav'
import Campaigns from '../pages/Campaigns/CampaignsList'
import CreateCampaign from '../pages/Campaigns/CreateCampaign'
import Dashboard from '../pages/Dashboard/Dashboard'
import Login from '../pages/Login/Login'
import LoginNoAutorized from '../pages/Login/NoAuthorized/LoginNoAuthorized'
import Portal from '../pages/Portal/PortalList'
import CreateRole from '../pages/Role/CreateRole'
import Roles from '../pages/Role/RoleList'
import Support from '../pages/Support/SupportPage'
import PrivateRouteTecnoIt from './RoutesPrivate/PrivateRouteTecnoIt'
import ProtectedRoute from './RoutesPrivate/ProtectedRoute'

export enum ROUTES {
  HOME = '/',
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  NOAUTHORIZED = '/no-authorized',
  COMPANY = '/empresas',
  COMPANYCREATE = '/empresa',
  EDITCOMPANY = '/empresa/:id',
  USERMANAGER = '/usuarios',
  USERMANAGERCREATE = '/usuario',
  EDITUSERMANAGER = '/usuario/:id',
  ENRICHMENT = '/enriquecimentos',
  ENRICHMENTCREATE = '/enriquecimento',
  EDITENRICHMENT = '/enriquecimento/:id',
  PROFILE = '/perfil',
  PROFILEVIEW = '/perfil/:id',
  NOTIFICATIONCREATE = '/notificacao',
  NOTIFICATIONVIEW = '/notificacao/:id',
  POINT = '/gerenciamento/pontos',
  POINTCREATE = '/gerenciamento/ponto',
  EDITPOINT = '/gerenciamento/ponto/:id',
  PORTAIS = '/gerenciamento/portais',
  PORTALCREATE = '/gerenciamento/portal',
  EDITPORTAL = '/gerenciamento/portal/:id',
  GROUPPOINT = '/gerenciamento/grupo-de-pontos',
  GROUPPOINTCREATE = '/gerenciamento/grupo-de-ponto',
  EDITGROUPPOINT = '/gerenciamento/grupo-de-ponto/:id',
  CAMPAIGNS = '/gerenciamento/campanhas',
  CAMPAIGNSCREATE = '/gerenciamento/campanha',
  CAMPAIGNRESULT = '/gerenciamento/resultado/campanha/:id',
  EDITCAMPAIGN = '/gerenciamento/campanha/:id',
  COLORMAP = '/gerenciamento/mapa',
  SUPPORT = 'gerenciamento/suporte',
  FAQS = '/gerenciamento/perguntas-frequentes',
  FAQSCREATE = '/gerenciamento/pergunta-frequente',
  EDITFAQS = '/gerenciamento/pergunta-frequente/:id',
  TRAININGVIDEO = '/gerenciamento/video-aulas',
  TRAININGVIDEOCREATE = '/gerenciamento/video-aula',
  EDITTRAININGVIDEO = '/gerenciamento/video-aula/:id',
  ROLE = '/papeis',
  ROLECREATE = '/papel',
  EDITROLE = '/papel/:id',
  ERROR401 = '/error401',
  ERROR404 = '/error404',
  ERROR403 = '/error403',
}

export default function Router() {
  return (
    <Routes>
      <Route element={<WithoutNav />}>
        <Route path={ROUTES.HOME} element={<Login />} />

        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.NOAUTHORIZED} element={<LoginNoAutorized />} />
      </Route>
      <Route path={ROUTES.HOME} element={<ProtectedRoute />}>
        <Route element={<WithNav />}>
          {/* TECNOIT */}
          <Route path={ROUTES.HOME} element={<PrivateRouteTecnoIt />}>
            <Route path={ROUTES.COMPANY} element={<CompanyList />} />
            <Route path={ROUTES.COMPANYCREATE} element={<CreateCompany />} />
            <Route path={ROUTES.EDITCOMPANY} element={<CreateCompany />} />

            <Route path={ROUTES.ENRICHMENT} element={<EnrichmentProfile />} />
            <Route path={ROUTES.ENRICHMENTCREATE} element={<CreateEnrichment />} />
            <Route path={ROUTES.EDITENRICHMENT} element={<CreateEnrichment />} />

            <Route path={ROUTES.NOTIFICATIONCREATE} element={<CreateNotification />} />

            <Route path={ROUTES.FAQSCREATE} element={<CreateFAQ />} />
            <Route path={ROUTES.EDITFAQS} element={<CreateFAQ />} />

            <Route path={ROUTES.TRAININGVIDEOCREATE} element={<CreateVideo />} />
            <Route path={ROUTES.EDITTRAININGVIDEO} element={<CreateVideo />} />
          </Route>

          <Route path={ROUTES.USERMANAGER} element={<UserManager />} />
          <Route path={ROUTES.USERMANAGERCREATE} element={<CreaterUserManager />} />
          <Route path={ROUTES.EDITUSERMANAGER} element={<CreaterUserManager />} />

          <Route path={ROUTES.NOTIFICATIONVIEW} element={<ViewNotification />} />
          <Route path={ROUTES.FAQS} element={<FAQsList />} />
          <Route path={ROUTES.TRAININGVIDEO} element={<VideoList />} />

          <Route path={ROUTES.PROFILE} element={<ViewProfilePage />} />

          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />

          <Route path={ROUTES.POINT} element={<Point />} />
          <Route path={ROUTES.POINTCREATE} element={<CreatePoint />} />
          <Route path={ROUTES.EDITPOINT} element={<CreatePoint />} />

          <Route path={ROUTES.PORTAIS} element={<Portal />} />
          <Route path={ROUTES.PORTALCREATE} element={<CreatePortal />} />
          <Route path={ROUTES.EDITPORTAL} element={<CreatePortal />} />

          <Route path={ROUTES.GROUPPOINT} element={<PointGroups />} />
          <Route
            path={ROUTES.GROUPPOINTCREATE}
            element={<CreatePointGroups />}
          />
          <Route path={ROUTES.EDITGROUPPOINT} element={<CreatePointGroups />} />

          <Route path={ROUTES.CAMPAIGNS} element={<Campaigns />} />
          <Route path={ROUTES.CAMPAIGNRESULT} element={<CampaignResult />} />
          <Route path={ROUTES.CAMPAIGNSCREATE} element={<CreateCampaign />} />
          <Route path={ROUTES.EDITCAMPAIGN} element={<CreateCampaign />} />
          <Route path={ROUTES.COLORMAP} element={<ColorMap />} />

          <Route path={ROUTES.SUPPORT} element={<Support />} />

          <Route path={ROUTES.ROLE} element={<Roles />} />
          <Route path={ROUTES.ROLECREATE} element={<CreateRole />} />
          <Route path={ROUTES.EDITROLE} element={<CreateRole />} />

          <Route path={ROUTES.ERROR401} element={<Error401 />} />
          <Route path={ROUTES.ERROR404} element={<Error404 />} />
          <Route path={ROUTES.ERROR403} element={<Error403 />} />
        </Route>
      </Route>
    </Routes>
  )
}
