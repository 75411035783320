import { Select, Typography } from '@mui/material'
import { Box } from '@mui/system'

import LogoItBuzz from '../../assets/icons/itBuzz.png'
import LogoTecnoIt from '../../assets/icons/tecnoIT.png'
import DefaultBG from '../../assets/icons/default-bg-preview.png'
import version from '../../../package.json'

const defaultConfig = {
  buttonColor: 'rgba(229, 79, 18, 1)',
  textColor: '#FFF',
  backgroundImage: DefaultBG,
  logoImage: LogoItBuzz,
}

export const PortalPreviewTemplate = ({ config, children }) => {
  const portalConfig = config || defaultConfig
  return (
    <Box
      sx={{
        width: '100%',
        height: '70vh',
        maxHeight: '600px',
        overflowY: 'scroll',
        backgroundImage: `url('${
          portalConfig?.backgroundImage || defaultConfig?.backgroundImage
        }')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <>
        <Box
          sx={{
            minHeight: '24px',
            width: '100%',
            bgcolor: '#1D1D1D',
          }}
        />
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            p: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              alignItems: 'center',
              color: 'white',
              gap: '100px',
            }}
          >
            <img
              src={portalConfig?.logoImage || defaultConfig?.logoImage}
              width="100px"
            />
            <Select
              disabled
              placeholder="PT"
              sx={{
                height: '30px',
                border: 'none',
                width: '65px',
                bgcolor: 'rgba(0, 0, 0, 0.6)',
                ':disabled': { color: '#ffff' },
                '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled':
                  {
                    WebkitTextFillColor: 'white',
                  },
              }}
              open={false}
              defaultValue="PT"
            >
              <option value={'PT'}>
                <Typography
                  sx={{ color: '#ffff', ':disabled': { color: '#ffff' } }}
                >
                  PT
                </Typography>
              </option>
            </Select>
          </Box>
          {children}
          <Box
            sx={{
              height: '42px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              bgcolor: 'rgba(61, 61, 61, 1)',
              borderRadius: '4px',
            }}
          >
            <img src={LogoItBuzz} width="50px" />
            <Typography
              sx={{
                fontSize: '8px',
                fontWeight: '500',
                color: 'rgba(255, 255, 255, 0.74)',
              }}
            >
              {version.version} ©2023
            </Typography>
            <img src={LogoTecnoIt} width="80px" />
          </Box>
        </Box>
      </>
    </Box>
  )
}
