import * as yup from 'yup'
import { ValidationMode } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { User } from '../../../models/login-model'

export const userDefaultValues: User = {
  id: '',
  email: '',
  password: '',
}

const mode: keyof ValidationMode = 'onBlur'

export const userSchemaValidation = yup.object({
  id: yup.string(),
  email: yup
    .string()
    .email('Digite um email válido')
    .required('E-mail é obrigatório'),
  password: yup
    .string()
    .test('password-length', 'Tamanho mínimo 6 caracteres', (value) => {
      if (value.length > 2 && value.length < 6) {
        return false;
      }
      return true;
    })
    .required('Senha é obrigatória'),
});

export const userUseFormArgs = {
  mode,
  defaultValues: userDefaultValues,
  resolver: yupResolver(userSchemaValidation),
}
export const emailUserSchemaValidation = yup.object({
  userEmail: yup
    .string()
    .email('Digite um email válido')
    .required('E-mail é obrigatório'),
})

export const confirmPasswordSchema = yup.object().shape({
  password: yup.string().min(6, 'modal.password.error.characters').required(),
  newPassword: yup
    .string()
    .min(6, 'modal.password.error.characters')
    .required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'modal.password.error'),
})

export interface UserTypes extends yup.InferType<typeof userSchemaValidation> {}
