import { useContext } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Button, IconButton, Modal, SxProps, Typography } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"
import ClearIcon from '@mui/icons-material/Clear'

import { confirmPasswordSchema } from "src/pages/Login/validators/userSchemaValidator"
import { AlertContext } from "src/context/AlertContext"
import { getUserEmail, logout } from "src/service/authService"
import { changePassword } from "src/service/userAdminsService"
import InputPasswordRHF from "src/components/RHF/InputRHFPassword"
import { ROUTES } from "src/routes/Router"
import { useNavigate } from "react-router-dom"
import { goToLogin } from "src/routes/coordinator"

const style: SxProps = {
  boxSizing: ' border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  minHeight: '500px',
  heigth: 'auto',
  width: '650px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: 24,
  p: '16px 16px 24px 16px',
  borderRadius: '4px',
  gap: '24px',
}

const buttonDefaultStyle: SxProps = {
  width: '85%',
  height: '54px',
  mb: '15px',
}

interface ChangePasswordModalProps {
  open: boolean
  handleClose(): void
}

type FormData = {
  email: string
  password: string
  newPassword: string
  passwordConfirmation: string
}

export const ChangePasswordModal = ({ open, handleClose}: ChangePasswordModalProps) => {
  const { t } = useTranslation()
  const history = useNavigate()
  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: { password: '', newPassword: '', passwordConfirmation: '' },
    resolver: yupResolver(confirmPasswordSchema),
  })
  const { control, handleSubmit, formState } = form
  const { createAlert } = useContext(AlertContext)

  const onSubmit = async (data: FormData) => {
    const validData = {
      ...data,
      email: getUserEmail(),
    }
    const response = await changePassword(validData)
    if (response.status === 204) {
      handleClose()
      createAlert(ROUTES.PROFILE, t('alert.info.password'), 'success')
      setTimeout(() => {
        logout()
        goToLogin(history)
      }, 1000)
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant='h5' sx={{ ml: '30px' }}>{t('profile.change.password.modal.title')}</Typography>
          <IconButton
            onClick={handleClose}
            size={'small'}
            sx={{
              left: '1px',
              color: '#00000099',
              ':hover': {
                color: '#FF5E1E',
                backgroundColor: '#FF5E1E14',
              },
            }}
          >
            <ClearIcon sx={{ height: '16px', width: '16px', left: '1px' }} />
          </IconButton>
        </Box>
        <Box
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '400px'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mx: '50px',
              color: '#FF5E1E',
            }}
          >
            {t('profile.change.password.modal.description')}
          </Typography>
          <InputPasswordRHF
            name="password"
            label={t('profile.change.password.modal.form.old.password.label')}
            placeholder={t('profile.change.password.modal.form.old.password.placeholder')}
            control={control}
            sx={buttonDefaultStyle}
          />
          <InputPasswordRHF
            name="newPassword"
            label={t('profile.change.password.modal.form.new.password.label')}
            placeholder={t('profile.change.password.modal.form.new.password.placeholder')}
            control={control}
            sx={buttonDefaultStyle}
          />
          <InputPasswordRHF
            name="passwordConfirmation"
            label={t('profile.change.password.modal.form.password.confirmation.label')}
            placeholder={t('profile.change.password.modal.form.password.confirmation.placeholder')}
            control={control}
            sx={buttonDefaultStyle}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                minWidth: '200px',
                height: '40px',
                marginRight: '16px',
                gap: 1,
                color: '#00000099',
                border: '1px solid #00000099',
                ':hover': {
                  border: '1px solid #00000099',
                  backgroundColor: '#00000099',
                  color: '#ffff',
                },
              }}
            >
              {t('profile.change.password.cancel')}
            </Button>
            <Button
              type="submit"
              disabled={!formState.isValid}
              variant="outlined"
              sx={{
                minWidth: '200px',
                height: '40px',
                gap: 1,
                color: '#FF5E1E',
                border: '1px solid #FF5E1E',
                ':hover': {
                  backgroundColor: '#FF5E1E',
                  color: '#ffff',
                },
              }}
            >
              {t('profile.change.password.accept')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
