import { createContext, ReactNode, useState } from 'react'

const defaultState = {
  step: 0,
  nextStep: () => {},
  prevStep: () => {},
  initStepper: (total: number) => {},
  canGoBack: () => true,
  canGoOn: () => true,
  nextButtonDiseabled: null,
  setIsNextButtonDiseabled: (total) => {},
  cleanButtonDiseabled: null,
  setCleanButtonDiseabled: (total) => {},
}

interface ContextProviderProps {
  children: ReactNode
}

export const StepperContext = createContext(defaultState)

function StepperProvider({ children }: ContextProviderProps) {
  const [step, setStep] = useState<number>(0)
  const [totalSteps, setTotalSteps] = useState<number>(0)
  const [nextButtonDiseabled, setIsNextButtonDiseabled] = useState()
  const [cleanButtonDiseabled, setCleanButtonDiseabled] = useState()
  const nextStep = () => {
    if (step < totalSteps) {
      setStep(step + 1)
    }
  }
  const prevStep = () => {
    if (step >= 0) {
      setStep(step - 1)
    }
  }

  const initStepper = (total: number) => {
    setTotalSteps(total)
  }

  const canGoOn = () => Boolean(totalSteps > step + 1)

  const canGoBack = () => Boolean(step > 0)

  return (
    <StepperContext.Provider
      value={{
        step,
        nextStep,
        prevStep,
        initStepper,
        canGoOn,
        canGoBack,
        nextButtonDiseabled,
        setIsNextButtonDiseabled,
        cleanButtonDiseabled,
        setCleanButtonDiseabled,
      }}
    >
      {children}
    </StepperContext.Provider>
  )
}

export default StepperProvider
