import { useState } from 'react'
import { t } from 'i18next'
import { IconButton, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { GridSearchIcon } from '@mui/x-data-grid'

interface Props {
  onSearch(search: string): void
}
export const Search = ({ onSearch }: Props) => {
  const [search, setSearch] = useState('')

  const handleSearch = (e) => {
    e.preventDefault()
    onSearch(search)
  }
  return (
    <Box component={'form'}>
      <TextField
        label={t('table.button.search')}
        sx={{
          width: '100%',
          height: '48px',
          mb: '16px',
          '& .MuiOutlinedInput-root': {
            paddingRight: '0',
          },

          '&:hover': {
            '& .MuiButtonBase-root': {
              color: '#ff5e1e',
              border: '1px solid #ff5e1e',
              borderBottomRightRadius: '4px',
              borderTopRightRadius: '4px',
              backgroundColor: 'rgba(255, 94, 30, 0.12)',
            },

            '& .MuiInputBase-root': {
              borderColor: '#ff5e1e',
            },

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ff5e1e',
            },
          },

          '&:focus': {
            '& .MuiButtonBase-root': {
              color: '#ff5e1e',
              borderLeft: '1px solid #ff5e1e',
              backgroundColor: 'rgba(255, 94, 30, 0.12)',
            },
          },

          '&:active': {
            '& .MuiButtonBase-root': {
              color: '#ff5e1e',
              borderLeft: '1px solid #ff5e1e',
              backgroundColor: 'rgba(255, 94, 30, 0.12)',
            },
          },

          '&.MuiFormControl-root': {
            ':focus': {
              color: '#ff5e1e',
            },
          },
        }}
        onChange={({ target: { value } }) => setSearch(value)}
        value={search}
        InputProps={{
          endAdornment: (
            <div>
              <IconButton
                type="submit"
                onClick={handleSearch}
                sx={{
                  borderRadius: '0px',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.4)',
                  padding: '15px',
                  ':focus': {
                    borderLeft: '1px solid #ff5e1e',
                    backgroundColor: 'rgba(255, 94, 30, 0.12)',
                    color: '#ff5e1e',
                  },

                  '&:hover': {
                    borderLeft: '1px solid #ff5e1e',
                    backgroundColor: 'rgba(255, 94, 30, 0.12)',
                    color: '#ff5e1e',
                  },
                }}
              >
                <GridSearchIcon />
              </IconButton>
            </div>
          ),
        }}
      />
    </Box>
  )
}
