export function createMessageExcerpt (text: string, excerptSize?: number): string {
  const maxLength = excerptSize ?? 150;
  if (text.length <= maxLength) {
    return text;
  }
  let excerpt = text.slice(0, maxLength + 1); // Take 151 characters to check the next character
  // Check if the last character in the 150 character slice is part of a word
  if (/\S/.test(excerpt[maxLength])) {
    // Find the last space within the 150 character limit to avoid cutting a word
    let lastSpaceIndex = excerpt.lastIndexOf(' ', maxLength);
    if (lastSpaceIndex > -1) {
      excerpt = excerpt.slice(0, lastSpaceIndex);
    }
  } else {
    excerpt = excerpt.slice(0, maxLength);
  }
  return excerpt.trim() + "...";
}

// Example usage:
// let longText = "This is an example of a long text that should be trimmed down to a maximum of 150 characters, and if the text exceeds the limit, it should end gracefully with ellipses..."
// let excerpt = createMessageExcerpt(longText)
// console.log(excerpt)
