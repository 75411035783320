import { yupResolver } from '@hookform/resolvers/yup';
import type { ValidationMode } from 'react-hook-form';
import * as yup from 'yup';

export enum Categories {
  'access_point' = 'enum.access.point',
  'admin_user' = 'enum.admin.user',
  'captive_portal' = 'enum.captive.portal',
  'group' = 'enum.group',
  'marketing_campaign' = 'enum.marketing.campaign',
  'role' = 'enum.role',
  'reports' = 'enum.reports',
}

type Category = keyof typeof Categories;

export const trainingVideoDefaultValues: { title: string, description: string, videoUrl: string, category: Category | ''} = {
  title: '',
  description: '',
  videoUrl: '',
  category: ''
}

const mode: keyof ValidationMode = 'all'

export const trainingVideoSchemaValidation = yup.object({
  title: yup.string().required('required'),
  description: yup.string().required('required'),
  videoUrl: yup.string().required('required'),
  category: yup.string().required('required'),
})

export const trainingVideoUseFormArgs = {
  mode,
  defaultValues: trainingVideoDefaultValues,
  resolver: yupResolver(trainingVideoSchemaValidation),
}
