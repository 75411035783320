import { Clear } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

import { useTranslation } from 'react-i18next'

const style = {
  boxSizing: ' border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  height: '249px',
  width: '376px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: 24,
  p: '16px 16px 24px 16px',
  borderRadius: '4px',
  gap: '24px',
}

export interface ModalSupportProps {
  open: boolean
  onHandleClose(): void
}

export function ModalSupport (props: ModalSupportProps) {
  const { t } = useTranslation()

  return (
    <Box>
      <Modal
        open={props.open}
        onClose={props.onHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <IconButton
              onClick={() => props.onHandleClose()}
              sx={{ height: '16px', width: '16px', left: '1px' }}
            >
              <Clear sx={{ height: '16px', width: '16px', left: '1px' }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CheckCircleIcon sx={{ color: '#FF5E1E', mb: '8px' }} />

            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: '24px',
                lineHeight: '29px',
                letterSpacing: '0.18px',
                color: '#FF5E1E',
              }}
            >
              {t('login.no_authorized.suport.title')}
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.6)',
                width: '344px',
                height: '40px',
                textAlign: 'center',
                mt: '16px',
              }}
            >
              {t('login.no_authorized.suport.message')}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={() => props.onHandleClose()}
              variant="outlined"
              sx={{
                minWidth: '344px',
                height: '40px',
                color: 'rgba(0, 0, 0, 0.6)',
                border: '1px solid rgba(0, 0, 0, 0.6)',
                ':hover': {
                  color: '#FFF',
                  backgroundColor: '#FF5E1E',
                },
              }}
            >
              {t('login.no_authorized.button')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
