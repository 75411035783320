import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import CircleIcon from '@mui/icons-material/Circle'

import { Card } from './Card'
import PieChart2 from '../Charts/PieChart2'

export const Gender = ({ labels, data, total, sx }) => {
  const { t } = useTranslation()
  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'row',
        gap: '30px',
        p: '16px',
        px: '31px',
        pb: '20px',
        ...sx
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {t('card.gender')}
        </Typography>
        <Typography
          sx={{
            fontSize: '34px',
            fontWeight: 400,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {total}
        </Typography>
      </Box>

     
        <Box
          sx={{
            display: total ? 'none' : 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            // gap: '5px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '110px',
              height: '110px',
              border: '2px solid #DBDBDB',
              borderRadius: '100%',
              justifyContent: 'center',
              color: 'rgba(0, 0, 0, 0.38)',
              mt: '30px',
              ml: '45px',
            }}
          ></Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
              ml: '32px',
            }}
          >
            <CircleIcon
              sx={{ color: '#DBDBDB', width: '9px', height: '9px' }}
            />
            <Typography sx={{ width: '124px' }}>
              {t('card.gender.subtitle')}
            </Typography>
          </Box>
        </Box>

        <PieChart2 labels={labels} data={data} />
      
    </Card>
  )
}
