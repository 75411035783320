import { useContext } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'

import CKEditorRHF, {
  ParseCFKContent,
} from 'src/components/CKEditorRHF/CKEditorRHF'
import { ColorPicker } from 'src/components/ColorPicker'
import InputRHF from 'src/components/RHF/InputRHF'
import {
  CampaignContext,
  contentTypeEnum,
} from 'src/context/CampaignFormContext'

export const checkHasLink = (content: string) => {
  const message = ParseCFKContent(content)
  return (
    message.includes('www.') ||
    message.includes('http://') ||
    message.includes('https://') ||
    message.includes('.com')
  )
}

export const FormHeader = ({ title, isViewMode }) => {
  const {
    formState: { control, watch, setValue },
    contentType,
  } = useContext(CampaignContext)

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        flexWrap: 'wrap',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          color: ' #FF5E1E',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '24px',
          mb: 1.5,
        }}
      >
        {t(title)}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputRHF
            disabled={disableInput()}
            control={control}
            label={t('campaign.label.input.page_title')}
            name="content.title"
            sx={{
              width: '100%',
              height: '56px',

              '& input': { color: watch('content.titleColor') },
            }}
            InputProps={{
              endAdornment: (
                <ColorPicker
                  disabled={disableInput()}
                  onColorChange={(color) => {
                    setValue('content.titleColor', color)
                  }}
                  defaultColor={watch('content.titleColor')}
                  name="content.titleColor"
                  value={watch('content.titleColor')}
                />
              ),
            }}
          />
        </Box>
        <CKEditorRHF
          disabled={disableInput()}
          key={watch('contentType')}
          label={t('campaign.label.input.description')}
          sx={{
            width: '100%',
            mt: 3,
            color: watch('content.descriptionColor'),
          }}
          name="content.description"
          control={control}
          charCounter={
            contentType === contentTypeEnum.TEXT ||
            contentType === contentTypeEnum.IMAGE
          }
          charCounterMessage={t('campaign.editor.char.couter.message')}
          onColorChange={(color) => setValue('content.descriptionColor', color)}
          defaultColor={watch('content.descriptionColor') || '#000000'}
          colorValue={watch('content.descriptionColor')}
          errorMessage={
            checkHasLink(watch('content.description'))
              ? t('editor.error.with.link')
              : null
          }
        />
      </Box>
    </Box>
  )
}
