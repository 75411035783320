import { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRouteTecnoIt = () => {
  const [user, setuser] = useState(
    localStorage.getItem('role') === 'TECNOIT' ? true : null
  )

  return user ? <Outlet /> : <Navigate to="/error401" />
}

export default PrivateRouteTecnoIt
