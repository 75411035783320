import { Enrichment } from 'src/models/campaigns-model/enrichment-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const getEnrichmentProfiles = async (options?: any) => {
  try {
    const res = await client().get(`${BASE_URL}/enrichment`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getEnrichmentProfileById = async (id: string): Promise<Enrichment | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/enrichment/${id}`)
    return res.data
  } catch (error) { }
}

export const createEnrichmentProfile = async (enrichment: Enrichment): Promise<Enrichment | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/enrichment`, enrichment)
    return res.data
  } catch (error) {
    return false
  }
}

export const deleteEnrichmentProfileById = async (id: string | number): Promise<boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/enrichment/${id}`)
    return true
  } catch (error) {
    return false
  }
}

export const recycleEnrichmentProfileById = async (id: string): Promise<Enrichment | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/enrichment/${id}/enable`, {})
    return res.data
  } catch (error) {
    return false
  }
}
