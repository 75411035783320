import { Box, Chip, Typography } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import { t } from 'i18next'
export const DefaultCampaignChip = () => {
  return (
    <Chip
      sx={{ background: '#E65015' }}
      size="small"
      label={
        <Box
          sx={{
            display: 'flex',
            gap: '6px',
            color: 'white',
            alignItems: 'center',
            px: '5px',
          }}
        >
          <StarIcon sx={{ fontSize: '14px' }} />
          <Typography
            sx={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '12px' }}
          >
            {t('chip.default.campaigin')}
          </Typography>
        </Box>
      }
    />
  )
}
