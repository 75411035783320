import { createContext, ReactNode, useState } from 'react'

const defaultState = {
  open: false,
  handleOpen: () => {},
  handleClose: () => {},
}

interface ContextProviderProps {
  children: ReactNode
}

export const ModalChangePasswordContext = createContext<any>(defaultState)

function ModalChangePasswordProvider({ children }: ContextProviderProps) {
  const [open, setOpen] = useState<boolean>(false)
  const [page, setPage] = useState<string>()

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const openModalChangePassword = (page: string) => {
    setPage(page)
    handleOpenModal()
  }

  const openModal = (route) => {
    openModalChangePassword(route)
  }

  return (
    <ModalChangePasswordContext.Provider
      value={{
        open,
        handleCloseModal,
        handleOpenModal,
        openModalChangePassword,
        openModal,
        setOpen,
      }}
    >
      {children}
    </ModalChangePasswordContext.Provider>
  )
}

export default ModalChangePasswordProvider
