import { BASE_URL } from '../constants/url'
import { DashboardData } from '../models/dashboardData'
import { client, downloadFile } from './baseService'

export const getCompany = async () => {
  try {
    const res = await client().get(`${BASE_URL}/company`)
    return res.data
  } catch (error) { }
}

export const getInfoDiario = async (
  id: string
): Promise<DashboardData | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/Dashboard/diario/${id}`)
    return res.data
  } catch (error) { }
}

export const getDataAp = async (id: string) => {
  try {
    downloadFile(`${BASE_URL}/Data/getApList/${id}`, 'ApList.csv')
  } catch (error) { }
}

export const getDataUseBand = async (id: string, idAp: number) => {
  try {
    downloadFile(
      `${BASE_URL}/Data/getPerformanceByAp/${id}/${idAp}`,
      'PerformanceByAp.csv'
    )
  } catch (error) {
    //alert(error.message);
  }
}

export const getDashboardData = async (options?: any) => {
  try {
    const res = await client().post(`${BASE_URL}/dashboard`, {}, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getDashboardReport = async (options?: any) => {
  try {
    const res = await client().post(
      `${BASE_URL}/reports/dashboard`,
      {},
      options
    )
    return res.data
  } catch (error) {
    return false
  }
}
export const getVisitorsReport = async (options?: any) => {
  try {
    const res = await client().post(
      `${BASE_URL}/reports/visitors`,
      {},
      options
    )
    return res.data
  } catch (error) {
    return false
  }
}
