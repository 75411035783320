import { Container, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { NotificationModel } from 'src/models/notification-model'
import { goToDashboard } from 'src/routes/coordinator'
import { getNotificationById, markNotificationByIdAsRead } from 'src/service/notificationService'

export function ViewNotification () {
  const history = useNavigate()
  const { id } = useParams()
  const [notification, setNotification] = useState<NotificationModel>({} as NotificationModel)

  const loadData = async () => {
    if (id) {
      const notification = await getNotificationById(id)
      if (notification && notification.readAt === null) {
        await markNotificationByIdAsRead(id)
      }
      setNotification(notification)
    } else {
      goToDashboard(history)
    }
  }

  useEffect(() => {
    loadData()
  }, [id])

  return (
    <Container
      sx={{
        mt: '96px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography variant="h4" color="primary"><b>{notification.title}</b></Typography>
      <Typography variant="caption" color="text.secondary" alignSelf='flex-start'>
        {new Date(notification.createdAt).toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: notification.content }} />
    </Container>
  )
}
