import { ChartOptions } from 'chart.js'
import { Pie } from 'react-chartjs-2'

const Colors = ['#BF3C08', '#FF5E1E', '#FF8D64', '#6F6F6F', '#1D1D1D']

const PieChart2 = ({ labels, data }) => {
  const dados = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: Colors,
        hoverBackgroundColor: Colors,
      },
    ],
  }

  const options: ChartOptions<'pie'> = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 100,
          padding: 20,
          usePointStyle: true,
          boxHeight: 10,
        },
      },
    },
  }

  return (
    <div>
      <Pie
        data={dados}
        options={options}
        style={{ width: 310, height: 200, marginTop: '-75px', }}
      />
    </div>
  )
}

export default PieChart2
