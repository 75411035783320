import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Avatar, Button, Divider, Menu, MenuItem, SxProps } from "@mui/material"
import AccountCircle from '@mui/icons-material/AccountCircle'

import { goToLogin } from '../../routes/coordinator'
import { getProfileImage, logout } from 'src/service/authService'
import { ROUTES } from "src/routes/Router"

const buttonStyles: SxProps = {
  boxShadow: 'none',
  ':hover': {
    backgroundColor: 'transparent',
  },
}

const menuItemStyle: SxProps = {
  ':hover': {
    backgroundColor: 'rgba(255, 94, 30, 0.12)',
  },
}

export const AvatarMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const history = useNavigate()
  const { t } = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    goToLogin(history)
  }

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={buttonStyles}
      >
        <Avatar src={getProfileImage()}>
          <AccountCircle sx={{ width: 45, height: 45 }} />
        </Avatar>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ boxShadow: 'none' }}
      >
        <MenuItem component={Link} to={ROUTES.PROFILE} sx={menuItemStyle}>
          {t('layout.header.button_profile')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} sx={menuItemStyle}>
          {t('layout.header.button_exit')}
        </MenuItem>
      </Menu>
    </>
  )
}
