import { useTranslation } from 'react-i18next'
import { SxProps, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { Card } from './Card'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import icon from '../../../../../assets/icons/Vector.svg'
interface Props {
  activeTotal: number | string
  inactiveTotal: number | string
  sx?: SxProps
}
export const ActictiveAndaInactive = ({
  activeTotal,
  inactiveTotal,
  sx,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        height: '200px',
        minHeight: '200px',
        flexDirection: 'column',
        py: '16px',
        px: '32px',
        ...sx,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >

        <Box sx={{ width: '50%' }}>
          <Typography
            sx={{
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.6);',
              whiteSpace: 'pre-wrap',
            }}
          >
            {t('campaign.result.card.campaign.active')}
          </Typography>
          <Typography
            sx={{
              fontSize: '34px',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            {activeTotal}
          </Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              height: '48px',
              alignItems: 'center',
            }}
          >
            <CampaignOutlinedIcon
              sx={{
                fontSize: '3rem',
                color: Number(activeTotal) <= 0 ? '#DBDBDB' : '#FF5E1E',
                mt: 2,
              }}
            />
          </Box>
        </Box>

        <Box sx={{ width: '50%' }}>
          <Typography
            sx={{
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.6);',
              whiteSpace: 'pre-wrap',
            }}
          >
            {t('campaign.result.card.campaign.inactive')}
          </Typography>
          <Typography
            sx={{
              fontSize: '34px',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            {inactiveTotal}
          </Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              height: '48px',
              alignItems: 'center',
            }}
          >
            <img
              alt="icon"
              src={icon}
              style={{ marginTop: '18px', width: '29.25px', height: '28.17px' }}
            />
          </Box>
        </Box>

      </Box>
    </Card>
  )
}
