import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'

import logoItBuzz from '../../assets/icons/ITBUZZ-MARCA.png'
import logoTecnoIt from '../../assets/icons/TECNO-IT-MARCA.png'
import ModalComponent from '.'
import InputPasswordRHF from '../RHF/InputRHFPassword'
import { confirmPasswordSchema } from 'src/pages/Login/validators/userSchemaValidator'
import { changePassword } from 'src/service/userAdminsService'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { getUserEmail } from 'src/service/authService'

interface Props {
  open?: boolean
  handleClose?: any
  setOpen?: any
}
type FormData = {
  email: string
  password: string
  newPassword: string
  passwordConfirmation: string
}

export const ModalPassword = ({ open, handleClose }: Props) => {
  const { t } = useTranslation()
  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: { password: '', newPassword: '', passwordConfirmation: '' },
    resolver: yupResolver(confirmPasswordSchema),
  })
  const { control, handleSubmit, formState } = form
  const [isOldPasswordInvalid, setIsOldPasswordInvalid] = useState(false)
  const { createAlert } = useContext(AlertContext)

  const onSubmit = async (data: FormData) => {
    const validData = {
      ...data,
      email: getUserEmail(),
    }

    changePassword(validData)
      .then((resp) => {
        if (resp.status === 204) {
          handleClose()
          createAlert(ROUTES.DASHBOARD, t('alert.info.password'), 'success')
        } else {
          setIsOldPasswordInvalid(true)
        }
      })
      .catch(() => {
        setIsOldPasswordInvalid(true)
      })
  }

  return (
    <ModalComponent open={open} hideClose={false} handleClose={handleClose}>
      <Box
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ mt: '16px', mb: '12px' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '29px',
              letterSpacing: '0.18px',
              color: '#FF5E1E',
            }}
          >
            {t('modal.password.title.one')}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '29px',
              letterSpacing: '0.18px',
              color: '#000000',
            }}
          >
            {t('modal.password.title.two')}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 2,
            ml: 1,
            gap: '20px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: '25px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              align="justify"
              sx={{
                color: '#FF5E1E',
                fontWeight: 400,
                fontSize: '16px',
                mb: '8px',
              }}
            >
              {t('modal.password.old.password')}
            </Typography>

            <InputPasswordRHF
              name="password"
              placeholder={t('modal.password.input.old.password')}
              control={control}
              isOldPasswordInvalid={isOldPasswordInvalid}
              sx={{
                width: '326px',
                height: '54px',
              }}
            />

            {isOldPasswordInvalid && (
              <Typography
                align="left"
                sx={{
                  color: ' #810000',
                  fontSize: '12px',
                  ml: 2,
                  mt: '05px',
                }}
              >
                {t('modal.password.old.error')}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              align="justify"
              sx={{
                color: '#FF5E1E',
                fontWeight: 400,
                fontSize: '16px',
                mb: '8px',
              }}
            >
              {t('modal.password.new.password')}
            </Typography>

            <InputPasswordRHF
              name="newPassword"
              placeholder={t('modal.password.input.password')}
              control={control}
              sx={{
                width: '326px',
                height: '54px',
                mb: '26px',
              }}
            />

            <InputPasswordRHF
              name="passwordConfirmation"
              placeholder={t('modal.password.input.confirm')}
              control={control}
              sx={{
                width: '326px',
                height: '54px',
              }}
            />
          </Box>
        </Box>
        <Button
          type="submit"
          disabled={!formState.isValid}
          variant={'outlined'}
          sx={{
            minWidth: '328px',
            height: '40px',
            mb: '10px',
            ':hover': {
              backgroundColor: '#FF5E1E',
              color: '#FFFFFF',
            },
          }}
        >
          {t('modal.password.button')}
        </Button>
        <Divider sx={{ width: '336px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 5,
            mt: '10px',
            mb: '10px',
            mr: '74px',
          }}
        >
          <img alt="logo" src={logoItBuzz} style={{ width: '82.56px' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
              mt: '6.5px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '10px',
                lineHeight: ' 16px',
                letterSpacing: '1.5px',
                color: 'rgba(0, 0, 0, 0.6)',
                mt: '6px',
              }}
            >
              {t('login.image.title.per')}
            </Typography>
            <img
              alt="logo"
              src={logoTecnoIt}
              style={{ width: '93.5px', height: '24px' }}
            />
          </Box>
        </Box>
      </Box>
    </ModalComponent>
  )
}
