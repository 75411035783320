import * as yup from 'yup'
import { Answer } from '../../../models/campaigns-model/answer-model'

export const answerDefaultValues: Answer = {
  id: '',
  name: '',
}

export const answerSchemaValidation = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required('required'),
})
