import { useContext } from 'react'
import { Box } from '@mui/system'
import {
  CampaignContext,
  contentTypeEnum,
} from 'src/context/CampaignFormContext'
import { ContentType } from './ContentType'
import { ImageForm } from './ImageForm'
import { MediaForm } from './MediaForm'
import { SearchForm } from './SearchForm.tsx'
import { TextForm } from './TextForm'

interface Props {
  isViewMode?: boolean
}

export const ContentForm = ({ isViewMode }: Props) => {
  const { contentType } = useContext(CampaignContext)
  if (!contentType) return <ContentType isView={isViewMode} />

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  return (
    <Box>
      {contentType === contentTypeEnum.IMAGE && (
        <ImageForm isViewMode={isViewMode} />
      )}
      {contentType === contentTypeEnum.TEXT && (
        <TextForm isViewMode={isViewMode} disabled={disableInput()} />
      )}
      {contentType === contentTypeEnum.MEDIA && (
        <MediaForm isViewMode={isViewMode} />
      )}
      {contentType === contentTypeEnum.SEARCH && (
        <SearchForm isViewMode={isViewMode} disabled={disableInput()} />
      )}
    </Box>
  )
}
