import { Box, Skeleton } from '@mui/material'

export function VideoListingSkeleton () {
  return (
    <>
      {
        Array.from({ length: 5 }).map((_, index) => {
          return (
            <Box key={index} sx={{ display: 'flex', mb: '10px' }}>
              <Skeleton variant="rectangular" width="128px" height="74px" />
              <div style={{ marginLeft: '6px', width: '50%', minWidth: '100px' }}>
                <Skeleton variant="text" height="24px" width="100%" />
                <Skeleton variant="text" height="14px" width="100%" />
                <Skeleton variant="text" height="14px" width="100%" />
              </div>
            </Box>
          )
        })
      }
    </>
  )
}
