import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { DoughnutChartColors } from './DoughnutChart'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  indexAxis: 'y' as const,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: true,
        borderColor: 'white',
        tickColor: 'white',
      },
      ticks: {
        callback: function (value, index, values) {
          const labels = ['0%', '20%', '40%', '60%', '80%', '100%']
          return labels[index]
        },
        min: 100,
        max: 100,
        stepSize: 310,
        display: true,
        color: '#00000099',
        font: {
          size: 12,
          family: 'Roboto',
        },
      },
    },
    y: {
      grid: {
        display: false,
        borderColor: '#0000001F',
      },
      ticks: {
        display: true,
        color: '#00000099',
        font: {
          size: 12,
          family: 'Roboto',
        },
      },
    },
  },
}

interface Props {
  labels: string[]
  data: number[]
}

export default function HorizontalChart({ labels, data }: Props) {
  const completeData = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: DoughnutChartColors,
        categoryPercentage: 0.35,
        minBarLength: 10,
      },
    ],
  }
  return (
    <div>
      <Bar options={options} data={completeData} style={{ marginLeft: '-40px'}} />
    </div>
  )
}
