export const UploadIcon = () => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0001 17.5013V21.0013H7.00008V17.5013H4.66675V21.0013C4.66675 22.2846 5.71675 23.3346 7.00008 23.3346H21.0001C22.2834 23.3346 23.3334 22.2846 23.3334 21.0013V17.5013H21.0001ZM8.16675 10.5013L9.81175 12.1463L12.8334 9.1363V18.668H15.1667V9.1363L18.1884 12.1463L19.8334 10.5013L14.0001 4.66797L8.16675 10.5013Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  )
}
