import { BASE_URL } from 'src/constants/url'
import { client } from './baseService'

export const uploadFileImg = async (file: any): Promise<any | undefined> => {
  try {
    var formData = new FormData()
    formData.append('file', file)
    return client().post(`${BASE_URL}/file/image`, formData)
  } catch (error) {
    return false
  }
}

export const uploadFileVideo = async (file: any, params?: any): Promise<any | undefined> => {
  try {
    var formData = new FormData()
    formData.append('file', file)
    return client().post(`${BASE_URL}/file/video`, formData, params)
  } catch (error) {
    return false
  }
}

export const deleteUploadById = async (
  id: string | number
): Promise<boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/file/${id}`)
    return true
  } catch (error) {
    return false
  }
}
