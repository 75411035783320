import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import { validateCep, validateCNPJ } from 'validations-br'

import { Company } from '../../../models/company-model'

export const MSG_REQUIRED_FIELD = 'O campo é obrigatório.'

export const yupZipCodeValidate = (value: string | undefined) => {
  if (value && value.replace(/\D/g, '').length === 8) {
    return validateCep(value)
  }
  return value === null || value === ''
}

const yupCNPJValidate = (value: string | undefined) => {
  if (value && value.replace(/\D/g, '').length === 14) {
    return validateCNPJ(value)
  }
  return value === null || value === ''
}

export const companyDefaultValues: Company = {
  id: '',
  companyName: '',
  isActive: true,
  contractNumber: '',
  managerName: '',
  cnpj: '',
  companyAddress: '',
  cep: '',
  companyState: '',
  companyCity: '',
  companyLogo: '',
  companyLogoSmall: '',
  urlAgent: '',
  urlController: '',
  controllerManufacturer: '',
}

const mode: keyof ValidationMode = 'all'
export const companySchemaValidation = yup.object({
  id: yup.string(),
  companyName: yup.string().required('required'),
  managerName: yup.string().required('required'),
  isActive: yup.boolean(),
  contractNumber: yup.string().required('required').typeError('invalid'),
  cnpj: yup
    .string()
    .required('required')
    .test('cnpj', 'invalid', yupCNPJValidate),

  companyAddress: yup.string().required('required'),
  cep: yup
    .string()
    .required('required')
    .test('cep', 'invalid', yupZipCodeValidate),
  companyState: yup.string().required('required'),
  companyCity: yup.string().required('required'),
})

export const companyUseFormArgs = {
  mode,
  defaultValues: companyDefaultValues,
  resolver: yupResolver(companySchemaValidation),
}

export interface CompanyTypes
  extends yup.InferType<typeof companySchemaValidation> {}
