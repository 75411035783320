import { Chart as ChartJS, ArcElement, PointElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, PointElement, Tooltip)
interface Props {
  labels: string[]
  data: number[]
  total: number
}

export const DoughnutChartColors = [
  '#BF3C08',
  '#FF5E1E',
  '#FF8D64',
  '#1D1D1D',
  '#6F6F6F',
  '#DBDBDB',
]
export default function DoughnutChart({ labels, data, total }: Props) {
  return (
    <div style={{ width: '100%', height: '110px' }}>
      <Doughnut
        width={'200px'}
        key={total}
        data={{
          datasets: [
            {
              data,
              label: labels as any,
              backgroundColor: total <= 0 ? '#DBDBDB' : DoughnutChartColors,
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          cutout: 45,
          plugins: {
            legend: {
              display: false,
              position: 'top',
              align: 'center',
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx) => {
                  return `${labels[ctx.dataIndex]?.slice(0, 12) || '?'}: ${
                    data[ctx.dataIndex]
                  }`
                },
              },
            },
          },
        }}
        plugins={[
          {
            id: 'texttotal',
            beforeDraw: function (chart) {
              var width = chart.width,
                height = chart.height,
                ctx = chart.ctx
              ctx.restore()
              ctx.canvas.style.fontSize = '22px'
              var fontSize = (height / 114).toFixed(2)
              ctx.font = fontSize + 'em sans-serif'
              ctx.textBaseline = 'middle'
              var text = total,
                textX = Math.round(
                  (width - ctx.measureText(text as any).width) / 2
                ),
                textY = height / 2

              ctx.fillText(text as any, textX, textY)
              ctx.save()
            },
          },
        ]}
      />
    </div>
  )
}
