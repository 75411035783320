import { ChangeEvent, FocusEvent } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IconButton, InputAdornment, Radio } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import TextField from '@mui/material/TextField'

import { InputRHFProps } from '../../models/inputRHF-model'

export default function InputRadioRHF(props: InputRHFProps) {
  const { t } = useTranslation()
  const {
    required,
    name,
    control,
    children,
    disabled,
    onBlurAction,
    onChangeAction,
    label,
    removeAnswer,
    disabledRemoveAnswer,
    hideDelete,
  } = props
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name, control })
  const { ref, onBlur, onChange } = field

  const onBlurPropagator = (event: FocusEvent<HTMLInputElement>) => {
    onBlur()
    onBlurAction && onBlurAction(event)
  }

  const onChangePropagator = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(event.target.value)
    onChangeAction && onChangeAction(event.target.value)
  }

  return (
    <TextField
      {...field}
      sx={{
        height: '56px',
        '.MuiFormHelperText-root.Mui-error': {
          m: 0,
        },
        ...props?.sx,
      }}
      disabled={disabled}
      onBlur={onBlurPropagator}
      onChange={onChangePropagator}
      inputRef={ref}
      autoComplete={'off'}
      error={invalid}
      helperText={t(error?.message)}
      required={Boolean(required)}
      placeholder={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Radio disabled={true} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              sx={{
                display: hideDelete ? 'flex' : 'none',
                ':hover': {
                  color: '#FF5E1E',
                },
              }}
              disabled={disabledRemoveAnswer}
              onClick={removeAnswer}
            >
              <DeleteForeverIcon sx={{ width: '20px' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    >
      {children}
    </TextField>
  )
}
