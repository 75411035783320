import { useContext, useState } from 'react'
import ReactPlayer from 'react-player'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { PreviewTemplate } from 'src/components/Preview/PreviewTemplate'
import { CampaignContext } from 'src/context/CampaignFormContext'
export const MediaCampaignPreview = () => {
  const {
    formState: { watch },
  } = useContext(CampaignContext)
  const [config, setConfig] = useState<any>()
  return (
    <PreviewTemplate onLoadConfig={(config) => setConfig(config)}>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          p: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '16px',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            color: watch('content.titleColor'),
            fontWeight: 400,
            lineHeight: '29px',
          }}
        >
          {watch('content.title') || ''}
        </Typography>
        <Typography
          sx={{ color: watch('content.descriptionColor'), fontSize: '14px' }}
        >
          {new DOMParser().parseFromString(
            watch('content.description') || '',
            'text/html'
          )?.body?.textContent || ''}
        </Typography>
        <ReactPlayer
          playsinline={true}
          url={watch('content.mediaURL')}
          controls
          playing={true}
          volume={0.75}
          width="100%"
          height="232px"
        />
      </Box>
    </PreviewTemplate>
  )
}
