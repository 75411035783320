import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Typography } from '@mui/material'
import DomainIcon from '@mui/icons-material/Domain'
import AddIcon from '@mui/icons-material/Add'

import { goToCreateCompany } from '../../routes/coordinator'
import useProtectedPage from 'src/hooks/useProtectedPage'
import CompanyTable from './components/Table/CompanyTable'
import ActivityHistory from '../../components/SideMenuAudit/ActivityHistory'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { ModalCreatedCompany } from './components/Form/ModalCreatedCompany'
import { getPermissions } from 'src/service/authService'

const CompanyList = () => {
  useProtectedPage()
  const { t } = useTranslation()
  const history = useNavigate()
  const permissions = JSON.parse(getPermissions())

  return (
    <Container
      sx={{
        mt: '96px',
      }}
    >
      <AlertComponent />
      <ModalCreatedCompany />

      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <DomainIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: ' #FF5E1E',
              marginTop: '-1px',
            }}
          >
            {t('company.title')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {
            permissions?.auditory?.includes('auditory:list') && <ActivityHistory path={'empresas'} keySubtitle={'companyName'} />
          }
        </Box>
      </Box>

      <Button
        onClick={() => goToCreateCompany(history)}
        disabled={!permissions?.company?.includes("company:create")}
        variant="outlined"
        sx={{
          gap: '6px',
          width: '50%',
          height: '36px',
          mb: '40px',
          ':hover': {
            backgroundColor: '#FF5E1E',
            color: '#ffff',
          },
        }}
      >
        <AddIcon sx={{ width: '24px', height: '24px' }} />
        {t('company.button.create')}
      </Button>

      <CompanyTable />
    </Container>
  )
}
export default CompanyList
