import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Tooltip } from '@mui/material'
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import InputRHF from '../../../../components/RHF/InputRHF'
import { goToPoints } from '../../../../routes/coordinator'
import {
  pointDefaultValues,
  pointSchemaValidation,
} from '../../validators/pointSchemaValidators'
import SwitchRHF from 'src/components/RHF/SwitchRHF'
import ClicableMap from '../../../../components/Map/ClicabeMap'
import AutocompleteMap from '../Map/AutocompleteMap'
import { Point } from 'src/models/point-model'
import { MapContext } from 'src/context/MapContext'
import { CompanySelect } from 'src/components/CompanySelect/CompanySelect'
import { Company } from 'src/models/company-model'
import { getCompanyId, getPermissions, getRole } from 'src/service/authService'
import { FooterBottons } from 'src/components/FormUtils/FooterButtons'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'

interface PointFormProps {
  data: Point
  onSubmit(data: any): void
  isViewMode?: boolean
}
export const PointForm = ({ data, onSubmit, isViewMode }: PointFormProps) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { setLatLng, latLng, place, resetLatLng } = useContext(MapContext)
  const { isGlobalAdminSelected, selectedCompanyData } = useGlobalSelectedCompany()
  const [mac, setMac] = useState<any>('')
  const [ip, setIp] = useState<any>('')
  const [defaultPoint, setDefaultPoint] = useState<any>(null)
  const permissions = JSON.parse(getPermissions())

  const hasArchiveUpdatePermission = permissions?.accesspoint.includes('accesspoint:archive:update')

  const onChangeApList = () => {
    setLatLng(new google.maps.LatLng(666, 0))
  }

  useEffect(() => {
    if (latLng.lat() === 90) {
      setLatLng(
        new google.maps.LatLng(
          getValues('geoLatitude'),
          getValues('geoLongitude')
        )
      )
    }
  }, [latLng])

  const removeData = () => {
    resetLatLng()
  }

  const form = useForm<any>({
    mode: 'all',
    defaultValues: pointDefaultValues,
    resolver: yupResolver(pointSchemaValidation),
  })
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState,
    setValue,
    trigger,
    watch,
  } = form

  useEffect(() => {
    if (data) {
      setLatLng(new google.maps.LatLng(data?.geoLatitude, data?.geoLongitude))
      reset(data)
      if (getRole() !== 'TECNOIT') {
        setValue('companyId', {
          companyId: getCompanyId(),
          companyName: data?.company?.companyName,
        })
      }
      setValue('companyId', {
        companyId: data.companyId,
        companyName: data?.company?.companyName,
      })
      setIp(data?.ipAddress)
      setMac(data?.macAddress)
      setValue('ipAddress', data?.ipAddress)
      setValue('macAddress', data?.macAddress)
    }
  }, [data])

  useEffect(() => {
    if (getRole() !== 'TECNOIT') {
      setValue('companyId', {
        companyId: getCompanyId(),
        companyName: data?.company?.companyName,
      })
    }
  }, [])

  useEffect(() => {
    if (!isGlobalAdminSelected()) {
      onChangeCompany(selectedCompanyData as Company)
    }
    if(!isGlobalAdminSelected && !selectedCompanyData) {
      setValue('companyId', undefined)
    }
  }, [selectedCompanyData])

  useEffect(() => {
    setValue('geoLatitude', latLng.lat() || '')
    setValue('geoLongitude', latLng.lng() || '')
    if (latLng.lat()) {
      trigger('geoLongitude')
      trigger('geoLatitude')
    }
  }, [latLng])

  useEffect(() => {
    setValue('geoAddress', place?.description)
    trigger('geoAddress')
  }, [place])

  const formatMACAddress = (value) => {
    const cleanedValue = value.replace(/[^0-9a-fA-F]/g, '').slice(0, 12)
    const formattedValue = cleanedValue
      .replace(/(.{2})/g, '$1:')
      .slice(0, 17)
      .replace(/:$/, '')
    return formattedValue
  }

  const onChangeCompany = (company: Company) => {
    setValue('companyId', company)
  }

  const resetForm = () => {
    setMac('')
    setIp('')
    setValue('ipAddress', ip.replace(/[^0-9a-zA-Z]/g, ''))
    setValue('macAddress', mac.replace(/[^0-9a-fA-F]/g, ''))
    resetLatLng()
    reset({ apName: '' })
    setValue('companyId', {})
  }

  const onChangeLatLng = () => {
    if (getValues('geoLatitude') && getValues('geoLongitude')) {
      setLatLng(
        new google.maps.LatLng(
          getValues('geoLatitude'),
          getValues('geoLongitude')
        )
      )
    }
  }

  const isAddressValid =
    watch('geoAddress') && watch('geoLatitude') && watch('geoLongitude')

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  const formIsValid = () =>
    Boolean(
      watch('companyId') &&
        watch('apName') &&
        watch('ipAddress') &&
        watch('macAddress') &&
        watch('geoAddress') &&
        watch('geoLongitude')
    )

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" sx={{ mb: 2 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        {getRole() === 'TECNOIT' && (
          <CompanySelect
            sx={{
              gridColumn: 'span 12',
            }}
            disabled={disableInput()}
            control={control}
            onChangeAction={onChangeCompany}
          />
        )}
        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('point.form.input.label.name')}
          placeholder={t('point.form.input.placeholder.name')}
          name="apName"
          sx={{
            gridColumn: hasArchiveUpdatePermission ? 'span 10' : 'span 11',
          }}
        />
        {
          hasArchiveUpdatePermission &&
          <SwitchRHF
            disabled={disableInput()}
            name='isArchived'
            control={control}
            onChangeAction={() => {
              trigger && trigger('isArchived')
              setValue && setValue('isArchived', '')
            }}
            labelNameOn='form.input.label.archived.o'
            labelNameOff='form.input.label.published.o'
            sx={{
              gridColumn: 'span 1',
            }}
          />
        }
        <SwitchRHF
          sx={{
            gridColumn: 'span 1',
          }}
          disabled={disableInput()}
          name="isActive"
          control={control}
          onChangeAction={() => {
            trigger && trigger('isActive')
            setValue && setValue('isActive', '')
          }}
        />

        <InputRHF
          disabled={disableInput()}
          control={control}
          label="IPv4/IPv6"
          value={ip}
          onChange={(e) => {
            const value = e.target.value
            const limita = value.slice(0, 39)
            setIp(limita)
            setValue('ipAddress', limita)
          }}
          placeholder={t('point.form.input.placeholder.ip')}
          name="ipAddress"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 6',
              md: 'span 6',
              lg: 'span 6',
            },
          }}
        />

        <InputRHF
          disabled={disableInput()}
          control={control}
          label="MAC ADDRESS"
          name="macAddress"
          value={formatMACAddress(mac)}
          onChange={(e) => {
            const inputValue = e.target.value
            const filteredValue = inputValue
              .replace(/[^0-9a-fA-F]/g, '')
              .slice(0, 12)
            setMac(filteredValue)
            setValue('macAddress', filteredValue)
          }}
          placeholder={t('point.form.input.placeholder.macaddress')}
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 6',
              md: 'span 6',
              lg: 'span 6',
            },
          }}
        />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridColumn: 'span 12',
          }}
        >
          <AutocompleteMap
            sx={{
              mt: 1,
              gridColumn: {
                xs: 'span 10',
                sm: 'span 5',
                md: 'span 5',
                lg: 'span 5',
              },
            }}
            name="geoAddress"
            disabled={disableInput()}
          />
          <InputRHF
            disabled={disableInput()}
            control={control}
            label={t('point.form.input.label.latitude')}
            name="geoLatitude"
            sx={{
              gridColumn: {
                xs: 'span 10',
                sm: 'span 3',
                md: 'span 3',
                lg: 'span 3',
              },
            }}
            onBlurAction={onChangeLatLng}
          />
          <InputRHF
            disabled={disableInput()}
            control={control}
            label={t('point.form.input.label.longitude')}
            name="geoLongitude"
            sx={{
              gridColumn: {
                xs: 'span 10',
                sm: 'span 3',
                md: 'span 3',
                lg: 'span 3',
              },
            }}
            onBlurAction={onChangeLatLng}
          />
          {isAddressValid && (
            <Box sx={{ gridColumn: 'span 1', mt: 2, ml: 2 }}>
              <Tooltip title={t('pointGroups.button.toLocate')}>
                <Button
                  disabled={disableInput()}
                  sx={{
                    minWidth: '25px',
                    color: '#00000099',
                    ':hover': {
                      color: ' #FF5E1E',
                    },
                  }}
                  onClick={onChangeApList}
                >
                  <GpsFixedRoundedIcon sx={{ width: '20px' }} />
                </Button>
              </Tooltip>

              <Tooltip title={t('table.button.delete')}>
                <Button
                  disabled={disableInput()}
                  sx={{
                    minWidth: '25px',
                    color: '#00000099',
                    ':hover': {
                      color: ' #FF5E1E',
                    },
                  }}
                  onClick={removeData}
                >
                  <DeleteForeverIcon sx={{ width: '20px' }} />
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            gridColumn: 'span 12',
            mt: 1,
          }}
        >
          <ClicableMap defaultPoint={defaultPoint} isViewMode={isViewMode} />
        </Box>
      </Box>
      {!isViewMode && (
        <FooterBottons
          isValid={!formState.isValid}
          formIsValid={formState.isValid}
          isEdit={Boolean(id)}
          goTo={goToPoints}
          reset={() => resetForm()}
          size="100%"
          buttonName="point.button.create"
        />
      )}
    </Box>
  )
}
