import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import ErrorIcon from '@mui/icons-material/Error'

const style = {
  boxSizing: ' border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  minHeight: '350px',
  heigth: 'auto',
  width: '650px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: 24,
  p: '16px 16px 24px 16px',
  borderRadius: '4px',
  gap: '24px',
}

export interface ConfirmCreationModalProps {
  open: boolean
  onHandleCloseAndAccept(): void
  onHandleCloseAndReject(): void
}

export default function ConfirmCreationModal(props: ConfirmCreationModalProps) {
  const { t } = useTranslation()
  return (
    <Modal
      open={props.open}
      onClose={props.onHandleCloseAndReject}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            alignSelf: 'flex-end',
            width: 'auto',
          }}
        >
          <IconButton
            onClick={props.onHandleCloseAndReject}
            size={'small'}
            sx={{
              left: '1px',
              color: '#00000099',
              ':hover': {
                color: '#FF5E1E',
                backgroundColor: '#FF5E1E14',
              },
            }}
          >
            <ClearIcon sx={{ height: '16px', width: '16px', left: '1px' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ErrorIcon sx={{ color: '#FF5E1E', mb: '8px' }} />

          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '29px',
              letterSpacing: '0.18px',
              color: '#FF5E1E',
              textAlign: 'center',
            }}
          >
            {t('enrichment.create.modal.title')}
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center',
              }}
            >
              {t('enrichment.create.modal.description')}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={props.onHandleCloseAndReject}
            variant="outlined"
            sx={{
              minWidth: '163px',
              height: '40px',
              marginRight: '16px',
              gap: 1,
              color: '#00000099',
              border: '1px solid #00000099',
              ':hover': {
                border: '1px solid #00000099',
                backgroundColor: '#00000099',
                color: '#ffff',
              },
            }}
          >
            {t('enrichment.create.modal.reject')}
          </Button>
          <Button
            onClick={props.onHandleCloseAndAccept}
            variant="outlined"
            sx={{
              minWidth: '163px',
              height: '40px',
              gap: 1,
              color: '#FF5E1E',
              border: '1px solid #FF5E1E',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
            }}
          >
            {t('enrichment.create.modal.accept')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
