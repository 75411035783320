import { Box } from '@mui/system'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import { IconButton } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { DashboardViewContext } from 'src/context/DashboardViewContext'

export const ToggableCard = (props: any) => {
  const [toggleOn, setToggleOn] = useState(true)
  const { isEditMode, subscribeResult, shouldShow, getEyeState } =
    useContext(DashboardViewContext)

  useEffect(() => {
    setToggleOn(getEyeState(props?.viewkey))
  }, [isEditMode])

  return (
    shouldShow(props?.viewkey) && (
      <Box
        {...props}
        sx={{
          position: 'relative',
          background:
            isEditMode && toggleOn
              ? 'linear-gradient(0deg, rgba(255, 94, 30, 0.12), rgba(255, 94, 30, 0.12)), #FFFFFF;'
              : '',
          border:
            isEditMode && toggleOn
              ? 'solid 1px #FF5E1E'
              : 'solid 1px transparent',
          borderRadius: '4px',
          ...props?.sx,
        }}
      >
        {!toggleOn && (
          <Box
            sx={{
              position: 'absolute',
              minWidth: '100%',
              minHeight: '100%',
              bgcolor: 'rgba(255, 255, 255, 0.6)',
            }}
          />
        )}
        {isEditMode && (
          <IconButton
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: toggleOn ? '#FF5E1E' : 'rgba(0, 0, 0, 0.6)',
              zIndex: 10,
            }}
            onClick={() => {
              props?.viewkey && subscribeResult(props?.viewkey, !toggleOn)
              setToggleOn(!toggleOn)
            }}
          >
            {!toggleOn ? (
              <VisibilityOffRoundedIcon />
            ) : (
              <RemoveRedEyeOutlinedIcon />
            )}
          </IconButton>
        )}
        {props?.children}
      </Box>
    )
  )
}
