import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import ModalComponent from '.'

const iconMap = {
  warning: null,
  success: <CheckCircleIcon sx={{ color: '#FF5E1E' }} />,
  error: <ErrorIcon sx={{ color: '#FF5E1E' }} />,
  none: '',
  info: <ErrorIcon sx={{ color: '#FF5E1E', width: '32px', height: '32px' }} />,
}
interface Props {
  open: boolean
  handleClose(): void
  children?: any
  title: string
  subtitle: string
  type: 'warning' | 'success' | 'error' | 'none' | 'info'
}

export const NoticeModal = ({
  open,
  handleClose,
  children,
  title,
  subtitle,
  type,
}: Props) => {
  const { t } = useTranslation()
  return (
    <ModalComponent open={open} handleClose={handleClose} hideClose={true}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        {type !== 'none' && iconMap[type]}
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '29px',
            letterSpacing: '0.18px',
            color: '#FF5E1E',
            textAlign: 'center',
            my: 1,
            width: type === 'info' ? '336px' : 'none',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)',
            textAlign: 'center',
            width: '90%',
            my: 1,
          }}
        >
          {subtitle}
        </Typography>
        {children}
        <Button
          onClick={handleClose}
          sx={{
            fontSize: '12px',
            p: '10px 20px',
            border: '1px solid #00000099',
            display: 'block',
            color: 'rgba(0, 0, 0, 0.6)',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '1.25px',
            textTransform: 'uppercase',
            borderRadius: '4px',
            mt: '30px',
            width: '100%',
            ':hover': {
              border: '1px solid #00000099',
              backgroundColor: '#00000099',
              color: '#ffff',
            },
          }}
        >
          {t('common.close')}
        </Button>
      </Box>
    </ModalComponent>
  )
}
