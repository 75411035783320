import ApartmentIcon from '@mui/icons-material/Apartment'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna'
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import html2canvas from 'html2canvas'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanySelect } from 'src/components/CompanySelect/CompanySelect'
import { Dropdown } from 'src/components/DropDown/DropDown'
import { GroupSelect } from 'src/components/GroupSelect/GroupSelect'
import { PointSearch } from 'src/components/PointSearch/PointSearch'
import { StyledTooltip } from 'src/components/Tooltip/StyledTooltip'
import { getRole } from 'src/service/authService'
import { ReactComponent as WorldIcon } from '../../assets/icons/ICON-WORLD-VISION-02.svg'

const HeatMapHeader = ({ onHeatMapChange, form, mapRef, onCenter }) => {
  const [isHeatMap, setIsHeatMap] = useState(true)
  const { t } = useTranslation()
  const { control, watch, getValues, setValue } = form
  const isTecnoit = getRole() === 'TECNOIT'
  const hasSelectedPoint = Boolean(watch('point')?.apName)
  const hasSelectedGroup = Boolean(watch('groups')?.length)

  const exportComponentAsImage = () => {
    html2canvas(mapRef.current, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const a = document.createElement('a')
      a.setAttribute('href', imgData)
      a.setAttribute('download', t('heatMap.export.filename'))
      a.click()
    })
  }

  useEffect(() => {
    onHeatMapChange(isHeatMap)
  }, [isHeatMap])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '1200px',
        width: '100%',
        justifyContent: 'center',
        gap: '20px',
        pr: 1,
        mt: '6px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#FF5E1E',
        }}
      >
        <MapOutlinedIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            ml: '8px',
            mb: '-3px',
          }}
        >
          {t('heatMap.title')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: '34px' }}>
          {t('heatMap.header.filterDate')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <StyledTooltip content={t('heatMap.header.activeColor.tip')}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: '4px 0px 0px 4px',
                minWidth: '48px',
                width: '48px',
                height: '48px',
                color: isHeatMap ? "'#FF5E1E'" : 'rgba(0, 0, 0, 0.6)',
                borderColor: isHeatMap ? "'#FF5E1E'" : 'rgba(0, 0, 0, 0.12)',
                backgroundColor: isHeatMap ? 'rgba(255, 94, 30, 0.04);' : '',
                ':hover': {
                  color: '#FF5E1E',
                },
              }}
              onClick={() => {
                setIsHeatMap(!isHeatMap)
              }}
            >
              <WorldIcon />
            </Button>
          </StyledTooltip>
          <Dropdown
            icon={<FileDownloadOutlinedIcon />}
            title={t('heatMap.header.exportMap.tip')}
            itens={[
              {
                title: t('heatMap.header.exportMap.png'),
                onClick: exportComponentAsImage,
              },
            ]}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        {isTecnoit && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <ApartmentIcon sx={{ color: '#00000099' }} />
            <CompanySelect control={control} sx={{ width: '100%' }} />
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <SettingsInputAntennaIcon
            sx={{ color: hasSelectedGroup ? '#00000061' : '#00000099' }}
          />
          <Box sx={{ width: '97%', my: 2, zIndex: '0' }}>
            <PointSearch
              watch={watch}
              control={control}
              name={'point'}
              onCenterMap={onCenter}
              onClean={() => {
                setValue('point', {})
              }}
              disabled={hasSelectedGroup}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 , mt: "12px", mb: 1}}>
          <WorkspacesOutlinedIcon
            sx={{ color: hasSelectedPoint ? '#00000061' : '#00000099' }}
          />
          <GroupSelect
            control={control}
            companyId={watch('companyId')?.id}
            sx={{ width: '100%' }}
            label={t('heatMap.header.findByGroups')}
            disabled={hasSelectedPoint}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default HeatMapHeader
