import { Box, Container, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Shield } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { AlertContext } from 'src/context/AlertContext'
import MapProvider from 'src/context/MapContext'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { Role } from 'src/models/role-model'
import { ROUTES } from 'src/routes/Router'
import { goToRoles } from 'src/routes/coordinator'
import {
  createRole,
  editRole,
  getRoleById,
} from 'src/service/roleService'
import { RoleForm } from './components/Form/RoleForm'
interface Props {
  isViewMode?: boolean
  viewId?: any
}

const CreateRole = ({ isViewMode, viewId }: Props) => {
  useProtectedPage()
  const { t } = useTranslation()
  const { id } = useParams()
  const [role, setRole] = useState<Role>()
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)

  const getRole = async () => {
    if (id || isViewMode) {
      const role = await getRoleById(id || viewId)
      setRole(role)
    }
  }

  const onSubmit = async (data) => {
    if (data?.id) {
      const {
        id,
        companyId,
        roleName,
        isTecnoitRole,
        permissions,
        company,
      } = data
      const res = await editRole(id, {
        roleName,
        isTecnoitRole,
        permissions,
        company,
      })
      if (res) {
        createCRUDAlert(ROUTES.ROLE, data.roleName, 'edited')
        goToRoles(history)
      }
      return
    }
    const res = await createRole({
      ...data,
      companyId: data.companyId?.id ?? null,
    })
    if (res) {
      createCRUDAlert(ROUTES.ROLE, data.roleName, 'created')
      goToRoles(history)
    }
  }

  useEffect(() => {
    getRole()
  }, [])

  return (
    <MapProvider>
      <Container
        sx={{
          mt: isViewMode ? '' : '96px',
          '.gm-style': {
            div: {
              cursor: 'default',
              ':active': {
                cursor: 'grabbing',
              },
            },
          },
        }}
      >
        <AlertComponent isView={isViewMode} />

        <Box
          component="div"
          sx={{
            color: '#FF5E1E',
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mb: isViewMode ? '10px' : '24px',
          }}
        >
          <Shield />
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: ' #FF5E1E',
            }}
          >
            {t('role.title')}
          </Typography>
        </Box>

        <RoleForm data={role} onSubmit={onSubmit} isViewMode={isViewMode} />
      </Container>
    </MapProvider>
  )
}
export default CreateRole
