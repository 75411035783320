import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { Card } from './Card'

interface Props {
  total: string | number
  icon: JSX.Element
  text: string
  sx?: any
}
export const Visualization = ({ total, icon, text, sx }: Props) => {
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        p: '16px',
        px: '31px',
        ...sx
      }}
    >
      <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6);' }}>
        {t(text)}
      </Typography>
      <Typography
        sx={{
          fontSize: '34px',
          color: 'rgba(0, 0, 0, 0.87)',
          mt: 1,
        }}
      >
        {total}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          color: Number(total) <= 0 ? '#DBDBDB' : '#FF5E1E',
        }}
      >
        {icon}
      </Box>
    </Card>
  )
}
