import { useEffect, useState } from 'react'

interface Props {
  time: number | string
  onEnd: () => void
}
export const TimeDecreaser = ({ time, onEnd }: Props) => {
  const [value, setValue] = useState(undefined)
  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  useEffect(() => setValue(Number(time)), [])

  useEffect(() => {
    if (value > 0) {
      delay(1000).then(() => {
        if (value - 1 === 0 || value === 0) {
          onEnd()
          return
        }
        setValue(value - 1)
      })
    } else if (value !== undefined) {
      onEnd()
    }
  }, [value])

  return <>{value}</>
}
