import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Card } from './Card'
import { ToggableCard } from './ToggableCard'
import { useTranslation } from 'react-i18next'

type ConnectionInfo = {
  name: string
  value: string | number
  index?: number
}
interface Props {
  data: ConnectionInfo[] 
}

export const AccessRanking = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <ToggableCard viewkey="accessranking">
      <Card
        sx={{
          minWidth: '460px',
          minHeight: '424px',
          width: '222px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            minWidth: '460px',
            minHeight: '424px',
            width: '222px',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              width: '100%',
              minHeight: '50px',
              borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
              py: '14px',
              px: '32px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.6);',
                whiteSpace: 'pre-wrap',
              }}
            >
              {t('painel.accessranking')}
            </Typography>
          </Box>
          <Box sx={{ width: '100%', py: '16px', px: '32px' }}>
            {data &&
              data.map(({ name, value }, index) =>
                index < 10 ? (
                  <Row
                    key={`${name}-${value}`}
                    name={name}
                    value={value}
                    index={index + 1}
                  />
                ) : null
              )}
          </Box>
        </Box>
      </Card>
    </ToggableCard>
  )
}

const Row = ({ name, value, index }: ConnectionInfo) => {
  const { t } = useTranslation()
  const isPlural = value !== 1
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        mb: '16px',
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6);',
          fontWeight: 500,
          width: '80px',
        }}
      >
        {index}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6);',
          fontWeight: 500,
          width: '150px',
          mr: '20px',
        }}
      >
        {name.slice(0, 20)}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6);',
          fontWeight: 500,
          width: '150px',
        }}
      >
        {isPlural
          ? `${value} ${t('painel.connections.label')}`
          : `${value} ${t('painel.connection.label')}`}
      </Typography>
    </Box>
  )
}
