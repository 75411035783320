import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColumns } from '@mui/x-data-grid'
import { Chip } from '@mui/material'
import { goToEditRole } from 'src/routes/coordinator'
import {
  deleteRoleById,
  editRole,
  getRoles,
} from 'src/service/roleService'
import { getPermissions } from 'src/service/authService'
import { Table } from 'src/components/Table/Table'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { NumberSort, StringSort } from 'src/components/Table/SortingFunctions'
import CreateRole from '../../CreateRole'

export default function RoleTable() {
  const { t } = useTranslation()
  const { createCRUDAlert, createAlert } = useContext(AlertContext)
  const [tableKey, setTableKey] = useState(0)
  const fileSelectRef = useRef(null)
  const permissions = JSON.parse(getPermissions())

  const getData = async (options) => {
    const data = await getRoles(options)
    return {
      data: data?.listRoles,
      totalPages: data?.page,
      size: data?.size,
    }
  }

  const handleDelete = async (id, name) => {
    const res = await deleteRoleById(id)
    if (res) {
      let entry = name !== undefined ? name : t('entry');
      createCRUDAlert(ROUTES.ROLE, entry, 'deleted')
    }
  }
  const handleBeforeUnload = (event) => {
    event.preventDefault()
    event.returnValue = ''
  }

  const columns: GridColumns = [
    {
      field: 'roleName',
      headerName: t('table.header.role.name'),
      width: 300,
      editable: false,
    },
    {
      field: 'companyName',
      headerName: t('table.header.company'),
      width: 300,
      editable: false,
      valueGetter: (params) => {
        return params.row.company?.companyName
          ? params.row.company?.companyName
          : ''
      },
    },
    {
      field: 'isTecnoitRole',
      headerName: t('table.status.isTecnoitRole'),
      type: 'boolean',
      editable: false,
      renderCell: ({ row }) => {
        return row.isTecnoitRole ? (
          <Chip
            sx={{
              background: 'rgba(255, 94, 30, 0.12)',
            }}
            size="small"
            label={t('campaign.table.header.segmentation.label.yes')}
          />
        ) : (
          <Chip size="small" label={t('campaign.table.header.segmentation.label.no')} />
        )
      },
    },
  ]
  return (
    <>
      <label ref={fileSelectRef} htmlFor="pointfile"></label>
      <Table
        getData={getData}
        key={tableKey}
        columns={columns}
        goTo={goToEditRole}
        titleKey={'apName'}
        handleDelete={handleDelete}
        defaultPageSize={30}
        model={'role'}
        Children={CreateRole}
        size={'16px'}
        mr={'24px'}
        permissionUpdate={!permissions?.role?.includes("role:update")}
        permissionDelete={!permissions?.role?.includes("role:delete")}
        permissionView={!permissions?.role?.includes("role:view")}
      />
    </>
  )
}
