import { Box, ListSubheader } from '@mui/material'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import { CSSObject, Theme, styled } from '@mui/material/styles'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-perfect-scrollbar/dist/css/styles.css'

import { ActivityContext } from 'src/context/ActivityContext'
import { IMenuGroup, IMenuItem } from 'src/models/menuItem-model'
import { getRole } from 'src/service/authService'
import LogoIt from '../../assets/icons/IT-SIMBOLO-MENU-REDUZIDO.svg'
import LogoTecnoIt from '../../assets/icons/TECNO-IT-MARCA.svg'
import { CompanyLogo } from './CompanyLogo'
import { Copyright } from './Copyright'
import { getMenuItems } from './Menu'
import MenuItem from './MenuItem'
import { TecnoitCompanySelection } from './TecnoitCompanySelection'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: getRole() === 'TECNOIT' ? 'flex-end' : 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'pre-wrap',
  boxSizing: 'border-box',
  m: '0 6px',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function Sidebar({ open }): any {
  const { t } = useTranslation()
  const [logoCompany, setLogoCompany] = useState<string>(LogoTecnoIt)
  const [logoSmallCompany, setLogoSmallCompany] = useState<string>(LogoIt)
  const [logoSmallLogoIt, setLogoSmallLogoIt] = useState<string>(LogoIt)
  const { openDrawer } = useContext(ActivityContext)
  const [loading, setLoading] = useState(true)
  const hasRole = (roles: string[]): boolean => {
    return localStorage.getItem('role') !== null
    ? roles.includes(localStorage.getItem('role') as string)
    : false
  }

  let permissions = JSON.parse(localStorage.getItem('permissions'))
  const menu = getMenuItems(permissions)

  const renderDrawerHeader = () => {
    if (getRole() === 'TECNOIT') {
      if (!(!openDrawer && open)) {
        return (
          <img
            src={logoSmallLogoIt}
            alt="logo Mini"
            style={{
              width: '43.9px',
              height: '36px',
              marginRight: getRole() === 'TECNOIT' ? 6 : 0,
            }}
            onLoad={() => setLoading(false)}
          />
        )
      } else {
        return <TecnoitCompanySelection />
      }
    } else {
      return (
        <CompanyLogo
          open={open}
          openDrawer={openDrawer}
          loading={loading}
          setLoading={setLoading}
          logoCompany={logoCompany}
          logoSmallCompany={logoSmallCompany}
          logoSmallLogoIt={logoSmallLogoIt}
        />
      )
    }
  }

  useEffect(() => {
    permissions =  JSON.parse(localStorage.getItem('permissions'))
    if (
      localStorage.getItem('companyLogo') &&
      localStorage.getItem('companyLogoSmall')
    ) {
      setLogoCompany(localStorage.getItem('companyLogo'))
      setLogoSmallCompany(localStorage.getItem('companyLogoSmall'))
    }
  }, [permissions])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        ml: open ? '32px' : '76px',
      }}
    >
      <Drawer
        variant={'permanent'}
        open={!openDrawer && open}
        sx={{
          '& .MuiDrawer-paper': {
            boxShadow:
              '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);',
          },
        }}
      >
        <DrawerHeader sx={{ mt: !openDrawer && open ? '80px' : 10 }}>
          { renderDrawerHeader() }
        </DrawerHeader>
        <Divider sx={{ mt: '10px' }} />
        <List disablePadding>
          {menu.map((item: IMenuGroup | IMenuItem, key) => {
            if (item.type === 'item' && hasRole(item.roles)) {
              return (
                <div key={key}>
                  <MenuItem item={item} open={!openDrawer && open} />
                </div>
              )
            }
            if (item.type === 'group' && hasRole(item.roles)) {
              return (
                <div key={key}>
                  <div className="item-menu">
                    <ListSubheader
                      sx={{
                        display: !openDrawer && open ? 'flex' : 'none',
                        opacity: !openDrawer && open ? 1 : 0,
                        width: '90%',
                        fontSize: '0.725rem',
                      }}
                    >
                      {t(item.title)}
                    </ListSubheader>
                    {item.items.map((item: IMenuGroup | IMenuItem, key) => {
                      return (
                        <div key={key}>
                          <MenuItem item={item} open={!openDrawer && open} />
                        </div>
                      )
                    })}
                    <Divider />
                  </div>
                </div>
              )
            }
          })}
          <Copyright open={open} />
        </List>
      </Drawer>
    </Box>
  )
}
