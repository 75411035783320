import { useEffect, useState } from 'react'
import { t } from 'i18next'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { getRole } from 'src/service/authService'

import { getRoles } from 'src/service/roleService'
import InputSelectRHF from '../RHF/InputSelectRHF'

export const RoleSelect = (props: any) => {
  const [roles, setRoles] = useState([])

  const getRolesList = async () => {
    let roles = [];
    const data = await getRoles({ params: { size: 100000000 } })
    data.listRoles.forEach((row) => {
      if (getRole() === 'TECNOIT') {
        row.roleName = row.roleName + ' - ' + row?.company?.companyName
      }
      roles.push(row)
    })
    setRoles(roles)
  }

  useEffect(() => {
    getRolesList()
  }, [])

  return (
    <InputSelectRHF
      name="roleId"
      options={roles}
      label={t('user.form.input.label.role')}
      placeholder={t('user.form.input.label.role')}
      loading
      noOptionsText={t('noRowsLabel.general')}
      multiple={false}
      isOptionEqualToValueFn={(option: any, value: any) =>
        option.roleName === value.roleName
      }
      getOptionLabel={(value: any) =>
        value.roleName ? value.roleName : ''
      }
      sx={{ ...props.sx, width: '100%', height: '48px' }}
      {...props}
      renderOption={(props, option: any, { inputValue }) => {
        const matches = match(option.roleName, inputValue, {
          insideWords: true,
        })
        const parts = parse(option.roleName, matches)

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    color: part.highlight ? '#FF5E1E' : '#000000DE',
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}
