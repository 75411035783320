import type { ChangeEvent, FocusEvent } from 'react'
import { t } from 'i18next'
import { useController } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { CircularProgress } from '@mui/material'
import TextField from '@mui/material/TextField'

import { InputMaskRHFProps } from '../../models/inputRHF-model'

export default function InputMaskRHF(props: InputMaskRHFProps) {
  const {
    disabled,
    label,
    mask,
    required,
    name,
    control,
    sx,
    onBlurAction,
    onChangeAction,
    loading,
    size,
  } = props
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({ name, control })

  const onBlurPropagator = (event: FocusEvent<HTMLInputElement>) => {
    onBlur()
    onBlurAction && onBlurAction(event)
  }

  const onChangePropagator = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(event.target.value)
    onChangeAction && onChangeAction(event.target.value)
  }

  return (
    <InputMask
      disabled={disabled}
      mask={mask}
      onChange={onChangePropagator}
      onBlur={onBlurPropagator}
      value={value}
      size={size}
    >
      {
        (() => (
          <TextField
            inputRef={ref}
            label={label}
            error={invalid}
            helperText={t(error?.message)}
            required={Boolean(required)}
            disabled={disabled}
            sx={{
              '.MuiFormHelperText-root.Mui-error': {
              m: 0,
            }, 
            ...sx }}
            InputProps={{
              endAdornment: loading && <CircularProgress size="1.3rem" />,
            }}
          />
        )) as any
      }
    </InputMask>
  )
}
