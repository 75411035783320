import { Box } from '@mui/material'

import itBackground from '../../../assets/icons/img2.png'
import LoginErrorEmail from './LoginFormNoAuthorized'

const LoginNoAutorized = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#DBDBDB',
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${itBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center ',
        backgroundSize: '1920px 1080px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoginErrorEmail />
    </Box>
  )
}
export default LoginNoAutorized
