import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { Card } from './Card'
import groupColor from '../../../../../assets/icons/group.svg'
import groupGray from '../../../../../assets/icons/groupGray.svg'
import ageRange from '../../../../../assets/icons/agerange.svg'
import LinearChart from '../Charts/LinearChart'

export const AgeRange = ({ labels, total, data, sx }) => {
  const { t } = useTranslation()

  const convertToPercentage = (num) => {
    return (num / 100).toFixed(2) + '%'
  }

  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'row',
        gap: '50px',
        p: '16px',
        px: '31px',
        pb: '20px',
        ...sx
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {t('card.age.range')}
        </Typography>
        <Typography
          sx={{
            fontSize: '34px',
            fontWeight: 400,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {total}
        </Typography>

        {total === 0 ? (
          <img
            src={groupGray}
            alt="group"
            style={{ width: '40px', height: '40px' }}
          />
        ) : (
          <img
            src={groupColor}
            alt="group"
            style={{ width: '40px', height: '40px' }}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {total === 0 ? (
          <img src={ageRange} alt="ageRange" />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <LinearChart labels={labels} data={data} />
          </Box>
        )}
      </Box>
    </Card>
  )
}
