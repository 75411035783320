import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Typography } from '@mui/material'
import PollIcon from '@mui/icons-material/Poll'
import AddIcon from '@mui/icons-material/Add'

import useProtectedPage from 'src/hooks/useProtectedPage'
import { goToCreateEnrichment } from 'src/routes/coordinator'
import ActivityHistory from 'src/components/SideMenuAudit/ActivityHistory'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { ActivityContext } from 'src/context/ActivityContext'
import { getPermissions } from 'src/service/authService'
import EnrichmentTable from './components/Table/EnrichmentTable'

export const EnrichmentProfile = () => {
  useProtectedPage()
  const { t } = useTranslation()
  const history = useNavigate()
  const { openDrawer } = useContext(ActivityContext)
  const permissions = JSON.parse(getPermissions())

  return (
    <Container
      sx={{
        mt: '96px',
        '.MuiDataGrid-row': {
          minHeight: '62px!important',
          '.MuiDataGrid-cell': {
            paddingTop: '8px',
            paddingBottom: '8px',
            minHeight: '62px!important',
          },
        },
        width: openDrawer ? 'calc(100% - 280px)' : '100%',
      }}
    >
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <PollIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: ' #FF5E1E',
              marginTop: '-3px',
            }}
          >
            {t('enrichment.header')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {
            permissions?.auditory?.includes('auditory:list') && <ActivityHistory path="enriquecimentos" keySubtitle="name" />
          }
        </Box>
      </Box>
      <Box
        sx={{
          mb: '40px',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
            xl: 'row',
          },
          gap: '16px',
          width: openDrawer ? 'calc(100% - 280px)' : '100%',
        }}
      >
        <Button
          onClick={() => goToCreateEnrichment(history)}
          disabled={!permissions?.enrichment?.includes("enrichment:create")}
          variant="outlined"
          sx={{
            gap: '6px',
            width: openDrawer ? '100%' : '49%',
            height: '36px',
            ':hover': {
              backgroundColor: '#FF5E1E',
              color: '#ffff',
            },
          }}
        >
          <AddIcon sx={{ width: '24px', height: '24px' }} />
          {t('enrichment.button.create_enrichment')}
        </Button>
      </Box>

      <EnrichmentTable />
    </Container>
  )
}
