import { ClickAwayListener } from '@mui/base'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import NotificationAddRoundedIcon from '@mui/icons-material/NotificationAddRounded'
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'
import { Button, CircularProgress } from '@mui/material'
import Badge from '@mui/material/Badge'
import MuiDivider from '@mui/material/Divider'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import MuiList from '@mui/material/List'
import MuiListItem from '@mui/material/ListItem'
import MuiPaper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { NotificationModel } from 'src/models/notification-model'
import { goToCreateNotification } from 'src/routes/coordinator'
import { getPermissions } from 'src/service/authService'
import { fetchNotifications, markAllNotificationsAsRead } from 'src/service/notificationService'
import { cleanHtmlString } from 'src/util/clean-html-string'
import { createMessageExcerpt } from 'src/util/generate-excerpt'

const formatMessageDescription = (message: string): string => {
  const cleanMessage = cleanHtmlString(message)
  const excerpt = createMessageExcerpt(cleanMessage)
  return excerpt
}

const Paper = styled(MuiPaper)({
  transformOrigin: 'top right',
  backgroundImage: 'none',
})

const List = styled(MuiList)(({ theme }) => ({
  width: theme.spacing(40),
  maxHeight: 540,
  overflow: 'auto',
  padding: theme.spacing(1, 0),
}))

const ListItem = styled(MuiListItem)({
  display: 'flex',
  flexDirection: 'column',
  "&:hover": {
    backgroundColor: 'rgba(255, 94, 30, 0.06)'
  },
})

const Loading = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(3, 0),
}))

const Divider = styled(MuiDivider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}))

export function Notifications() {
  const history = useNavigate()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const anchorRef = useRef(null)
  const [notifications, setNotifications] = useState<NotificationModel[]>([])

  const unreadNotificationsAmount = notifications ? notifications.reduce((count, message) => (message.readAt === null ? count + 1 : count), 0) : 0

  const permissions = JSON.parse(getPermissions())
  const canCreateNotification: boolean = permissions?.notification?.includes('notification:create')

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
    setTooltipOpen(false)
  }

  const handleMarkAllNotificationsAsRead = async () => {
    const result = await markAllNotificationsAsRead()
    if (result) {
      const notificationsAsRead = notifications
      const currentDate = new Date()
      notificationsAsRead.forEach(notification => {
          notification.readAt = currentDate
      })
      setNotifications(notificationsAsRead)
    }
  }

  useEffect(() => {
    let active = true

    const timeout = setTimeout(async () => {
      const notifications = await fetchNotifications().catch(() => {
        return []
      })
      if (active) {
        setNotifications(notifications)
      }
    }, 1500)

    return () => {
      clearTimeout(timeout)
      active = false
    }
  }, [history])

  return (
    <Fragment>
      <Tooltip
        open={tooltipOpen}
        title={t('notification.toggleNotifications')}
        enterDelay={300}
        onOpen={() => {
          setTooltipOpen(!open)
        }}
        onClose={() => {
          setTooltipOpen(false)
        }}
      >
        <IconButton
          color={unreadNotificationsAmount !== 0 ? "primary" : "inherit"}
          ref={anchorRef}
          aria-controls={open ? 'notifications-popup' : undefined}
          aria-haspopup="true"
          aria-label={`${unreadNotificationsAmount} ${t('notification.unreadNotifications')}`}
          data-ga-event-category="AppBar"
          data-ga-event-action="notification.toggleNotifications"
          onClick={handleToggle}
        >
          <Badge color="primary" badgeContent={unreadNotificationsAmount}>
            <NotificationsNoneRoundedIcon fontSize="medium" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        id="notifications-popup"
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        role={undefined}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false)
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper
                sx={(theme) => ({
                  mt: 0.5,
                  border: '1px solid',
                  borderColor: 'grey.200',
                  boxShadow: `0px 4px 20px rgba(170, 180, 190, 0.3)`,
                })}
              >
                <List>
                  <MuiListItem sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: canCreateNotification ? '10px' : '0', width: '100%' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Notificações</Typography>
                      <Button onClick={handleMarkAllNotificationsAsRead} variant="text" style={{ display: 'flex' }}>
                        <DoneAllIcon fontSize='small' style={{ marginRight: '5px' }} />
                        {t("notification.mark.all.as.read")}
                      </Button>
                    </div>
                    {
                      canCreateNotification && (
                        <Button variant='text' onClick={() => goToCreateNotification(history) } style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                          <NotificationAddRoundedIcon fontSize='small' />
                          <span style={{ marginLeft: '10px' }}>
                            {t('notification.create.notification')}
                          </span>
                        </Button>
                      )
                    }
                  </MuiListItem>
                  <Divider />
                  { notifications.length === 0 ? (
                    <MuiListItem sx={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <NotificationsNoneRoundedIcon color='primary' fontSize='large' />
                      <Typography mt={2} textAlign='center'>{t("notifications.navbar.empty")}</Typography>
                    </MuiListItem>
                  ) : null }
                  {notifications ? (
                    notifications.map((message, index) => (
                      <Fragment key={message.id}>
                        <ListItem alignItems="flex-start">
                          <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/notificacao/${message.id}`}>
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                              { message.readAt === null ? <UnreadNotification /> : null }
                              <Typography><b>{message.title}</b></Typography>
                            </div>
                            <Typography gutterBottom variant="body2" color="text.secondary">
                              <span id="notification-message">
                                {formatMessageDescription(message.content)}
                              </span>
                            </Typography>
                            {message.createdAt && (
                              <Typography variant="caption" color="text.secondary">
                                {new Date(message.createdAt).toLocaleDateString('pt-BR', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                })}
                              </Typography>
                            )}
                          </Link>
                        </ListItem>
                        {index < notifications.length - 1 ? <Divider /> : null}
                      </Fragment>
                    ))
                  ) : (
                    <Loading>
                      <CircularProgress size={32} />
                    </Loading>
                  )}
                </List>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </Fragment>
  )
}

function UnreadNotification () {
  return (
    <span style={{
        borderRadius: '9999px',
        width: '0.5rem',
        height: '0.5rem',
        minWidth: '0.5rem',
        backgroundColor: '#FF5E1E'
      }}
    />
  )
}
