import { PointGroups } from 'src/models/pointGroups-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'
import xlsx from 'json-as-xlsx'

export const getPointGroups = async (options?) => {
  try {
    const res = await client().get(`${BASE_URL}/group`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getPointGroupById = async (
  id: string
): Promise<PointGroups | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/group/${id}`)
    return res.data
  } catch (error) {
    console.log(error)

    //alert(error);
  }
}

export const createPointGroup = async (
  point: PointGroups
): Promise<boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/group`, point)
    return res.status === 200
  } catch (error) {
    return false
  }
}

export const editPointGroup = async (
  id: string,
  point: any
): Promise<PointGroups | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/group/${id}`, point)
    return res.data
  } catch (error) {
    return false
  }
}

export const deletePointGroupById = async (
  id: string | number
): Promise<Boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/group/${id}`)
    return true
  } catch (error) {
    return false
  }
}

export const recyclePointGroupById = async (id: string): Promise<PointGroups | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/group/${id}/enable`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const unarchivePointGroupById = async (id: string): Promise<PointGroups | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/group/${id}/unarchive`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const getProfileByGroupPoint = async (id) => {
	try {
		const res = await client().post(
			`${BASE_URL}/reports/profile-by-group/${id}`,
			{},
		)

		if (res.data) {
			const columns = (['question', 'field', 'total', 'answers'] || [])?.map((key) => ({
				value: key,
				label: key,
			}))
			const transformedData = res.data.map(item => {
				const { answers, ...rest } = item;
				const answersString = Object.keys(answers).map(key => `${key}: ${answers[key]}`).join('; '); // Concatenando respostas em uma única string com ponto e vírgula
				return {
					...rest,
					answers: answersString,
				};
			});
			console.log(transformedData)
			const xlsxData = [
				{
					sheet: 'ItBuzz Report',
					columns,
					content: transformedData,
				},
			]
			xlsx(xlsxData as any, { fileName: 'ProfileByGroupPoint' })
		}
		return false
	} catch (error) {
	}
}