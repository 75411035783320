import { NotificationModel } from 'src/models/notification-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const createNotification = async (data: { title: string, content: string }): Promise<boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/notification`, data)
    return true
  } catch (error) {
    return false
  }
}

export const fetchNotifications = async (): Promise<NotificationModel[] | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/notification`)
    return res.data
  } catch (error) {}
}

export const getNotificationById = async (id: string): Promise<NotificationModel | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/notification/${id}`)
    return res.data
  } catch (error) {}
}

export const markNotificationByIdAsRead = async (id: string): Promise<boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/notification/${id}/read`, {})
    return true
  } catch (error) {
    return false
  }
}

export const markAllNotificationsAsRead = async (): Promise<boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/notification/read-all`, {})
    return true
  } catch (error) {
    return false
  }
}
