import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Container, Typography } from '@mui/material'
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo'

import PortalForm from './components/Form/PortalForm'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { Portal } from 'src/models/portal-model'
import {
  createPortal,
  editPortal,
  getPortalById,
  getPortalIsActive,
} from 'src/service/portalService'
import { AlertContext } from 'src/context/AlertContext'
import { goToPortal } from 'src/routes/coordinator'
import { ROUTES } from 'src/routes/Router'
import { NotifyError } from 'src/service/NotifyError'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { ModalPortalIsActiveContext } from 'src/context/ModalPortalIsActiveContext'
import { getCompanyId, getRole } from 'src/service/authService'
import { ModalPortalIsActive } from './components/Modais/ModalPortalIsActive'

interface Props {
  isViewMode?: boolean
  viewId?: any
}

const CreatePortal = ({ isViewMode, viewId }: Props) => {
  useProtectedPage()
  const history = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const [portal, setPortal] = useState<Portal>()
  const { createCRUDAlert } = useContext(AlertContext)
  const { openModalPortalIsActive } = useContext(ModalPortalIsActiveContext)

  const getPortal = async () => {
    if (id || isViewMode) {
      const portal = await getPortalById(id || viewId)
      setPortal(portal)
    }
  }

  const onSubmit = async (data) => {
    if (!data?.backgroundImage || !data?.logoImage) {
      NotifyError('Inserir Imagem.')
      return
    }

    const resultPortal: any = await getPortalIsActive(
      getRole() === 'TECNOIT' ? data.companyId.id : getCompanyId()
    )

    if (
      resultPortal &&
      resultPortal.captivePortal.length > 0 &&
      resultPortal.result === 'true' &&
      data.isActive === true
    ) {
      openModalPortalIsActive(data)
    } else {
      if (data?.id) {
        const result = await editPortal(id, data)
        if (result) {
          createCRUDAlert(ROUTES.PORTAIS, data.portalName, 'edited')
          goToPortal(history)
        }
        return
      }

      const result = await createPortal(data)

      if (result) {
        createCRUDAlert(ROUTES.PORTAIS, data.portalName, 'created')
        goToPortal(history)
      }
    }
  }

  useEffect(() => {
    getPortal()
  }, [])

  return (
    <Container
      sx={{
        mt: isViewMode ? '' : '96px',
      }}
    >
      <AlertComponent isView={isViewMode} />
      <ModalPortalIsActive />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: isViewMode ?  '0px': '24px',
        }}
      >
        <PersonalVideoIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
          }}
        >
          {t('portal.title')}
        </Typography>
      </Box>

      <PortalForm
        data={portal!}
        onSubmit={onSubmit}
        isViewMode={isViewMode}
        viewId={viewId}
      />
    </Container>
  )
}
export default CreatePortal
