import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'
import { t } from 'i18next'
import { ActivityContext } from './ActivityContext'

type IAlertContext = {
  open: boolean
  message: string
  handleClose: () => void
  handleOpen: () => void
  shouldShowAlert(): boolean
  createAlert(page: string, message: string): void
}

const defaultState = {
  open: false,
  message: '',
  handleClose: () => {},
  handleOpen: () => {},
  shouldShowAlert: () => false,
  createAlert: () => {},
  createCRUDAlert: (name) => {},
  onChangeIdModal: (id) => {},
}

interface ContextProviderProps {
  children: ReactNode
}

export const AlertContext = createContext<any>(defaultState)

function AlertProvider({ children }: ContextProviderProps) {
  const [type, setType] = useState()
  const [message, setMessage] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [page, setPage] = useState<string>()
  const { handleClose: closeActivityBar } = useContext(ActivityContext)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const createAlert = (page: string, message: string, type?) => {
    closeActivityBar()
    setMessage(message)
    setPage(page)
    setType(type)
    handleOpen()
  }

  const createCRUDAlert = (
    route,
    name,
    action: 'deleted' | 'created' | 'edited',
    useQuotes?: boolean
  ) => {
    if (useQuotes) {
      createAlert(route, `"${name}" ${t(`general.alert.${action}`)}`)
    }
    createAlert(route, `${name} ${t(`general.alert.${action}`)}`)
  }

  const shouldShowAlert = useCallback(
    () => window.location.pathname.includes(page as string) && open,
    [page, open]
  )

  return (
    <AlertContext.Provider
      value={{
        open,
        message,
        handleClose,
        handleOpen,
        createAlert,
        shouldShowAlert,
        createCRUDAlert,
        type,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
