import { useContext, useState } from 'react'
import { Box } from '@mui/system'

import { FormHeader, checkHasLink } from '../FormHeader'
import { UploadMedia } from '../../upload/UploadMedia'
import {
  CampaignContext,
  contentTypeEnum,
} from 'src/context/CampaignFormContext'
import { StepperContext } from 'src/context/StepperContext'
import { FormButtons } from '../../FormButtons'
import { t } from 'i18next'
import { PreviewModal, PreviewMode } from 'src/components/Modal/PreviewModal'
import { MediaCampaignPreview } from '../../../Preview/MediaCampaignPreview'
import { useParams } from 'react-router-dom'
import { goToCampaigns } from 'src/routes/coordinator'

interface Props {
  isViewMode?: boolean
  disabled?: any
}
export const MediaForm = ({ isViewMode, disabled }: Props) => {
  const [showPreview, setShowPreview] = useState(false)
  const [previewMode, setPreviewMode] = useState(PreviewMode.view)
  const [imageError, setImageError] = useState(false)
  const {
    setContentType,
    formState: { setValue, watch, reset },
  } = useContext(CampaignContext)
  const { nextStep } = useContext(StepperContext)
  const { id } = useParams()
  const isEdit = Boolean(id)

  const formIsValid =
    Boolean(watch('content.mediaURL')) &&
    Boolean(!checkHasLink(watch('content.description')))

  const clearForm = () => {
    setValue('content.title', '')
    setValue('content.description', '')
    setValue('content.mediaURL', null)
    setValue('content.mediaInterval', 0)
    setValue('content.hasMediaInterval', false)
    setValue('content.titleColor', undefined)
    setValue('content.descriptionColor', undefined)
  }

  return (
    <Box>
      <PreviewModal
        handleClose={() => setShowPreview(false)}
        open={showPreview}
        title={`${t('campaign.preview.visualization.off')}\n${t(
          'campaign.media.title'
        )}`}
        mode={previewMode}
        onConfirm={() => {
          setShowPreview(false)
          nextStep()
        }}
      >
        <MediaCampaignPreview />
      </PreviewModal>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <FormHeader title={'campaign.media.title'} isViewMode={isViewMode} />
        <UploadMedia
          isView={isViewMode}
          onFileChange={(mediaURL, imageName) => {
            setValue('content.mediaURL', mediaURL)
            setValue('content.imageName', imageName)
          }}
          defaultFile={watch('content.mediaURL')}
          placeholder={t('input.upload.file.media')}
          acceptedFormats={t('input.upload.media.acceptedformats')}
          accept=".mp4"
          error={imageError}
          errorMessage={t('campaign.media.upload.error')}
          checkFileSizeCallback={(size) => {
            if (size > 25) {
              setImageError(true)
              return false
            }
            setImageError(false)
            return true
          }}
          helperText="Recomenda-se a duração máxima de 20 segundos"
        />
        <Box sx={{ width: '100%', p: 'none' }}>
          {!isViewMode && (
            <FormButtons
              onClean={() => clearForm()}
              nextButtonEnabled={formIsValid}
              prevButtonEnabled={true}
              formIsValid={formIsValid}
              cleanButtonEnabled={formIsValid}
              previewButtonEnabled={formIsValid}
              prevButtonAction={() => {
                reset({})
                setContentType(contentTypeEnum.NONE)
              }}
              previewButtonAction={() => {
                setPreviewMode(PreviewMode.view)
                setShowPreview(true)
              }}
              nextButtonAction={() => {
                setPreviewMode(PreviewMode.confirm)
                setShowPreview(true)
              }}
              nextButtonCta="form.button.next"
              nextButtonArrow
              isEdit={isEdit}
              goTo={goToCampaigns}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
