import { Card } from './Card'
import { SxProps, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { CircleIcon } from 'src/components/Icons/CircleIcon'
interface Props {
  sx?: SxProps
}
export const Delivery = ({ sx }: Props) => {
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        p: '16px',
        px: '31px',
        ...sx,
      }}
    >
      <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6);' }}>
        {t('campaign.result.delivery.title')}
      </Typography>
      <Typography
        sx={{
          fontSize: '34px',
          color: 'rgba(0, 0, 0, 0.87)',
          mt: 1,
        }}
      >
        {t('campaign.result.global.title')}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6)',
          mt: 1,
        }}
      >
        {t('campaign.result.nosegmentation')}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CircleIcon />
      </Box>
    </Card>
  )
}
