import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CKEditorRHF from 'src/components/CKEditorRHF/CKEditorRHF'
import { FrequentlyAskedQuestion } from 'src/models/faqs-model'
import InputRHF from '../../../../components/RHF/InputRHF'
import { faqsDefaultValues, faqsSchemaValidation } from '../../validators/faqsSchemaValidator'

interface FaqsFormProps {
  data: FrequentlyAskedQuestion
  onSubmit(data: any): void
}

export const FaqsForm = ({ data, onSubmit }: FaqsFormProps) => {
  const { id } = useParams()
  const { t } = useTranslation()

  const form = useForm<any>({
    mode: 'all',
    defaultValues: faqsDefaultValues,
    resolver: yupResolver(faqsSchemaValidation),
  })
  const { control, handleSubmit, reset, setValue, watch } = form

  useEffect(() => {
    if (data) {
      reset(data)
      setValue('question', data.question)
      setValue('answer', data.answer)
    }
  }, [data])

  const formIsValid = () => Boolean(watch('question') && watch('answer'))

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" sx={{ mb: 2 }}>
      <Box
        sx={{
          boxShadow: 'none',
          p: ' 0px 0px 16px 0px',
          borderRadius: '4px',
          mb: '24px',
          mt: '16px',
        }}
      >
        <InputRHF
          control={control}
          label={t('faqs.form.input.label.question')}
          placeholder={t('faqs.form.input.placeholder.question')}
          name="question"
          sx={{
            width: '100%',
            height: '56px',
            mb: 2,
          }}
        />

        <CKEditorRHF
          label={t('faqs.form.input.label.answer')}
          name="answer"
          control={control}
          sx={{
            width: '100%',
            mt: 3,
            mb: 2,
          }}
          displayColorPicker={false}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
          }}
        >
          <Button
            type="submit"
            variant="outlined"
            disabled={!formIsValid()}
            sx={{
              p: 0,
              height: '36px',
              minWidth: '308px',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px',
              color: '#FF5E1E',
              borderColor: '#FF5E1E',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
              alignSelf: 'flex-end'
            }}
          >
            <AddIcon />
            {id ? t('faqs.button.edit') : t('faqs.button.create')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
