import { t } from 'i18next'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded'

import InputSelectRHF from 'src/components/RHF/InputSelectRHF'

export const OptionsPointSelect = ({
  onCenterMap,
  watch,
  index,
  onRemove,
  onChangeApList,
  isViewMode,
  disabled,
  ...props
}: any) => {
  const hideButtons =
    watch(`apList[${index}].apName`) &&
    watch(`apList[${index}].geoAddress`) &&
    watch(`apList[${index}].geoLatitude`) &&
    watch(`apList[${index}].geoLongitude`)

  return (
    <Box
      sx={{
        mt: '6px',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridColumn: 'span 12',
        paddingRight: {
          lg: 3,
          xl: 1,
        },
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          height: '55px',
          borderRadius: '4px',
          fontWeight: 400,
          fontSize: '16px',
          color: 'rgba(0, 0, 0, 0.38)',
          gridColumn: 'span 1',
          mr: 1,
        }}
      >
        {index + 1}
      </Typography>

      <InputSelectRHF
        disableClearable
        disabled={disabled}
        label={index === 0 ? t('form.pointgroup.select.label') : null}
        placeholder={t('form.pointgroup.select.placeholder.points')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.apName === value.apName
        }
        forcePopupIcon={false}
        getOptionLabel={(value: any) => value?.apName || ''}
        freeSolo
        sx={{
          gridColumn: 'span 3',
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px 0 0 4px',
            borderWidth: '1px 0.5px 1px 1px',
          },
        }}
        {...props}
        onChangeAction={(v) => {
          if (!v) onRemove()
          props.onChangeAction(v)
          onChangeApList(index)
        }}
        renderOption={(props, option: any, { inputValue }) => {
          const matches = match(option.apName, inputValue, {
            insideWords: true,
          })
          const parts = parse(option.apName, matches)

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? '#FF5E1E' : '#000000DE',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          )
        }}
      />
      <InputSelectRHF
        disableClearable
        disabled={disabled}
        label={index === 0 ? t('form.pointgroup.select.label.address') : null}
        placeholder={t('form.pointgroup.select.placeholder.address')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.geoAddress === value.geoAddress
        }
        forcePopupIcon={false}
        getOptionLabel={(value: any) => value?.geoAddress || ''}
        freeSolo
        sx={{
          gridColumn: 'span 3',
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '0px',
            borderWidth: '1px 1px 1px 1px',
          },
        }}
        {...props}
        onChangeAction={(v) => {
          if (!v) onRemove()
          props.onChangeAction(v)
          onChangeApList(index)
        }}
        renderOption={(props, option: any, { inputValue }) => {
          const matches = match(option.geoAddress, inputValue, {
            insideWords: true,
          })
          const parts = parse(option.geoAddress, matches)

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? '#FF5E1E' : '#000000DE',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          )
        }}
      />
      <InputSelectRHF
        disableClearable
        disabled={disabled}
        label={index === 0 ? t('point.form.input.label.latitude') : null}
        placeholder={t('point.form.input.label.latitude')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.geoLatitude === value.geoLatitude
        }
        forcePopupIcon={false}
        getOptionLabel={(value: any) => value?.geoLatitude || ''}
        freeSolo
        sx={{
          gridColumn: 'span 2',
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '0px',
            borderWidth: '1px 1px 1px 1px',
          },
        }}
        {...props}
        onChangeAction={(v) => {
          if (!v) onRemove()
          props.onChangeAction(v)
          onChangeApList(index)
        }}
        renderOption={(props, option: any, { inputValue }) => {
          const matches = match(option.geoLatitude, inputValue, {
            insideWords: true,
          })
          const parts = parse(option.geoLatitude, matches)

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? '#FF5E1E' : '#000000DE',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          )
        }}
      />
      <InputSelectRHF
        disableClearable
        disabled={disabled}
        label={index === 0 ? t('point.form.input.label.longitude') : null}
        placeholder={t('point.form.input.label.longitude')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.geoLongitude === value.geoLongitude
        }
        forcePopupIcon={false}
        getOptionLabel={(value: any) => value?.geoLongitude || ''}
        freeSolo
        sx={{
          gridColumn: 'span 2',
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '0px 4px  4px 0',
            borderWidth: '1px 1px 1px 1px',
          },
        }}
        {...props}
        onChangeAction={(v) => {
          if (!v) onRemove()
          props.onChangeAction(v)
          onChangeApList(index)
        }}
        renderOption={(props, option: any, { inputValue }) => {
          const matches = match(option.geoLongitude, inputValue, {
            insideWords: true,
          })
          const parts = parse(option.geoLongitude, matches)

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? '#FF5E1E' : '#000000DE',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          )
        }}
      />
      {hideButtons && (
        <Box sx={{ display: 'flex', ml: 1, mt: 1, gridColumn: 'span 1' }}>
          <Tooltip title={t('pointGroups.button.toLocate')}>
            <Button
              disabled={disabled}
              sx={{
                minWidth: '25px',
                color: '#00000099',
                ':hover': {
                  color: ' #FF5E1E',
                },
              }}
              onClick={() => onCenterMap(index)}
            >
              <GpsFixedRoundedIcon sx={{ width: '20px' }} />
            </Button>
          </Tooltip>

          <Tooltip title={t('table.button.delete')}>
            <Button
              disabled={disabled}
              sx={{
                minWidth: '25px',
                color: '#00000099',
                ':hover': {
                  color: ' #FF5E1E',
                },
              }}
              onClick={() => onRemove(index)}
            >
              <DeleteForeverIcon sx={{ width: '20px' }} />
            </Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}
