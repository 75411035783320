import { useContext, useState } from 'react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import EventInfo from '@ckeditor/ckeditor5-utils/src/eventinfo'
import { useController } from 'react-hook-form'
import { t } from 'i18next'
import { Box, FormControl, FormHelperText, FormLabel } from '@mui/material'

import { CharCounter } from './CharCouter'
import { ColorPicker } from '../ColorPicker'
import { CampaignContext } from 'src/context/CampaignFormContext'

export default function CKEditorRHF(props: any) {
  const {
    placeholder,
    label,
    required,
    name,
    control,
    sx,
    disabled,
    onChangeAction,
    charCounterMessage,
    charCounter,
    onColorChange,
    defaultColor,
    colorValue,
    errorMessage,
    displayColorPicker = true
  } = props

  const {
    field,
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({ name, control })
  const { onBlur, onChange, value } = field

  const [borderCss, setBorderCss] = useState('')
  const { contentType } = useContext(CampaignContext)

  const onChangePropagator = (_event: EventInfo, editor: ClassicEditor) => {
    onChange(editor.getData())
    onChangeAction && onChangeAction(editor.getData())
  }

  return (
    <FormControl sx={{ ...sx }} error={invalid} required={required}>
      <FormLabel sx={{ fontSize: '12px', mb: 1 }}>{label}</FormLabel>
      <Box
        sx={{
          '&': {
            '--ck-color-toolbar-background': '#fff',
            '--ck-border-radius': '4px',
            '--ck-focus-ring': '1.5px solid #FF5E1E',
            '>div:nth-of-type(2)': {
              borderRadius: 1,
            },
            ...(invalid && {
              ' .ck-content': {
                borderColor: '#d32f2f !important',
                borderWidth: `${borderCss} !important`,
              },
            }),
            position: 'relative',
          },
        }}
      >
        <CKEditor
          id={name}
          editor={ClassicEditor}
          config={{
            toolbar: [
              'undo',
              'redo',
              '|',
              'bold',
              'italic',
              '|',
              'bulletedList',
              'numberedList',
              '|',
              'link',
              'blockQuote',
              'heading',
            ],
            language: 'pt-br',
          }}
          data={value}
          onChange={onChangePropagator}
          disabled={disabled || isSubmitting}
          onFocus={(event: EventInfo, editor: ClassicEditor) => {
            invalid && setBorderCss('1.5px')
          }}
          onBlur={(event: EventInfo, editor: ClassicEditor) => {
            invalid && setBorderCss('1.5px')
            onBlur()
          }}
          onReady={(editor: any) => {
            editor.editing.view.change((writer: any) => {
              const root = editor.editing.view.document.getRoot()
              root && writer.setStyle('min-height', '128px', root)
            })
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '6px',
            right: '10px',
          }}
        >
          {
            displayColorPicker ?
              <ColorPicker
                disabled={disabled}
                onColorChange={(color) => onColorChange && onColorChange(color)}
                defaultColor={defaultColor}
                name="editorColor"
                value={colorValue}
              />
            : null
          }
        </Box>
      </Box>
      {error?.message && (
        <FormHelperText
          sx={{
            ml: '0px',
          }}
        >
          {t(error?.message)}
        </FormHelperText>
      )}
      {errorMessage && (
        <FormHelperText
          sx={{
            color: '#d32f2f',
          }}
        >
          {errorMessage}
        </FormHelperText>
      )}
      {contentType !== 'image' && charCounter && !errorMessage && (
        <CharCounter message={charCounterMessage} content={value} />
      )}
    </FormControl>
  )
}

export const ParseCFKContent = (content) =>
  new DOMParser().parseFromString(content, 'text/html').body.textContent
