import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import {
  Campaign,
  CampaignOption,
} from '../../../models/campaigns-model/campaign-model'
import { questionSchemaValidation } from './questionSchemaValidator'

const campaignOptionDefaultValues: CampaignOption = {
  title: '',
  description: '',
  mediaURL: '',
  quiz: [],
}

export const campaignDefaultValues: Campaign = {
  name: '',
  isActive: true,
  isArchived: false,
  initialDate: null,
  finalDate: null,
  groups: [],
  companyId: null,
  contentType: null,
  content: campaignOptionDefaultValues,
}

const mode: keyof ValidationMode = 'all'

export const campaignSchemaValidation = yup.object({
  name: yup.string().required('required'),
  isActive: yup.boolean(),
  isArchived: yup.boolean().default(false),
  initialDate: yup.date().required('required'),
  finalDate: yup.date().required('required'),
  groups: yup.array().min(1, 'Selecione uma opção'),
  companyId: yup
    .object()
    .typeError('invalid')
    .test((value, context) => {
      if (value.id || value.companyId) {
        return true
      }
      return context.createError({
        message: `required`,
        path: 'companyId',
      })
    }),
  contentType: yup.string().required('required'),
  content: yup.object({
    title: yup.string().required('required'),
    description: yup.string().required('required'),
    mediaURL: yup.string(),
    quiz: yup.array().of(questionSchemaValidation),
  }),
})

export const campaignUseFormArgs = {
  mode,
  defaultValues: campaignDefaultValues,
  resolver: yupResolver(campaignSchemaValidation),
}

export interface CampaignTypes
  extends yup.InferType<typeof campaignSchemaValidation> {}

export interface resultValid {
  campaign: any
  contentType: string
}
export const defaultValues: resultValid = {
  campaign: '',
  contentType: 'todas',
}

export const resultSchemaValidation = yup.object({})

export interface optionsType {
  value: string
  label: string
}

export const options: optionsType[] = [
  {
    value: 'todas',
    label: 'Todos',
  },
  {
    value: 'media',
    label: 'Vídeo',
  },
  {
    value: 'image',
    label: 'Imagem',
  },
  {
    value: 'text',
    label: 'Texto',
  },
  {
    value: 'quiz',
    label: 'Pesquisa',
  },


]
