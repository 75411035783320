import { BASE_URL } from 'src/constants/url'
import { client } from './baseService'

export const getAuditoryById = async (
  id: string,
  options?: any
): Promise<any | boolean> => {
  try {
    const res = await client().get(`${BASE_URL}/auditory/${id}`, {
      params: options,
    })
    return res.data
  } catch (error) {
    return false
  }
}

export const getAuditoryByObjectType = async (
  objectType: string,
  options?: any
): Promise<any | boolean> => {
  try {
    const res = await client().get(`${BASE_URL}/auditory/list/${objectType}`, {
      params: options,
    })
    return res.data
  } catch (error) {
    return false
  }
}
