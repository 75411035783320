import AddIcon from '@mui/icons-material/Add'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import { Box, Button, Container, Divider, Typography } from '@mui/material'
import { SxProps } from '@mui/material/styles'
import { t } from 'i18next'
import { SyntheticEvent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { AlertContext } from 'src/context/AlertContext'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { FrequentlyAskedQuestion } from 'src/models/faqs-model'
import { ROUTES } from 'src/routes/Router'
import { goToCreateFaq, goToEditFaq } from 'src/routes/coordinator'
import { getPermissions } from 'src/service/authService'
import { deleteFAQById, fetchFaqs } from 'src/service/faqsService'
import { ConfirmDeleteModal } from './components/modal/ConfirmDeleteModal'
import { FaqAccordionSkeleton } from './components/ui/FaqAccordionSkeleton'
import { Accordion, AccordionDetails, AccordionSummary } from './components/ui/FaqAccordionStyles'

const ButtonStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

interface ObjectType {
  id: string
  question: string
}

export function FAQsList () {
  useProtectedPage()
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)
  const [expanded, setExpanded] = useState<string | false>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [faqs, setFaqs] = useState<FrequentlyAskedQuestion[]>([])
  const [objectToBeDeleted, setObjectToBeDeleted] = useState<ObjectType>({} as ObjectType)

  const permissions = JSON.parse(getPermissions())
  const canCreateFAQ: boolean = permissions?.faqs?.includes('faqs:create')
  const canUpdateFAQ: boolean = permissions?.faqs?.includes('faqs:update')
  const canDeleteFAQ: boolean = permissions?.faqs?.includes('faqs:delete')
  const canSeeItemMenu: boolean = canUpdateFAQ || canDeleteFAQ

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleDelete = async (id: string, question: string) => {
    setIsModalOpen(true)
    setObjectToBeDeleted({ id, question })
  }

  const loadFaqs = async () => {
    const faqs = await fetchFaqs().catch(() => {
      return []
    })
    setFaqs(faqs)
  }

  useEffect(() => {
    loadFaqs()
  }, [objectToBeDeleted])

  const onAccept = async () => {
    const res = await deleteFAQById(objectToBeDeleted.id)
    if (res) createCRUDAlert(ROUTES.FAQS, objectToBeDeleted.question, 'deleted')
    setObjectToBeDeleted({} as ObjectType)
    setIsModalOpen(false)
  }

  const onReject = () => {
    setObjectToBeDeleted({} as ObjectType)
    setIsModalOpen(false)
  }

  return (
    <Container sx={{ mt: '96px' }} >
      <ConfirmDeleteModal
        open={isModalOpen}
        onHandleCloseAndAccept={onAccept}
        onHandleCloseAndReject={onReject}
        objectName={objectToBeDeleted.question}
      />
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '10px',
          justifyContent: 'flex-start',
          mb: '24px',
        }}
      >
        <QuestionAnswerRoundedIcon sx={{ position: 'relative', top: '3px' }}/>
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
          }}
        >
          {t('faqs.title')}
        </Typography>
      </Box>
      { canCreateFAQ && (
        <Box sx={{ mb: '10px' }}>
          <Button
            onClick={() => goToCreateFaq(history)}
            variant="outlined"
            sx={{
              gap: '6px',
              width: '50%',
              height: '36px',
              mb: '40px',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
            }}
          >
            <AddIcon sx={{ width: '24px', height: '24px' }} />
            {t('faqs.button.create')}
          </Button>
        </Box>
        )
      }
      { faqs.length !== 0 ? (
        faqs.map((faq) => {
          return (
            <Accordion key={faq.id} expanded={expanded === `panel-${faq.id}`} onChange={handleChange(`panel-${faq.id}`)}>
              <AccordionSummary aria-controls={`panel-${faq.id}-d-content`} id={`panel-${faq.id}-d-header`}>
                <Typography variant="h6" color="#333">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component='div' gutterBottom>
                  <div style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </Typography>
                { canSeeItemMenu && (
                  <>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                      { canUpdateFAQ && (
                        <Button variant='text' onClick={() => goToEditFaq(history, faq.id)} sx={ButtonStyles}>
                          <EditIcon fontSize='small' />
                          <Typography component='span' sx={{ marginLeft: '10px' }}>
                            {t('faqs.button.update')}
                          </Typography>
                        </Button>
                      )}
                      { canDeleteFAQ && (
                        <Button variant='text' onClick={() => handleDelete(faq.id, faq.question)} sx={ButtonStyles}>
                          <DeleteOutlinedIcon fontSize='small' />
                          <Typography component='span' sx={{ marginLeft: '10px' }}>
                            {t('faqs.button.delete')}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )
        })
      ) : <FaqAccordionSkeleton /> }
    </Container>
  )
}
