import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { Box } from '@mui/material'

import { ButtonComponent } from 'src/components/Button/Button'
import { NoticeModal } from 'src/components/Modal/NoticeModal'
import useUnprotectedPage from 'src/hooks/useUnprotectedPage'
import itBackground from '../../assets/icons/img2.png'
import { LoginForm } from './LoginForm'
import { loginSupport } from '../../service/supportService'
import {
  clearUserEmail,
  getDefaultPassword,
  getRole,
  login,
  storeUserEmail,
} from 'src/service/authService'
import { goToCompany, goToDashboard } from 'src/routes/coordinator'
import { Gif } from 'src/components/Loading/Gif'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { ModalChangePasswordContext } from 'src/context/ModalChangePasswordContext'

const Login = () => {
  useUnprotectedPage()
  const history = useNavigate()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSupportModal, setShowSupportModal] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { createAlert } = useContext(AlertContext)
  const { openModalChangePassword } = useContext(ModalChangePasswordContext)

  const handleCallSuport = async () => {
    setIsLoading(true)
    await loginSupport({ userEmail })
    setIsLoading(false)
    setShowSupportModal(true)
    setShowErrorModal(false)
  }
  const isMobile = () => window.innerWidth < 900
  const notifyMobileUser = () =>
    createAlert(
      getRole() === 'TECNOIT' ? ROUTES.COMPANY : ROUTES.DASHBOARD,
      t('alert.info.mobile.not.supported'),
      'info'
    )

  const onSubmit = async ({ email, password }) => {
    setIsLoading(true)

    const result = await login(email, password)
    if (!result) {
      setShowErrorModal(true)
      setUserEmail(email)
      clearUserEmail()
    }
    if (getDefaultPassword() === 'true') {
      openModalChangePassword(ROUTES.DASHBOARD)
    }
    storeUserEmail(email)

    isMobile() && notifyMobileUser()
    if (getRole() === 'TECNOIT') goToCompany(history)
    if (getRole() === 'ADMIN') goToDashboard(history)
    setIsLoading(false)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        minHeight: '100vh',
        backgroundImage: `url(${itBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center ',
        backgroundSize: '1920px 1080px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLoading ? <Gif /> : <LoginForm onSubmit={onSubmit} />}
      <NoticeModal
        type="error"
        title={t('login.check.email.title')}
        subtitle={t('login.check.email.message')}
        open={showErrorModal}
        handleClose={() => setShowErrorModal(!showErrorModal)}
      >
        <Box
          sx={{
            padding: 2,
            textAlign: 'left',
            backgroundColor: '#21212114',
            borderRadius: '4px 4px 0px 0px',
            width: '100%',
          }}
        >
          {userEmail}
        </Box>
        <ButtonComponent onClick={handleCallSuport}>
          {t('login.no_authorized.suport.button')}
        </ButtonComponent>
      </NoticeModal>
      <NoticeModal
        type="success"
        title={t('login.no_authorized.suport.title')}
        subtitle={t('login.no_authorized.suport.message')}
        open={showSupportModal}
        handleClose={() => setShowSupportModal(!showSupportModal)}
      >
        <ButtonComponent
          onClick={() => setShowSupportModal(!showSupportModal)}
          sx={{
            mt: 1,
            width: '100%',
            height: '40px',
            borderRadius: '4px',
            ':hover': {
              backgroundColor: '#FF5E1E',
              color: '#ffff',
            },
            color: 'black',
            borderColor: 'black',
          }}
        >
          {t('login.no_authorized.button')}
        </ButtonComponent>
      </NoticeModal>
    </Box>
  )
}
export default Login
