import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import * as yup from 'yup'

export const faqsDefaultValues: { question: string, answer: string } = {
  question: '',
  answer: '',
}

const mode: keyof ValidationMode = 'all'

export const faqsSchemaValidation = yup.object({
  question: yup.string().required('required'),
  answer: yup.string().required('required'),
})

export const faqsUseFormArgs = {
  mode,
  defaultValues: faqsDefaultValues,
  resolver: yupResolver(faqsSchemaValidation),
}
