import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { Box, Container, Typography } from "@mui/material"
import { t } from "i18next"
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { AlertComponent } from "src/components/Alert/AlertComponent"
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { goToDashboard } from 'src/routes/coordinator'
import { createNotification } from 'src/service/notificationService'
import { NotificationForm } from './components/Form/NotificationForm'

export function CreateNotification () {
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)

  async function onSubmit (data: { title: string, content: string }) {
    const result = await createNotification(data)

    if (result) {
      createCRUDAlert(ROUTES.DASHBOARD, data.title, 'created')
      goToDashboard(history)
    } else {
      createCRUDAlert(ROUTES.DASHBOARD, 'Erro ao criar notificação')
      goToDashboard(history)
    }
  }

  return (
    <Container sx={{ mt: '96px' }}>
      <AlertComponent isView={false} />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: '24px',
        }}
      >
        <NotificationsNoneOutlinedIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            mb: '-8px',
          }}
        >
          {t('notification.title')}
        </Typography>
      </Box>
      <NotificationForm onSubmit={onSubmit} />
    </Container>
  )
}
