import { createContext, ReactNode, useState } from 'react'

const defaultState = {}

interface ContextProviderProps {
  children: ReactNode
}

export const DashboardViewContext = createContext<any>(defaultState)

function DashboardViewProvider({ children }: ContextProviderProps) {
  const [isEditMode, setIsEditMode] = useState(false)
  const [editResult, setEditResult] = useState({})

  const handleToggleEdit = () => {
    setIsEditMode(!isEditMode)
  }

  const subscribeResult = (key: string, value: boolean) => {
    let newResult = { ...editResult }
    newResult[key] = value
    setEditResult(newResult)
  }

  const writeResult = () => {
    const oldValueStr = localStorage.getItem('dashboardViewConfig')
    if (oldValueStr) {
      const oldValue = JSON.parse(oldValueStr)
      const newValue = Object.assign(oldValue, editResult)
      localStorage.setItem('dashboardViewConfig', JSON.stringify(newValue))
      setEditResult({ ...{} })

      const totalVisibleCards = visibleCards();
      if (totalVisibleCards === 0) {
        return false
      }

      return
    }
    localStorage.setItem('dashboardViewConfig', JSON.stringify(editResult))
    setEditResult({ ...{} })
  }

  const shouldShow = (key) => {
    const config = localStorage.getItem('dashboardViewConfig')
    if (!config) return true
    const configObj = JSON.parse(config)
    if (!(key in configObj)) return true
    return configObj[key] || isEditMode
  }

  const visibleCards = () => {
    const config = localStorage.getItem('dashboardViewConfig')
    let countVisibleCards = 0
    for (const [key, value] of Object.entries(JSON.parse(config))) {
      if (value === true) {
        countVisibleCards += 1;
      }
    }
    return countVisibleCards
  }

  const getEyeState = (key) => {
    const config = localStorage.getItem('dashboardViewConfig')
    if (!config) return true
    const configObj = JSON.parse(config)
    if (!(key in configObj)) return true
    return configObj[key]
  }

  return (
    <DashboardViewContext.Provider
      value={{
        isEditMode,
        handleToggleEdit,
        subscribeResult,
        writeResult,
        shouldShow,
        getEyeState,
      }}
    >
      {children}
    </DashboardViewContext.Provider>
  )
}

export default DashboardViewProvider
