import * as yup from 'yup'
import type { ValidationMode } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PointAdd, PointGroups } from 'src/models/pointGroups-model'

export const pointAddDefaultValues: PointAdd = {
  id: '',
  apName: '',
  geoAddress: '',
  geolatitude: '',
  geolongitude: '',
}

export const pointGroupsDefaultValues: PointGroups = {
  groupName: '',
  isActive: true,
  isArchived: false,
  apList: [],
  companyId: '',
}

const mode: keyof ValidationMode = 'all'

export const pointAddSchemaValidation = yup
  .object()
  .shape({
    id: yup.string().nullable(),
    apName: yup.string().nullable(),
    geoAddress: yup.string().nullable(),
    geoLatitude: yup.number().nullable(),
    geoLongitude: yup.number().nullable(),
  })
  .typeError('invalid')

export const pointGroupsSchemaValidation = yup.object({
  groupName: yup.string().required('required'),
  apList: yup.array().min(1, 'required'),
  isActive: yup.boolean(),
  isArchived: yup.boolean().default(false),
  companyId: yup
    .object()
    .typeError('invalid')
    .test((value, context) => {
      if (value.id || value.companyId) {
        return true
      }
      return context.createError({
        message: `required`,
        path: 'companyId',
      })
    }),
})

export const pointGroupsUseFormArgs = {
  mode,
  defaultValues: pointGroupsDefaultValues,
  resolver: yupResolver(pointGroupsSchemaValidation),
}

export interface PointGroupsTypes
  extends yup.InferType<typeof pointGroupsSchemaValidation> {}
