import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SmartphoneIcon from '@mui/icons-material/Smartphone'

import useProtectedPage from 'src/hooks/useProtectedPage'
import ActivityHistory from 'src/components/SideMenuAudit/ActivityHistory'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { getPermissions } from 'src/service/authService'
import { goToCreatePortal } from '../../routes/coordinator'
import PortalTable from './components/Table/PortalTable'

const Portal = () => {
  useProtectedPage()
  const { t } = useTranslation()
  const history = useNavigate()
  const permissions = JSON.parse(getPermissions())

  return (
    <Container
      sx={{
        mt: '96px',
      }}
    >
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <SmartphoneIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: '#FF5E1E',
              marginTop: '-4px',
            }}
          >
            {t('portal.title')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {
            permissions?.auditory?.includes('auditory:list') && <ActivityHistory path="portais" keySubtitle="portalName" />
          }
        </Box>
      </Box>

      <Button
        onClick={() => goToCreatePortal(history)}
        disabled={!permissions?.portal?.includes("portal:create")}
        variant="outlined"
        sx={{
          width: '50%',
          gap: '6px',
          height: '36px',
          mb: '40px',
          ':hover': {
            backgroundColor: '#FF5E1E',
            color: '#ffff',
          },
        }}
      >
        <AddIcon sx={{ width: '24px', height: '24px' }} />
        {t('portal.button.create')}
      </Button>

      <PortalTable />
    </Container>
  )
}
export default Portal
