import { HTMLAttributes, ReactNode } from 'react'
import { useController } from 'react-hook-form'
import { t } from 'i18next'
import Autocomplete, {
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { InputRHFProps } from 'src/models/inputRHF-model'

interface AutocompleteRHFProps<T> extends InputRHFProps {
  options: T[] | string[] | null
  placeholder?: string
  forcePopupIcon?: boolean
  freeSolo?: boolean
  popupIcon?: boolean
  disableClearable?: boolean
  disableListWrap?: boolean
  noOptionsText?: string
  multiple?: boolean
  loading?: boolean
  loadingText?: string
  defaultValue?: any
  isOptionEqualToValueFn?: (option: T, value: any) => boolean
  getOptionLabel?: (option: T) => string
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => ReactNode
}

const InputSelectRHF = <T,>(props: AutocompleteRHFProps<T>) => {
  const {
    control,
    name,
    label,
    required,
    options,
    sx,
    noOptionsText,
    multiple,
    loading,
    loadingText,
    isOptionEqualToValueFn,
    disabled,
    getOptionLabel,
    renderOption,
    onChangeAction,
    popupIcon,
    freeSolo,
    helperText,
    disableClearable,
    placeholder,
    defaultValue,
    forcePopupIcon,
  } = props
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { invalid, error },
  } = useController({ name, control })

  const onChangePropagator = (_: any, newValue: typeof options) => {
    onChange(newValue)
    onChangeAction?.(newValue)
  }


  return (
    <Autocomplete
      autoComplete={false}
      renderInput={(params) => (
        <TextField
          {...params}
          required={Boolean(required)}
          error={invalid}
          helperText={t(error?.message || helperText)}
          FormHelperTextProps={{ 'aria-live': invalid ? 'polite' : 'off' }}
          label={label}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
      onBlur={onBlur}
      ref={ref}
      loading={loading}
      loadingText={t('list.loading')}
      disabled={disabled}
      options={options || []}
      value={value}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      onChange={onChangePropagator}
      multiple={multiple}
      sx={{
        '.MuiFormHelperText-root.Mui-error': {
          m: 0,
        },
        ...sx,
      }}
      isOptionEqualToValue={isOptionEqualToValueFn}
      popupIcon={popupIcon}
      freeSolo={freeSolo}
      disableClearable={disableClearable}
      forcePopupIcon={forcePopupIcon}
      renderOption={renderOption}
      defaultValue={defaultValue}
    />
  )
}

export default InputSelectRHF
