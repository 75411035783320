import { Company } from 'src/models/company-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

import axios from 'axios'
export const instance = axios.create()

export const getCompanies = async (options?: any) => {
  try {
    const res = await client().get(`${BASE_URL}/company`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getCompanyById = async (
  id: string
): Promise<Company | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/company/${id}`)
    return res.data
  } catch (error) { }
}

export const getLogoCompany = async (): Promise<Company | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/assets/company/`)
    return res.data
  } catch (error) { }
}

export const createCompany = async (
  company: Company
): Promise<Company | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/company`, company)
    return res.data
  } catch (error) {
    return false
  }
}

export const editCompany = async (
  id: string,
  company: Company
): Promise<boolean> => {
  try {
    const result = await client().patch(`${BASE_URL}/company/${id}`, company)
    return result.status === 200
  } catch (error) {
    return false
  }
}

export const recycleCompanyById = async (id: string): Promise<Company | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/company/${id}/enable`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const deleteCompanyById = async (
  id: string | number
): Promise<boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/company/${id}`)
    return true
  } catch (error) {
    return false
  }
}

export const searchZipcode = async (zipcode: string) => {
  try {
    const res = await instance.get(`https://viacep.com.br/ws/${zipcode}/json/`)
    return res.data
  } catch (error) {
    return false
  }
}

export type CompaniesData = {
  id: string
  companyName: string
  companyLogo: string
  companyLogoSmall: string
  isActive: boolean
}

export const getCompaniesLogoAndName = async (): Promise<CompaniesData[]> => {
  try {
    const res = await client().get(`${BASE_URL}/assets/companies/`)
    return res.data.companiesData
  } catch (error) { }
}
