import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined'
import { useState } from 'react'
import { t } from 'i18next'

export const Legend = () => {
  const [isOpen, setIsOpen] = useState(true)
  const colorLegend = [
    'heatmap.legend.color.1',
    'heatmap.legend.color.2',
    'heatmap.legend.color.3',
  ]
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: isOpen ? 'flex-end' : 'center',
        width: 'auto',
        height: 'auto',
        gap: '20px',
      }}
    >
      <Tooltip
        placement="left-start"
        title={
          <div style={{ padding: '5px' }}>
            <Typography sx={{ fontWeight: '700', fontSize: '14px' }}>
              {t('heatmap.legend.tip.1')}
            </Typography>
            <Typography sx={{ fontWeight: '700', fontSize: '14px', ml: 2 }}>
              {t('heatmap.legend.tip.2')}
            </Typography>
          </div>
        }
      >
        <InfoIcon
          sx={{
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
        />
      </Tooltip>
      <Box
        sx={{
          backgroundColor: 'white',
          paddingX: '15px',
          paddingY: '10px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);',
          borderRadius: '4px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
            height: 'auto',
            gap: '20px',
          }}
        >
          <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
            {t('heatmap.legend.title')}
          </Typography>
          <IconButton
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              ':hover': {
                color: '#FF5E1E',
              },
            }}
          >
            <ArrowDropDownCircleOutlinedIcon
              sx={{
                transform: !isOpen ? 'rotate(180deg)' : '',
                transition: '.5s',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            />
          </IconButton>
        </Box>

        {isOpen && (
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                minHeight: '80px',
                minWidth: '20px',
                background: `linear-gradient(180deg,
                rgba(244, 67, 54, 0.8) 14.58%,
                rgba(255, 167, 38, 0.8) 34.9%,
                rgba(255, 167, 38, 0.8) 67.19%,
                rgba(34, 238, 121, 0.8) 100%);`,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                gap: '5px',
              }}
            >
              {colorLegend.map((legend, i) => {
                return (
                  <Box
                    key={legend}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <ArrowLeftOutlinedIcon
                      sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.87)' }}
                    />
                    <Typography>{t(legend)}</Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
