import moment from 'moment'

export const formatDate = (value: any) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY')
  }
  return ''
}

export const formatTime = (value: any) => {
  if (value) {
    return moment(value).format('hh:mm A')
  }
  return ''
}

export const formatDateAndTime = (value: any) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm ')
  }
  return ''
}
