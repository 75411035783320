import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useController } from 'react-hook-form'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { SwitchRHFProps } from 'src/models/inputRHF-model'

export default function SwitchRHF(props: SwitchRHFProps) {
  const {
    name,
    control,
    label,
    labelPlacement,
    sx,
    onChangeAction,
    disabled,
    onChangeInterceptor,
  } = props
  const { field } = useController({ name, control })
  const { value, onChange } = field
  const { t } = useTranslation()

  const onChangePropagator = (event: SyntheticEvent, checked: boolean) => {
    onChangeAction && onChangeAction(checked)
    if (onChangeInterceptor) {
      onChangeInterceptor(onChange, checked)
    } else {
      onChange(event, checked)
    }
  }

  if (props?.disableLabel) {
    return (
      <FormControlLabel
        control={<Switch />}
        {...field}
        onChange={onChangePropagator}
        checked={value}
        disabled={disabled}
        label={label}
        labelPlacement={labelPlacement}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '4px',
        borderInline: '1px solid #00000040',
        borderBottom: '1px solid #00000040',
        boxShadow: 'none',
        height: '54.5px',
        mt: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: '12px',
          color: '#00000099',
          marginBlockStart: '-8px',
        }}
      >
        {value
          ? props.labelNameOn
            ? t(props.labelNameOn)
            : t('form.input.label.active')
          : props.labelNameOff
          ? t(props.labelNameOff)
          : t('form.input.label.inactive')}
      </Typography>
      <Box >
        <FormControlLabel
          control={<Switch sx={{ ml: 3}}/>}
          {...field}
          onChange={onChangePropagator}
          checked={value}
          disabled={disabled}
          label={label}
          labelPlacement={labelPlacement}
          sx={{ alignItems: 'baseline' }}
        />
      </Box>
    </Box>
  )
}
