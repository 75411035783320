import { Portal } from 'src/models/portal-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const getPortals = async (options: any) => {
  try {
    const res = await client().get(`${BASE_URL}/captive`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getPortalById = async (
  id: string
): Promise<Portal | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/captive/${id}`)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const createPortal = async (data: any): Promise<Portal | boolean> => {
  const portal = {
    companyId: data.companyId.id,
    portalName: data.portalName,
    buttonColor: data.buttonColor,
    isActive: data.isActive,
    isArchived: data?.isArchived,
    textColor: data.textColor,
    logoImage: data.logoImage,
    backgroundImage: data.backgroundImage,
    termsAgreementTitle: data.termsAgreementTitle,
    termsAgreementContent: data.termsAgreementContent,
    containerColor: data.containerColor,
    containerTextColor: data.containerTextColor,
  }

  try {
    const res = await client().post(`${BASE_URL}/captive`, portal)
    return res.data
  } catch (error) {
    return false
  }
}

export const editPortal = async (
  id: string,
  data: any
): Promise<Portal | boolean> => {
  const portal = {
    companyId: data.companyId.id,
    portalName: data.portalName,
    buttonColor: data.buttonColor,
    containerColor: data.containerColor,
    containerTextColor: data.containerTextColor,
    isActive: data.isActive,
    isArchived: data?.isArchived,
    textColor: data.textColor,
    logoImage: data.logoImage,
    backgroundImage: data.backgroundImage,
    termsAgreementTitle: data.termsAgreementTitle,
    termsAgreementContent: data.termsAgreementContent,
  }

  try {
    const res = await client().patch(`${BASE_URL}/captive/${id}`, portal)
    return res.data
  } catch (error) {
    return false
  }
}

export const deletePortalById = async (
  id: string | number
): Promise<boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/captive/${id}`)
    return true
  } catch (error) {
    return false
  }
}

export const recyclePortalById = async (id: string): Promise<Portal | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/captive/${id}/enable`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const unarchivePortalById = async (id: string): Promise<Portal | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/captive/${id}/unarchive`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const getPortalConfigByCompanyId = async (
  companyId: string
): Promise<Portal | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/captive_company/${companyId}`)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const getPortalIsActive = async (
  companyId: string
): Promise<Portal | undefined> => {
  try {
    const res = await client().get(
      `${BASE_URL}/has_active/captive/${companyId}`
    )
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const inactivePortalsByCompanyId = async (
  companyId: string
): Promise<Portal | boolean> => {
  try {
    const res = await client().patch(
      `${BASE_URL}/has_active/captive/${companyId}`,
      {}
    )
    return res.data
  } catch (error) {
    return false
  }
}


// /toggle/campaign/:marketingCampaignId

export const togglePortal = async (
  id: string
): Promise<Portal | boolean> => {
  try {
    const res = await client().patch(
      `${BASE_URL}/toggle/captive/${id}`,
      {}
    )
    return res.data
  } catch (error) {
    return false
  }
}
