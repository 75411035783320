import { SelectChangeEvent } from '@mui/material'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { getRole } from 'src/service/authService'
import { CompaniesData, getCompaniesLogoAndName } from 'src/service/companyService'

interface GlobalSelectedCompanyContextData {
  selectedCompanyId: string
  selectedCompanyData: CompaniesData
  allCompaniesData: CompaniesData[]
  onChangeSelectedCompanyId: (value: string) => void
  onChangeSelectedCompanyData: (event: SelectChangeEvent) => void
  onUnselectCompany: () => void
  isGlobalAdminSelected: () => boolean
}

interface ContextProviderProps {
  children: ReactNode
}

export const GlobalSelectedCompanyContext = createContext({} as GlobalSelectedCompanyContextData)

export function GlobalSelectedCompanyProvider ({ children }: ContextProviderProps) {
  const [allCompaniesData, setAllCompaniesData] = useState<CompaniesData[]>([])
  const [selectedCompanyData, setSelectedCompanyData] = useState<CompaniesData | null>(null)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('global-sys-admin')

  const getCompaniesData = async () => {
    const data = await getCompaniesLogoAndName()
    setAllCompaniesData(data)
  }

  const onChangeSelectedCompanyData = (event: SelectChangeEvent) => {
    onChangeSelectedCompanyId(event.target.value as string)
    const selected = allCompaniesData.find((item) => item.id === event.target.value)
    setSelectedCompanyData(selected)
  }

  const onChangeSelectedCompanyId = (value: string) => {
    setSelectedCompanyId(value)
  }

  const onUnselectCompany = () => {
    setSelectedCompanyId('global-sys-admin')
    setSelectedCompanyData(null)
  }

  const isGlobalAdminSelected = () => {
    return selectedCompanyId === 'global-sys-admin'
  }

  useEffect(() => {
    if (getRole() === 'TECNOIT') {
      getCompaniesData()
    }
  }, [])

  return (
    <GlobalSelectedCompanyContext.Provider
      value={{
        selectedCompanyId,
        selectedCompanyData,
        allCompaniesData,
        onChangeSelectedCompanyId,
        onChangeSelectedCompanyData,
        onUnselectCompany,
        isGlobalAdminSelected,
      }}
    >
      {children}
    </GlobalSelectedCompanyContext.Provider>
  )
}

export function useGlobalSelectedCompany () {
  const context = useContext(GlobalSelectedCompanyContext)
  return context
}
