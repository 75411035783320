import { useTranslation } from 'react-i18next'
import { SxProps, Typography } from '@mui/material'
import { Box } from '@mui/system'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'

import { Card } from './Card'
import { MegaphoneDarkgreyOffIcon } from 'src/components/Icons/MegaphoneDarkgreyOffIcon'
import { MegaphoneGreenOffIcon } from 'src/components/Icons/MegaphoneGreenOffIcon'

interface Props {
  sx?: SxProps
  dataCampaign: {
    isActive?: Boolean
    initialDate?: Date
    finalDate?: Date
  }
}

export const Situation = ({ dataCampaign, sx }: Props) => {
  const { t } = useTranslation()

  function situationCampaign() {
    if (!dataCampaign.isActive) {
      return 'inactive'
    }
    if (new Date(dataCampaign.initialDate) > new Date()) {
      return 'active'
    }
    if (
      new Date() > new Date(dataCampaign.finalDate) &&
      dataCampaign.isActive
    ) {
      return 'finished'
    }
    if (
      new Date() < new Date(dataCampaign.finalDate) &&
      new Date() > new Date(dataCampaign.initialDate)
    ) {
      return 'running'
    }
  }

  function getLabelColor() {
    if (situationCampaign() == 'active') {
      return '#FF5E1E'
    } else if (situationCampaign() == 'inactive') {
      return 'rgba(0, 0, 0, 0.6)'
    } else if (situationCampaign() == 'finished') {
      return '#4CAF50'
    } else {
      return 'rgba(255, 167, 38, 1)'
    }
  }

  function getIconColor() {
    if (situationCampaign() == 'active') {
      return '#FF5E1E'
    } else if (situationCampaign() == 'inactive') {
      return 'rgba(0, 0, 0, 0.6)'
    } else if (situationCampaign() == 'finished') {
      return '#4CAF50'
    } else {
      return 'rgba(255, 167, 38, 1)'
    }
  }
  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        p: '16px',
        px: '31px',
        ...sx,
      }}
    >
      <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6);' }}>
        {t('campaign.result.situation.title')}
      </Typography>

      {situationCampaign() == 'active' ? (
        <Typography
          sx={{
            fontSize: '34px',
            color: getLabelColor(),
            mt: 1,
          }}
        >
          {t('table.status.active')}
        </Typography>
      ) : (
        ''
      )}

      {situationCampaign() == 'inactive' ? (
        <Typography
          sx={{
            fontSize: '34px',
            color: getLabelColor(),
            mt: 1,
          }}
        >
          {t('table.status.inactive')}
        </Typography>
      ) : (
        ''
      )}

      {situationCampaign() == 'finished' ? (
        <Typography
          sx={{
            fontSize: '34px',
            color: getLabelColor(),
            mt: 1,
          }}
        >
          {t('table.status.finished')}
        </Typography>
      ) : (
        ''
      )}

      {situationCampaign() == 'running' ? (
        <Typography
          sx={{
            fontSize: '30px',
            color: getLabelColor(),
            mt: 1,
            width:'217px',
          }}
        >
          {t('table.status.running')}
        </Typography>
      ) : (
        ''
      )}

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'left',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            color: getLabelColor(),
            mt: 1,
          }}
        >
          {new Date(dataCampaign.initialDate).toLocaleDateString()}
          {' -'}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            color:
              situationCampaign() !== 'running'
                ? getLabelColor()
                : 'rgba(0, 0, 0, 0.6)',
            mt: 1,
            marginLeft: '2px',
          }}
        >
          {new Date(dataCampaign.finalDate).toLocaleDateString()}
        </Typography>
      </Box>

      {situationCampaign() == 'active' ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            marginTop: '-5px',
            justifyContent: 'center',
          }}
        >
          <CampaignOutlinedIcon
            sx={{
              fontSize: '3rem',
              color: getIconColor(),
              mt: 1.5,
            }}
          />
        </Box>
      ) : (
        ''
      )}

      {situationCampaign() == 'inactive' ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'center',
          }}
        >
          <MegaphoneDarkgreyOffIcon />
        </Box>
      ) : (
        ''
      )}

      {situationCampaign() == 'finished' ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'center',
          }}
        >
          <MegaphoneGreenOffIcon />
        </Box>
      ) : (
        ''
      )}

      {situationCampaign() == 'running' ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            marginTop: '-5px',
            justifyContent: 'center',
          }}
        >
          <CampaignOutlinedIcon
            sx={{
              fontSize: '3rem',
              color: getIconColor(),
              mt: 1.5,
            }}
          />
        </Box>
      ) : (
        ''
      )}
    </Card>
  )
}
