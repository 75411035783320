export const ReturneesUserIcon = () => {
  return (
    <svg
      width="42"
      height="31"
      viewBox="0 0 42 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.909 7.75C22.909 3.46812 19.4918 0 15.2727 0C11.0536 0 7.6363 3.46812 7.6363 7.75C7.6363 12.0319 11.0536 15.5 15.2727 15.5C19.4918 15.5 22.909 12.0319 22.909 7.75ZM-6.10352e-05 27.125V31H30.5454V27.125C30.5454 21.9713 20.3699 19.375 15.2727 19.375C10.1754 19.375 -6.10352e-05 21.9713 -6.10352e-05 27.125Z"
        fill="#FF5E1E"
      />
      <path
        d="M34.3636 17.6812L39.0828 20.3438L37.8305 15.3256L41.9999 11.9493L36.5094 11.5138L34.3636 6.78125L32.2178 11.5138L26.7272 11.9493L30.8967 15.3256L29.6443 20.3438L34.3636 17.6812Z"
        fill="#FF5E1E"
      />
    </svg>
  )
}
