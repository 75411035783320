import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Chip } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'src/components/DropDown/DropDown'
import { UploadIcon } from 'src/components/Icons/UploadIcon'
import { NumberSort, StringSort } from 'src/components/Table/SortingFunctions'
import { Table } from 'src/components/Table/Table'
import UploadFile from 'src/components/UploadFile/UploadFile'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { goToEditPoint } from 'src/routes/coordinator'
import AccessPointCsvService from 'src/service/AccessPointCsvExporter'
import { deleteAccessPointById, getAccessPoints, recycleAccessPointById, unarchiveAccessPointById } from 'src/service/accesspointService'
import { getPermissions } from 'src/service/authService'
import CreatePoint from '../../CreatePoint'

export default function PointTable() {
  const { t } = useTranslation()
  const { createCRUDAlert, createAlert } = useContext(AlertContext)
  const [tableKey, setTableKey] = useState(0)
  const fileSelectRef = useRef(null)
  const permissions = JSON.parse(getPermissions())

  const getData = async (options) => {
    const data = await getAccessPoints(options)
    return {
      data: data?.listAccessPoints,
      totalPages: data?.page,
      size: data?.size,
    }
  }

  const handleDelete = async (id, name) => {
    const res = await deleteAccessPointById(id)
    if (res) {
      createCRUDAlert(ROUTES.POINT, name, 'deleted')
    }
  }

  const handleRecycle = async (id, name) => {
    const res = await recycleAccessPointById(id)
    if (res) {
      createCRUDAlert(ROUTES.POINT, name, 'edited')
    }
  }

  const handleUnarchive = async (id, name) => {
    const res = await unarchiveAccessPointById(id)
    if (res) {
      createCRUDAlert(ROUTES.POINT, name, 'edited')
    }
  }

  const handleExportToCsv = async () => {
    try {
      window.addEventListener('beforeunload', handleBeforeUnload)
      createAlert(ROUTES.POINT, t('points.export.alert.loading'), 'warning')
      const exporter = new AccessPointCsvService([])
      const result = exporter.exportCsv()
      if (!result) throw new Error()
      createAlert(ROUTES.POINT, t('points.export.alert.success'))
    } catch (error) {
      createAlert(ROUTES.POINT, t('points.export.alert.error'), 'error')
    } finally {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }

  const handleImportCsv = async (file) => {
    try {
      window.addEventListener('beforeunload', handleBeforeUnload)
      createAlert(ROUTES.POINT, t('points.import.alert.loading'), 'warning')
      await AccessPointCsvService.importCsv(file)
      createAlert(ROUTES.POINT, t('points.import.alert.success'))
    } catch (error) {
      createAlert(ROUTES.POINT, t('points.import.alert.error'), 'error')
    } finally {
      // Atualiza a tabela
      setTableKey(tableKey + 1)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault()
    event.returnValue = ''
  }

  const columns: GridColumns = [
    {
      field: 'apName',
      headerName: t('table.header.point'),
      width: 125,
      editable: false,
    },
    {
      field: 'isActive',
      headerName: t('table.header.situation'),
      type: 'boolean',
      editable: false,
      renderCell: ({ row }) => {
        return row.isActive ? (
          <Chip
            sx={{
              background: 'rgba(255, 94, 30, 0.12)',
            }}
            size="small"
            label={t('table.status.active')}
          />
        ) : (
          <Chip size="small" label={t('table.status.inactive')} />
        )
      },
    },
    {
      field: 'companyName',
      headerName: t('table.header.company'),
      width: 150,
      type: 'number',
      editable: false,
      headerAlign: 'left',
      align: 'left',
      valueGetter: ({ row }) => row?.company?.companyName || '',
      sortComparator: StringSort,
    },
    {
      field: 'geoAddress',
      headerName: t('table.header.address'),
      width: 190,
      type: 'number',
      editable: false,
      headerAlign: 'left',
      align: 'left',
      sortComparator: StringSort,
    },
    {
      field: 'ipAddress',
      headerName: 'IP',
      type: 'number',
      width: 140,
      editable: false,
      headerAlign: 'left',
      align: 'left',
      sortComparator: NumberSort,
    },
  ]

  return (
    <>
      <UploadFile
        onReadCallback={handleImportCsv}
        accept=".csv"
        type="file"
        hidden
        id="pointfile"
      />
      <label ref={fileSelectRef} htmlFor="pointfile"></label>
      <Table
        getData={getData}
        key={tableKey}
        columns={columns}
        goTo={goToEditPoint}
        titleKey={'apName'}
        handleDelete={handleDelete}
        defaultPageSize={30}
        model={'accessPoint'}
        Children={CreatePoint}
        size={'16px'}
        mr={'24px'}
        aditionalButtons={
          <Dropdown
            pos="pre"
            icon={<FileDownloadOutlinedIcon />}
            title={t('points.export.menu.title')}
            itens={[
              {
                icon: <FileDownloadOutlinedIcon sx={{ color: '#00000099' }} />,
                title: t('export.point.model'),
                onClick: handleExportToCsv,
              },
              {
                icon: <UploadIcon />,
                title: t('points.export.button.import.title'),
                onClick: () => fileSelectRef?.current?.click(),
              },
            ]}
          />
        }
        permissionUpdate={!permissions?.accesspoint?.includes("accesspoint:update")}
        permissionDelete={!permissions?.accesspoint?.includes("accesspoint:delete")}
        permissionView={!permissions?.accesspoint?.includes("accesspoint:view")}
        canShowRecycleButtons={true}
        permissionViewRecycle={permissions?.accesspoint?.includes("accesspoint:recycle:view")}
        permissionUpdateRecycle={permissions?.accesspoint?.includes("accesspoint:recycle:update")}
        handleRecycle={handleRecycle}
        canShowUnarchiveButtons={true}
        permissionViewUnarchive={permissions?.accesspoint?.includes("accesspoint:archive:view")}
        permissionUpdateUnarchive={permissions?.accesspoint?.includes("accesspoint:archive:update")}
        handleUnarchive={handleUnarchive}
        canShowProfileByAccessPoint={permissions?.reports?.includes("reports:ap-profile")}
      />
    </>
  )
}
