import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ErrorIcon from '@mui/icons-material/Error'

const style = {
  boxSizing: ' border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  minHeight: '266px',
  heigth: 'auto',
  width: '376px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: 24,
  p: '16px 16px 24px 16px',
  borderRadius: '4px',
  gap: '24px',
}

export interface ModalActionTableInfoProps {
  open: boolean
  onHandleClose(): void
  onHandleDeleteClick(): void
  name: string
  title: string
}

export function ModalActionTableInfo (props: ModalActionTableInfoProps) {
  const { t } = useTranslation()

  return (
    <Modal
      open={props.open}
      onClose={props.onHandleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            width: 'auto',
          }}
        >
          <IconButton
            onClick={props.onHandleClose}
            size={'small'}
            sx={{
              left: '1px',
              color: '#00000099',
              ':hover': {
                color: '#FF5E1E',
                backgroundColor: '#FF5E1E14',
              },
            }}
          >
            <ClearIcon sx={{ height: '16px', width: '16px', left: '1px' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ErrorIcon sx={{ color: '#FF5E1E', mb: '8px' }} />

          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '29px',
              letterSpacing: '0.18px',
              color: '#FF5E1E',
              textAlign: 'center',
            }}
          >
            {t(props.title)}
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center',
              }}
            >
              {props.name}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={props.onHandleDeleteClick}
            variant="outlined"
            sx={{
              minWidth: '344px',
              height: '40px',
              gap: 1,
              color: '#FF5E1E',
              border: '1px solid #FF5E1E',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
            }}
          >
            {
              props.title === 'table.modal.delete.title' ?
                <>
                  <DeleteForeverIcon />
                  {t('modal.button.delete')}
                </> :
                <>
                  <CheckCircleIcon />
                  {t('modal.button.confirm')}
                </>
            }
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
