import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import { Box, Button } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'

import { getSystemEnrichments } from 'src/service/marketingCampaignService'
import { Divider } from '../Dividers/Divider'
import { Question } from './Question'

export const SegmentationForm = ({
  formState: { control, setValue, getValues, trigger, watch },
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  })
  const [enrichment, setEnrichment] = useState([])

  const getEnrichmentList = async () => {
    const data = await getSystemEnrichments({})
    setEnrichment(data?.listEnrichmentProfile || [])
  }

  useEffect(() => {
    getEnrichmentList()
  }, [])

  useEffect(() => {
    if (fields.length === 0) append({})
  }, [watch()])
  const removeDuplicatedOptions = (options = []) => {
    return enrichment.filter(
      (enrichment) => !options.find((option) => option.name === enrichment.name)
    )
  }
  return (
    <Box
      sx={{
        display: 'block',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        height: 'auto',
        gap: '25px',
      }}
    >
      {fields.map((field, i) => {
        return (
          <div key={i}>
            <Question
              sx={{
                width: '100%',
              }}
              formState={{ control, setValue, getValues, trigger, watch }}
              key={field?.id}
              name={`questions[${i}]`}
              onRemove={() => {
                remove(i)
              }}
              enrichment={removeDuplicatedOptions(getValues('questions'))}
            />
            {fields.length > 1 && (
              <Box
                sx={{
                  mt: '30px',
                  mb: '20px',
                }}
              >
                <Divider />
              </Box>
            )}
          </div>
        )
      })}
      {fields.length < 3 && (
        <Button
          onClick={() => append({})}
          variant="outlined"
          sx={{
            height: '50px',
            width: '100%',
            borderRadius: '4px',
            mt: '20px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
        >
          <AddBoxOutlinedIcon
            sx={{
              height: '24px',
              minWidth: '24px',
              mr: 1,
            }}
          />
          {t('campaign.button.segmentation.add.button')}
        </Button>
      )}
    </Box>
  )
}
