import { Select, MenuItem, Typography } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import SmallTecnoItLogo from '../../assets/icons/IT-SIMBOLO-MENU-REDUZIDO.svg'
import { useGlobalSelectedCompany } from "src/context/GlobalSelectedCompanyContext"

export const TecnoitCompanySelection = () => {
  const {
    selectedCompanyId,
    selectedCompanyData,
    allCompaniesData,
    onChangeSelectedCompanyData,
    onUnselectCompany
  } = useGlobalSelectedCompany()

  return (
    <>
      {
        selectedCompanyId !== 'global-sys-admin' &&
        <DeleteOutlineIcon
          onClick={onUnselectCompany}
          sx={{
            marginTop: selectedCompanyData.companyLogo === '' ? '-3rem' : '-4.5rem',
            marginRight: '-1rem',
            zIndex: '9999',
            backgroundColor: 'white',
            borderRadius: '50%',
            ':hover': {
              cursor: 'pointer',
              color: '#FF5E1E',
            }
          }}
        />
      }
      <Select
        labelId="company-selection-dropdown-label"
        id="company-selection-dropdown"
        value={selectedCompanyId}
        onChange={onChangeSelectedCompanyData}
        fullWidth
        disableUnderline
        variant="standard"
        sx={{
          '.MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
        }}
      >
        <MenuItem value="global-sys-admin">
          <img
            src={SmallTecnoItLogo}
            style={{
              maxWidth: '124px',
              maxHeight: '55px'
            }}
            title="Administrador do Sistema"
            alt="Administrador do Sistema"
          />
          <Typography variant="h6" sx={{ paddingLeft: '10px' }}>Admin</Typography>
        </MenuItem>
        {
          allCompaniesData?.map((company) => {
            return (
              <MenuItem key={company.id} value={company.id}>
                {
                  company.companyLogo === '' ?
                    company.companyName :
                    <img
                      style={{
                        maxWidth: '124px',
                        maxHeight: '90px'
                      }}
                      src={company.companyLogo}
                      title={company.companyName}
                      alt={company.companyName}
                    />
                }
              </MenuItem>
            )
          })
        }
      </Select>
    </>
  )
}
