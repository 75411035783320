import { ReactNode, useEffect, useState } from 'react'
import { t } from 'i18next'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Box, CircularProgress, Typography } from '@mui/material'

import UploadFile from '../../../../components/UploadFile/UploadFile'
import { uploadFileImg } from 'src/service/uploadservice'
interface Props {
  onFileChange: (file) => void
  placeholder: string
  acceptedFormats: string
  icon: ReactNode
  value?: string
  isView?: boolean
  size: string
  error: string
  isValid: any
}
export const UploadPortalPhoto = ({
  onFileChange,
  placeholder,
  acceptedFormats,
  icon,
  value,
  isView,
  size,
  error,
  isValid,
}: Props) => {
  const [file, setFile] = useState<string>(value)
  const [isLoading, setIsLoading] = useState(false)
  const onReadFile = async (file) => {
    setIsLoading(true)
    const res = await uploadFileImg(file)
    const fileUrl = res.data.file
    onFileChange(fileUrl)
    setFile(fileUrl)
    setIsLoading(false)
  }
  useEffect(() => {
    setFile(value)
  }, [value])

  const handleClick = () => {
    document.getElementById(placeholder).click()
  }

  const handleDownload = () => {
    if (isView) {
      const link = document.createElement('a')
      link.download = file
      link.href = file
      link.click()
    }
  }

  const borderColor = () => {
    if (file) {
      return ' 1px solid #FF5E1E'
    }
    if (isValid) {
      return ' 2.5px dashed #d32f2f'
    } else {
      return ' 2.5px dashed rgba(0, 0, 0, 0.38)'
    }
  }

  return (
    <Box sx={{ width: '49%' }}>
      <Box
        onClick={() => {
          !file && handleClick()
          handleDownload()
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',

          height: isView ? '90px' : '120px',
          border: borderColor(),
          borderBottom: file && 'solid 4px #FF5E1E',
          backgroundColor: file && 'rgba(255, 94, 30, 0.08)',
          mt: 1.5,
          gap: '10px',
          color: isValid ? '#d32f2f' : 'rgba(0, 0, 0, 0.38)',
          ':hover': {
            color: '#FF5E1E',
            borderColor: '#FF5E1E',
          },
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        <UploadFile
          onReadCallback={onReadFile}
          hidden
          type="file"
          accept=".png,.jpg"
          id={placeholder}
        />

        <Box
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
          }}
        >
          {file || isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Box
                sx={{
                  width: 'auto',
                  minWidth: '230px',
                  minHeight: '80px',
                  wordBreak: 'break-all',
                  backgroundImage: file ? `url(${file})` : 'none',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {isLoading && (
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                    <Typography sx={{ color: '#FF5E1E', fontWeight: 500 }}>
                      {t('company.form.input.placeholder.logo.loading')}
                    </Typography>
                  </div>
                )}
              </Box>
              {!isLoading && (
                <DeleteForeverIcon
                  sx={{
                    color: '#00000099',
                    cursor: 'pointer',
                    display: isView ? 'none' : 'flex',
                  }}
                  onClick={() => {
                    onFileChange(undefined)
                    setFile(undefined)
                  }}
                />
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: 1,
              }}
            >
              {icon}
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '16px',
                  letterSpacing: '1.25px',
                }}
              >
                <label htmlFor={placeholder} style={{ cursor: 'pointer' }}>
                  {placeholder}
                </label>
                <br></br>
                <label
                  htmlFor={acceptedFormats}
                  style={{ cursor: 'pointer', fontSize: '10px' }}
                >
                  {acceptedFormats}
                </label>
                <br></br>
                <label
                  htmlFor={acceptedFormats}
                  style={{ cursor: 'pointer', fontSize: '10px' }}
                >
                  {size}
                </label>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {isValid && (
        <Typography
          align="left"
          sx={{
            color: ' #d32f2f',
            fontSize: '12px',
            mt: '05px',
            display: file ? 'none' : '',
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  )
}
