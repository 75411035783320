import { useContext, useEffect } from 'react'
import { t } from 'i18next'
import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'

import { StepperContext } from 'src/context/StepperContext'

type Item = {
  content: JSX.Element
  label: string
  icon: JSX.Element
}

interface Props {
  itens: Array<Item>
  isView?: any
}

export const Stepper = ({ itens, isView }: Props) => {
  const { initStepper, step } = useContext(StepperContext)

  useEffect(() => {
    initStepper(itens.length)
  }, [itens])

  return (
    <Box
      sx={{
        boxShadow: 'none',
        border: '1px solid #0000001F',
        borderRadius: '4px',
        mb: isView ? '' : '24px',
        mt: '16px',
        width: '100%',
        overflow: isView ? 'auto' : '',
        height: isView ? '70vh' : '',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTabs-flexContainer': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
          },
        }}
      >
        <Tabs value={step}>
          {itens.map(({ label, icon }, index) => (
            <Tab
              sx={{
                width: '50%',
                maxWidth: '50%',
                height: '72px',
                ':disabled': { color: step === index ? '#FF5E1E' : '' },
              }}
              icon={icon}
              label={t(label)}
              disabled={true}
              key={label}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          p: '16px',
          pt: '32px',
          width: '100%',
          minHeight: '284px',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'top',
          flexDirection: ' column',
        }}
      >
        {itens[step] && itens[step].content}
      </Box>
    </Box>
  )
}
