import { Select, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { getCompanyId, getRole } from 'src/service/authService'
import LogoItBuzz from '../../assets/icons/itBuzz.png'
import LogoTecnoIt from '../../assets/icons/tecnoIT.png'
import DefaultBG from '../../assets/icons/default-bg-preview.png'
import { useEffect, useState } from 'react'
import { getPortalConfigByCompanyId } from 'src/service/portalService'
import { Loading } from '../Loading/Loading'
import version from '../../../package.json'

const defaultConfig = {
  buttonColor: 'rgba(229, 79, 18, 1)',
  textColor: '#FFF',
  backgroundImage: DefaultBG,
  logoImage: LogoItBuzz,
}
export const PreviewTemplate = ({ children, onLoadConfig }) => {
  const [portalConfig, setPortalConfig] = useState<any>()
  const [loading, setLoading] = useState(false)
  const isAdmin = getRole() === 'TECNOIT'

  const getPortalConfig = async () => {
    setLoading(true)
    if (isAdmin) {
      setPortalConfig(defaultConfig)
      onLoadConfig(defaultConfig)
      setLoading(false)
      return
    }
    const config = await getPortalConfigByCompanyId(getCompanyId())
    setPortalConfig(config || defaultConfig)
    onLoadConfig(config)
    setLoading(false)
  }

  useEffect(() => {
    getPortalConfig()
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '70vh',
        maxHeight: '600px',
        overflowY: 'scroll',
        backgroundImage: `url('${portalConfig?.backgroundImage}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {loading && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading />
        </Box>
      )}
      {!loading && (
        <>
          <Box
            sx={{
              minHeight: '24px',
              width: '100%',
              bgcolor: '#1D1D1D',
            }}
          />
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              p: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'white',
              }}
            >
              <img src={portalConfig?.logoImage} width="100px" />
              <Select
                placeholder="PT"
                sx={{
                  height: '30px',
                  border: 'none',
                  width: '65px',
                  bgcolor: 'rgba(0, 0, 0, 0.6)',
                  fill: 'white',
                  ':disabled': { color: '#ffff' },
                  '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled':
                    {
                      WebkitTextFillColor: 'white',
                    },
                }}
                open={false}
                defaultValue="PT"
                disabled
              >
                <option value={'PT'}>
                  <Typography color="#ffff">PT</Typography>
                </option>
              </Select>
            </Box>
            {children}
            <Box
              sx={{
                height: '42px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
                bgcolor: 'rgba(61, 61, 61, 1)',
                borderRadius: '4px',
              }}
            >
              <img src={LogoItBuzz} width="50px" />
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '500',
                  color: 'rgba(255, 255, 255, 0.74)',
                }}
              >
                {version.version} ©2023
              </Typography>
              <img src={LogoTecnoIt} width="80px" />
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}
