import { SyntheticEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import { Box, Tab, Tabs, SxProps } from '@mui/material'
import { useForm } from 'react-hook-form'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { Devices } from './components/Result/Cards/Devices'
import { Navegators } from './components/Result/Cards/Navegators'
import { TotalView } from './components/Result/Cards/TotalView'
import { Visualization } from './components/Result/Cards/Visualization'
import { ActictiveAndaInactive } from './components/Result/Cards/ActiveAndInactive'
import { CampaignRanking } from './components/Result/Cards/CampaignRanking'
import CampaignResultHeader from './components/Result/CampaignResultHeader'
import { ResultService } from './components/Result/ResultService'
import { getCampaignResults } from 'src/service/marketingCampaignService'
import { Gender } from './components/Result/Cards/Gender'
import { AgeRange } from './components/Result/Cards/AgeRange'
import { MaritalStatus } from './components/Result/Cards/MaritalStatus'
import { Education } from './components/Result/Cards/Education'
import { Occupation } from './components/Result/Cards/Occupation'
import { Situation } from './components/Result/Cards/Situation'
import { Delivery } from './components/Result/Cards/Delivery'
import { defaultValues } from './validators/campaignSchemaValidator'
import { Questions } from './components/Result/Cards/Questions'
import { CustomTabPanel } from 'src/components/Tabs/CustomTabPanel'
import { a11yProps } from 'src/components/Tabs/Helper'

const visualizationMap = {
  text: {
    text: 'campaign.result.card.view.text',
    icon: <TextFormatIcon sx={{ fontSize: '4rem', mt: 2 }} />,
  },
  image: {
    text: 'campaign.result.card.view.image',
    icon: <InsertPhotoOutlinedIcon sx={{ fontSize: '4rem', mt: 2 }} />,
  },
  media: {
    text: 'campaign.result.card.view.video',
    icon: <PlayCircleOutlineIcon sx={{ fontSize: '4rem', mt: 2 }} />,
  },
  quiz: {
    text: 'campaign.result.card.view.search',
    icon: <FormatListNumberedIcon sx={{ fontSize: '4rem', mt: 2 }} />,
  },
}

const getGridColumns = (xs: number, sm: number, md: number, lg: number): SxProps => ({
  gridColumn: {
    xs: `span ${xs}`,
    sm: `span ${sm}`,
    md: `span ${md}`,
    lg: `span ${lg}`,
  }
})

const boxStyles: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  rowGap: 2,
  columnGap: 2,
  gridColumn: 'span 12',
  mt: '20px',
  mb: '16px',
}

export const CampaignResult = () => {
  useProtectedPage()
  const { t } = useTranslation()
  const [data, setData] = useState<any>()
  const [campaign, setCampaign] = useState()
  const [campaigntype, setCampaignType] = useState()
  const [selectedTab, setSelectedTab] = useState(0)
  const visualizationType = visualizationMap[campaigntype as string] || {}
  const resultService = new ResultService()
  const { id } = useParams()
  const form = useForm<any>({
    mode: 'all',
    defaultValues: defaultValues,
  })
  const { control, watch, setValue } = form

  const getData = async () => {
    if (id === 'todas') {
      const res = await getCampaignResults(campaign, campaigntype)
      setData(res)
    } else {
      const res = await getCampaignResults(id, campaigntype)
      setData(res)
    }
  }

  const handleSelectedTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    getData()
  }, [campaign, campaigntype])

  return (
    <Container sx={{ mt: '96px' }}>
      <AlertComponent />
      <CampaignResultHeader
        control={control}
        watch={watch}
        setValue={setValue}
        onCampaignChange={(v) => setCampaign(v)}
        onCampaignTypeChange={(v) => setCampaignType(v)}
        data={resultService.getDataCampaign(data)}
      />

      <Tabs value={selectedTab} onChange={handleSelectedTabChange} variant="fullWidth" sx={{ mt: '15px' }} aria-label="select-tab">
        <Tab sx={{ fontSize: '1.5rem' }} label={t("campaign.result.tabs.results")} {...a11yProps(0)} />
        <Tab sx={{ fontSize: '1.5rem' }} label={t("campaign.result.tabs.visitor.profile")} {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={selectedTab} index={0}>
        <Box sx={boxStyles}>
          {campaigntype === 'todas' && (
            <TotalView
              total={resultService.getTotalView(data)}
              sx={getGridColumns(12, 6, 6, 3)}
            />
          )}

          {campaigntype !== 'todas' && (
            <Visualization
              {...visualizationType}
              total={resultService.getTotalVisualization(data, campaigntype)}
              sx={getGridColumns(12, 6, 6, 3)}
            />
          )}
          <Navegators
            sx={getGridColumns(12, 6, 6, 3)}
            {...resultService.getNavegatorsData(data)}
          />
          <Devices
            sx={getGridColumns(12, 12, 12, 6)}
            params={resultService.getDevicesData(data)}
          />

          {campaigntype === 'todas' && (
            <>
              {Object.keys(visualizationMap)?.map((type) => {
                const visualizationType = visualizationMap[type]
                return (
                  <Box
                    sx={getGridColumns(12, 6, 6, 3)}
                  >
                    <Visualization
                      key={type}
                      {...visualizationType}
                      total={resultService.getTotalVisualization(data, type)}
                    />
                  </Box>
                )
              })}
            </>
          )}

          {watch('campaign')?.name !== 'Todas as campanhas' ? (
            <>
              <Situation
                sx={getGridColumns(12, 12, 6, 3)}
                dataCampaign={resultService.getDataCampaign(data)}
              />
              <Delivery sx={getGridColumns(12, 12, 6, 3)} />
            </>
          ) : (
            watch('contentType') && (
              <>
                <ActictiveAndaInactive
                  sx={getGridColumns(12, 12, 6, 6)}
                  activeTotal={resultService.getActiveTotal(data)}
                  inactiveTotal={resultService.getInactiveTotal(data)}
                />

                <CampaignRanking
                  sx={getGridColumns(12, 12, 6, 6)}
                  data={resultService.getRanking(data)}
                />
              </>
            )
          )}

          {watch('contentType') === 'quiz' &&
            watch('campaign')?.name !== 'Todas as campanhas' && (
              <Questions
                sx={getGridColumns(12, 12, 12, 6)}
              />
            )}
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <Box sx={boxStyles}>
            <>
              <Gender
                sx={getGridColumns(12, 12, 12, 6)}
                {...resultService.getGenderData(data)}
              />
              <AgeRange
                sx={getGridColumns(12, 12, 12, 6)}
                {...resultService.getAgeRangeData(data)}
              />
              <MaritalStatus
                sx={getGridColumns(12, 12, 12, 6)}
                {...resultService.getMaritalStatusData(data)}
              />
              <Education
                sx={getGridColumns(12, 12, 12, 6)}
                {...resultService.getEducationData(data)}
              />
              <Occupation
                sx={getGridColumns(12, 12, 12, 6)}
                {...resultService.getOccupationData(data)}
              />
            </>
        </Box>
      </CustomTabPanel>
    </Container>
  )
}
