export const RetryIcon = () => {
  return (
    <svg
      width="52"
      height="38"
      viewBox="0 0 52 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.0417 9.83268L32.875 18.9993H39.75C39.75 26.5848 33.5854 32.7493 26 32.7493C23.6854 32.7493 21.4854 32.1764 19.5833 31.1452L16.2375 34.491C19.0562 36.2785 22.4021 37.3327 26 37.3327C36.1292 37.3327 44.3333 29.1285 44.3333 18.9993H51.2083L42.0417 9.83268ZM12.25 18.9993C12.25 11.4139 18.4146 5.24935 26 5.24935C28.3146 5.24935 30.5146 5.82226 32.4167 6.85351L35.7625 3.50768C32.9437 1.72018 29.5979 0.666016 26 0.666016C15.8708 0.666016 7.66666 8.87018 7.66666 18.9993H0.791664L9.95833 28.166L19.125 18.9993H12.25Z"
        fill="#FF5E1E"
      />
    </svg>
  )
}
