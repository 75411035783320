import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Clear } from '@mui/icons-material'

import ModalSuport from './components/ModalSuport'
import InputRHF from '../../../components/RHF/InputRHF'
import { goToLogin } from '../../../routes/coordinator'
import { emailUserSchemaValidation } from '../validators/userSchemaValidator'
import { loginSupport } from 'src/service/supportService'

export default function LoginErrorEmail() {
  const history = useNavigate()
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: { userEmail: '' },
    resolver: yupResolver(emailUserSchemaValidation),
  })

  const { control, handleSubmit, formState, getValues } = form

  const onSubmit = () => {
    loginSupport(getValues())
      .then(() => {
        handleOpen()
      })
      .catch((error) => alert(error))
  }

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      component="form"
      sx={{
        height: '315px',
        width: '376px',
        backgroundColor: '#FFFFFF',
        padding: '24px',
        boxShadow:
          '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          ml: '332px',
        }}
      >
        <IconButton
          onClick={() => goToLogin(history)}
          sx={{ 
            height: '16px', 
            width: '16px', 
            left: '1px',
            ':hover': {
              color: '#FF5E1E',
            },
          }}
        >
          <Clear sx={{ height: '16px', width: '16px', left: '1px' }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <InfoIcon sx={{ color: '#FF5E1E', mb: '8px' }} />

        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '29px',
            letterSpacing: '0.18px',
            color: '#FF5E1E',
          }}
        >
          {t('login.check.email.title')}
        </Typography>

        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)',
            width: '344px',
            height: '40px',
            textAlign: 'center',
          }}
        >
          {t('login.check.email.message')}
        </Typography>
      </Box>

      <InputRHF
        name="userEmail"
        label="Email"
        control={control}
        sx={{
          height: '54px',
          width: '344px',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          disabled={!formState.isValid}
          variant="outlined"
          type="submit"
          sx={{
            p: '6px, 6px, 6px, 8px',
            minWidth: '344px',
            height: '36px',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
            color: formState.isValid ? '#FF5E1E' : 'rgba(0, 0, 0, 0.38)',
            border: formState.isValid
              ? '1px solid #FF5E1E'
              : '1px solid rgba(0, 0, 0, 0.12)',
            ':hover': {
              backgroundColor: '#FF5E1E',
              color: '#ffff',
            },
          }}
        >
          {t('login.no_authorized.suport.button')}
        </Button>

        <ModalSuport open={open} onHandleClose={handleClose} />
      </Box>
    </Box>
  )
}
