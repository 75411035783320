import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import { Role } from 'src/models/role-model'
import * as yup from 'yup'

export const roleDefaultValues: Role = {
  companyId: null,
  roleName: '',
  isTecnoitRole: false,
  permissions: [],
  company: [],
}

const mode: keyof ValidationMode = 'all'

export const roleSchemaValidation = yup.object({
  roleName: yup.string().required('required'),
  isTecnoitRole: yup.boolean().default(false),
  companyId: yup.object().nullable(),
})

export const roleUseFormArgs = {
  mode,
  defaultValues: roleDefaultValues,
  resolver: yupResolver(roleSchemaValidation),
}

export interface RoleTypes
  extends yup.InferType<typeof roleSchemaValidation> { }
