import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import Header from 'src/layout/Header/Header'
import Sidebar from 'src/layout/SiderBar/SideBar'

export const WithNav = () => {
  const [open, setOpen] = useState(true)
  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <Header handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={open} />
      <Outlet />
    </React.Fragment>
  )
}
