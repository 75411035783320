import React, { useContext } from 'react'
import { t } from 'i18next'

import { instance } from 'src/service/baseService'
import { AlertContext } from 'src/context/AlertContext'

const { useState, useCallback, useMemo, useEffect } = React

const ax = instance // export this and use it in all your components

export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0)
  const { createAlert } = useContext(AlertContext)
  const inc = useCallback(
    () => setCounter((counter) => counter + 1),
    [setCounter]
  ) // add to counter
  const dec = useCallback(
    () => setCounter((counter) => counter - 1),
    [setCounter]
  ) // remove from counter
  const isServerError = (code) => (code >= 500 && code < 600) || code === 404
  const interceptors = useMemo(
    () => ({
      request: (config) => {
        inc()
        return config
      },
      response: (response) => {
        if (isServerError(response?.response?.status)) {
          createAlert('/', t('error.not.completed'), 'error')
        }
        dec()
        return response
      },
      error: (error) => {
        createAlert('/', t('error.not.completed'), 'error')
        dec()
        return Promise.reject(error)
      },
    }),
    [inc, dec]
  ) // create the interceptors

  useEffect(() => {
    // add request interceptors
    ax.interceptors.request.use(interceptors.request, interceptors.error)
    // add response interceptors
    ax.interceptors.response.use(interceptors.response, interceptors.error)
    return () => {
      // remove all intercepts when done
      ax.interceptors.request.eject(interceptors.request as any)
      ax.interceptors.request.eject(interceptors.error as any)
      ax.interceptors.response.eject(interceptors.response as any)
      ax.interceptors.response.eject(interceptors.error as any)
    }
  }, [interceptors])

  return [counter > 0]
}
