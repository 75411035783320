import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ptBR } from 'date-fns/locale'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { InputRHFProps } from '../../models/inputRHF-model'

interface DateTimePickerRHFprops extends InputRHFProps {
  disableFuture?: boolean
  disablePast?: boolean
}

export default function DateTimePickerRHF(props: DateTimePickerRHFprops) {
  const { t } = useTranslation()
  const {
    label,
    required,
    name,
    control,
    disableFuture,
    disablePast,
    onChangeAction,
  } = props
  const {
    field: { ref, value, onBlur, onChange },
    fieldState: { invalid, error },
  } = useController({ name, control })

  const onChangePropagator = (
    date: React.SyntheticEvent<Element, Event> | null,
    keyboardInputValue?: string | undefined
  ) => {
    onChange(date, keyboardInputValue)
    onChangeAction && onChangeAction(value)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <DateTimePicker
        onChange={onChangePropagator}
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabled={props.disabled}
        value={value}
        label={label}
        ref={ref}
        inputRef={ref}
        onClose={onBlur}
        openTo="day"
        renderInput={(params) => (
          <TextField
            {...params}
            error={invalid}
            onBlur={onBlur}
            helperText={
              error?.type === 'typeError' ? t('required') : t(error?.message)
            }
            required={Boolean(required)}
            sx={{
              width: '100%',
              color: '#0000',

              '.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                ':hover': {
                  color: '#FF5E1E',
                },
              },
              '& .MuiFormHelperText-root': {
                m: 0,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
