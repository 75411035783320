import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'

import { NoticeModal } from 'src/components/Modal/NoticeModal'
import { ModalPortalIsActiveContext } from 'src/context/ModalPortalIsActiveContext'
import {
  createPortal,
  editPortal,
  inactivePortalsByCompanyId,
} from 'src/service/portalService'
import { AlertContext } from 'src/context/AlertContext'
import { goToPortal } from 'src/routes/coordinator'
import { getCompanyId, getRole } from 'src/service/authService'
import { ROUTES } from 'src/routes/Router'

export const ModalPortalIsActive = () => {
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)
  const { t } = useTranslation()
  const { id } = useParams()
  const { open, handleCloseModal, data } = useContext(
    ModalPortalIsActiveContext
  )

  const onClickIsActive = () => {
    onSubmit(true)
    handleCloseModal(!open)
  }
  const onClickIsInactive = () => {
    onSubmit(false)
    handleCloseModal(!open)
  }

  const onSubmit = async (isActive: boolean) => {
    if (isActive) {
      await inactivePortalsByCompanyId(
        getRole() === 'TECNOIT' ? data.companyId.id : getCompanyId()
      )
    }

    if (data?.id) {
      const result = await editPortal(id, {
        ...data,
        isActive: isActive,
      })

      if (result) {
        goToPortal(history)
        createCRUDAlert(ROUTES.PORTAIS, data.portalName, 'edited')
      }
      return
    }

    const result = await createPortal({ ...data, isActive: isActive })

    if (result) {
      goToPortal(history)
      createCRUDAlert(ROUTES.PORTAIS, data.portalName, 'created')
    }
  }

  return (
    <NoticeModal
      type="info"
      children={
        <Box
          sx={{ display: 'flex', flexDirection: 'row', gap: '8px', mt: '32px' }}
        >
          <Button
            variant="outlined"
            onClick={onClickIsInactive}
            sx={{
              minWidth: '163px',
              height: '36px',
              color: 'rgba(0, 0, 0, 0.6)',
              border: '1px solid rgba(0, 0, 0, 0.6)',
              ':hover': {
                color: '#FF5E1E',
              },
            }}
          >
            {t('portal.modal.isActive.button.inactive')}
          </Button>
          <Button
            variant="outlined"
            onClick={onClickIsActive}
            sx={{
              minWidth: '163px',
              height: '36px',
              color: 'rgba(0, 0, 0, 0.6)',
              border: '1px solid rgba(0, 0, 0, 0.6)',
              ':hover': {
                color: '#FF5E1E',
              },
            }}
          >
            {t('portal.modal.isActive.button.active')}
          </Button>
        </Box>
      }
      title={t('portal.modal.isActive.title')}
      subtitle={t('portal.modal.isActive.subtitle')}
      open={open}
      handleClose={() => handleCloseModal(!open)}
    />
  )
}
