import { Button } from '@mui/material'

export const ButtonComponent = (props: any) => {
  return (
    <Button
      variant="outlined"
      sx={{
        mt: 1,
        width: '100%',
        height: '40px',
        borderRadius: '4px',
        ':hover': {
          backgroundColor: '#FF5E1E',
          color: '#ffff',
        },
      }}
      {...props}
    >
      {props.children}
    </Button>
  )
}
