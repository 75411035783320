import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'
import { useCallback, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { CompanySelect } from 'src/components/CompanySelect/CompanySelect'
import { TitleWithDivider } from 'src/components/Dividers/TitleWithDivider'
import { GroupSelect } from 'src/components/GroupSelect/GroupSelect'
import CheckBoxRHF from 'src/components/RHF/CheckBoxRHF'
import DateTimePickerRHF from 'src/components/RHF/DateTimePickerRHF'
import InputRHF from 'src/components/RHF/InputRHF'
import SwitchRHF from 'src/components/RHF/SwitchRHF'
import { SegmentationForm } from 'src/components/SegmentationForm/SegmentationForm'
import { CampaignContext, contentTypeEnum } from 'src/context/CampaignFormContext'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'
import { StepperContext } from 'src/context/StepperContext'
import { Company } from 'src/models/company-model'
import { goToCampaigns } from 'src/routes/coordinator'
import { getCompanyId, getPermissions, getRole } from 'src/service/authService'
import { FormButtons } from '../FormButtons'
import { DependencyForm } from './DependencyForm'

const campaignTypeTitle = {
  [contentTypeEnum.IMAGE]: 'campaign.image.title',
  [contentTypeEnum.TEXT]: 'campaign.text.title',
  [contentTypeEnum.MEDIA]: 'campaign.media.title',
  [contentTypeEnum.SEARCH]: 'campaign.search.title',
}

export const DefinitionsForm = () => {
  const {
    formState: { control, setValue, getValues, trigger, watch },
    handleSubmit,
    contentType,
  } = useContext(CampaignContext)
  const { prevStep } = useContext(StepperContext)
  const { isGlobalAdminSelected, selectedCompanyData } = useGlobalSelectedCompany()
  const { id } = useParams()

  const isEdit = Boolean(id)
  const permissions = JSON.parse(getPermissions())
  const hasArchiveUpdatePermission = permissions?.campaign.includes('campaign:archive:update')

  const formIsValid = useCallback(() => {
    const hasCompany = Boolean(getValues('companyId') || getValues('company'))
    const hasName = Boolean(getValues('name'))
    const hasDate = Boolean(getValues('initialDate') && getValues('finalDate'))
    const hasGroup = Boolean(getValues('groups')?.length > 0)
    const segmentedActive = Boolean(getValues('segmentedActive'))
    const segmentedCampaign = Boolean(getValues('segmentedCampaign'))

    if (!segmentedActive && !segmentedCampaign) return hasCompany && hasName && hasDate && hasGroup

    const questions = getValues('questions') || []
    const isQuestionsAndOptionsValid = !questions
      ?.map((question) => {
        if (!question?.options) return false
        const selectedOptions = question?.options
          .map((option) => option?.name)
          .filter((name) => Boolean(name))
        return selectedOptions.length > 0
      })
      .includes(false)

    if (segmentedActive && !segmentedCampaign) return hasCompany && hasName && hasDate && hasGroup && isQuestionsAndOptionsValid

    /* Validação das Regras de Segmentação por outra campanha dos clientes
     *
     * Se a campanha for do tipo: 'media' | 'image' | 'text', só precisa validar se a campanha foi salva no "dependency.campaign"
     * Se a campanha for do tipo: 'quiz', é necessário validar se o "dependency.selectedOption" possui um id e um name
     * - como o id pode ser vazio, precisa validar se ele existe ou se ele é igual a ''
     */
    const dependencyConfig = getValues('dependency') || {}
    const isDependencyAQuiz = dependencyConfig?.campaign?.contentType === 'quiz' ? true : false
    const isQuizConfiguredCorrectly = (dependencyConfig?.selectedOption?.id || dependencyConfig?.selectedOption?.id === '') && dependencyConfig?.selectedOption?.name ? true : false
    const dependencyCampaign = dependencyConfig?.campaign || {}
    const isOtherTypesConfiguredCorrectly = Object.keys(dependencyCampaign).length > 0 ? true : false
    const isDependencyConfigurationValid = isDependencyAQuiz ? isQuizConfiguredCorrectly : isOtherTypesConfiguredCorrectly
    /* Fim da Validação*/

    if (!segmentedActive && segmentedCampaign) return hasCompany && hasName && hasDate && hasGroup && isDependencyConfigurationValid

    return hasCompany && hasName && hasDate && hasGroup && isQuestionsAndOptionsValid && isDependencyConfigurationValid
  }, [watch()])

  useEffect(() => {
    if (getRole() !== 'TECNOIT') setValue('companyId', getCompanyId())
  }, [])

  useEffect(() => {
    if (!isGlobalAdminSelected()) {
      onChangeCompany(selectedCompanyData as Company)
    }
    if(!isGlobalAdminSelected && !selectedCompanyData) {
      setValue('companyId', '')
      setValue('company', { companyName: '' })
    }
  }, [selectedCompanyData])

  const clearForm = () => {
    setValue('companyId', null)
    setValue('company', null)
    setValue('name', null)
    setValue('initialDate', null)
    setValue('finalDate', null)
    setValue('groups', [])
    setValue('questions', [])
    setValue('segmentedActive', false)
    setValue('segmentedCampaign', false)
    setValue('defaultCampaign', false)
    if (getRole() !== 'TECNOIT') setValue('companyId', getCompanyId())
  }

  const onChangeCompany = (company: Company) => {
    setValue('company', company)
    setValue('companyId', company?.id)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        width: '100%',
        gap: '30px',
      }}
    >
      <Typography
        sx={{
          color: ' #FF5E1E',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '24px',
          mb: 1.5,
        }}
      >
        {t(campaignTypeTitle[contentType])}
      </Typography>
      {getRole() === 'TECNOIT' && (
        <CompanySelect
          key={getValues('companyId')}
          control={control}
          onChangeAction={onChangeCompany}
          name="company"
        />
      )}
      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        <InputRHF
          name="name"
          control={control}
          label={t('campaign.label.input.campaign_name')}
          sx={{
            width: '100%',
            height: '54.5px',
          }}
        />
        {
          hasArchiveUpdatePermission &&
          <SwitchRHF
            name='isArchived'
            control={control}
            onChangeAction={() => {
              trigger && trigger('isArchived')
              setValue && setValue('isArchived', '')
            }}
            labelNameOn='form.input.label.archived.o'
            labelNameOff='form.input.label.published.o'
            disabled={watch('defaultCampaign')}
          />
        }
        <SwitchRHF
          name="isActive"
          control={control}
          onChangeAction={() => {
            trigger && trigger('isActive')
            setValue && setValue('isActive', '')
          }}
          disabled={watch('defaultCampaign')}
        />
        <CheckBoxRHF
          name="defaultCampaign"
          control={control}
          disabled={watch('contentType') === 'quiz'}
          onChangeAction={(v) => {
            trigger && trigger('defaultCampaign')
            setValue && setValue('defaultCampaign', '')
            if (v) {
              setValue('isActive', v)
              setValue('initialDate', new Date())
              setValue('finalDate', new Date())
            }
          }}
          labelNameOn={t('campaign.form.default.check')}
          labelNameOff={t('campaign.form.default.check')}
        />
      </Box>
      {!watch('defaultCampaign') &&
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <DateTimePickerRHF
          control={control}
          name="initialDate"
          label={t('campaign.label.input.initial_date')}
          sx={{ width: '49%' }}
          disablePast
          disabled={watch('defaultCampaign')}
        />

        <DateTimePickerRHF
          control={control}
          name="finalDate"
          label={t('campaign.label.input.final_date')}
          sx={{ width: '49%' }}
          disabled={watch('defaultCampaign')}
          disablePast
        />
      </Box>}
      <GroupSelect
        control={control}
        companyId={watch('companyId') || watch('company')?.id}
        key={getValues('companyId') + '-group'}
        sx={{ width: '100%' }}
      />
      <Box sx={{ width: '100%', display: 'flex' }}>
        <SwitchRHF
          control={control}
          name="segmentedActive"
          disableLabel={true}
        />
        <TitleWithDivider title={t('campaign.segmentation.check.label')} />
      </Box>
      {watch('segmentedActive') && (
        <SegmentationForm
          formState={{ control, setValue, getValues, trigger, watch }}
        />
      )}
      <Box sx={{ width: '100%', display: 'flex' }}>
        <SwitchRHF
          control={control}
          name="segmentedCampaign"
          disableLabel={true}
        />
        <TitleWithDivider title={t('campaign.dependency.check.label')} />
      </Box>
      {watch('segmentedCampaign') && (
        <DependencyForm formState={{ control, setValue, getValues, trigger, watch }} />
      )}
      <Box sx={{ width: '100%', p: 'none' }}>
        <FormButtons
          onClean={() => clearForm()}
          nextButtonEnabled={formIsValid()}
          prevButtonEnabled={true}
          formIsValid={formIsValid()}
          cleanButtonEnabled={formIsValid()}
          previewButtonEnabled={false}
          prevButtonAction={() => prevStep()}
          previewButtonAction={() => {}}
          nextButtonCta={
            isEdit ? 'form.button.save' : 'campaign.button.create_campaign'
          }
          nextButtonAction={() => handleSubmit()}
          goTo={goToCampaigns}
        />
      </Box>
    </Box>
  )
}
