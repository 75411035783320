import { useEffect, useState } from 'react'
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form'
import { Box, IconButton } from '@mui/material'

import InputRHF from '../RHF/InputRHF'

interface ButtonProps {
  label: string
  name: string
  control: Control<FieldValues>
  defaultColor: any
  setValue: UseFormSetValue<FieldValues>
  disabled?: any
  isView?: any
}

export const ButtonColor = ({
  label,
  name,
  control,
  defaultColor,
  setValue,
  disabled,
  isView
}: ButtonProps) => {
  const [color, setColor] = useState(defaultColor)

  useEffect(() => {
    if (defaultColor) {
      setColor(defaultColor)
    }
  }, [defaultColor])

  const onChangeColor = (e) => {
    setColor(e.target.value)
    setValue(name, color)
  }

  return (
    <Box
      sx={{
        mb: isView ? '5px' : '28px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      <InputRHF
        disabled={disabled}
        control={control}
        name={name}
        label={label}
        value={color}
        sx={{
          height: '48px',
          '.MuiInputLabel-root': {
            fontWeight: 500,
            color: ' rgba(0, 0, 0, 0.6)',
          },
          '.MuiOutlinedInput-root': {
            borderRadius: '4px 0px 0px 4px',
          },
        }}
      />

      <IconButton
        sx={{
          border: '1px solid #00000040',
          borderLeft: 'none',
          height: '56px',
          width: '58px',
          borderRadius: '0px 4px 4px 0px',
          mt: '8px',
        }}
      >
        <input
          disabled={disabled}
          onChange={onChangeColor}
          value={color}
          type="color"
          style={{
            height: '25px',
            width: '25px',
            background: 'none',
            border: 'none',
            borderRadius: '4px',
          }}
        />
      </IconButton>
    </Box>
  )
}
