import { BASE_URL } from '../constants/url'
import { client } from './baseService'
import xlsx from 'json-as-xlsx'

export const getProfileByAccessPoint = async (id) => {
	try {
		const res = await client().post(
			`${BASE_URL}/reports/profile-by-access-point/${id}`,
			{},
		)

		if (res.data) {
			const columns = (['question', 'field', 'total', 'answers'] || [])?.map((key) => ({
				value: key,
				label: key,
			}))
			const transformedData = res.data.map(item => {
				const { answers, ...rest } = item;
				const answersString = Object.keys(answers).map(key => `${key}: ${answers[key]}`).join('; '); // Concatenando respostas em uma única string com ponto e vírgula
				return {
					...rest,
					answers: answersString,
				};
			});
			console.log(transformedData)
			const xlsxData = [
				{
					sheet: 'ItBuzz Report',
					columns,
					content: transformedData,
				},
			]
			xlsx(xlsxData as any, { fileName: 'ProfileByAccessPoint' })
		}
		return false
	} catch (error) {
	}
}