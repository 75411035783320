import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { Card } from './Card'
import ringOff from '../../../../../assets/icons/ringOff.svg'
import ring from '../../../../../assets/icons/ring.svg'
import HorizontalChart from '../Charts/HorizontalChart'
import disabledAge from '../../../../../assets/icons/disabledAge.svg'

export const MaritalStatus = ({ labels, total, data, sx }) => {
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'row',
        gap: '70px',
        p: '16px',
        px: '31px',
        pb: '20px',
        ...sx
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {t('card.maritalStatus')}
        </Typography>
        <Typography
          sx={{
            fontSize: '34px',
            fontWeight: 400,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {total}
        </Typography>

        {total === 0 ? (
          <img
            src={ringOff}
            alt="group"
            style={{ width: '36px', height: '45px' }}
          />
        ) : (
          <img
            src={ring}
            alt="group"
            style={{ width: '36px', height: '45px' }}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {total === 0 ? (
          <img
            src={disabledAge}
            alt={'disabledAge'}
            style={{ marginTop: '-10px' }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '8px',
            }}
          >
            <HorizontalChart labels={labels} data={data} />
          </Box>
        )}
      </Box>
    </Card>
  )
}
