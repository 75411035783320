import { Card, CardContent, Skeleton, Stack } from '@mui/material'

export function SelectedVideoSkeleton () {
  return (
    <>
      <Skeleton variant="rectangular" width="640px" height="360px" />
      <Card sx={{ margin: '10px 0', maxWidth: '640px' }}>
        <CardContent>
          <Stack alignItems="center" direction="row" gap={2} mb='10px'>
            <Skeleton variant="text" height="32px" width="50%" />
            <Skeleton variant="text" height="20px" width="10%" sx={{ marginLeft: 'auto' }} />
          </Stack>
          <Skeleton variant="rectangular" width="100%" height={40} />
        </CardContent>
      </Card>
    </>
  )
}
