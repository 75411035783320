import * as yup from 'yup'

export const CampaignFormDefaultValues = {
  content: {
    description: '',
    title: '',
  },
  campaignName: '',
  isActive: true,
  isArchived: false,
  initialDate: '',
  finalDate: '',
  segmentedActive: false,
  segmentedCampaign: false
}

export const CampaignFormResolver = yup.object().shape({
  description: yup.string().required('required'),
  title: yup.string().required('required'),
  campaignName: yup.string().required('required'),
  isActive: yup.boolean(),
  isArchived: yup.boolean().default(false),
  initialDate: yup.date().required('required'),
  finalDate: yup.date().required('required'),
  segmentedActive: yup.boolean(),
  segmentedCampaign: yup.boolean(),
})
