import { ReactNode, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type Iten = {
  title: string | ReactNode
  onClick: () => void
  icon?: ReactNode
}

interface Props {
  itens: Iten[]
  onOpen?: () => void
  icon?: ReactNode
  title?: string
  pos?: 'pre' | 'pos'
}

export const Dropdown = ({ icon, onOpen, itens, title, pos }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && event.target instanceof HTMLElement) {
        if (!event.target.closest('.dropdown')) {
          setIsOpen(false)
        }
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  return (
    <div style={{ zIndex: 1 }}>
      <Box
        sx={{
          '.action-btns': {
            border: 'none',
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Roboto',
            background: 'white',
            padding: '8px 16px',
            margin: '0 0 8px 0',
            cursor: 'pointer',
            width: '100%',
            textAlign: 'left',
            ':hover': {
              backgroundColor: 'rgba(255, 94, 30, 0.04)',
              color: '#FF5E1E',
            },
            svg: {
              position: 'relative',
              margin: '0px 12px -6px 0px',
              width: '24px',
              color: '#00000099',
              ':hover': {
                color: '#FF5E1E',
              },
            },
          },
          '.drop-header': {
            p: '17px 16px',
            height: '63px',
            marginBottom: '8px',
            backgroundColor: 'white',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: 'rgba(0, 0, 0, 0.12)',
            marginLeft: '5px',
          },
        }}
      >
        <Button
          style={
            isOpen ? { color: '#FF5E1E', border: '1px solid #FF5E1E' } : {}
          }
          onClick={() => {
            setIsOpen(!isOpen)
            onOpen && onOpen()
          }}
          variant="outlined"
          sx={{
            // Design things
            borderRadius: pos === 'pre' ? '4px 0px 0px 4px' : '0px 4px 4px 0px',
            borderRight: pos === 'pre' ? 'none' : '',
            minWidth: '48px',
            width: '48px',
            height: '48px',
            color: '#00000099',
            borderColor: 'rgba(0, 0, 0, 0.12)',
            ':hover': {
              color: '#FF5E1E',
            },
          }}
        >
          {icon}
        </Button>

        <div
          style={{
            position: 'relative',
          }}
        >
          {isOpen && (
            <ul
              style={{
                position: 'absolute',
                listStyleType: 'none',
                margin: '5px 0',
                padding: '0',
                width: pos === 'pre' ? '300px' : '200px',
                boxShadow:
                  '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
                borderRadius: 4,
                right: '0px',
                backgroundColor: '#ffff',
                zIndex: 9,
              }}
            >
              <li className="drop-header">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '80%',
                      color: '#000000DE',
                      fontSize: '16px',
                    }}
                  >
                    {title}
                  </div>
                  <div
                    style={{
                      width: '20%',
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        setIsOpen(false)
                      }}
                      sx={{
                        color: '#00000099',
                        ':hover': {
                          color: '#FF5E1E',
                          backgroundColor: 'rgba(255, 94, 30, 0.04)',
                        },
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </li>
              <>
                {itens?.map(({ icon, title, onClick }) => (
                  <li style={{ cursor: 'pointer', color: '#00000099' }}>
                    <button className="action-btns" onClick={onClick}>
                      {icon}
                      {title}
                    </button>
                  </li>
                ))}
              </>
            </ul>
          )}
        </div>
      </Box>
    </div>
  )
}
