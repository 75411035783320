import { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Typography } from "@mui/material"
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'

import InputRHF from "src/components/RHF/InputRHF"
import SwitchRHF from "src/components/RHF/SwitchRHF"
import CKEditorRHF from "src/components/CKEditorRHF/CKEditorRHF"
import InputRadioRHF from "src/components/RHF/InputRadioRHF"
import { Enrichment } from "src/models/campaigns-model/enrichment-model"
import { FooterBottons } from "src/components/FormUtils/FooterButtons"
import { goToEnrichment } from "src/routes/coordinator"
import {
  enrichmentAnswerDefaultValues,
  enrichmentDefaultValues,
  enrichmentSchemaValidation
} from "../../validators/enrichmentSchemaValidators"

interface EnrichmentFormProps {
  data: Enrichment
  onSubmit(data: any): void
  isViewMode?: boolean
}

export const EnrichmentForm = ({ data, onSubmit, isViewMode }: EnrichmentFormProps) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const form = useForm<any>({
    mode: 'all',
    defaultValues: enrichmentDefaultValues,
    resolver: yupResolver(enrichmentSchemaValidation),
  })
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState,
    setValue,
    trigger,
    watch,
  } = form
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'content.quiz[0].options',
  })
  const disabled = !(fields.length < 5)

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  const addAnswer = () => {
    append(enrichmentAnswerDefaultValues)
  }

  const removeAnswer = (index: number) => {
    if (index > 1) {
      remove(index)
    }
  }

  const resetForm = () => {
    reset(data)
    setValue('name', data?.name)
    setValue('sku', data?.sku)
    setValue('isActive', data?.isActive)
    setValue('content.title', data?.content.title)
    setValue('content.description', data?.content.description)
    setValue('content.quiz[0].question', data?.content.quiz[0].question)
  }

  useEffect(() => {
    if (data) {
      resetForm()
    }
  }, [data])

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" sx={{ mb: 2 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('enrichment.form.input.label.name')}
          placeholder={t('enrichment.form.input.placeholder.name')}
          name="name"
          sx={{
            gridColumn: 'span 8',
          }}
        />
        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('enrichment.form.input.label.sku')}
          placeholder={t('enrichment.form.input.placeholder.sku')}
          name="sku"
          sx={{
            gridColumn: 'span 3',
          }}
        />
        <SwitchRHF
          sx={{
            gridColumn: 'span 1',
          }}
          disabled={disableInput()}
          name="isActive"
          control={control}
          onChangeAction={() => {
            trigger && trigger('isActive')
            setValue && setValue('isActive', '')
          }}
          labelNameOn="form.input.label.active.o"
          labelNameOff="form.input.label.inactive.o"
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputRHF
            disabled={disableInput()}
            control={control}
            label={t('enrichment.form.input.placeholder.content.title')}
            name="content.title"
            sx={{
              width: '100%',
              height: '56px',

              '& input': { color: watch('content.titleColor') },
            }}
          />
        </Box>
        <CKEditorRHF
          disabled={disableInput()}
          key={watch('description')}
          label={t('enrichment.form.input.placeholder.content.description')}
          sx={{
            width: '100%',
            mt: 3
          }}
          name="content.description"
          control={control}
          defaultColor='#000000'
          displayColorPicker={false}
        />
      </Box>
      <Typography
          variant="h6"
          sx={{
            fontWeight: '400',
            fontSize: '15px',
            lineHeight: '160%',
            letterSpacing: '0.15px',
            color: ' #FF5E1E',
            mt: '22px',
          }}
      >
        {t('enrichment.form.divider')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',
          gap: '8px',
          width: '100%'
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignContent: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            width: '54px',
            height: '56px',
            borderRadius: '4px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: 'rgba(0, 0, 0, 0.38)',
          }}
        >
          {t('enrichment.form.marker')}
        </Typography>
        <InputRHF
          disabled={disableInput()}
          name={'content.quiz[0].question'}
          label={t('enrichment.form.input.label.content.question')}
          control={control as any}
          sx={{
            width: '100%',
            height: '56px',
            mb: '10px'
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'baseline',
          width: '100%',
          mb: '35px',
        }}
      >
        {fields.map((answer, index) => {
          return (
            <Box
              key={answer.id}
              sx={{
                display: 'flex',
                gap: '8px',
                pl: 7.5,
                pr: 20,
                mt: 1,
                width: '100%',
              }}
            >
              <InputRadioRHF
                disabled={disableInput()}
                name={`content.quiz[0].options[${index}].name`}
                label={`${t('enrichment.form.input.label.option')} ${Number(index) + 1}`}
                control={control}
                sx={{ width: '100%', height: '56px' }}
                removeAnswer={() => removeAnswer(index)}
                hideDelete={
                  watch(
                    `content.quiz[0].options[${index}].name`
                  ) && index > 1 && !isViewMode
                }
              />
            </Box>
          )
        })}
        {fields.length < 5 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              disabled={disabled}
              onClick={addAnswer}
              variant="outlined"
              sx={{
                width: '100%',
                mt: 2,
                p: 0,
                height: '56px',
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                color: '#00000099',
                ':hover': {
                  color: '#FF5E1E',
                },
              }}
            >
              <AddBoxOutlinedIcon
                sx={{
                  height: '24px',
                  minWidth: '24px',
                }}
              />
              {t('enrichment.form.add.option')}
            </Button>
          </Box>
        )}
      </Box>
      {!isViewMode && (
        <FooterBottons
          isValid={!formState.isValid}
          formIsValid={formState.isValid}
          isEdit={Boolean(id)}
          goTo={goToEnrichment}
          reset={() => resetForm()}
          size="100%"
          buttonName="enrichment.button.create"
        />
      )}
    </Box>
  )
}
