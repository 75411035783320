import { Container } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'
import { useContext, useEffect, useState } from 'react'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { ModalPassword } from 'src/components/Modal/ModalPassword'
import { AlertContext } from 'src/context/AlertContext'
import DashboardViewProvider from 'src/context/DashboardViewContext'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'
import { ModalChangePasswordContext } from 'src/context/ModalChangePasswordContext'
import { ROUTES } from 'src/routes/Router'
import {
  getDashboardData,
  getDashboardReport,
  getVisitorsReport,
} from 'src/service/dashboardService'
import { AccessRanking } from './Cards/AccessRanking'
import { Connections } from './Cards/Connections'
import { PerGroupConnections } from './Cards/PerGroupConnections'
import { ReconnectionsAverage } from './Cards/ReconnectionsAverage'
import { UsePerPeriod } from './Cards/UsePerPeriod'
import { Users } from './Cards/Users'
import DashboardHeader from './DashboardHeader'
import { DashboardService } from './DashboardService'

const Dashboard = () => {
  const [period, setPeriod] = useState('1d')
  const [data, setData] = useState()
  const dashboardService = new DashboardService()
  const { createAlert } = useContext(AlertContext)
  const { open, handleCloseModal, setOpen } = useContext(
    ModalChangePasswordContext
  )
  const { selectedCompanyId, isGlobalAdminSelected } = useGlobalSelectedCompany()

  const dateToday = new Date().toISOString()
  var dateYesterday = new Date()
  dateYesterday.setDate(dateYesterday.getDate() - 1)

  const today =
    dateToday.substring(5, 7) +
    '/' +
    dateToday.substring(8, 10) +
    '/' +
    dateToday.substring(0, 4)
  const yesterday =
    dateYesterday.toISOString().substring(5, 7) +
    '/' +
    dateYesterday.toISOString().substring(8, 10) +
    '/' +
    dateYesterday.toISOString().substring(0, 4)

  const [initialDate, setInitialDate] = useState(yesterday)
  const [finalDate, setFinalDate] = useState(today)

  const [initialDateFormated, setInitialDateFormated] = useState(today)
  const [finalDateFormated, setFinalDateFormated] = useState(today)

  const getData = async () => {
    let monthInitial = initialDate.substring(0, 2)
    let dayInitial = initialDate.substring(3, 5)
    let yearInitial = initialDate.substring(6, 10)

    let monthFinal = finalDate.substring(0, 2)
    let dayFinal = finalDate.substring(3, 5)
    let yearFinal = finalDate.substring(6, 10)

    const dateStart = yearInitial + '/' + monthInitial + '/' + dayInitial
    const dateEnd = yearFinal + '/' + monthFinal + '/' + dayFinal

    setInitialDateFormated(dateStart)
    setFinalDateFormated(dateEnd)

    const paramsCompanyId = isGlobalAdminSelected() ? {} : { companyId : selectedCompanyId }
    const data = await getDashboardData({
      params: { time: period, dateStart: dateStart, dateEnd: dateEnd, ...paramsCompanyId },
    })
    setData(data)
  }

  const getDataByDate = async (date: string, type: string) => {
    if (type === 'initial') {
      var monthInitial = date.substring(0, 2)
      var dayInitial = date.substring(3, 5)
      var yearInitial = date.substring(6, 10)
    } else {
      var monthInitial = initialDate.substring(0, 2)
      var dayInitial = initialDate.substring(3, 5)
      var yearInitial = initialDate.substring(6, 10)
    }

    if (type === 'final') {
      var monthFinal = date.substring(0, 2)
      var dayFinal = date.substring(3, 5)
      var yearFinal = date.substring(6, 10)
    } else {
      var monthFinal = finalDate.substring(0, 2)
      var dayFinal = finalDate.substring(3, 5)
      var yearFinal = finalDate.substring(6, 10)
    }

    const dateStart = yearInitial + '/' + monthInitial + '/' + dayInitial
    const dateEnd = yearFinal + '/' + monthFinal + '/' + dayFinal

    setInitialDateFormated(dateStart)
    setFinalDateFormated(dateEnd)

    const data = await getDashboardData({
      params: { time: period, dateStart: dateStart, dateEnd: dateEnd },
    })
    setData(data)
  }

  const onReportDownload = async (period, format) => {
    createAlert(
      ROUTES.DASHBOARD,
      `${t('dashboard.exportation.of')} ${t(
        `dashboard.export.userLogin`
      )} (${format.toUpperCase()}) ${t('dashboard.exportation.in.pediod')} ${
        period?.name
      } ${t('dashboard.exportation.running')}`,
      'warning'
    )
    const reportData = await getDashboardReport({
      params: {
        time: period?.value,
        dateStart: initialDateFormated,
        dateEnd: finalDateFormated,
      },
    })
    dashboardService.generateAndDownloadFile(
      reportData,
      `ItBuzz Access Report - ${period?.value}`,
      format,
      ['login_date', 'useragent', 'user_mac_address', 'groups']
    )
    createAlert(
      ROUTES.DASHBOARD,
      `${t('dashboard.exportation.of')} ${t(
        `dashboard.export.userLogin`
      )} (${format.toUpperCase()}) ${t('dashboard.exportation.in.pediod')} ${
        period?.name
      } ${t('dashboard.exportation.done')}`
    )
  }

  const onReportVisitorsDownload = async (period, format) => {
    createAlert(
      ROUTES.DASHBOARD,
      `${t('dashboard.exportation.of')} ${t(
        `dashboard.export.userData`
      )} (${format.toUpperCase()}) ${t('dashboard.exportation.in.pediod')} ${
        period?.name
      } ${t('dashboard.exportation.running')}`,
      'warning'
    )
    const reportData = await getVisitorsReport({
      params: {
        time: period?.value,
        dateStart: initialDateFormated,
        dateEnd: finalDateFormated,
      },
    })

    dashboardService.generateAndDownloadFile(
      reportData,
      `ItBuzz Visitors Report`,
      format,
      ['name', 'email', 'phone', 'registrationDate']
    )

    createAlert(
      ROUTES.DASHBOARD,
      `${t('dashboard.exportation.of')} ${t(
        `dashboard.export.userData`
      )} (${format.toUpperCase()}) ${t('dashboard.exportation.in.pediod')} ${
        period?.name
      } ${t('dashboard.exportation.done')}`
    )
  }

  const onChangeDateInitial = async (date: string) => {
    setInitialDate(date)
    getDataByDate(date, 'initial')
  }

  const onChangeDateFinal = (date: string) => {
    setFinalDate(date)
    getDataByDate(date, 'final')
  }

  useEffect(() => {
    getData()
  }, [period, selectedCompanyId])

  return (
    <DashboardViewProvider>
      <Container
        sx={{
          mt: '64px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            minHeight: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            px: '28px',
            pb: '50px',
            flexGrow: '0',
            position: 'relative',
          }}
        >
          <ModalPassword open={open} handleClose={handleCloseModal} />

          <Box sx={{ width: '100%', maxWidth: '950px', mt: 2 }}>
            <AlertComponent />
          </Box>
          <DashboardHeader
            onPeriodChange={(
              periodValue,
              periodName,
              initialDate,
              finalDate
            ) => {
              setPeriod(periodValue)
              setInitialDate(initialDate)
              setFinalDate(finalDate)
            }}
            onReportDownload={onReportDownload}
            onReportVisitorsDownload={onReportVisitorsDownload}
            onChangeDateInitial={onChangeDateInitial}
            onChangeDateFinal={onChangeDateFinal}
          />

          <Box
            sx={{
              maxWidth: '950px',
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '15px',
              mt: '16px',
            }}
          >
            <Connections value={dashboardService.getConnections(data)} />
            <ReconnectionsAverage
              value={dashboardService.getAverageReconnections(data)}
            />
            <PerGroupConnections
              labels={dashboardService.getConnectionsPerGroupLabels(data)}
              data={dashboardService.getConnectiosPerGroupValues(data)}
              total={dashboardService.getConnectiosPerGroupTotalValue(data)}
            />

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: '16px',
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
              >
                <UsePerPeriod
                  labels={dashboardService.getPeriodUseLabels(data)}
                  data={dashboardService.getPeriodUseValues(data)}
                />

                <Users
                  newUsersValue={dashboardService.getNewUsers(data)}
                  returningUsersValue={dashboardService.getReturningUsers(data)}
                />
              </Box>
              <AccessRanking
                data={dashboardService.getAccessRankingData(data)}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </DashboardViewProvider>
  )
}

export default Dashboard
