import { Box } from '@mui/system'

export const Divider = () => {
  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '2px',
        width: '80%',
        height: '2px',
        bgcolor: 'rgba(0, 0, 0, 0.12);',
      }}
    />
  )
}
