import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Container, Typography } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

import { UserManagerForm } from './components/Form/UserManagerForm'
import { UserManager } from 'src/models/user-model'
import { getUserById } from 'src/service/userAdminsService'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
interface Props {
  isViewMode?: boolean
  viewId?: any
}

const CreaterUserManager = ({ isViewMode, viewId }: Props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [user, setUser] = useState<UserManager>()

  const getUser = async () => {
    if (id || isViewMode) {
      const user = await getUserById(id || viewId)
      setUser(user)
    }
  }
  useEffect(() => {
    getUser()
  }, [])

  return (
    <Container
      sx={{
        mt: isViewMode ? '' : '96px',
      }}
    >
      <AlertComponent isView={isViewMode} />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: '24px',
        }}
      >
        <PersonOutlineOutlinedIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            mb: '-8px',
          }}
        >
          {t('user.title')}
        </Typography>
      </Box>
      <UserManagerForm data={user!} isViewMode={isViewMode} />
    </Container>
  )
}
export default CreaterUserManager
