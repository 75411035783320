import { BASE_URL } from 'src/constants/url'
import { LoginSupport, Support } from 'src/models/support-model'
import { client } from './baseService'

export const createSupport = async (
  support: Support
): Promise<Support | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/ticket`, support)
    return res.data
  } catch (error) {
    return false
  }
}

export const loginSupport = async (
  support: LoginSupport
): Promise<LoginSupport | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/support`, support)
    return res.data
  } catch (error) {
    return false
  }
}
