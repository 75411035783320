import { useContext } from 'react'
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { AlertContext } from 'src/context/AlertContext'

interface Props {
  isView?: any
}
export const AlertComponent = ({ isView }: Props) => {
  const { handleClose, shouldShowAlert, message, type } =
    useContext(AlertContext)
  return (
    <Box>
      <Collapse in={shouldShowAlert()} sx={{ zIndex: 100 }}>
        <Alert
          severity={type || 'success'}
          variant="outlined"
          iconMapping={{
            success: (
              <CheckCircleIcon
                sx={{ color: type === 'error' ? 'white' : '#FF5E1E' }}
              />
            ),
            error: (
              <ErrorIcon
                sx={{ color: type === 'error' ? 'white' : '#FF5E1E' }}
              />
            ),
            warning: (
              <CircularProgress sx={{ color: '#FF5E1E' }} size={'20px'} />
            ),
            info: (
              <InfoOutlinedIcon
                sx={{ color: type === 'error' ? 'white' : '#FF5E1E' }}
              />
            ),
          }}
          sx={{
            display: isView ? 'none' : 'flex',
            left: ' 0px',
            top: '8px',
            borderRadius: '4px',
            padding: '6px, 8px, 6px, 16px',
            color: type === 'error' ? 'white' : '#FF5E1E',
            backgroundColor: type === 'error' ? '#810000' : '#ffff',
            boxShadow:
              type === 'error'
                ? ''
                : '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
            borderColor: type === 'error' ? 'none ' : '#FF5E1E',
            border: type === 'error' ? '#810000 ' : 'solid 1.5px #FF5E1E',
            gap: '8px',
            mb: 3,
            textAlign: 'center',
          }}
          action={
            <IconButton
              aria-label="close"
              // color="inherit"
              size="small"
              onClick={() => handleClose()}
              sx={{
                color: '#00000099',
                ':hover': {
                  color: '#FF5E1E',
                  backgroundColor: '#FF5E1E14',
                },
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  )
}
