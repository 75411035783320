import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Button, Divider, Stack, SxProps, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { VideoTraining } from 'src/models/training-video-model'
import { goToEditVideo } from 'src/routes/coordinator'

const ButtonStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

interface SelectedVideoCardProps {
  currentVideo: VideoTraining
  canSeeMenu: boolean
  canEditVideo: boolean
  canRemoveVideo: boolean
  handleDelete: (id: string, title: string) => void
}

export function SelectedVideoCard({ currentVideo, canSeeMenu, canEditVideo, canRemoveVideo, handleDelete }: SelectedVideoCardProps) {
  const history = useNavigate()

  return (
    <Card sx={{ margin: '10px 0', maxWidth: '640px' }}>
      <CardContent sx={{ paddingBottom: '8px' }}>
        <Stack alignItems="center" direction="row" gap={2} mb='10px'>
          <PlayCircleOutlineIcon color='primary' />
          <Typography variant='h5' color='primary' >{currentVideo?.title}</Typography>
          <Typography variant='caption' marginLeft='auto'>{new Date(currentVideo?.createdAt).toLocaleString('pt-BR', { dateStyle: 'short' })}</Typography>
        </Stack>
        <div dangerouslySetInnerHTML={{ __html: currentVideo?.description }} />
        { canSeeMenu && <Divider /> }
      </CardContent>
      {
        canSeeMenu && (
          <CardActions disableSpacing sx={{ paddingTop: '0' }}>
            { canEditVideo && (
                <Button variant='text' onClick={() => goToEditVideo(history, currentVideo.id)} sx={ButtonStyles}>
                  <EditIcon fontSize='small' />
                  <Typography component='span' fontSize='0.75rem' sx={{ marginLeft: '10px' }}>
                    {t('training.video.button.update')}
                  </Typography>
                </Button>
              )}
            { canRemoveVideo && (
                <Button variant='text' onClick={() => handleDelete(currentVideo.id, currentVideo.title)} sx={ButtonStyles}>
                  <DeleteOutlinedIcon fontSize='small' />
                  <Typography component='span' fontSize='0.75rem' sx={{ marginLeft: '10px' }}>
                    {t('training.video.button.delete')}
                  </Typography>
                </Button>
              )}
          </CardActions>
        )
      }
    </Card>
  )
}
