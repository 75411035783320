import type { ChangeEvent, FocusEvent } from 'react'
import { t } from 'i18next'
import { useController } from 'react-hook-form'
import { CircularProgress } from '@mui/material'
import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'

import { InputMaskRHFProps } from '../../models/inputRHF-model'

export default function InputNumberRHF(props: InputMaskRHFProps) {
  const {
    disabled,
    label,
    required,
    name,
    control,
    sx,
    onBlurAction,
    onChangeAction,
    loading,
    size,
  } = props
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({ name, control })

  const onBlurPropagator = (event: FocusEvent<HTMLInputElement>) => {
    onBlur()
    onBlurAction && onBlurAction(event)
  }

  const onChangePropagator = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(event.target.value)
    onChangeAction && onChangeAction(event.target.value)
  }

  return (
    <div>
      <NumericFormat
        id={name}
        name={name}
        onChange={onChangePropagator}
        style={{
          width: '56px',
          height: '40px',
          border: '1px solid rgb(0 0 0 / 21%)',
          borderRadius: '4px',
          padding: '17px',
        }}
        allowNegative={false}
        fixedDecimalScale={true}
        onBlur={onBlurPropagator}
        value={value}
        disabled={disabled}
      />
      {
        (() => (
          <TextField
            inputRef={ref}
            label={label}
            error={invalid}
            helperText={t(error?.message)}
            required={Boolean(required)}
            disabled={disabled}
            sx={{
              '.MuiFormHelperText-root.Mui-error': {
                m: 0,
              },
              ...sx,
            }}
            InputProps={{
              endAdornment: loading && <CircularProgress size="1.3rem" />,
            }}
          />
        )) as any
      }
    </div>
  )
}
