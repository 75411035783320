import { Link } from "react-router-dom"
import { ROUTES } from "src/routes/Router"
import { getRole } from "src/service/authService"

export interface CompanyLogoProps {
  openDrawer: any
  open: any
  loading: boolean
  logoCompany: string
  logoSmallLogoIt: string
  logoSmallCompany: string
  setLoading: any
}

export const CompanyLogo = ({ openDrawer, open, loading, logoCompany, logoSmallCompany, logoSmallLogoIt, setLoading }: CompanyLogoProps) => {
  return (
    <Link to={ROUTES.DASHBOARD}>
      {!openDrawer && open ? (
        <img
          src={logoCompany}
          alt="logo"
          style={{
            width: getRole() === 'TECNOIT' ? '168px' : '124px',
            height: getRole() === 'TECNOIT' ? '36px' : '92px',
            marginBottom: getRole() === 'TECNOIT' ? 0 : '30px',
            marginRight: getRole() === 'TECNOIT' ? '-40px' : 0,
            marginTop: getRole() === 'TECNOIT' ? 0 : '8px',
          }}
        />
      ) : loading ? (
        <img
          src={logoSmallLogoIt}
          alt="logo Mini"
          style={{
            width: '43.9px',
            height: '36px',
            marginRight: getRole() === 'TECNOIT' ? 6 : 0,
          }}
          onLoad={() => setLoading(false)}
        />
      ) : (
        <img
          src={logoSmallCompany}
          alt="logo Mini"
          style={{
            width: '43.9px',
            height: '36px',
            marginRight: getRole() === 'TECNOIT' ? 6 : 0,
          }}
          onLoadStart={() => setLoading(true)}
        />
      )}
    </Link>
  )
}
