import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColumns } from '@mui/x-data-grid'
import { Chip } from '@mui/material'

import {
  deleteEnrichmentProfileById,
  getEnrichmentProfiles,
  recycleEnrichmentProfileById,
} from 'src/service/enrichmentProfileService'
import { getPermissions } from 'src/service/authService'
import { Table } from 'src/components/Table/Table'
import { NumberSort } from 'src/components/Table/SortingFunctions'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { goToEditEnrichment } from 'src/routes/coordinator'
import { CreateEnrichment } from '../../CreateEnrichment'

export default function EnrichmentTable() {
  const { t } = useTranslation()
  const { createCRUDAlert } = useContext(AlertContext)
  const [tableKey, setTableKey] = useState(0)
  const permissions = JSON.parse(getPermissions())

  const getData = async (options: any) => {
    const data = await getEnrichmentProfiles(options)
    return { data: data.listEnrichmentProfile, totalPages: data.page, size: data?.size }
  }

  const handleDelete = async (id, name) => {
    const res = await deleteEnrichmentProfileById(id)
    if (res) createCRUDAlert(ROUTES.ENRICHMENT, name, 'deleted')
    return
  }

  const handleRecycle = async (id, name) => {
    const res = await recycleEnrichmentProfileById(id)
    if (res) {
      createCRUDAlert(ROUTES.ENRICHMENT, name, 'edited')
    }
  }

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: t('table.header.name'),
      width: 300,
      editable: false,
    },
    {
      field: 'isActive',
      headerName: t('table.header.situation'),
      width: 95,
      type: 'boolean',
      editable: false,
      renderCell: ({ row }) => {
        return row.isActive ? (
          <Chip
            sx={{
              background: 'rgba(255, 94, 30, 0.12)',
            }}
            size="small"
            label={t('table.status.active')}
          />
        ) : (
          <Chip size="small" label={t('table.status.inactive')} />
        )
      },
    },
    {
      field: 'sku',
      headerName: t('table.header.sku'),
      width: 222,
      type: 'number',
      editable: false,
      headerAlign: 'left',
      align: 'left',
      sortComparator: NumberSort,
    },
  ]
  return (
    <Table
      columns={columns}
      getData={getData}
      key={tableKey}
      handleDelete={handleDelete}
      titleKey="name"
      goTo={goToEditEnrichment}
      defaultPageSize={5}
      model={'enrichment'}
      Children={CreateEnrichment}
      size={'16px'}
      mr="25px"
      permissionUpdate={!permissions?.enrichment?.includes("enrichment:update")}
      permissionDelete={!permissions?.enrichment?.includes("enrichment:delete")}
      permissionView={!permissions?.enrichment?.includes("enrichment:view")}
      canShowRecycleButtons={true}
      permissionViewRecycle={permissions?.enrichment?.includes("enrichment:recycle:view")}
      permissionUpdateRecycle={permissions?.enrichment?.includes("enrichment:recycle:update")}
      handleRecycle={handleRecycle}
      canShowUnarchiveButtons={false}
    />
  )
}
