import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Card } from './Card'
import { ToggableCard } from './ToggableCard'
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined'
import { useTranslation } from 'react-i18next'
interface Props {
  value?: number | string
}
export const Connections = ({ value }: Props) => {
  const { t } = useTranslation()

  return (
    <ToggableCard viewkey="connections">
      <Card
        sx={{
          minWidth: '222px',
          minHeight: '200px',
          width: '300px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          p: '16px',
          px: '31px',
        }}
      >
        <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6);' }}>
          {t('painel.connections')}
        </Typography>
        <Typography
          sx={{
            fontSize: '34px',
            color: 'rgba(0, 0, 0, 0.87)',
            mt: 1,
          }}
        >
          {value}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <WifiOutlinedIcon sx={{ fontSize: '4rem', color: '#FF5E1E' }} />
        </Box>
      </Card>
    </ToggableCard>
  )
}
