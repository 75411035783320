import { useContext } from 'react'
import { t } from 'i18next'
import { Typography } from '@mui/material'

import { CampaignContext } from 'src/context/CampaignFormContext'

export const CharCounter = ({ message, content }) => {
  const parsed = new DOMParser().parseFromString(content, 'text/html').body
    .textContent
  const text = parsed === 'undefined' ? '' : parsed
  const isOverLimit = text?.length >= 2000
  const size = text?.length || 0

  const { setCountContent } = useContext(CampaignContext)
  setCountContent(text?.length)

  return (
    <Typography
      sx={{
        width: '100%',
        display: 'flex',
        gap: '5px',
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '12px',
        mt: '5px',
      }}
    >
      <Typography
        color={
          text?.length > 0
            ? isOverLimit
              ? 'rgba(129, 0, 0, 1)'
              : '#FF5E1E'
            : 'rgba(0, 0, 0, 0.6)'
        }
        sx={{ fontSize: '12px' }}
      >
        {size} {t('editor.chart.counter.letters')}{' '}
        {isOverLimit && t('editor.char.couter.limit.error')}
      </Typography>
      {!isOverLimit && message}
      {isOverLimit && t('editor.char.couter.limit.error.helper')}
    </Typography>
  )
}
