import { Chart as ChartJS, ArcElement, PointElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, PointElement, Tooltip)
interface Props {
  labels: string[]
  data: number[]
  total: number
}
export default function DoughnutChart({ labels, data, total }: Props) {
  return (
    <div style={{ width: '100%', height: '110px' }}>
      <Doughnut
        key={total}
        data={{
          datasets: [
            {
              data,
              label: labels as any,
              backgroundColor: [
                '#FF5E1E',
                '#DBDBDB',
                '#6F6F6F',
                '#1D1D1D',
                '#FF8D64',
                '#F4581A',
              ],
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          cutout: 45,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx) => {
                  return `${labels[ctx.dataIndex]?.slice(0, 12) || '?'}: ${
                    data[ctx.dataIndex]
                  }`
                },
              },
            },
          },
        }}
        plugins={[
          {
            id: 'texttotal',
            beforeDraw: function (chart) {
              var width = chart.width,
                height = chart.height,
                ctx = chart.ctx
              if (data.length <= 0) {
                ctx.canvas.style.height = '110px'
                ctx.canvas.style.borderRadius = '50%'
                ctx.canvas.style.width = '110px'
                ctx.canvas.style.border = '8px solid #DBDBDB'
                ctx.canvas.style.marginLeft = '66px'
                ctx.canvas.style.objectFit = 'cover'
                ctx.canvas.style.fontSize = '23px'
              }
              ctx.canvas.style.fontSize = '23px'
              var fontSize = (height / 114).toFixed(2)
              ctx.font = fontSize + 'em sans-serif'
              ctx.textBaseline = 'middle'
              var text = total,
                textX = Math.round(
                  (width - ctx.measureText(text as any).width) / 2
                ),
                textY = height / 2
              ctx.fillText(text as any, textX, textY)
              ctx.save()
            },
          },
        ]}
      />
    </div>
  )
}
