import MenuItem from '@mui/material/MenuItem'

export default function MenuItemMap(
  option:
    | {
        label?: string
        value: string | number
        disabled?: boolean
      }
    | string,
  index: number
) {
  if (typeof option === 'string') {
    return (
      <MenuItem key={index} value={option}>
        {option}
      </MenuItem>
    )
  }
  return (
    <MenuItem disabled={option?.disabled} key={index} value={option.value}>
      {option.label}
    </MenuItem>
  )
}
