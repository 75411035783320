import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { Card } from './Card'

export interface Props {
  sx?: any
}

const mocks = [
  {
    id: 1,
    question: 'Quantas vezes por mês você frequenta os parques da cidade? ',
    answer: {
      options: [
        { percentage: '16%', option: 'a. Nenhuma vez por mês' },
        { percentage: '16%', option: 'b. 1 vez por mês' },
        { percentage: '16%', option: 'c. 2 a 3 vezes por mês' },
        { percentage: '16%', option: 'd. 4 a 5 vezes por mês' },
        { percentage: '16%', option: 'e. Mais de 6 vezes por mês' },
        ,
      ],
    },
  },
  {
    id: 2,
    question: 'Qual a forma de transporte que você mais utiliza na cidade? ',
    answer: {
      options: [
        { percentage: '16%', option: 'a. A pé' },
        { percentage: '16%', option: 'b. Ônibus' },
        { percentage: '16%', option: 'c. Carro próprio' },
        { percentage: '16%', option: ' d. Uber' },
        { percentage: '16%', option: 'e. Bicicleta' },
      ],
    },
  },
]

export const Questions = ({ sx }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      {mocks.map((mock) => (
        <Card
          key={mock.id}
          sx={{
            height: '200px',
            minHeight: '200px',
            p: '16px',
            px: '15px',
            ...sx,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, ml: 2 }}>
            <Typography
              sx={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.6)',
                fontWeight: 500,
              }}
            >
              {t('card.question')} {mock.id} -
            </Typography>

            <Typography
              sx={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.6)',
                width: '335px',
              }}
            >
              {mock.question}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(12, 1fr)',
              gridColumn: 'span 12',
              mt: 1,
              ml: 2,
            }}
          >
            {mock.answer.options.map((option, index) => {
              return (
                <>
                  <Typography
                    key={index}
                    sx={{
                      fontSize: '16px',
                      color: '#FF5E1E',
                      fontWeight: 700,
                      gridColumn: 'span 2',
                    }}
                  >
                    {option.percentage}
                  </Typography>

                  <Typography
                    key={index}
                    sx={{
                      fontSize: '16px',
                      color: '#000000DE',
                      fontWeight: 400,
                      gridColumn: 'span 10',
                    }}
                  >
                    {option.option}
                  </Typography>
                </>
              )
            })}
          </Box>
        </Card>
      ))}
    </>
  )
}
