import * as yup from 'yup'
import { Question } from '../../../models/campaigns-model/question-model'
import { answerSchemaValidation } from './answerSchemaValidator'

export const questionDefaultValues: Question = {
  id: '',
  question: '',
  options: [],
}

export const questionSchemaValidation = yup.object().shape({
  id: yup.string().nullable(),
  question: yup.string().required('required'),
  options: yup.array().of(answerSchemaValidation),
})
