import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Container, Typography } from '@mui/material'
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'
import AddIcon from '@mui/icons-material/Add'

import useProtectedPage from 'src/hooks/useProtectedPage'
import ActivityHistory from 'src/components/SideMenuAudit/ActivityHistory'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { getPermissions } from 'src/service/authService'
import { goToCreatePointGroups } from '../../routes/coordinator'
import PointGroupsTable from './components/Table/PointGroupsTable'

const PointGroups = () => {
  useProtectedPage()
  const { t } = useTranslation()
  const history = useNavigate()
  const permissions = JSON.parse(getPermissions())

  return (
    <Container
      sx={{
        mt: '96px',
      }}
    >
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <WorkspacesOutlinedIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: ' #FF5E1E',
              marginTop: '-4px',
            }}
          >
            {t('pointGroups.title')}
          </Typography>
        </Box>
        {
          permissions?.auditory?.includes('auditory:list') && <ActivityHistory path="grupo-de-pontos" keySubtitle="groupName" />
        }
      </Box>
      <Button
        onClick={() => goToCreatePointGroups(history)}
        disabled={!permissions?.groups?.includes("groups:create")}
        variant="outlined"
        sx={{
          width: '50%',
          gap: '6px',
          height: '36px',
          mb: '40px',
          ':hover': {
            backgroundColor: '#FF5E1E',
            color: '#ffff',
          },
        }}
      >
        <AddIcon /> {t('pointGroups.button.create')}
      </Button>

      <PointGroupsTable />
    </Container>
  )
}
export default PointGroups
