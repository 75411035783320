import { useContext, useState } from 'react'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'

import { Loading } from 'src/components/Loading/Loading'
import { PreviewTemplate } from 'src/components/Preview/PreviewTemplate'
import { TimeDecreaser } from 'src/components/TimeDecreaser/TimeDecreaser'
import { CampaignContext } from 'src/context/CampaignFormContext'

export const TextCampaignPreview = () => {
  const {
    formState: { watch, getValues },
  } = useContext(CampaignContext)
  const [waiting, setWaiting] = useState(true)
  const [config, setConfig] = useState<any>()
  return (
    <PreviewTemplate onLoadConfig={(config) => setConfig(config)}>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          p: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '16px',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '4px',
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            color: watch('content.titleColor'),
            fontWeight: 400,
            lineHeight: '29px',
            overflowWrap: 'break-word',
            width: '100%',
          }}
        >
          {watch('content.title') || ''}
        </Typography>
        <Typography
          sx={{
            color: watch('content.descriptionColor'),
            fontSize: '14px',
            width: '100%',
            overflowWrap: 'break-word',
          }}
        >
          {new DOMParser().parseFromString(
            watch('content.description'),
            'text/html'
          )?.body?.textContent || ''}
        </Typography>
        {waiting && getValues('content.hasMediaInterval') && (
          <Button
            variant="outlined"
            sx={{
              p: 0,
              height: '45px',
              width: '100%',
              backgroundColor: config?.buttonColor,
              color: config?.textColor,
              ':disabled': {
                color: config?.textColor,
              },
            }}
            disabled={true}
          >
            <Loading size="15px" sx={{ color: config?.textColor, mr: 1 }} />
            <TimeDecreaser
              time={Math.round(getValues('content.mediaInterval'))}
              onEnd={() => setWaiting(false)}
            />
            {t('campaign.time.count.to.continue')}
          </Button>
        )}

        {(!waiting || !getValues('content.hasMediaInterval')) && (
          <Button
            variant="outlined"
            sx={{
              p: 0,
              height: '45px',
              width: '100%',
              backgroundColor: config?.buttonColor,
              color: config?.textColor,
              ':disabled': {
                color: config?.textColor,
              },
            }}
            disabled={true}
          >
            {t('campaign.preview.join')}
          </Button>
        )}
      </Box>
    </PreviewTemplate>
  )
}
