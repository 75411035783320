import { useContext, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ListIcon from '@mui/icons-material/List'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined'

import { goToCampaigns } from 'src/routes/coordinator'
import { StepperContext } from 'src/context/StepperContext'
import ModalDiscardInfo from 'src/components/Modal/ModalDiscardInfo'

interface Props {
  onClean: () => void;
  cleanButtonEnabled: boolean;
  nextButtonEnabled: boolean;
  prevButtonEnabled: boolean;
  previewButtonEnabled: boolean;
  formIsValid: boolean;
  prevButtonAction: () => void;
  previewButtonAction: () => void;
  nextButtonAction?: () => void;
  nextButtonCta?: string;
  nextButtonArrow?: boolean;
  isEdit?: any;
  goTo?: any;
}

export const FormButtons = ({
  onClean,
  cleanButtonEnabled,
  nextButtonEnabled,
  prevButtonEnabled,
  previewButtonEnabled,
  prevButtonAction,
  previewButtonAction,
  nextButtonCta,
  nextButtonAction,
  nextButtonArrow,
  isEdit,
  goTo,
}: Props) => {
  const { nextStep } = useContext(StepperContext);
  const history = useNavigate();
  const [modalDiscardInfo, setModalDiscardInfo] = useState<any>('');

  const handleOpenModalDiscardInfo = () => {
    setModalDiscardInfo(
      <ModalDiscardInfo
        open={true}
        title={
          isEdit
            ? t('modal.edit.discardinfo.title')
            : t('modal.create.discardinfo.title')
        }
        onHandleClose={() => setModalDiscardInfo('')}
        onHandlDiscardClick={() => {
          setModalDiscardInfo('');
          goTo(history);
        }}
      />
    );
  };

  const backToList = () => {
    window.location.href = '/gerenciamento/campanhas';
  };

  const handleListIconClick = () => {
    if (nextButtonEnabled) {
      handleOpenModalDiscardInfo();
    } else {
      backToList();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '16px',
        my: 1,
      }}
    >
      {modalDiscardInfo && modalDiscardInfo}
      <Tooltip title={t('form.button.back_to_list')} placement="right">
        <Button
          onClick={handleListIconClick}
          variant="outlined"
          sx={{
            p: 0,
            height: '36px',
            minWidth: '36px',
            borderColor: 'rgba(0, 0, 0, 0.6)',
            color: '#00000099',
            //   backgroundColor: !reset ? '#00000099' : '#ffff',
            ':hover': {
              color: '#ffff',
              backgroundColor: '#00000099',
              border: 'none',
            },
          }}
        >
          <ListIcon />
        </Button>
      </Tooltip>
      <Box sx={{ minWidth: '36px' }}>
        {previewButtonEnabled && (
          <Tooltip title={t('form.button.to_view')} placement="left">
            <Button
              onClick={previewButtonAction}
              variant="outlined"
              disabled={!prevButtonEnabled}
              sx={{
                display: 'flex',
                p: 0,
                height: '36px',
                minWidth: '36px',
                borderColor: 'rgba(0, 0, 0, 0.6)',
                color: '#00000099',
                backgroundColor: '#ffff',
                ':hover': {
                  color: '#ffff',
                  backgroundColor: '#00000099',
                  border: 'none',
                },
              }}
            >
              <PreviewOutlinedIcon />
            </Button>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ minWidth: '364px' }}>
        <Button
          variant="outlined"
          sx={{
            p: 0,
            height: '36px',
            minWidth: '364px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
            gap: 1,
            color: '#FF5E1E',
            borderColor: '#FF5E1E',
            ':hover': {
              backgroundColor: '#FF5E1E',
              color: '#ffff',
            },
          }}
          disabled={!prevButtonEnabled}
          onClick={prevButtonAction}
        >
          <ArrowBackIcon /> {t('form.button.previous')}
        </Button>
      </Box>
      <Box sx={{ minWidth: '36px' }}>
        {cleanButtonEnabled && (
          <Tooltip title={t('form.button.clean')} placement="left">
            <Button
              onClick={() => onClean && onClean()}
              variant="outlined"
              sx={{
                p: 0,
                height: '36px',
                minWidth: '36px',
                borderColor: 'rgba(0, 0, 0, 0.6)',
                color: '#00000099',
                ':hover': {
                  color: '#ffff',
                  backgroundColor: '#00000099',
                  border: 'none',
                },
              }}
            >
              <DeleteOutlineIcon />
            </Button>
          </Tooltip>
        )}
      </Box>

      <Button
        variant="outlined"
        sx={{
          height: '36px',
          width: '100%',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '16px',
          gap: 1,
          color: '#FF5E1E',
          borderColor: '#FF5E1E',
          ':hover': {
            backgroundColor: '#FF5E1E',
            color: '#ffff',
          },
        }}
        disabled={!nextButtonEnabled}
        onClick={() => {
          if (nextButtonAction) {
            nextButtonAction()
            return
          }
          nextStep()
        }}
      >
        {nextButtonCta ? t(nextButtonCta) : t('form.button.next')}
        {nextButtonArrow && <ArrowForwardIcon />}
      </Button>
    </Box>
  )
}
