import { Box } from '@mui/system'

export const Card = (props: any) => {
  return (
    <Box
      {...props}
      sx={{
        ...props?.sx,
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);',
        borderRadius: '4px;',
      }}
    >
      {props?.children as any}
    </Box>
  )
}
