import TitleIcon from '@mui/icons-material/Title'
import TuneIcon from '@mui/icons-material/Tune'
import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { CampaignContext } from 'src/context/CampaignFormContext'
import StepperProvider from 'src/context/StepperContext'
import { dependencyToFrontend, segmentedRulesToQuestions } from 'src/service/campaignFormService'
import {
  getCampaignById,
  getSystemEnrichments,
} from 'src/service/marketingCampaignService'
import { Stepper } from '../Stepper'
import { ContentForm } from './ContentForm/Index'
import { DefinitionsForm } from './DefinitionsForm/DefinitionsForm'

interface Props {
  isViewMode?: boolean
  viewId?: any
}

export const CampaignForm = ({ isViewMode, viewId }: Props) => {
  const { id } = useParams()
  const { setContentType, formState } = useContext(CampaignContext)

  const getCampaign = async () => {
    if (id || isViewMode) {
      const campaign = await getCampaignById(id || viewId)
      formState?.reset({})
      setContentType(campaign?.contentType as any)
      const res = []
      const enrichments = await getSystemEnrichments()
      const questions = segmentedRulesToQuestions(
        campaign?.segmentedRules,
        enrichments?.listEnrichmentProfile || []
      )
      const dependency = await dependencyToFrontend(campaign?.segmentedCampaignDependency || {})
      formState?.reset({
        ...campaign,
        questions,
        dependency,
        content: {
          ...campaign?.content,
          hasMediaInterval: campaign?.content?.hasMediaInterval,
          mediaInterval: campaign?.content?.mediaInterval,
        },
      })
    }
  }

  useEffect(() => {
    getCampaign()
  }, [])

  return (
    <StepperProvider>
      <Stepper
        isView={isViewMode}
        itens={[
          {
            label: 'campaign.label.tabs.contents',
            icon: <TitleIcon />,
            content: <ContentForm isViewMode={isViewMode} />,
          },
          {
            label: 'campaign.label.tabs.definitions',
            icon: <TuneIcon />,
            content: <DefinitionsForm />,
          },
        ]}
      />
    </StepperProvider>
  )
}
