import { useTranslation } from 'react-i18next'
import { List, ListSubheader, SxProps, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { Card } from './Card'
type CampaignInfo = {
  name: string
  value: string | number
  index?: number
}

interface Props {
  data: CampaignInfo[]
  sx?: SxProps
}
export const CampaignRanking = ({ data, sx }: Props) => {
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        ...sx,
      }}
    >
      <List
        sx={{
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          '& ul': { padding: 0 },
        }}
      >
        <ul>
          <ListSubheader
            sx={{
              top: '-8px',
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.6)',
              paddingLeft: '31px',
              fontWeight: 400,
              borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
            }}
          >
            {t('painel.views')}
          </ListSubheader>

          <Box sx={{ width: '100%', py: '16px', px: '32px' }}>
            {data &&
              data.map(
                ({ name, value }, index) =>
                  index < 10 && (
                    <Row
                      key={`${name}-${value}`}
                      name={name}
                      value={value}
                      index={index + 1}
                    />
                  )
              )}
          </Box>
        </ul>
      </List>
    </Card>
  )
}

const Row = ({ name, value, index }: CampaignInfo) => {
  const { t } = useTranslation()
  const isPlural = value !== 1
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        mb: '16px',
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6)',
          fontWeight: 500,
          width: '55px',
        }}
      >
        {index}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6)',
          fontWeight: 500,
          width: '150px',
          mr: '20px',
        }}
      >
        {name.length <= 0 ? `${'-'}` : name.slice(0, 20)}
      </Typography>

      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6)',
          fontWeight: 500,
          width: '150px',
          textAlign: 'right',
        }}
      >
        {isPlural
          ? `${value} ${t('campaign.result.card.ranking.visualizations')}`
          : `${value} ${t('campaign.result.card.ranking.visualization')}`}
      </Typography>
    </Box>
  )
}
