import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Box } from '@mui/system'

import { DoughnutChartColors } from './DoughnutChart'

interface Props {
  labels: string[]
  data: number[]
}

export const PieChart = ({ labels, data }) => {
  ChartJS.register(ArcElement, Tooltip, Legend)
  const Colors = ['#FF5E1E', '#DBDBDB', '#6F6F6F', '#1D1D1D']

  const chartData = {
    datasets: [
      {
        data,
        backgroundColor: data.length <= 0 ? '#DBDBDB' : Colors,
        borderColor: ['white'],
        borderWidth: 2,
      },
    ],
  }
  return (
    <Box sx={{ height: '130px', width: '130px' }}>
      <Pie
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx) => {
                  return `${labels[ctx.dataIndex]?.slice(0, 12) || '?'}: ${
                    data[ctx.dataIndex]
                  }`
                },
              },
            },
          },
        }}
      />
    </Box>
  )
}
