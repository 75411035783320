import { FrequentlyAskedQuestion } from 'src/models/faqs-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const createFAQ = async (faq: { question: string, answer: string}): Promise<FrequentlyAskedQuestion | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/faqs`, faq)
    return res.data
  } catch (error) {
    return false
  }
}

export const fetchFaqs = async (options?: any): Promise<FrequentlyAskedQuestion[] | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/faqs`, options)
    return res.data
  } catch (error) {}
}

export const getFAQById = async (id: string): Promise<FrequentlyAskedQuestion | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/faqs/${id}`)
    return res.data
  } catch (error) {}
}

export const editFAQ = async (id: string, faq: FrequentlyAskedQuestion): Promise<FrequentlyAskedQuestion | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/faqs/${id}`, faq)
    return res.data
  } catch (error) {
    return false
  }
}

export const deleteFAQById = async (id: string | number): Promise<FrequentlyAskedQuestion | boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/faqs/${id}`)
    return true
  } catch (error) {
    return false
  }
}
