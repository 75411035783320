/* global google */
import * as React from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { createCustomEqual } from 'fast-equals'
import { isLatLngLiteral } from '@googlemaps/typescript-guards'

import { getMapsApiKey } from '../../domain/getEnvVarOrThrow'
import { MapContext } from 'src/context/MapContext'
import markerIt from '../../assets/icons/ICON-MAP-ACTIVE.svg'
import markerItInactive from '../../assets/icons/ICON-MAP-INACTIVE.svg'

const render = (status: Status) => {
  return <h1>{status}</h1>
}

const styles: Record<string, google.maps.MapTypeStyle[]> = {
  silver: [
    {
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#c9c9c9' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
  ],
}
export interface MapComponetProps {
  defaultPoint?: google.maps.LatLng | null
  center?: google.maps.LatLng
  zoom?: number
  onClickMarker?: (value: google.maps.LatLng) => void
  isViewMode?: any
}

const ClicableMap = ({ defaultPoint, isViewMode }: MapComponetProps) => {
  const centerMap: google.maps.LatLng = new google.maps.LatLng(
    -16.683922067895228,
    -49.2659034729004
  )
  const [zoom, setZoom] = React.useState(12)
  const [center, setCenter] = React.useState<google.maps.LatLng>(centerMap)
  const [clear, setClear] = React.useState<google.maps.LatLng[]>([])
  const { latLng, setLatLng } = React.useContext(MapContext)
  React.useEffect(() => {
    if (defaultPoint) {
      setCenter(defaultPoint)
      setLatLng(defaultPoint)
    }
  }, [defaultPoint])

  const onClick = (e: google.maps.MapMouseEvent) => {
    setZoom(17)
  }

  const onDblclick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setLatLng(e.latLng!)
      setClear([])
    }
  }

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!)
    setCenter(m.getCenter()!)
  }
  return (
    <Wrapper apiKey={getMapsApiKey()} render={render}>
      <Map
        disableDefaultUI={isViewMode ? true : false}
        disableDoubleClickZoom={isViewMode ? true : false}
        styles={styles.silver}
        center={
          latLng.lng()
            ? latLng
            : new google.maps.LatLng(-16.683922067895228, -49.2659034729004)
        }
        onClick={onClick}
        onDblclick={onDblclick}
        onIdle={onIdle}
        zoom={latLng.lng() ? 18 : 10}
        style={{
          height: isViewMode ? '140px' : '280px',
          with: '100%',
        }}
        mapTypeControl
        streetViewControl={false}
        zoomControl={isViewMode ? false : true}
        gestureHandling={isViewMode ? 'none' : 'greedy'}
        mapTypeId="roadmap"
        clickableIcons={isViewMode ? false : true}
      >
        <Marker
          clickable={isViewMode ? false : true}
          draggable={isViewMode ? false : true}
          position={latLng}
          key={`${latLng?.lat()}x${latLng?.lng()}`}
        />
      </Map>
    </Wrapper>
  )
}

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string }
  onClick?: (e: google.maps.MapMouseEvent) => void
  onDblclick?: (e: google.maps.MapMouseEvent) => void
  onIdle?: (map: google.maps.Map) => void
  children?: React.ReactNode
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  onDblclick,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [map, setMap] = React.useState<google.maps.Map>()
  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
  }, [ref, map])

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  React.useEffect(() => {
    if (map) {
      ;['click', 'places_changed'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      )

      if (onClick) {
        map.addListener('click', onClick)
        map.setZoom(18)
      }

      if (onDblclick) {
        map.addListener('dblclick', onDblclick)
      }

      if (onIdle) {
        map.addListener('places_changed', () => onIdle(map))
      }
    }
  }, [map, onClick, onIdle, onDblclick])

  return (
    <div>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement<any>(child, { map })
        }
      })}
    </div>
  )
}

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>()
  const [fresh, setFresh] = React.useState(true)
  const changeIcon = async () => {
    setFresh(true)
    await waitSeconds(4000)
    setFresh(false)
  }
  const waitSeconds = async (s: number) =>
    await new Promise((resolve) => setTimeout(resolve, s))

  React.useEffect(() => {
    changeIcon()
  }, [])

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker())
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  React.useEffect(() => {
    if (marker) {
      marker.setOptions({
        ...options,
        icon: fresh ? markerIt : markerItInactive,
      })
    }
  }, [marker, options, fresh])

  return null
}

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
    }

    return deepEqual(a, b)
  }
)

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

export default ClicableMap
