import { t } from 'i18next'
import { Box } from '@mui/material'

import InputSelectRHF from '../RHF/InputSelectRHF'

interface Props {}
export const OptionsSelect = ({ onRemove, ...props }: any) => {
  return (
    <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
      }}
    >
      <InputSelectRHF
        placeholder={t('campaign.button.segmentation.option.label')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.name === value.name
        }
        getOptionLabel={(value: any) => value?.name || ''}
        freeSolo
        sx={{
          width: '100%',
          height: '48px',
        
        }}
        {...props}
        onChangeAction={(v) => {
          if (!v) onRemove()
          props.onChangeAction(v)
        }}
      />
    </Box>
  )
}
