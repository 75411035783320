import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import { FooterBottonsView } from '../FormUtils/FooterButtonsView'

const style = {
  boxSizing: ' border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  bgcolor: 'background.paper',
  p: '16px',
  borderRadius: '4px',
  border: 'none',
}

export interface ViewItemModalProps {
  open: boolean
  handleClose(): void
  children?: any
  id?: string
  goTo?: any
  size?: string
  ml?: string
  mr?: string
  handleOpenModal?: any
  name?: any
  showEditButton?: boolean
}

export default function ViewItemModal({
  handleClose,
  open,
  children,
  id,
  goTo,
  size,
  ml,
  mr,
  handleOpenModal,
  name,
  showEditButton = true,
}: ViewItemModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        overflow:'scroll',
        position:'absolute',
      }}
    >
      <Box sx={style}>
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{
            left: '1px',
            color: '#00000099',
            ':hover': {
              color: '#FF5E1E',
              backgroundColor: '#FF5E1E14',
            },
          }}
        >
          <ClearIcon sx={{ height: '16px', width: '16px', left: '1px' }} />
        </IconButton>

        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Box>
        <Box>
          <FooterBottonsView
            id={id}
            goTo={goTo}
            size={size}
            handleClose={handleClose}
            mr={mr}
            ml={ml}
            handleOpenModal={handleOpenModal}
            name={name}
            showEditButton={showEditButton}
          />
        </Box>
      </Box>
    </Modal>
  )
}
