import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import SwitchRHF from 'src/components/RHF/SwitchRHF'
import InputRHF from '../../../../components/RHF/InputRHF'
import { goToRoles } from '../../../../routes/coordinator'
import { roleDefaultValues, roleSchemaValidation } from '../../validators/roleSchemaValidators'

import { CompanySelect } from 'src/components/CompanySelect/CompanySelect'
import { FooterBottons } from 'src/components/FormUtils/FooterButtons'
import { Company } from 'src/models/company-model'
import { Role } from 'src/models/role-model'
import { getCompanyId, getRole } from 'src/service/authService'
import { getPermissions } from 'src/service/roleService'

interface RoleFormProps {
  data: Role
  onSubmit(data: any): void
  isViewMode?: boolean
}

export const RoleForm = ({ data, onSubmit, isViewMode }: RoleFormProps) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [permissions, setPermissions] = useState([])
  const [termSearch, setTermSearch] = useState<any>('')

  const getPermission = async () => {
    const permissions = await getPermissions()
    setPermissions(permissions)
  }

  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const handleCheckboxChange = (isChecked: boolean, permission: string) => {
    if (isChecked) {
      const updatedPermissions = [...selectedPermissions, permission];
      setSelectedPermissions(updatedPermissions);
      setValue('permissions', updatedPermissions);
    }
    else {
      const updatedPermissions = selectedPermissions.filter((selectedPermission) =>
        selectedPermission !== permission);
      setSelectedPermissions(updatedPermissions);
      setValue('permissions', updatedPermissions);
    }
  };

  useEffect(() => {
    getPermission()
  }, [])
  const form = useForm<any>({
    mode: 'all',
    defaultValues: roleDefaultValues,
    resolver: yupResolver(roleSchemaValidation),
  })
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState,
    setValue,
    trigger,
    watch,
  } = form

  const formIsValid = selectedPermissions.length > 0

  function checkPresence(array, item) {
    return array.some(element => element.name === item);
  }

  useEffect(() => {
    if (data) {
      reset(data)
      var arrayPermissions = []
      data?.permissions.forEach((permission) => {
        arrayPermissions.push(permission["name"]);
      })
      setSelectedPermissions(arrayPermissions)
      setValue('permissions', arrayPermissions);
      setValue('roleId', data.id)
      setValue('companyId', {
        companyId: data?.companyId ?? '',
        companyName: data?.company?.["companyName"] ?? ''
      })
    }
    console.log(data)
  }, [data])

  useEffect(() => {
    if (getRole() !== 'TECNOIT') {
      setValue('companyId', {
        companyId: getCompanyId()
      })
    }
  }, [])

  const onChangeCompany = (company: Company) => {
    setValue('companyId', company)
  }
  const resetForm = () => {
  }

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" sx={{ mb: 2 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        {getRole() === 'TECNOIT' && (
          <CompanySelect
            sx={{
              gridColumn: 'span 11',
            }}
            control={control}
            onChangeAction={onChangeCompany}
          />
        )}
        {getRole() === 'TECNOIT' && (
          <SwitchRHF
            sx={{
              gridColumn: 'span 1',
            }}
            name="isTecnoitRole"
            control={control}
            labelNameOn={t('role.form.input.placeholder.isTecnoitRole')}
            labelNameOff={t('role.form.input.placeholder.isTecnoitRole')}
            onChangeAction={() => {
              trigger && trigger('isTecnoitRole')
              setValue && setValue('isTecnoitRole', '')
            }}
          />
        )}
        <InputRHF
          control={control}
          label={t('role.form.input.label.name')}
          placeholder={t('role.form.input.placeholder.name')}
          name="roleName"
          sx={{
            gridColumn: 'span 11',
          }}
        />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            rowGap: 1,
            columnGap: 2,
            gridColumn: 'span 12',
          }}
        >
          <div>
            {Object.keys(permissions).map((module, index) => (
              <Fragment key={index}>
                <div>
                  {getRole() === 'TECNOIT' ? (<h2>{t(module)}</h2>) : (!module.includes('faqs') && !module.includes('training-video') && !module.includes('notification') && !module.includes('company') && !module.includes('enrichment')) ? (<h2>{t(module)}</h2>) : ('')}
                  {Array.isArray(permissions[module]) ? (
                    permissions[module].map((item, i) => (
                      getRole() === 'TECNOIT' ? (
                        <Fragment key={i}>
                          <div>
                            <FormControlLabel
                              name={item.id}
                              control={<Checkbox
                                onChange={(event) => handleCheckboxChange(event.target.checked, item.name)}
                                checked={selectedPermissions.includes(item.name) || false} />}
                              label={item.description} />
                          </div>
                          <hr style={{ borderTop: '1px dashed darkgrey' }} />
                        </Fragment>
                      ) : (!item.name.includes('company') && !item.name.includes('enrichment') && !item.name.includes('faqs') && !item.name.includes('training-video') && !item.name.includes('notification')) ? (
                        <Fragment key={i}>
                          <div>
                            <FormControlLabel
                              name={item.id}
                              control={<Checkbox
                                onChange={(event) => handleCheckboxChange(event.target.checked, item.name)}
                                checked={selectedPermissions.includes(item.name) || false} />}
                              label={item.description} />
                          </div>
                          <hr style={{ borderTop: '1px dashed darkgrey' }} />
                        </Fragment>
                      ) : ('')
                    ))
                  ) : ('')}
                </div>
              </Fragment>
            ))}
          </div>
        </Box>
      </Box>
      {!isViewMode && (
        <FooterBottons
          isValid={!formIsValid}
          formIsValid={formIsValid}
          isEdit={Boolean(id)}
          goTo={goToRoles}
          reset={() => resetForm()}
          size="100%"
          buttonName="role.button.create"
        />
      )}
    </Box>
  )
}
