import { toInteger } from 'lodash'
import { Point } from './Map'
import { t } from 'i18next'

const config = [
  {
    longRangeStart: 0,
    longRangeEnd: 33,
    shortRangeSet: [
      {
        color: 'rgba(46, 125, 50, .3)',
        shortRangeStart: 1,
        shortRangeEnd: 11,
      },
      {
        color: 'rgba(46, 125, 50, .6)',
        shortRangeStart: 11,
        shortRangeEnd: 22,
      },
      {
        color: 'rgba(46, 125, 50, .9)',
        shortRangeStart: 22,
        shortRangeEnd: 33,
      },
    ],
  },
  {
    longRangeStart: 33,
    longRangeEnd: 66,
    shortRangeSet: [
      {
        color: 'rgba(255, 167, 38, .3)',
        shortRangeStart: 33,
        shortRangeEnd: 44,
      },
      {
        color: 'rgba(255, 167, 38, .6)',
        shortRangeStart: 44,
        shortRangeEnd: 55,
      },
      {
        color: 'rgba(255, 167, 38, .9)',
        shortRangeStart: 55,
        shortRangeEnd: 66,
      },
    ],
  },
  {
    longRangeStart: 66,
    longRangeEnd: 100,
    shortRangeSet: [
      {
        color: 'rgba(244, 67, 54, .3)',
        shortRangeStart: 66,
        shortRangeEnd: 77,
      },
      {
        color: 'rgba(244, 67, 54, .6)',
        shortRangeStart: 77,
        shortRangeEnd: 88,
      },
      {
        color: 'rgba(244, 67, 54, .9)',
        shortRangeStart: 88,
        shortRangeEnd: 100,
      },
    ],
  },
]

export default class HeatMap {
  public static colorConfig = config

  public static getColor(percent: number) {
    if (!percent) return 'transparent'
    for (const config of this.colorConfig) {
      const { longRangeEnd, longRangeStart, shortRangeSet } = config
      if (HeatMap.isInRange(longRangeStart, longRangeEnd, percent)) {
        for (const colorSet of shortRangeSet) {
          const { shortRangeStart, shortRangeEnd, color } = colorSet
          if (HeatMap.isInRange(shortRangeStart, shortRangeEnd, percent)) {
            return color
          }
        }
      }
    }

    return 'rgba(0, 0, 0)'
  }

  private static isInRange(
    start: number,
    end: number,
    number: number
  ): boolean {
    return number >= start && number <= end
  }

  static getSumOfArray(numbers: number[]): number {
    let sum = 0
    for (const number of numbers) {
      sum += number
    }
    return sum
  }

  static calculatePercentageArray(numbers: number[], total: number): number[] {
    const percentageArray: number[] = []
    for (const number of numbers) {
      const percentage = (number / total) * 100
      percentageArray.push(toInteger(percentage))
    }
    return percentageArray
  }

  static getCenterLatLng(
    latlngList: google.maps.LatLng[]
  ): google.maps.LatLng | null {
    return latlngList[0]
    if (latlngList.length === 0) {
      return null
    }

    let totalLat = 0
    let totalLng = 0

    for (const latlng of latlngList) {
      totalLat += latlng.lat()
      totalLng += latlng.lng()
    }

    const centerLat = totalLat / latlngList.length
    const centerLng = totalLng / latlngList.length

    return new google.maps.LatLng(centerLat, centerLng)
  }

  static parsePoint(point): Point {
    const { geoLatitude, geoLongitude, isActive, totalAccess, apName } = point
    return {
      name: `${t(
        'heatmap.legend.point.label.prefix'
      )} ${apName}\n${totalAccess} ${t(
        'heatmap.legend.point.label.totalAccess.suffix'
      )}`,
      latlng: new google.maps.LatLng(geoLatitude, geoLongitude),
      isActive,
      totalAccess,
    }
  }

  static getByPointId(id: string, data): Point[] {
    const point = data?.accessPointsData?.find((point) => id === point?.id)
    const res = [this.parsePoint(point)]
    return this.groupByLatLng(res)
  }

  static getByGroupsId(groupsId: string[], data): Point[] {
    const points = data?.accessPointsData || []
    const filteredPoints = points.filter((point) => {
      const pointGroups = point?.groupData?.map(({ id }) => id)
      return HeatMap.arrHasComumValue(pointGroups, groupsId)
    })
    const res = filteredPoints?.map((point) => this.parsePoint(point))
    return this.groupByLatLng(res)
  }

  static getAll(data): Point[] {
    const points = data?.accessPointsData || []
    const res = points?.map((point) => this.parsePoint(point))
    return this.groupByLatLng(res)
  }

  private static groupByLatLng(points: Point[]) {
    const groupedPoints: { [key: string]: Point[] } = {}
    points.forEach((point) => {
      const latlngKey = point.latlng.toString()

      if (!groupedPoints[latlngKey]) {
        groupedPoints[latlngKey] = []
      }

      groupedPoints[latlngKey].push(point)
    })
    const data = Object.values(groupedPoints)
    const result = data?.map((pontList) => {
      if (pontList.length === 1) {
        return pontList[0]
      }
      const pointTitle = `${pontList?.length} ${t(
        'heatMap.groupedAps.ap.tip'
      )}\n${this.getTotalConnections(pontList)} ${t(
        'dashboard.card.label.connection'
      )}`

      return {
        name: pointTitle,
        latlng: pontList[0]?.latlng,
        isActive: pontList[0]?.isActive,
        totalAccess: this.getTotalConnections(pontList),
      }
    })
    return result
  }

  private static getTotalConnections(points: Point[]) {
    var total = 0
    for (const point of points) {
      total += point?.totalAccess
    }
    return total
  }

  private static arrHasComumValue(array1: any[], array2: any[]): boolean {
    for (const elemento1 of array1) {
      if (array2.includes(elemento1)) {
        return true
      }
    }
    return false
  }
}
