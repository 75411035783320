import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import { RoleType, UserManager } from 'src/models/user-model'

export const MSG_REQUIRED_FIELD = 'O campo é obrigatório.'

export const roleTypes: RoleType[] = [
  { label: 'ADMINISTRADOR TECNO IT', value: 'TECNOIT' },
  { label: 'GERENTE DE EMPRESA', value: 'ADMIN' },
]

export const userManagerDefaultValues: UserManager = {
  id: '',
  companyId: null,
  isActive: true,
  role: 'TECNOIT',
  defaultLanguage: '',
  name: '',
  email: '',
  password: '',
  phone: '',
  profileImage: '',
  roleId: ''
}

const mode: keyof ValidationMode = 'all'
export const userManagerSchemaValidation = yup.object({
  role: yup.string().oneOf(['TECNOIT', 'ADMIN']).required('required'),
  isActive: yup.boolean(),
  defaultLanguage: yup.object().nullable().required('required'),
  name: yup.string().required('required'),
  email: yup.string().email('invalid').required('required'),
  password: yup.string().min(6, 'invalid_pass').required('required').nullable(),
  phone: yup
    .string()
    .required('required')
    .test((value, context) => {
      if (value.includes('_') || value.length < 11 || !Boolean(value)) {
        return context.createError({
          message: 'required',
          path: 'phone',
        })
      }
      return true
    }),
  profileImage: yup.string(),
  roleId: yup.object().nullable(),
})

export const userUseFormArgs = {
  mode,
  defaultValues: userManagerDefaultValues,
  resolver: yupResolver(userManagerSchemaValidation),
}

export interface UserManagerTypes
  extends yup.InferType<typeof userManagerSchemaValidation> {}
