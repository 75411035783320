import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { answerDefaultValues } from '../../../../validators/answerSchemaValidator'
import InputRadioRHF from '../../../../../../components/RHF/InputRadioRHF'

export const AnswersCampaign = ({
  control,
  indexQuestion,
  disabled,
  watch,
}) => {
  const { t } = useTranslation()

  const { fields, append, remove } = useFieldArray({
    control,
    name: `content.quiz[${indexQuestion}].options`,
  })

  const removeAnswer = (index: number) => {
    if (index > 1) {
      remove(index)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'baseline',
        width: '100%',
      }}
    >
      {fields.map((answer, index) => {
        return (
          <Box
            key={answer.id}
            sx={{
              display: 'flex',
              gap: '8px',
              pl: 8,
              pr: 20,
              mt: 1,
              width: '100%',
            }}
          >
            <InputRadioRHF
              disabled={disabled}
              name={`content.quiz[${indexQuestion}].options[${index}].name`}
              label={`${t('campaign.label.input.option')} ${Number(index) + 1}`}
              control={control}
              sx={{ width: '100%', height: '56px' }}
              removeAnswer={() => removeAnswer(index)}
              hideDelete={
                watch(
                  `content.quiz[${indexQuestion}].options[${index}].name`
                ) && index > 1
              }
            />
          </Box>
        )
      })}

      <Button
        disabled={disabled}
        onClick={() => append(answerDefaultValues)}
        variant="outlined"
        sx={{
          mt: 2,
          p: 0,
          height: '50px',
          minWidth: '100%',
          borderRadius: '4px',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          mr: 4,
          color: '#00000099',
          ':hover': {
            color: '#FF5E1E',
          },
        }}
      >
        <AddIcon
          sx={{
            height: '24px',
            minWidth: '24px',
          }}
        />
      </Button>
    </Box>
  )
}
