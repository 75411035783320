import { useContext, useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined'
import TextFormatOutlinedIcon from '@mui/icons-material/TextFormatOutlined'

import {
  CampaignContext,
  contentTypeEnum,
} from 'src/context/CampaignFormContext'
import { Loading } from 'src/components/Loading/Loading'

const types = [
  {
    icon: <PlayCircleOutlinedIcon />,
    value: contentTypeEnum.MEDIA,
  },
  {
    icon: <ImageOutlinedIcon />,
    value: contentTypeEnum.IMAGE,
  },
  {
    icon: <TextFormatOutlinedIcon />,
    value: contentTypeEnum.TEXT,
  },
  {
    icon: <FormatListNumberedIcon />,
    value: contentTypeEnum.SEARCH,
  },
]

interface Props {
  isView?: any
}

export const ContentType = ({ isView }: Props) => {
  const { setContentType } = useContext(CampaignContext)

  return (
    <Box
      sx={{
        display: isView ? 'none' : 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: {
          sm: '4px',
          md: '8px',
          lg: '16px',
          xl: '16px',
        },
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        maxHeight: '180px',
      }}
    >
      {types.map(({ icon, value }) => (
        <IconButton
          key={value}
          onClick={() => setContentType(value)}
          sx={{
            width: '49%',
            height: '80px',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            borderRadius: '4px',
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 500,
            gap: 1,
            ':hover': {
              backgroundColor: 'rgba(255, 94, 30, 0.08)',
              color: '#FF5E1E',
              border: '1px solid #FF5E1E',
            },
          }}
        >
          {icon}
          {t(`campaign.button.type.${value}`)}
        </IconButton>
      ))}
    </Box>
  )
}
