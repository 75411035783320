import { createContext, ReactNode, useState } from 'react'

const defaultState = {
  open: false,
  handleOpen: () => {},
  handleClose: () => {},
}

interface ContextProviderProps {
  children: ReactNode
}

export const ModalPortalIsActiveContext = createContext<any>(defaultState)

function ModalPortalIsActiveProvider({ children }: ContextProviderProps) {
  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<any>({})

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const openModalPortalIsActive = (data: any) => {
    setData(data)

    handleOpenModal()
  }

  const openModal = (data) => {
    openModalPortalIsActive(data)
  }

  return (
    <ModalPortalIsActiveContext.Provider
      value={{
        open,
        data,
        handleCloseModal,
        handleOpenModal,
        openModalPortalIsActive,
        openModal,
      }}
    >
      {children}
    </ModalPortalIsActiveContext.Provider>
  )
}

export default ModalPortalIsActiveProvider
