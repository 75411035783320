import { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = () => {
  const [token, setToken] = useState(localStorage.getItem('token'))

  return token ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoute
