import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        borderColor: 'white',
      },
    },
    y: {
      grid: {
        display: false,
        borderColor: 'white',
      },
      ticks: {
        display: false,
      },
    },
  },
}

interface Props {
  labels: string[]
  data: number[]
}

export default function VerticalChart({ labels, data }: Props) {
  const colours = data.map((value) => (value <= 0 ? '#DBDBDB' : '#FF5E1E'))
  const completeData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colours,
        borderRadius: 55,
        categoryPercentage: 0.15,
        minBarLength: 2,
      },
    ],
  }
  return (
    <div style={{ height: '120px' }}>
      <Bar options={options} data={completeData} />
    </div>
  )
}
