import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { PieChart } from '../Charts/PieChart'
import { Card } from './Card'

export const Navegators = ({ labels, data, sx }) => {
  const { t } = useTranslation()
  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: '16px',
        px: '31px',
        pb: '20px',
        ...sx
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6);',
          whiteSpace: 'pre-line',
          textAlign: 'left',
        }}
      >
        {t('campaign.result.card.navigators')}
      </Typography>

      {/* /* empty result  */}

      <Box
        sx={{
          display: labels.length <= 0 ? 'flex' : 'none',
          width: '110px',
          height: '110px',
          border: '3px solid #DBDBDB',
          borderRadius: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: '400',
          fontSize: '22px',
          lineHeight: '24px',
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.38)',
          position: 'absolute',
          mt: '54px',
          ml: '45px',
        }}
      >
        <p>0</p>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <PieChart labels={labels} data={data} />
      </Box>
    </Card>
  )
}
