import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { t } from 'i18next'
import { Box } from '@mui/system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import SwitchRHF from 'src/components/RHF/SwitchRHF'
import { CampaignContext } from 'src/context/CampaignFormContext'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { TitleWithDivider } from 'src/components/Dividers/TitleWithDivider'
import InputNumberRHF from 'src/components/RHF/InputNumberRHF'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 230,
  },
})

export const MediaIntervalSelect = ({ disabled }) => {
  const {
    formState: { control, watch, setValue, getValues },
  } = useContext(CampaignContext)

  const [countCaracters, setCountCaracters] = useState<number>(
    watch('content.mediaInterval')
  )

  useEffect(() => {
    const count = getValues('countContent')
    const wpm = 20
    if (!countCaracters) {
      setValue('content.mediaInterval', 0)
    } else {
      setValue('content.mediaInterval', Math.round(count / wpm) || 0)
    }
  }, [watch('countContent'), countCaracters])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <TitleWithDivider title={t('campaign.content.visualization.time')} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          my: 2,
          gap: 1,
        }}
      >
        <Box sx={{ width: '40px' }}>
          <SwitchRHF
            disabled={disabled}
            control={control}
            label={''}
            name="content.hasMediaInterval"
            disableLabel={true}
            key={watch('content.mediaInterval')}
            onChangeAction={(v) => {
              let viewTime =
                getValues('countContent') < 10
                  ? 0 + getValues('countContent')
                  : getValues('countContent')
              if (v) {
                setValue('content.mediaInterval', viewTime)
                setValue('countContent', viewTime)
                setCountCaracters(viewTime)
              }
            }}
          />
        </Box>

        <InputNumberRHF
          name="content.mediaInterval"
          disabled={!watch('content.hasMediaInterval') && disabled}
          control={control}
          onChange={(e) => {
            setCountCaracters(e.target.value)
            setValue('countContent', e.target.value)
            setValue('content.mediaInterval', e.target.value)
          }}
        />
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 400,
            FontSize: '14px',
          }}
        >
          {t('campaign.content.visualization.time.info')}
        </Typography>
        <CustomWidthTooltip
          placement="right"
          classes={{ popper: 'toolclass' }}
          title={t('campaign.content.visualization.time.tip')}
          sx={{
            color: '#00000099',
            cursor: 'pointer',
            ':hover': {
              color: '#FF5E1E',
            },
          }}
        >
          <InfoOutlinedIcon />
        </CustomWidthTooltip>
      </Box>
    </Box>
  )
}
