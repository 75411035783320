import { useContext, useState } from 'react'
import {  Typography } from '@mui/material'
import { Box } from '@mui/system'

import { PreviewTemplate } from 'src/components/Preview/PreviewTemplate'
import { CampaignContext } from 'src/context/CampaignFormContext'
export const SearchCampaignPreview = () => {
  const {
    formState: { watch },
  } = useContext(CampaignContext)
  const [config, setConfig] = useState<any>()
  const [questionIndex, setQuestionIndex] = useState(0)
  return (
    <PreviewTemplate onLoadConfig={(config) => setConfig(config)}>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          p: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '16px',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            color: watch('content.titleColor'),
            fontWeight: 400,
            lineHeight: '29px',
          }}
        >
          {watch('content.title') || ''}
        </Typography>
        <Typography
          sx={{ color: watch('content.descriptionColor'), fontSize: '14px' }}
        >
          {new DOMParser().parseFromString(
            watch('content.description') || '',
            'text/html'
          )?.body?.textContent || ''}
        </Typography>
        <Box
          sx={{
            minWidth: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.87)',
              mb: 2,
              fontWeight: '400',
            }}
          >
            {watch(`content.quiz[${questionIndex}].question`)}
            <Typography
              sx={{
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.87)',
                mb: 2,
                fontWeight: '400',
              }}
            >
              {watch(`content.quiz[${questionIndex}].helperText`)}
            </Typography>
          </Typography>
          {watch(`content.quiz[${questionIndex}].options`)?.map(({ name }) => (
            <Box
              sx={{
                cursor: 'pointer',
                minWidth: '100%',
                height: 'auto',
                p: 3,
                bgcolor: 'rgba(0, 0, 0, 0.08)',
                color: 'rgba(0, 0, 0, 0.6)',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                my: 1.5,
                textAlign: 'center',
              }}
              onClick={() => {
                if (watch(`content.quiz[${questionIndex + 1}]`)) {
                  setQuestionIndex(questionIndex + 1)
                }
              }}
            >
              {name}
            </Box>
          ))}
        </Box>
      </Box>
    </PreviewTemplate>
  )
}
