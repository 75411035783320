import { useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { GridColumns } from '@mui/x-data-grid'
import { Chip } from '@mui/material'

import {
  deletePortalById,
  getPortals,
  recyclePortalById,
  unarchivePortalById,
} from 'src/service/portalService'
import { getPermissions } from 'src/service/authService'
import { ROUTES } from 'src/routes/Router'
import { AlertContext } from 'src/context/AlertContext'
import { Table } from 'src/components/Table/Table'
import { goToEditPortal } from 'src/routes/coordinator'
import CreatePortal from '../../CreatePortal'
import { ModalPortalIsActiveContext } from 'src/context/ModalPortalIsActiveContext'

export default function PortalTable() {
  const { t } = useTranslation()
  const [tableKey, setTableKey] = useState(0)
  const { createCRUDAlert } = useContext(AlertContext)
  const { openModalPortalIsActive, open, data } = useContext(
    ModalPortalIsActiveContext
  )
  const permissions = JSON.parse(getPermissions())

  const getData = async (options: any) => {
    const data = await getPortals(options)
    return {
      data: data.listCaptivePortals,
      totalPages: data.page,
      size: data?.size,
    }
  }

  const handleDelete = async (id, name) => {
    const res = await deletePortalById(id)
    if (res) createCRUDAlert(ROUTES.PORTAIS, name, 'deleted')
    return
  }

  const handleRecycle = async (id, name) => {
    const res = await recyclePortalById(id)
    if (res) {
      createCRUDAlert(ROUTES.PORTAIS, name, 'edited')
    }
  }

  const handleUnarchive = async (id, name) => {
    const res = await unarchivePortalById(id)
    if (res) {
      createCRUDAlert(ROUTES.PORTAIS, name, 'edited')
    }
  }

  const columns: GridColumns = [
    {
      field: 'portalName',
      headerName: t('table.header.portal'),
      width: 250,
      editable: false,
    },
    {
      field: 'isActive',
      headerName: t('table.header.situation'),
      type: 'boolean',
      editable: false,
      width: 105,
      renderCell: ({ row }) => {
        return row.isActive ? (
          <Chip
            sx={{
              background: 'rgba(255, 94, 30, 0.12)',
            }}
            size="small"
            label={t('table.status.active')}
          />
        ) : (
          <Chip size="small" label={t('table.status.inactive')} />
        )
      },
    },
    {
      field: 'termsAgreementTitle',
      headerName: t('table.header.termsAgreementTitle'),
      width: 360,
      type: 'number',
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
  ]

  return (
    <Table
      columns={columns}
      getData={getData}
      key={tableKey}
      handleDelete={handleDelete}
      titleKey="portalName"
      goTo={goToEditPortal}
      defaultPageSize={5}
      model={'portal'}
      Children={CreatePortal}
      size={'16px'}
      mr={'22px'}
      permissionUpdate={!permissions?.portal?.includes("portal:update")}
      permissionDelete={!permissions?.portal?.includes("portal:delete")}
      permissionView={!permissions?.portal?.includes("portal:view")}
      canShowRecycleButtons={true}
      permissionViewRecycle={permissions?.portal?.includes("portal:recycle:view")}
      permissionUpdateRecycle={permissions?.portal?.includes("portal:recycle:update")}
      handleRecycle={handleRecycle}
      canShowUnarchiveButtons={true}
      permissionViewUnarchive={permissions?.portal?.includes("portal:archive:view")}
      permissionUpdateUnarchive={permissions?.portal?.includes("portal:archive:update")}
      handleUnarchive={handleUnarchive}
    />
  )
}
