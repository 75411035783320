import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Button, Tooltip } from '@mui/material'

import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ListIcon from '@mui/icons-material/List'
import AddIcon from '@mui/icons-material/Add'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import FormatShapesIcon from '@mui/icons-material/FormatShapes'
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined'

import InputRHF from '../../../../components/RHF/InputRHF'
import { portalDefaultValues, portalSchemaValidation } from '../../validators/portalSchemaValidators'
import { goToPortal } from 'src/routes/coordinator'
import CKEditorRHF from 'src/components/CKEditorRHF/CKEditorRHF'
import { Portal } from 'src/models/portal-model'
import { Company } from 'src/models/company-model'
import { getCompanyId, getPermissions, getRole } from 'src/service/authService'
import { UploadPortalPhoto } from '../Upload/UploadPortalPhoto'
import { CompanySelect } from 'src/components/CompanySelect/CompanySelect'
import { PreviewModal, PreviewMode } from 'src/components/Modal/PreviewModal'
import { PortalPreview } from '../Modais/PortalPreview'
import SwitchRHF from 'src/components/RHF/SwitchRHF'
import { ButtonColor } from 'src/components/Button/ButtonColor'
import ModalDiscardInfo from 'src/components/Modal/ModalDiscardInfo'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface PortalFormProps {
  data: Portal
  onSubmit(data: any): void
  isViewMode?: boolean
  viewId?: any
}

export default function PortalForm({ data, onSubmit, isViewMode, viewId }: PortalFormProps) {
  const history = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const { isGlobalAdminSelected, selectedCompanyData } = useGlobalSelectedCompany()
  const [order, setOrder] = useState(0)
  const [logoImage, setLogoImage] = useState<string>()
  const [backgroundImage, setBackgroundImage] = useState<string>()
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewMode, setPreviewMode] = useState<PreviewMode>(PreviewMode.view)
  const [modalDiscardInfo, setModalDiscardInfo] = useState<any>('')

  const permissions = JSON.parse(getPermissions())
  const TECNOIT: string = 'TECNOIT'
  const isEdit = Boolean(id)

  const form = useForm<any>({
    mode: 'all',
    defaultValues: portalDefaultValues,
    resolver: yupResolver(portalSchemaValidation),
  })
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState,
    register,
  } = form

  useEffect(() => {
    if (data) {
      reset(data)
      setLogoImage(data?.logoImage)
      setBackgroundImage(data?.backgroundImage)

      if (getRole() !== TECNOIT) {
        setValue('companyId', {
          companyId: getCompanyId(),
          companyName: data?.company?.companyName,
        })
      } else {
        setValue('companyId', {
          companyId: data.companyId,
          companyName: data?.company?.companyName,
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (getRole() !== TECNOIT) {
      setValue('companyId', {
        companyId: getCompanyId(),
        companyName: data?.company?.companyName,
      })
    }
  }, [])

  useEffect(() => {
    if (!isGlobalAdminSelected()) {
      onChangeCompany(selectedCompanyData as Company)
    }
    if(!isGlobalAdminSelected && !selectedCompanyData) {
      setValue('companyId', undefined)
    }
  }, [selectedCompanyData])

  useEffect(() => {
    setValue('logoImage', logoImage)
    setValue('backgroundImage', backgroundImage)
    trigger('logoImage')
    trigger('backgroundImage')
  }, [logoImage, backgroundImage])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setOrder(newValue)
  }

  const onChangeCompany = (company: Company) => {
    setValue('companyId', company)
  }

  const resetForm = () => {
    reset({
      companyId: '',
      portalName: '',
      buttonColor: '#000000',
      textColor: '#ffffff',
      containerColor: '#ffffff',
      containerTextColor: '#000000',
    })
    setLogoImage(undefined)
    setBackgroundImage(undefined)
    if (getRole() !== 'TECNOIT') setValue('companyId', { id: getCompanyId() })
  }

  const firstStepIsValid = () =>
    Boolean(
      watch('companyId') &&
        watch('portalName') &&
        watch('buttonColor') &&
        watch('textColor') &&
        watch('containerColor') &&
        watch('containerTextColor') &&
        watch('backgroundImage') &&
        watch('logoImage')
    )

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  const handleOpenModalDiscardInfo = () => {
    setModalDiscardInfo(
      <ModalDiscardInfo
        open={true}
        title={
          isEdit
            ? t('modal.edit.discardinfo.title')
            : t('modal.create.discardinfo.title')
        }
        onHandleClose={() => setModalDiscardInfo('')}
        onHandlDiscardClick={async () => {
          setModalDiscardInfo('')
          goToPortal(history)
        }}
      />
    )
  }

  const handleAlternateClick = () => {
    window.location.href = '/gerenciamento/portais'
  }

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete={'off'}
      noValidate
      sx={{
        boxShadow: 'none',
        border: '1px solid #0000001F',
        p: isViewMode ? '0px' : ' 0px 0px 16px 0px',
        borderRadius: '4px',
        mb: isViewMode ? '0px' : '24px',
        mt: '16px',
      }}
    >
      <PreviewModal
        handleClose={() => setShowPreview(false)}
        open={showPreview}
        title={t('portal.peview.modal.title')}
        mode={previewMode}
        onConfirm={() => {
          setShowPreview(false)
          setOrder(1)
        }}
      >
        <PortalPreview
          config={{
            backgroundImage: getValues('backgroundImage'),
            logoImage: getValues('logoImage'),
            primaryColor: getValues('buttonColor'),
            secondaryColor: getValues('textColor'),
            containerBackgroundColor: getValues('containerColor'),
            containerTextColor: getValues('containerTextColor'),
          }}
        />
      </PreviewModal>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTabs-flexContainer': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
          },
        }}
      >
        <Tabs
          value={order}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{
              width: '100%',
              height: '72px',
              ':disabled': { color: order === 0 ? '#FF5E1E' : '' },
            }}
            icon={<CropOriginalIcon />}
            label={t('portal.tabs.label.visual')}
            {...a11yProps(0)}
            disabled={true}
          />
          <Tab
            sx={{
              width: '100%',
              height: '72px',
              ':disabled': { color: order === 1 ? '#FF5E1E' : '' },
            }}
            disabled={true}
            icon={<HandshakeOutlinedIcon />}
            label={t('portal.tabs.label.acceptance_term')}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={order} index={0}>
        <Box>
          {getRole() === TECNOIT && (
            <CompanySelect
              disabled={disableInput()}
              control={control}
              onChangeAction={onChangeCompany}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mt: isViewMode ? '10px' : '28.5px',
              mb: isViewMode ? '5px' : '28px',
            }}
          >
            <InputRHF
              disabled={disableInput()}
              control={control}
              label={t('portal.form.input.label.name')}
              placeholder={t('portal.form.input.placeholder.portal')}
              name="portalName"
              sx={{
                width: '100%',
                height: '48px',
              }}
            />
            {
              permissions?.portal.includes('portal:archive:update') &&
              <SwitchRHF
                disabled={disableInput()}
                name='isArchived'
                control={control}
                onChangeAction={() => {
                  trigger && trigger('isArchived')
                  setValue && setValue('isArchived', '')
                }}
                labelNameOn='form.input.label.archived.o'
                labelNameOff='form.input.label.published.o'
              />
            }
            <SwitchRHF
              disabled={disableInput()}
              name="isActive"
              control={control}
              onChangeAction={() => {
                trigger && trigger('isActive')
                setValue && setValue('isActive', '')
              }}
              labelNameOn="form.input.label.active.o"
              labelNameOff="form.input.label.inactive.o"
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <ButtonColor
              isView={isViewMode}
              disabled={disableInput()}
              control={control}
              name={'containerColor'}
              defaultColor={getValues('containerColor')}
              setValue={setValue}
              label={t('portal.form.input.label.buttons.container')}
            />
            <ButtonColor
              isView={isViewMode}
              disabled={disableInput()}
              control={control}
              name={'containerTextColor'}
              setValue={setValue}
              defaultColor={getValues('containerTextColor')}
              label={t('portal.form.input.label.buttons.container.text')}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'baseline',
              }}
            >
              <Typography
                sx={{
                  ml: '24px',
                  mr:   '-24px',
                  width: '150px',
                  fontWeight: 400,
                  fontSize: '14px',
                  letterSpacing: '0.15px',
                  color: 'rgba(0, 0, 0, 0.6) ',
                }}
              >
                {t('portal.form.input.label.buttons')}
              </Typography>

              <ButtonColor
                isView={isViewMode}
                disabled={disableInput()}
                defaultColor={getValues('buttonColor')}
                control={control}
                name={'buttonColor'}
                setValue={setValue}
                label={t('portal.form.input.label.buttons.color.primary')}
              />

              <ButtonColor
                isView={isViewMode}
                disabled={disableInput()}
                defaultColor={getValues('textColor')}
                control={control}
                name={'textColor'}
                setValue={setValue}
                label={t('portal.form.input.label.buttons.color.secondary')}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              mt: isViewMode ? '0px' : 1,
            }}
          >
            <UploadPortalPhoto
              isView={isViewMode}
              icon={<FormatShapesIcon />}
              onFileChange={(file) => setLogoImage(file)}
              placeholder={t('portal.label.attach_brand')}
              acceptedFormats={t('input.upload.file.acceptedformats')}
              value={logoImage}
              size={'160 x 40px '}
              {...(register('logoImage'), { requerid: true })}
              isValid={formState.isSubmitted}
              error={t('portal.upload.error.logo')}
            />

            <UploadPortalPhoto
              isView={isViewMode}
              icon={<ImageOutlinedIcon />}
              onFileChange={(file) => setBackgroundImage(file)}
              placeholder={t('portal.label.background')}
              acceptedFormats={t('input.upload.file.acceptedformats')}
              value={backgroundImage}
              size={'1080 x 1920px'}
              isValid={formState.isSubmitted}
              error={t('portal.upload.error.backgound')}
            />
          </Box>
          {!isViewMode && (
            <Box
              sx={{
                display: 'flex',
                mt: '30px',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                {modalDiscardInfo && modalDiscardInfo}
                <Tooltip
                  title={t('form.button.back_to_list')}
                  placement="right"
                >
                  <Button
                    onClick={watch('companyId') ? handleOpenModalDiscardInfo : handleAlternateClick}
                    variant="outlined"
                    sx={{
                      p: 0,
                      height: '36px',
                      minWidth: '36px',
                      borderColor: 'rgba(0, 0, 0, 0.6)',
                      color: '#00000099',
                      backgroundColor: !reset ? '#00000099' : '#ffff',
                      ':hover': {
                        color: '#ffff',
                        backgroundColor: '#00000099',
                        borderColor: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                  >
                    <ListIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={t('form.button.to_view')} placement="left">
                  <Button
                    onClick={() => {
                      setPreviewMode(PreviewMode.view)
                      setShowPreview(true)
                    }}
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      p: 0,
                      height: '36px',
                      minWidth: '36px',
                      borderColor: 'rgba(0, 0, 0, 0.6)',
                      color: '#00000099',
                      backgroundColor: '#ffff',
                      ':hover': {
                        color: '#ffff',
                        backgroundColor: '#00000099',
                        borderColor: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                  >
                    <PreviewOutlinedIcon />
                  </Button>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '12px',
                }}
              >
                <Tooltip title={t('form.button.clean')} placement="left">
                  <Button
                    onClick={resetForm}
                    variant="outlined"
                    sx={{
                      p: 0,
                      minWidth: '36px',
                      height: '36px',
                      display:
                        watch('companyId') && watch('portalName')
                          ? 'flex'
                          : 'none',
                      borderColor: 'rgba(0, 0, 0, 0.30)',
                      color: '#00000099',
                      ':hover': {
                        color: '#ffff',
                        backgroundColor: '#00000099',
                        borderColor: 'rgba(0, 0, 0, 0.30)',
                      },
                    }}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Tooltip>

                <Button
                  variant="outlined"
                  type={isEdit ? 'button' : 'submit'}
                  sx={{
                    p: 0,
                    height: '36px',
                    minWidth: '308px',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    color: '#FF5E1E',
                    borderColor: '#FF5E1E',
                    gap: '10px',
                    ':hover': {
                      backgroundColor: '#FF5E1E',
                      color: '#ffff',
                    },
                  }}
                  onClick={() => {
                    if (firstStepIsValid()) {
                      setPreviewMode(PreviewMode.confirm)
                      setShowPreview(true)
                    }
                  }}
                >
                  {t('form.button.next')} <ArrowForwardIcon />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={order} index={1}>
        <InputRHF
          control={control}
          label={t('portal.form.input.label.term')}
          placeholder={t('portal.form.input.placeholder.term')}
          name="termsAgreementTitle"
          sx={{
            width: '100%',
            height: '56px',
            mb: 2,
          }}
        />

        <CKEditorRHF
          label={t('campaign.label.input.description')}
          name="termsAgreementContent"
          control={control}
          sx={{
            width: '100%',
            mt: 2,
            mb: 2,
          }}
          displayColorPicker={false}
        />

        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            justifyContent: 'space-between',
            mt: 1,
          }}
        >
          <Box>
            {modalDiscardInfo && modalDiscardInfo}
            <Tooltip title={t('form.button.back_to_list')} placement="right">
              <Button
                onClick={watch('companyId') ? handleOpenModalDiscardInfo : handleAlternateClick}
                variant="outlined"
                sx={{
                  p: 0,
                  height: '36px',
                  minWidth: '36px',
                  borderColor: 'rgba(0, 0, 0, 0.6)',
                  color: '#00000099',
                  backgroundColor: !reset ? '#00000099' : '#ffff',
                  ':hover': {
                    color: '#ffff',
                    backgroundColor: '#00000099',
                    borderColor: 'rgba(0, 0, 0, 0.6)',
                  },
                }}
              >
                <ListIcon />
              </Button>
            </Tooltip>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '16px',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                p: 0,
                minWidth: '145.76px',
                height: '36px',
                gap: '10px',
                color: 'rgba(0, 0, 0, 0.6)',
                borderColor: 'rgba(0, 0, 0, 0.6)',
                ':hover': {
                  color: '#ffff',
                  backgroundColor: '#00000099',
                  borderColor: 'rgba(0, 0, 0, 0.30)',
                },
              }}
              onClick={() => setOrder(0)}
            >
              <ArrowBackIcon /> {t('form.button.previous')}
            </Button>

            <Tooltip title={t('form.button.clean')} placement="left">
              <Button
                onClick={() => {
                  setValue('termsAgreementTitle', '')
                  setValue('termsAgreementContent', '')
                }}
                variant="outlined"
                sx={{
                  p: 0,
                  minWidth: '36px',
                  height: '36px',
                  display:
                    watch('termsAgreementTitle') &&
                    watch('termsAgreementContent')
                      ? 'flex'
                      : 'none',
                  borderColor: 'rgba(0, 0, 0, 0.6)',
                  color: '#00000099',
                  ':hover': {
                    color: '#ffff',
                    backgroundColor: '#00000099',
                    borderColor: 'rgba(0, 0, 0, 0.30)',
                  },
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            </Tooltip>

            <Button
              type="submit"
              variant="outlined"
              sx={{
                p: 0,
                height: '36px',
                minWidth: '308px',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '16px',
                color: '#FF5E1E',
                borderColor: '#FF5E1E',
                ':hover': {
                  backgroundColor: '#FF5E1E',
                  color: '#ffff',
                },
              }}
            >
              {!getValues('id') && <AddIcon />}
              {getValues('id')
                ? t('form.button.save')
                : t('portal.button.create')}
            </Button>
          </Box>
        </Box>
      </TabPanel>
    </Box>
  )
}
