import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CKEditorRHF from 'src/components/CKEditorRHF/CKEditorRHF'
import { VideoTraining } from 'src/models/training-video-model'
import InputRHF from '../../../../components/RHF/InputRHF'
import { trainingVideoDefaultValues, trainingVideoSchemaValidation } from '../../validators/trainingVideoSchemaValidator'
import { UploadVideo } from '../ui/UploadVideo'
import { VideoSelectForm } from '../ui/VideoSelectForm'

interface VideosFormProps {
  data: VideoTraining
  onSubmit(data: any): void
}

export const VideosForm = ({ data, onSubmit }: VideosFormProps) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [uploadError, setUploadError] = useState(false)

  const form = useForm<any>({
    mode: 'all',
    defaultValues: trainingVideoDefaultValues,
    resolver: yupResolver(trainingVideoSchemaValidation),
  })
  const { control, handleSubmit, reset, setValue, watch } = form

  useEffect(() => {
    if (data) {
      reset(data)
      setValue('title', data.title)
      setValue('description', data.description)
      setValue('videoUrl', data.videoUrl)
      setValue('category', data.category)
    }
  }, [data])

  const formIsValid = () => Boolean(watch('title') && watch('description'))

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" sx={{ mb: 2 }}>
      <Box
        sx={{
          boxShadow: 'none',
          p: ' 0px 0px 16px 0px',
          borderRadius: '4px',
          mb: '24px',
          mt: '16px',
        }}
      >
        <InputRHF
          control={control}
          label={t('training.video.form.input.label.title')}
          placeholder={t('training.video.form.input.placeholder.title')}
          name="title"
          sx={{
            width: '100%',
            height: '56px',
            mb: 2,
          }}
        />

        <CKEditorRHF
          label={t('training.video.form.input.label.description')}
          name="description"
          control={control}
          sx={{
            width: '100%',
            mt: 3,
            mb: 2,
          }}
          displayColorPicker={false}
        />

        <VideoSelectForm
          control={control}
          name='category'
          label={t('training.video.form.input.label.category')}
        />

        <UploadVideo
          onFileChange={(mediaURL) => {
            setValue('videoUrl', mediaURL)
          }}
          defaultFile={watch('videoUrl')}
          placeholder={t('input.upload.file.media')}
          acceptedFormats={t('input.upload.media.acceptedformats')}
          accept=".mp4"
          watch={watch}
          error={uploadError}
          errorMessage={t('campaign.media.upload.error')}
          checkFileSizeCallback={(size) => {
            if (size > 25) {
              setUploadError(true)
              return false
            }
            setUploadError(false)
            return true
          }}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
          }}
        >
          <Button
            type="submit"
            variant="outlined"
            disabled={!formIsValid()}
            sx={{
              p: 0,
              height: '36px',
              minWidth: '308px',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px',
              color: '#FF5E1E',
              borderColor: '#FF5E1E',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
              alignSelf: 'flex-end'
            }}
          >
            <AddIcon />
            {id ? t('training.video.form.button.edit') : t('training.video.form.button.create')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
