import { useTranslation } from 'react-i18next'
import { Box, Container, Divider, Typography } from '@mui/material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

import logoItBuzzWhite from '../../assets/icons/itBuzz.png'
import logoTecnoItWhite from '../../assets/icons/tecnoIT.png'

export const Error404 = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Box
        component="div"
        sx={{
          mt: 10,
          color: '#FF5E1E',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          mb: 2,
        }}
      >
        <CancelRoundedIcon
          sx={{ back: '#FF5E1E', width: '33.33', height: '31.91', mt: 2 }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mt: 2,
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '34px',
              lineHeight: '36px',
              color: '#FF5E1E',
            }}
          >
            {t('error_404_title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            ({t('error.name')} 404)
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {t('error_404_subtitle')}
        </Typography>
        <Divider sx={{ mt: 4 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            pl: 3.2,
            gap: '10px',
            width: '736px',
            height: '48px',
            background: '#3D3D3D',
            borderRadius: '4px',
            textAlign: 'end',
            mt: 1,
          }}
        >
          <img
            alt="logo"
            src={logoItBuzzWhite}
            style={{ width: '82.56px', height: '24px' }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              pl: 3,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '10px',
                lineHeight: ' 16px',
                letterSpacing: '1.5px',
                color: '#FFFFFF',
                mt: '2px',
              }}
            >
              {t('login.image.title.per')}
            </Typography>
            <img
              alt="logo"
              src={logoTecnoItWhite}
              style={{ width: '93.5', height: '20px' }}
            />
          </Box>

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: '16px',
              letterSpacing: '1.5px',
              color: '#FFFFFF',
              pl: 29,
            }}
          >
            SUPORTE@ITBUZZ.TECNOIT.COM.BR
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}
