import { createContext, ReactNode, useContext, useState } from 'react'

const defaultState = {
  openDrawer: false,
  handleClose: () => {},
  handleOpen: () => {},
  handleSetObjectId: (id) => {},
  objectId: undefined,
  handleSetSearch: (id) => {},
  search: undefined,
}

interface ContextProviderProps {
  children: ReactNode
}

export const ActivityContext = createContext(defaultState)

function ActovityProvider({ children }: ContextProviderProps) {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [objectId, setObjectId] = useState(undefined)
  const [search, setSearch] = useState(undefined)

  const handleOpen = () => {
    setOpenDrawer(true)
  }

  const handleClose = () => {
    setOpenDrawer(false)
  }

  const handleSetObjectId = (id) => setObjectId(id)
  const handleSetSearch = (id) => setSearch(id)

  return (
    <ActivityContext.Provider
      value={{
        openDrawer,
        handleClose,
        handleOpen,
        handleSetObjectId,
        objectId,
        handleSetSearch,
        search,
      }}
    >
      {children}
    </ActivityContext.Provider>
  )
}

export default ActovityProvider
