import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ActivityContext } from 'src/context/ActivityContext'
import { useContext } from 'react'

export default function MenuItem(props) {
  const { t } = useTranslation()
  const history = useNavigate()
  const location = useLocation()
  const { item, open } = props
  const { handleClose: handleCloseActivity } = useContext(ActivityContext)
  return (
    <ListItem
      key={item.path}
      disablePadding
      sx={{
        display: 'block',
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          padding: '0 20px',
          margin: open ? '8px' : '4px',
          px: 2.5,
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 94, 30, 0.12)',
            color: '#FF5E1E',
            p: '0 20px',
          },
        }}
        selected={location.pathname == item.path}
        disabled={item.disabled}
        onClick={() => {
          handleCloseActivity()
          history(item.path)
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            width: '24px',
            height: '24px',
            mr: open ? 3 : '0px',
            justifyContent: 'center',
            color: location.pathname == item.path ? '#FF5E1E' : '#00000099',
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={t(item.label)}
          sx={{
            opacity: open ? 1 : 0,
            display: open ? 'block' : 'none',
            width: '139px',
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}
