import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColumns } from '@mui/x-data-grid'
import { Chip } from '@mui/material'

import { goToEditPointGroups } from 'src/routes/coordinator'
import {
  deletePointGroupById,
  editPointGroup,
  getPointGroups,
  recyclePointGroupById,
  unarchivePointGroupById,
} from 'src/service/pointGroupsService'
import { getPermissions } from 'src/service/authService'
import { Table } from 'src/components/Table/Table'
import { ROUTES } from 'src/routes/Router'
import { AlertContext } from 'src/context/AlertContext'
import { NumberSort } from 'src/components/Table/SortingFunctions'
import CreatePointGroups from '../../CreatePointGroups'

export default function PointGroupsTable() {
  const { t } = useTranslation()
  const [tableKey, setTableKey] = useState(0)
  const { createCRUDAlert } = useContext(AlertContext)
  const permissions = JSON.parse(getPermissions())

  const getData = async (options) => {
    const data = await getPointGroups(options)
    return { data: data?.listGroups, totalPages: data?.page, size: data?.size }
  }
  const handleDelete = async (id, name) => {
    const res = await deletePointGroupById(id)
    if (res) {
      createCRUDAlert(ROUTES.GROUPPOINT, name, 'deleted')
    }
  }

  const handleRecycle = async (id, name) => {
    const res = await recyclePointGroupById(id)
    if (res) {
      createCRUDAlert(ROUTES.GROUPPOINT, name, 'edited')
    }
  }

  const handleUnarchive = async (id, name) => {
    const res = await unarchivePointGroupById(id)
    if (res) {
      createCRUDAlert(ROUTES.GROUPPOINT, name, 'edited')
    }
  }

  const columns: GridColumns = [
    {
      field: 'groupName',
      headerName: t('table.header.name'),
      width: 220,
      editable: false,
    },
    {
      field: 'isActive',
      headerName: t('table.header.situation'),
      type: 'boolean',
      editable: false,
      renderCell: ({ row }) => {
        return row.isActive ? (
          <Chip
            sx={{
              background: 'rgba(255, 94, 30, 0.12)',
            }}
            size="small"
            label={t('table.status.active')}
          />
        ) : (
          <Chip size="small" label={t('table.status.inactive')} />
        )
      },
    },
    {
      field: 'apListNames',
      headerName: t('table.header.pointList'),
      width: 380,
      editable: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row: { apListNames } }) => apListNames?.length,
      sortComparator: NumberSort,
    },
  ]

  return (
    <Table
      getData={getData}
      key={tableKey}
      columns={columns}
      goTo={goToEditPointGroups}
      titleKey={'groupName'}
      handleDelete={handleDelete}
      defaultPageSize={15}
      model={'pointGroups'}
      Children={CreatePointGroups}
      size={'16px'}
      mr="25px"
      permissionUpdate={!permissions?.groups?.includes("groups:update")}
      permissionDelete={!permissions?.groups?.includes("groups:delete")}
      permissionView={!permissions?.groups?.includes("groups:view")}
      canShowRecycleButtons={true}
      permissionViewRecycle={permissions?.groups?.includes("groups:recycle:view")}
      permissionUpdateRecycle={permissions?.groups?.includes("groups:recycle:update")}
      handleRecycle={handleRecycle}
      canShowUnarchiveButtons={true}
      permissionViewUnarchive={permissions?.groups?.includes("groups:archive:view")}
      permissionUpdateUnarchive={permissions?.groups?.includes("groups:archive:update")}
      handleUnarchive={handleUnarchive}
      canShowProfileByGroupPoint={permissions?.reports?.includes("reports:group-profile")}
    />
  )
}
