import { t } from 'i18next'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ModalComponent from '.'

export enum PreviewMode {
  view = 'view',
  confirm = 'confirm',
}
interface Props {
  open: boolean
  handleClose(): void
  children?: any
  title: string
  mode: PreviewMode
  onConfirm: () => void
  isSearch?: boolean
}

export const PreviewModal = ({
  open,
  handleClose,
  children,
  title,
  onConfirm,
  mode,
  isSearch,
}: Props) => {
  return (
    <ModalComponent open={open} handleClose={handleClose} hideClose={true}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '29px',
            letterSpacing: '0.18px',
            color: '#FF5E1E',
            textAlign: 'left',
            my: 1,
            whiteSpace: 'pre-wrap',
            width: '100%',
          }}
        >
          {title}
        </Typography>

        {children}
        <Box />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          {mode === PreviewMode.confirm && (
            <>
              <Button
                variant="outlined"
                sx={{
                  width: '48%',
                  height: '36px',
                  minWidth: '36px',
                  borderColor: 'rgba(0, 0, 0, 0.6)',
                  color: '#00000099',
                  ':hover': {
                    color: '#ffff',
                    backgroundColor: '#00000099',
                    borderColor: 'rgba(0, 0, 0, 0.6)',
                  },
                }}
                onClick={() => {
                  handleClose()
                }}
              >
                {t('login.no_authorized.button')}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  height: '36px',
                  width: '48%',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '16px',
                  gap: 1,
                  color: '#FF5E1E',
                  borderColor: '#FF5E1E',
                  ':hover': {
                    backgroundColor: '#FF5E1E',
                    color: '#ffff',
                  },
                }}
                onClick={() => {
                  onConfirm()
                }}
              >
                {t('campaign.preview.confirm')}
              </Button>
              
            </>
          )}

          {mode !== PreviewMode.confirm && (
            <Button
              variant="outlined"
              sx={{
                width: '100%',
                height: '36px',
                minWidth: '36px',
                borderColor: '#00000099',
                color: '#00000099',
                ':hover': {
                  color: '#ffff',
                  backgroundColor: '#00000099',
                  border: 'none',
                },
              }}
              onClick={() => {
                handleClose()
              }}
            >
              {t('login.no_authorized.button')}
            </Button>
          )}
        </Box>
      </Box>
    </ModalComponent>
  )
}
