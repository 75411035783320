import { SxProps, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import DoughnutChart, { DoughnutChartColors } from '../Charts/DoughnutChart'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Card } from './Card'
import { Theme } from 'react-toastify'

export interface DevicesProps {
  params: {
    labels: any[]
    data: any[]
    total: any
  }
  sx?: any
}

export const Devices = (props: DevicesProps) => {
  const { t } = useTranslation()
  const { labels, data, total } = props.params

  return (
    <Card
      sx={{
        height: '200px',
        minHeight: '200px',
        p: '16px',
        px: '31px',
        pb: '20px',
        ...props.sx,
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        {t('campaign.result.card.devices')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, ml: 8 }}>
        <Box
          sx={{
            display: labels.length <= 0 ? 'flex' : 'none',
            width: '110px',
            height: '110px',
            border: '8px solid #DBDBDB',
            borderRadius: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '400',
            fontSize: '22px',
            lineHeight: '24px',
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.38)',
          }}
        >
          <p>0</p>
        </Box>

        <Box
          sx={{
            display: labels.length <= 0 ? 'flex' : 'none',
            alignItems: 'center',
            marginLeft: '25px',
            fontSize: '12px',
            fontWeight: '400',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.38)',
        
          }}
        >
          <FiberManualRecordIcon
            sx={{
              width: '9px',
              height: '9px',
              mr: '8px',
              color: '#DBDBDB',
            }}
          />

          <Typography sx={{ }}>{t('dashboard.card.device')}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          display: labels.length <= 0 ? 'none' : 'flex',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <DoughnutChart labels={labels} data={data} total={total} />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              minHeight: '60%',
              height: 'auto',
              maxHeight: '110px',
              overflowY: 'auto',
              gap: '10px',
            }}
          >
            {labels.map((item, index) => {
              return (
                <Box
                  key={item}
                  sx={{
                    display: 'flex',
                    width: labels.length <= 0 ? '109%' : '45%',
                    height: '30px',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Box
                    sx={{
                      minWidth: '10px',
                      minHeight: '10px',
                      width: '10px',
                      height: '10px',
                      borderRadius: '30px',
                      backgroundColor:
                        labels.length <= 0
                          ? '#DBDBDB'
                          : DoughnutChartColors[index] ||
                            DoughnutChartColors[
                              index % DoughnutChartColors?.length
                            ],
                      mr: 1,
                    }}
                  />

                  <Typography
                    sx={{
                      m: '0',
                      color: labels.length <= 0 ? '#DBDBDB' : '#cecece',
                      fontSize: '12px',
                    }}
                  >
                    {labels.length <= 0
                      ? t('dashboard.card.device')
                      : item?.slice(0, 11)}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
