import BadgeIcon from '@mui/icons-material/Badge'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded'
import CheckIcon from '@mui/icons-material/Check'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import FilterListIcon from '@mui/icons-material/FilterList'
import PreviewIcon from '@mui/icons-material/Preview'
import RecyclingIcon from '@mui/icons-material/Recycling'
import RestoreIcon from '@mui/icons-material/Restore'
import StarIcon from '@mui/icons-material/Star'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import { Button, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridCellParams } from '@mui/x-data-grid'
import { t } from 'i18next'
import { MouseEvent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ActivityContext } from 'src/context/ActivityContext'
import { AlertContext } from 'src/context/AlertContext'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'
import { ROUTES } from 'src/routes/Router'
import { goToCampaignResult } from 'src/routes/coordinator'
import { getPermissions } from 'src/service/authService'
import { disableDefaultCampaigns, editCampaign } from 'src/service/marketingCampaignService'
import { getProfileByGroupPoint } from 'src/service/pointGroupsService'
import { getProfileByAccessPoint } from 'src/service/pointService'
import { Dropdown } from '../DropDown/DropDown'
import { ModalActionTableInfo } from '../Modal/ModalActionTableInfo'
import ModalBlockEditCampaing from '../Modal/ModalBlockEditCampaing'
import ViewItemModal from '../Modal/ViewItemModal'
import { Search } from '../Search/Search'
import { StyledTooltip } from '../Tooltip/StyledTooltip'
import { GridActions } from './GridActions'

type ToggleTypes = 'getDeleted' | 'getArchived' | null

interface Props {
  columns?: any
  getData?: any
  handleDelete?: any
  titleKey?: any
  goTo?: any
  defaultPageSize?: any
  resultButton?: any
  model?: string
  aditionalButtons?: any
  Children?: any
  key?: number
  size?: string
  ml?: string
  mr?: string
  openModalClickRow?: any
  permissionUpdate?: boolean
  permissionDelete?: boolean
  permissionView?: boolean
  canShowRecycleButtons?: boolean // Control boolean to display or hide the ToggleButtonGroup
  permissionViewRecycle?: boolean // Can view items
  permissionUpdateRecycle?: boolean // Can edit deleted items
  handleRecycle?: any // Function to handle recycling of selected item
  canShowUnarchiveButtons?: boolean //  Control boolean to display or hide the ToggleButtonGroup
  permissionViewUnarchive?: boolean // Can view items
  permissionUpdateUnarchive?: boolean // Can edit archived items
  handleUnarchive?: any // Function to handle unarchiving of selected item
  canShowProfileByAccessPoint?: boolean // Can view profile public,
  canShowProfileByGroupPoint?: boolean // Can view profile public,
}

export const Table = ({
  columns: customColumns,
  getData,
  handleDelete,
  titleKey,
  goTo,
  defaultPageSize,
  resultButton,
  model,
  size,
  aditionalButtons,
  Children,
  ml,
  mr,
  permissionUpdate,
  permissionDelete,
  permissionView,
  canShowRecycleButtons,
  permissionViewRecycle,
  permissionUpdateRecycle,
  handleRecycle,
  canShowUnarchiveButtons,
  permissionViewUnarchive,
  permissionUpdateUnarchive,
  canShowProfileByAccessPoint,
  canShowProfileByGroupPoint,
  handleUnarchive,
}: Props) => {
  const history = useNavigate()
  const { handleOpen, handleSetObjectId, openDrawer } = useContext(ActivityContext)
  const { createAlert } = useContext(AlertContext)
  const [getSpecialButtonSelected, setGetSpecialButtonSelected] = useState<ToggleTypes>(null);
  const [rows, setRows] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize)
  const [modalDelete, setModalDelete] = useState<any>('')
  const [modalBlockEdit, setModalBlockEdit] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [checkbox, setCheckbox] = useState(false)
  const [inHoverRow, setInHoverRow] = useState(null)
  const [viewId, setViewId] = useState(null)
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc')
  const { selectedCompanyId, isGlobalAdminSelected } = useGlobalSelectedCompany()

  const permissions = JSON.parse(getPermissions())

  const paramsCompanyId = isGlobalAdminSelected() ? {} : { companyId: selectedCompanyId }
  const canDisplayGroupOfSpecialButtons = canShowUnarchiveButtons || canShowRecycleButtons
  const canShowEditButton =
    (getSpecialButtonSelected === 'getDeleted' && permissionUpdateRecycle) ||
    (getSpecialButtonSelected === 'getArchived' && permissionUpdateUnarchive) ||
    (!getSpecialButtonSelected)
  const canShowUnarchiveButton = canShowUnarchiveButtons && permissionUpdateUnarchive
  const canShowRecycleButton = canShowRecycleButtons && permissionUpdateRecycle

  const columns = [
    ...customColumns,
    {
      field: 'actions',
      type: 'actions',
      width: resultButton ? 290 : 265,
      headerName: '',
      cellClassName: 'acoes',
      getActions: ({ row, id }) => {
        const show = id === inHoverRow
        return [
          <GridActions
            sx={{
              color: '#00000099',
              opacity: show ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              gap: '0',
              gridGap: '0',
              textAlign: 'left',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('form.button.to_view')}>
                <PreviewIcon />
              </Tooltip>
            }
            label="View"
            className="textPrimary"
            onClick={() => handleOpenModalViewId(row.id)}
            disabled={permissionView}
            color="inherit"
          />,
          <GridActions
            onMouseLeave={() => {
              setInHoverRow(null)
            }}
            sx={{
              display: canShowEditButton ? '' : 'none',
              color: '#00000099',
              opacity: show ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              gap: '0',
              gridGap: '0',
              textAlign: 'left',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('table.button.edit')}>
                <EditIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => goTo(history, row.id)}
            disabled={permissionUpdate}
            color="inherit"
          />,
          <GridActions
            sx={{
              color: '#00000099',
              opacity: show ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('table.button.delete')}>
                <DeleteIcon />
              </Tooltip>
            }
            onClick={() => handleOpenModalDeleteRow(row.id, row[titleKey])}
            disabled={permissionDelete}
            label="Delete"
            color="inherit"
          />,
          <GridActions
            sx={{
              display: canShowRecycleButton ? '' : 'none',
              color: '#00000099',
              opacity: show ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('table.button.recycle')}>
                <RecyclingIcon />
              </Tooltip>
            }
            onClick={() => handleOpenModalRecycleRow(row.id, row[titleKey])}
            disabled={!permissionUpdateRecycle}
            label="Recycle"
            color="inherit"
          />,
          <GridActions
            sx={{
              display: canShowUnarchiveButton ? '' : 'none',
              color: '#00000099',
              opacity: show ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('table.button.unarchive')}>
                <UnarchiveIcon />
              </Tooltip>
            }
            onClick={() => handleOpenModalUnarchiveRow(row.id, row[titleKey])}
            disabled={!permissionUpdateUnarchive}
            label="Unarchive"
            color="inherit"
          />,
          <GridActions
            sx={{
              color: '#00000099',
              opacity: show ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('table.button.activity')}>
                <RestoreIcon />
              </Tooltip>
            }
            label={t('table.button.activity ')}
            color="inherit"
            onClick={() => {
              handleSetObjectId(row.id)
              handleOpen()
            }}
            disabled={!permissions?.auditory?.includes('auditory:list')}
          />,
          <GridActions
            sx={{
              display: resultButton && show ? 'flex' : 'none',
              transition: 'opacity 0.3s ease-in-out',
              color: '#00000099',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('table.action.result.tip')}>
                <BarChartRoundedIcon />
              </Tooltip>
            }
            onClick={() => goToCampaignResult(history, row.id)}
            label="Delete"
            color="inherit"
          />,
          <GridActions
            sx={{
              opacity:
                resultButton &&
                  show &&
                  !row?.defaultCampaign &&
                  row?.contentType !== 'quiz'
                  ? 1
                  : 0,
              display:
                resultButton &&
                  show &&
                  !row?.defaultCampaign &&
                  row?.contentType !== 'quiz'
                  ? 'block'
                  : 'none',
              transition: 'opacity 0.3s ease-in-out',
              color: '#00000099',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('button.default.campaigin.table.action.tip')}>
                <StarIcon />
              </Tooltip>
            }
            onClick={() => handleMakeDefaultCampaign(row, true)}
            color="inherit"
          />,
          <GridActions
            sx={{
              opacity: resultButton && show && row?.defaultCampaign ? 1 : 0,
              display:
                resultButton && show && row?.defaultCampaign ? 'block' : 'none',
              transition: 'opacity 0.3s ease-in-out',
              color: '#00000099',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#FF5E1E',
                borderRadius: '4px',
                '#defaultStartIcon': {
                  color: 'white',
                },
              },
            }}
            icon={
              <StyledTooltip content={t('campaign.default.remove.tip')}>
                <StarIcon
                  id="defaultStartIcon"
                  sx={{
                    color: '#FF5E1E',
                  }}
                />
              </StyledTooltip>
            }
            onClick={() => handleMakeDefaultCampaign(row, false)}
            color="inherit"
          />,
          <GridActions
            onMouseLeave={() => {
              setInHoverRow(null)
            }}
            sx={{
              display: canShowProfileByAccessPoint ? '' : 'none',
              color: '#00000099',
              opacity: show ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              gap: '0',
              gridGap: '0',
              textAlign: 'left',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('table.button.profile.public')}>
                <BadgeIcon />
              </Tooltip>
            }
            label={t('table.button.profile.public')}
            className="textPrimary"
            onClick={() => handleProfileByAccessPoint(row.id)}
            disabled={!canShowProfileByAccessPoint}
            color="inherit"
          />,
          <GridActions
            onMouseLeave={() => {
              setInHoverRow(null)
            }}
            sx={{
              display: canShowProfileByGroupPoint ? '' : 'none',
              color: '#00000099',
              opacity: show ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              gap: '0',
              gridGap: '0',
              textAlign: 'left',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderRadius: '4px',
              },
            }}
            icon={
              <Tooltip title={t('table.button.profile.public')}>
                <BadgeIcon />
              </Tooltip>
            }
            label={t('table.button.profile.public')}
            className="textPrimary"
            onClick={() => handleProfileByGroupPoint(row.id)}
            disabled={!canShowProfileByGroupPoint}
            color="inherit"
          />,
        ]
      },
    },
  ]

  const handleMakeDefaultCampaign = async (row, value) => {
    try {
      if (value) {
        await disableDefaultCampaigns(row?.companyId)
      }
      await editCampaign(row?.id, {
        ...row,
        defaultCampaign: value,
      })
      fetchData({ params: { page: 0, size: rowsPerPage } })
      if (value) {
        const bannerMessage = `${t('table.campaign.default.banner.prefix')} "${row?.name
          }" ${t('table.campaign.default.banner.suffix')}`
        createAlert(ROUTES.CAMPAIGNS, bannerMessage)
      }
    } catch (error) { }
  }

  const shoudShowDefaultCampaign = (id, value) => {
    const campaign = rows.find((row) => row?.id === id)
    if (campaign?.contentType === 'quiz') return false
    if (value) {
      return !campaign?.defaultCampaign
    }
    return campaign?.defaultCampaign
  }

  const [termSearch, setTermSearch] = useState<any>('')
  const handleShowRowButtons = (event) => {
    const id = event.currentTarget.dataset.id
    setInHoverRow(id)
  }

  const handleOpenModalAllDeleteRows = () => {
    var names = rows
      .filter((row) => selectionModel.some((id) => id === row.id))
      .map((row) => row[titleKey])
      .join(', ')

    setModalDelete(
      <ModalActionTableInfo
        open={true}
        name={names}
        title={
          selectionModel.length === 1
            ? 'table.modal.delete.title'
            : 'table.modal.delete.all.title'
        }
        onHandleClose={() => setModalDelete('')}
        onHandleDeleteClick={async () => {
          const deletions = rows
            .filter((row) => selectionModel.some((id) => id === row.id))
            ?.map((row) => {
              return handleDelete(row.id, names)
            })
          await Promise.all(deletions)
          await fetchData({ params: { page: 0, size: rowsPerPage } })
          setSelectionModel([])
          setModalDelete('')
        }}
      />
    )
  }

  const handleOpenModalViewId = (id) => {
    setViewId(id)
  }

  const handleOpenModalDeleteRow = (id, name) => {
    setModalDelete(
      <ModalActionTableInfo
        open={true}
        name={name}
        title={'table.modal.delete.title'}
        onHandleClose={() => setModalDelete('')}
        onHandleDeleteClick={async () => {
          await handleDelete(id, name)
          await fetchData({ params: { page, size: rowsPerPage } })
          setModalDelete('')
          setViewId(null)
        }}
      />
    )
  }

  const handleOpenModalBlockEdit = () => {
    setModalBlockEdit(
      <ModalBlockEditCampaing
        open={true}
        title={t('modal.title.block.edit.campain')}
        onHandleClose={() => setModalBlockEdit('')}
        onClick={() => {
          setModalBlockEdit('')
        }}
      />
    )
  }

  const handleOpenModalRecycleRow = (id, name) => {
    setModalDelete(
      <ModalActionTableInfo
        open={true}
        name={name}
        title={'table.modal.recycle.title'}
        onHandleClose={() => setModalDelete('')}
        onHandleDeleteClick={async () => {
          await handleRecycle(id, name)
          await fetchData({ params: { page, size: rowsPerPage } })
          setModalDelete('')
          setViewId(null)
        }}
      />
    )
  }

  const handleOpenModalUnarchiveRow = (id, name) => {
    setModalDelete(
      <ModalActionTableInfo
        open={true}
        name={name}
        title={'table.modal.unarchive.title'}
        onHandleClose={() => setModalDelete('')}
        onHandleDeleteClick={async () => {
          await handleUnarchive(id, name)
          await fetchData({ params: { page, size: rowsPerPage } })
          setModalDelete('')
          setViewId(null)
        }}
      />
    )
  }

  const fetchData = async (options?: any) => {
    setLoading(true)
    const { data, size } = await getData({
      params: { ...options?.params, orderBy },
    })
    setRows(data || [])
    setTotalPages(Math.ceil(size / rowsPerPage))
    setLoading(false)
  }

  const handleSearch = (search: string) => {
    setTermSearch(search)
    fetchData({ params: { search, size: rowsPerPage } })
  }

  const handleSelectSpecial = async (event: MouseEvent<HTMLElement>, specialCondition: ToggleTypes) => {
    setGetSpecialButtonSelected(specialCondition)
    if (specialCondition) {
      fetchData({ params: { page, size: rowsPerPage, ...paramsCompanyId, [`${specialCondition}`]: "true" } })
    } else {
      fetchData({ params: { page, size: rowsPerPage, ...paramsCompanyId } })
    }
  }

  const handleProfileByAccessPoint = async (id: string) => {
    setLoading(true)
    await getProfileByAccessPoint(id)
    setLoading(false)
  }

  const handleProfileByGroupPoint = async (id: string) => {
    setLoading(true)
    await getProfileByGroupPoint(id)
    setLoading(false)
  }

  useEffect(() => {
    const special = getSpecialButtonSelected !== null ? { [`${getSpecialButtonSelected}`]: true } : {}
    fetchData({ params: { page, size: rowsPerPage, ...special, ...paramsCompanyId } })
  }, [page, rowsPerPage, orderBy, getSpecialButtonSelected, selectedCompanyId])

  const onRowsSelectionHandler = (ids) => {
    if (!loading) {
      setCheckbox(Boolean(ids.length))
      setSelectionModel(ids)
    }
  }

  const findObjectById = (array, id) => {
    return array.find((obj) => obj.id === id)
  }

  function isPast(dateString) {
    const currentDate = new Date()
    const inputDate = new Date(dateString)

    return inputDate < currentDate
  }

  const shouldShowResultButton = () => {
    if (selectionModel.length > 1) return
    const selectedCampaign = findObjectById(rows, selectionModel[0])
    return resultButton && isPast(selectedCampaign?.initialDate)
  }

  const getTypeCampaign = () => {
    if (selectionModel.length > 1) return
    const selectedCampaign = findObjectById(rows, selectionModel[0])
    return selectedCampaign?.contentType
  }

  const shouldntShowDefaultButton = () => {
    if (selectionModel.length > 1) return
    return resultButton
  }

  var names = []
  rows.forEach((row) => {
    if (row.id === viewId) {
      names.push('test')
    }
  })

  const Header = () => (
    <Box
      component={'div'}
      sx={{
        height: '54px',
        display: selectionModel.length > 0 && checkbox ? 'flex' : 'none',
        position: 'absolute',
        zIndex: 10,
        backgroundColor: 'white',
        ml: '55px',
        width: '94%',
        gap: 2,
        alignItems: 'center',
      }}
    >
      {selectionModel.length <= 1 && (
        <Button
          sx={{
            my: 1,
            height: '36px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            gap: '8px',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
          onClick={() => handleOpenModalViewId(selectionModel[0])}
          disabled={permissionView}
        >
          <PreviewIcon sx={{ width: '22px', height: '22px' }} />
          {t('table.button.cta.view')}
        </Button>
      )}
      {selectionModel.length <= 1 && (
        <Button
          onClick={() => {
            if (getTypeCampaign() === 'quiz' && shouldShowResultButton()) {
              handleOpenModalBlockEdit()
              return
            }
            goTo(history, selectionModel[0])
          }}
          sx={{
            display: canShowEditButton ? '' : 'none',
            my: 1,
            height: '36px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            gap: '8px',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
          disabled={permissionUpdate}
        >
          <EditIcon sx={{ width: '22px', height: '22px' }} />
          {t('table.button.cta.edit')}
        </Button>
      )}
      {selectionModel.length <= 1 && (
        <Button
          onClick={() => {
            const id = selectionModel[0]
            const row = rows.find((row) => row?.id === id)
            handleOpenModalRecycleRow(id, row[titleKey])
          }}
          sx={{
            display: canShowRecycleButton ? '' : 'none',
            my: 1,
            height: '36px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            gap: '8px',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
          disabled={!permissionUpdateRecycle}
        >
          <RecyclingIcon sx={{ width: '22px', height: '22px' }} />
          {t('table.button.cta.recycle')}
        </Button>
      )}
      {selectionModel.length <= 1 && (
        <Button
          onClick={() => {
            const id = selectionModel[0]
            const row = rows.find((row) => row?.id === id)
            handleOpenModalUnarchiveRow(id, row[titleKey])
          }}
          sx={{
            display: canShowUnarchiveButton ? '' : 'none',
            my: 1,
            height: '36px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            gap: '8px',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
          disabled={!permissionUpdateUnarchive}
        >
          <UnarchiveIcon sx={{ width: '22px', height: '22px' }} />
          {t('table.button.cta.unarchive')}
        </Button>
      )}
      {selectionModel.length <= 1 && (
        <Button
          onClick={() => {
            handleSetObjectId(selectionModel[0])
            handleOpen()
          }}
          sx={{
            my: 1,
            height: '36px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            gap: '8px',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
        >
          <RestoreIcon sx={{ width: '22px', height: '22px' }} />
          {t('table.button.cta.activity')}
        </Button>
      )}
      {shouldShowResultButton() && (
        <Button
          onClick={() => {
            goToCampaignResult(history, selectionModel[0])
          }}
          sx={{
            my: 1,
            height: '36px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            gap: '8px',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
        >
          <BarChartRoundedIcon sx={{ width: '22px', height: '22px' }} />
          {`${t('table.button.cta.result')}`}
        </Button>
      )}
      {shoudShowDefaultCampaign(selectionModel[0], true) &&
        shouldntShowDefaultButton() && (
          <Button
            onClick={() => {
              const id = selectionModel[0]
              const row = rows.find((row) => row?.id === id)
              handleMakeDefaultCampaign(row, true)
            }}
            sx={{
              my: 1,
              height: '36px',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '16px',
              gap: '8px',
              color: 'rgba(0, 0, 0, 0.6)',
              ':hover': {
                color: 'rgba(255, 94, 30, 1)',
              },
            }}
          >
            <StarIcon sx={{ width: '22px', height: '22px' }} />
            {`${t('button.default.campaigin.table.action.tip')}`}
          </Button>
        )}
      {shoudShowDefaultCampaign(selectionModel[0], false) && (
        <Button
          onClick={() => {
            const id = selectionModel[0]
            const row = rows.find((row) => row?.id === id)
            handleMakeDefaultCampaign(row, false)
          }}
          sx={{
            display: 'flex',
            my: 1,
            height: '36px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            gap: '8px',
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              color: 'rgba(255, 94, 30, 1)',
            },
          }}
        >
          <StarIcon sx={{ width: '22px', height: '22px', color: '#FF5E1E' }} />
          {`${t('campaign.default.remove.tip')}`}
        </Button>
      )}

      <Button
        onClick={handleOpenModalAllDeleteRows}
        sx={{
          my: 1,
          height: '36px',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '16px',
          gap: '8px',
          color: 'rgba(0, 0, 0, 0.6)',
          ':hover': {
            color: 'rgba(255, 94, 30, 1)',
          },
        }}
        disabled={permissionDelete}
      >
        <DeleteForeverIcon sx={{ width: '22px', height: '22px' }} />
        {selectionModel.length <= 1 && t('table.button.cta.delete')}
        {selectionModel.length > 1 &&
          `${t('table.button.cta.delete')} ${t(
            `table.button.cta.model.${model}`
          )}`}
      </Button>
    </Box>
  )

  return (
    <Box
      component={'div'}
      sx={{ width: openDrawer ? 'calc(100% - 280px)' : '100%' }}
    >
      {modalDelete && modalDelete}
      {modalBlockEdit && modalBlockEdit}
      {viewId && (
        <ViewItemModal
          goTo={goTo}
          id={viewId}
          name={names}
          open={true}
          handleClose={() => setViewId(null)}
          size={size}
          mr={mr}
          ml={ml}
          handleOpenModal={handleOpenModalDeleteRow}
          showEditButton={permissionUpdate}
        >
          <Children isViewMode={true} viewId={viewId} />
        </ViewItemModal>
      )}
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start ',
          mt: 1,
        }}
      >
        <Box
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '50%' }}>
            <Search onSearch={(search) => handleSearch(search)} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {
              canDisplayGroupOfSpecialButtons &&
              <ToggleButtonGroup
                value={getSpecialButtonSelected}
                onChange={handleSelectSpecial}
                aria-label="select special condition"
                exclusive
                sx={{
                  '& .Mui-selected': {
                    backgroundColor: 'rgba(255, 94, 30, 0.04) !important',
                    color: '#FF5E1E !important',
                  },
                  marginRight: '20px'
                }}
              >
                {
                  permissionViewUnarchive &&
                  <ToggleButton
                    value="getArchived"
                    aria-label="get archived"
                    sx={{
                      ':hover': {
                        backgroundColor: 'rgba(255, 94, 30, 0.04)',
                        border: '1px solid #FF5E1E',
                        borderLeft: '1px solid #FF5E1E !important',
                        color: '#FF5E1E',
                        svg: {
                          color: '#FF5E1E',
                        },
                      },
                    }}
                  >
                    <UnarchiveIcon />
                  </ToggleButton>
                }
                {
                  permissionViewRecycle &&
                  <ToggleButton
                    value="getDeleted"
                    aria-label="get deleted"
                    sx={{
                      ':hover': {
                        backgroundColor: 'rgba(255, 94, 30, 0.04)',
                        border: '1px solid #FF5E1E',
                        color: '#FF5E1E',
                        svg: {
                          color: '#FF5E1E',
                        },
                      },
                    }}
                  >
                    <RecyclingIcon />
                  </ToggleButton>
                }
              </ToggleButtonGroup>
            }
            {aditionalButtons && aditionalButtons}
            <Dropdown
              icon={<FilterListIcon />}
              title={t('table.order.orderBy')}
              itens={[
                {
                  title: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml: orderBy !== 'desc' && '38px',
                        fontWeight: orderBy !== 'asc' && 700,
                      }}
                    >
                      {orderBy === 'desc' && (
                        <CheckIcon
                          sx={{ color: '#FF5E1E', fontSize: '14px' }}
                        />
                      )}
                      {t('table.order.orderBy.desc')}
                    </Box>
                  ),
                  onClick: () => setOrderBy('desc'),
                },
                {
                  title: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml: orderBy !== 'asc' && '38px',
                        fontWeight: orderBy !== 'desc' && 700,
                      }}
                    >
                      {orderBy === 'asc' && (
                        <CheckIcon
                          sx={{ color: '#FF5E1E', fontSize: '14px' }}
                        />
                      )}
                      {t('table.order.orderBy.asc')}
                    </Box>
                  ),
                  onClick: () => setOrderBy('asc'),
                },
              ]}
            />
          </Box>
        </Box>
      </Box>
      <Box
        component={'div'}
        sx={{
          height: rowsPerPage === 5 ? 371 : 570,
          mb: 2,
          mt: '16px',
          width: '100%',
        }}
      >
        <DataGrid
          components={{
            Toolbar: Header,
          }}
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '.MuiTablePagination-root': {
              color: ' rgba(0, 0, 0, 0.6)',

              '.MuiSelect-select': {
                color: '#000',
              },
            },
            '& .emphasis': {
              fontWeight: 600,
              color: '#FF5E1E',
            },
          }}
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            if (params.field === 'id' || params.value == null) {
              return ''
            }
            let term = new RegExp(termSearch, 'gi')
            return termSearch !== null &&
              termSearch !== '' &&
              params.value.toString().match(term) !== null
              ? 'emphasis'
              : ''
          }}
          keepNonExistentRowsSelected
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          selectionModel={selectionModel}
          checkboxSelection
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          rowsPerPageOptions={[5, 15, 30]}
          onPageSizeChange={(value) => {
            setRowsPerPage(value)
            setPage(0)
          }}
          pagination
          disableColumnMenu
          page={page}
          rowCount={totalPages * rowsPerPage || 1}
          paginationMode="server"
          componentsProps={{
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
            row: {
              onMouseOver: handleShowRowButtons,
            },
          }}
          onPageChange={(page) => setPage(page)}
          loading={loading}
          localeText={{
            noResultsOverlayLabel: t('table.no.results'),
            MuiTablePagination: {
              labelRowsPerPage: `${t('table_label_rows_per_page')}`,
              labelDisplayedRows: () => {
                return (
                  <Box
                    component={'span'}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      gap: '5px',
                    }}
                  >
                    {t('table_page')}
                    <Box
                      component={'span'}
                      sx={{ color: '#000', fontSize: '14px' }}
                    >
                      {page + 1}
                    </Box>
                    {t('table_in')}
                    <Box
                      component={'span'}
                      sx={{ color: '#000', fontSize: '14px' }}
                    >
                      {totalPages > 0 ? totalPages : 1}
                    </Box>
                  </Box>
                )
              },
            },
            footerRowSelected: (n) => `${n} ${t('table.selected.rows')}`,
          }}
        />
      </Box>
    </Box>
  )
}
