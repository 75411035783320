import * as yup from 'yup'
import { ValidationMode } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Support } from '../../../models/support-model'

export const supportDefaultValues: Support = {
  message: '',
  ticketType: '',
  fileUrl: '',
}

const mode: keyof ValidationMode = 'onBlur'

export const supportSchemaValidation = yup.object({
  message: yup
    .string()
    .max(1000, 'A mensagem deve conter até 1000 caracteres')
    .required('required'),
  ticketType: yup.object().nullable().required('required'),
  fileUrl: yup.string(),
})

export const supportUseFormArgs = {
  mode,
  defaultValues: supportDefaultValues,
  resolver: yupResolver(supportSchemaValidation),
}

export interface SupportTypes
  extends yup.InferType<typeof supportSchemaValidation> {}
