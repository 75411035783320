import { Box, Container } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { getHeatMapByCompanyId } from 'src/service/HeatMapService'
import { getCompanyId, getRole } from 'src/service/authService'
import HeatMapHeader from './HeatMapHeader'
import { ActiveFilter } from './components/ActiveFilter/ActiveFilter'
import { Legend } from './components/Legend/Legend'
import HeatMapService from './components/Map/HeatMap'
import Map from './components/Map/Map'

export const ColorMap = () => {
  const form = useForm<any>({ defaultValues: { point: {} } })

  const [data, setData] = useState(null)
  const [mapData, setMapData] = useState(null)
  const [center, setCenter] = useState(null)
  const { watch, getValues, setValue } = form

  const [activeFilter, setActiveFilter] = useState<'active' | 'inative'>(null)
  const [isHeatMap, setIsHeatMap] = useState(true)
  const mapRef = useRef(null)
  const resetCenter = () => {
    setCenter(new google.maps.LatLng(666, 0))
  }
  const getData = async () => {
    const data = await getHeatMapByCompanyId(getValues('companyId')?.id)
    setData(data)
  }

  useEffect(() => {
    if (center?.lat() === 90 && getValues('point')?.id) {
      setCenter(
        new google.maps.LatLng(
          getValues('point')?.geoLatitude,
          getValues('point')?.geoLongitude
        )
      )
      return
    }

    if (
      center?.lat() === 90 &&
      getValues('groups')?.length &&
      mapData?.length
    ) {
      setCenter(
        new google.maps.LatLng(
          mapData?.[0]?.latlng?.lat(),
          mapData?.[0]?.latlng?.lng()
        )
      )
    }

    if (
      center?.lat() === 90 &&
      !getValues('groups')?.length &&
      !getValues('point')?.id &&
      mapData?.length
    ) {
      setCenter(
        new google.maps.LatLng(
          mapData[0]?.latlng?.lat(),
          mapData[0]?.latlng?.lng()
        )
      )
    }
  }, [center])
  useEffect(() => {
    if (getValues('companyId')?.id) {
      getData()
    }
  }, [watch('companyId')])
  useEffect(() => {
    if (getValues('point')?.id) {
      const res = HeatMapService.getByPointId(getValues('point')?.id, data)
      setMapData(res)
      resetCenter()
      return
    }
    if (getValues('groups')?.length) {
      const groupIds = getValues('groups')?.map(({ id }) => id)
      const res = HeatMapService.getByGroupsId(groupIds, data)
      setMapData(res)
      resetCenter()
      return
    }
    if (
      data?.accessPointsData?.length &&
      !getValues('point')?.id &&
      !getValues('groups')?.length
    ) {
      setMapData(HeatMapService.getAll(data))
      resetCenter()
      return
    }
    setMapData([])
  }, [watch('point'), watch('groups'), data, activeFilter])
  useEffect(() => {
    setMapData([])
  }, [watch('companyId')])
  useEffect(() => {
    if (getRole() !== 'TECNOIT' && !getValues('companyId')?.id) {
      setValue('companyId', { id: getCompanyId() })
    }
  }, [])
  useEffect(() => {
    if (activeFilter === 'active') {
      const filtedPoints = HeatMapService.getAll(data)?.filter(
        ({ isActive }) => isActive
      )
      setMapData(filtedPoints)
    }
    if (activeFilter === 'inative') {
      {
        const filtedPoints = HeatMapService.getAll(data)?.filter(
          ({ isActive }) => !isActive
        )
        setMapData(filtedPoints)
      }
    }
  }, [activeFilter])

  return (
    <Container
      sx={{
        mt: '96px',
        pb: '50px',
      }}
    >
      <AlertComponent />
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          px: '28px',
          pb: '700px',
          flexGrow: '0',
          position: 'relative',
        }}
      >
        <HeatMapHeader
          mapRef={mapRef}
          onCenter={(v) => {
            resetCenter()
          }}
          form={form}
          onHeatMapChange={(v) => {
            setIsHeatMap(v)
          }}
        />
        {/*  Map container */}
        <Box
          sx={{
            mt: 2,
            position: 'relative',
            width: '99.5%',
            height: '90%',
          }}
        >
          <Box
            sx={{
              width: 'auto',
              minHeight: '40px',
              position: 'absolute',
              top: 6,
              right: 53,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '10px',
              zIndex: 10,
              gap: '20px',
            }}
          >
            <ActiveFilter
              mode={'inactive'}
              onClick={() => {
                if (activeFilter === 'inative') {
                  setActiveFilter(null)
                  return
                }
                resetCenter()
                setActiveFilter('inative')
              }}
              isChecked={activeFilter === 'inative'}
            />
            <ActiveFilter
              mode={'active'}
              onClick={() => {
                if (activeFilter === 'active') {
                  setActiveFilter(null)
                  return
                }
                resetCenter()
                setActiveFilter('active')
              }}
              isChecked={activeFilter === 'active'}
            />
          </Box>

          <Box sx={{ width: '100%', position: 'absolute' }}>
            <div ref={mapRef}>
              <Map
                points={mapData || []}
                isColorMode={isHeatMap}
                center={
                  mapData?.length && center?.lat()
                    ? center
                    : new google.maps.LatLng(
                        -16.683922067895228,
                        -49.2659034729004
                      )
                }
                key={`${center?.toString()}-${isHeatMap}-${activeFilter}`}
              />
            </div>
          </Box>
          {isHeatMap && (
            <Box
              sx={{
                width: 'auto',
                minHeight: '40px',
                // backgroundColor: 'rgba(0,0,0,.6)',
                position: 'absolute',
                bottom: '-666px',
                right: 3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '10px',
                zIndex: 10,
                gap: '20px',
              }}
            >
              <Legend />
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  )
}
