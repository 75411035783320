import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import { Portal } from 'src/models/portal-model'

export const portalDefaultValues: Portal = {
  companyId: null,
  portalName: '',
  buttonColor: '#000000',
  textColor: '#ffffff',
  logoImage: '',
  backgroundImage: '',
  termsAgreementTitle: '',
  termsAgreementContent: '',
  isActive: false,
  isArchived: false,
  containerColor: '#ffffff',
  containerTextColor: '#000000',
}

const mode: keyof ValidationMode = 'all'

export const portalSchemaValidation = yup.object({
  portalName: yup.string().required('required'),
  isActive: yup.boolean(),
  isArchived: yup.boolean().default(false),
  companyId: yup
    .object()
    .typeError('invalid')
    .test((value, context) => {
      if (value.id || value.companyId) {
        return true
      }
      return context.createError({
        message: `required`,
        path: 'companyId',
      })
    }),
  backgroundImage: yup.string().required('required'),
  logoImage: yup.string().required('required'),
  buttonColor: yup.string(),
  textColor: yup.string(),
  containerColor: yup.string(),
  containerTextColor: yup.string(),
  termsAgreementTitle: yup.string().required('required'),
  termsAgreementContent: yup.string().required('required'),
})

export const portalUseFormArgs = {
  mode,
  defaultValues: portalDefaultValues,
  resolver: yupResolver(portalSchemaValidation),
}

export interface PortalTypes
  extends yup.InferType<typeof portalSchemaValidation> {}
