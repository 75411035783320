import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Card } from './Card'
import { ToggableCard } from './ToggableCard'
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import { ReturneesUserIcon } from 'src/components/Icons/ReturneesUserIcon'
import { NewUserIcon } from 'src/components/Icons/NewUserIcon'
import { useTranslation } from 'react-i18next'

interface Props {
  newUsersValue: number | string
  returningUsersValue: number | string
}
export const Users = ({ newUsersValue, returningUsersValue }: Props) => {
  const { t } = useTranslation()

  return (
    <ToggableCard viewkey="users">
      <Card
        sx={{
          minWidth: '460px',
          minHeight: '200px',
          width: '222px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          py: '16px',
          px: '32px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: '50%' }}>
            <Typography
              sx={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.6);',
                whiteSpace: 'pre-wrap',
              }}
            >
              {t('painel.newusers')}
            </Typography>
            <Typography
              sx={{
                fontSize: '34px',
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              {newUsersValue}
            </Typography>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                height: '48px',
                alignItems: 'center',
              }}
            >
              <NewUserIcon />
            </Box>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Typography
              sx={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.6);',
                whiteSpace: 'pre-wrap',
              }}
            >
              {t('painel.returningusers')}
            </Typography>
            <Typography
              sx={{
                fontSize: '34px',
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              {returningUsersValue}
            </Typography>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                height: '48px',
                alignItems: 'center',
              }}
            >
              <ReturneesUserIcon />
            </Box>
          </Box>
        </Box>
      </Card>
    </ToggableCard>
  )
}
