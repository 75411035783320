import { useState } from 'react'
import { t } from 'i18next'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Button, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import ListIcon from '@mui/icons-material/List'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddIcon from '@mui/icons-material/Add'

import ModalDiscardInfo from '../Modal/ModalDiscardInfo'

interface Props {
  formIsValid: boolean
  isEdit: boolean
  goTo: (history: NavigateFunction) => void
  reset: (arg?: any) => void
  size: string
  buttonName?: string
  mb?: string
  isValid?: boolean
}

export const FooterBottons = (props: Props) => {
  const history = useNavigate()
  const [modalDiscardInfo, setModalDiscardInfo] = useState<any>('')

  const handleOpenModalDiscardInfo = () => {
    setModalDiscardInfo(
      <ModalDiscardInfo
        open={true}
        title={
          props.isEdit
            ? t('modal.edit.discardinfo.title')
            : t('modal.create.discardinfo.title')
        }
        onHandleClose={() => setModalDiscardInfo('')}
        onHandlDiscardClick={async () => {
          setModalDiscardInfo('')
          props.goTo(history)
        }}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mt: '13px',
        justifyContent: 'space-between',
        width: props.size,
        mb: props.mb,
      }}
    >
      {modalDiscardInfo && modalDiscardInfo}
      <Tooltip title={t('form.button.back_to_list')} placement="right">
        <Button
          onClick={handleOpenModalDiscardInfo}
          variant="outlined"
          sx={{
            p: 0,
            height: '36px',
            minWidth: '36px',
            borderColor: 'rgba(0, 0, 0, 0.6)',
            color: '#00000099',
            ':hover': {
              color: '#ffff',
              backgroundColor: '#00000099',
              border: 'none',
            },
          }}
        >
          <ListIcon />
        </Button>
      </Tooltip>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title={t('form.button.clean')} placement="left">
          <Button
            onClick={() => props.reset({ name: '' })}
            variant="outlined"
            sx={{
              display: props.formIsValid ? 'flex' : 'none',
              mr: 1,
              p: 0,
              height: '36px',
              minWidth: '36px',
              borderColor: 'rgba(0, 0, 0, 0.6)',
              color: '#00000099',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                border: 'none',
              },
            }}
          >
            <DeleteOutlineIcon />
          </Button>
        </Tooltip>
        {!props.isEdit && (
          <Button
            disabled={props.isValid}
            type="submit"
            variant="outlined"
            sx={{
              minWidth: '360.49px',
              height: '36px',
              p: '6px 6px 6px 8px',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
            }}
          >
            <AddIcon
              sx={{
                width: '24px',
                height: '22px',
                mb: 0.5,
                gap: 1,
              }}
            />
            {t(props?.buttonName)}
          </Button>
        )}
        {props.isEdit && (
          <Button
            type="submit"
            variant="outlined"
            sx={{
              minWidth: '360.49px',
              height: '36px',
              p: '6px 6px 6px 8px',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
            }}
          >
            {t('form.button.save')}
          </Button>
        )}
      </Box>
    </Box>
  )
}
