import csvToXml from 'csv-to-xml'
import { t } from 'i18next'
import xlsx from 'json-as-xlsx'
import jsPDF, { TableConfig } from "jspdf"
const { Parser } = require('json2csv')

const pdfTableConfig: TableConfig = {
  fontSize: 10,
  headerBackgroundColor: '#FF5E1E',
  headerTextColor: '#FFF',
}

export class DashboardService {
  getConnections(data) {
    return data?.totalConnections || 0
  }

  getConnectionsPerGroupLabels(data) {
    return Object.keys(data?.groupsRanking || {}) || []
  }

  getConnectiosPerGroupValues(data) {
    return Object.keys(data?.groupsRanking || {}).map(
      (key) => data?.groupsRanking[key]
    )
  }

  getConnectiosPerGroupTotalValue(data) {
    return (
      Object.keys(data?.groupsRanking || {})
        .map((key) => data?.groupsRanking[key])
        .reduce((partialSum, a) => partialSum + a, 0) || 0
    )
  }

  getNewUsers(data) {
    return data?.newUsers || 0
  }
  getReturningUsers(data) {
    return data?.returningUsers || 0
  }

  getPeriodUseLabels(data) {
    return Object.keys(data?.activity || {}).map((label) => {
      if (/\d/.test(label)) return label.slice(0, 4)
      return t(`date.label.${label}`).slice(0, 3)
    })
  }

  getPeriodUseValues(data) {
    return Object.keys(data?.activity || {}).map((key) => data?.activity[key])
  }

  getDownloadUsage(data) {
    const result = this.convertBytesToGigaBytes(
      data?.bandwidthUsage?.download || 0
    )
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(Number(result))
      .replace('R$ ', '')
  }

  private convertBytesToGigaBytes(bytes) {
    return (bytes / 1073741824).toFixed(2)
  }

  getUploadUsage(data) {
    const result = this.convertBytesToGigaBytes(
      data?.bandwidthUsage?.upload || 0
    )
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(Number(result))
      .replace('R$ ', '')
  }

  getAccessRankingData(data) {
    const accessPoints = Object.keys(data?.accessPointRanking || {})
    const formatedData =
      accessPoints.map((ap) => ({
        name: ap === 'undefined' ? 'Ponto sem nome' : ap,
        value: data?.accessPointRanking[ap],
      })) || []

    const desc = (a, b) => b.value - a.value

    return formatedData.sort(desc)
  }

  getAverageReconnections(data) {
    return data?.averageReconnections || 0
  }

  getPercent(total, value) {
    return Number(((100 * value) / total).toFixed(0))
  }

  getMaxValue(arr) {
    return Math.max(...arr.map(({ value }) => value))
  }

  getReason(values, reasonInput) {
    return values.find(({ reason }) => reason === reasonInput.reason)
  }

  getDefaultReasons() {
    return [
      {
        reason: 'Admin-Reset',
        value: 0,
      },
      {
        reason: 'User-Request',
        value: 0,
      },
      {
        reason: 'Idle-Timeout',
        value: 0,
      },
      {
        reason: 'Service-Unavailable',
        value: 0,
      },
      {
        reason: 'Session-Timeout',
        value: 0,
      },
    ]
  }

  getDisconnectReasons(data) {
    const reasons = Object.keys(data?.disconnectionReasons || {}).filter(
      (reason) => reason !== 'total' && reason !== ''
    )

    const totalDisconnect = data?.disconnectionReasons?.total || 0

    const values =
      reasons.map((reason) => ({
        reason: reason,
        value: this.getPercent(
          totalDisconnect,
          data?.disconnectionReasons[reason]
        ),
      })) || []

    const safeResult = this.getDefaultReasons().map((defaultReason) => {
      if (this.getReason(values, defaultReason)) {
        return this.getReason(values, defaultReason)
      }
      return defaultReason
    })
    const withMajor = safeResult.map((row) => ({
      ...row,
      isMajor: row.value === this.getMaxValue(values),
    }))
    const sorted = withMajor
      .sort(({ value: a }, { value: b }) => a - b)
      .reverse()
    return sorted
  }

  private getContentTypeForFormat = (format: string) => {
    const contentTypeMap = {
      txt: 'text/plain;charset=utf-8;',
      csv: 'text/csv;charset=utf-8;',
      xlsx: 'text/plain;charset=utf-8;',
      xml: 'text/plain;charset=utf-8;',
    }

    return contentTypeMap[format]
  }

  private parseData = (data: any, format, fields?: string[], name?) => {
    if (format === '.csv' || format === '.txt') {
      const parser = new Parser({ fields })
      return parser.parse(data)
    }

    if (format === '.xlsx') {
      const columns = (fields || [])?.map((key) => ({
        value: key,
        label: key,
      }))
      const xlsxData = [
        {
          sheet: 'ItBuzz Report',
          columns,
          content: data,
        },
      ]
      xlsx(xlsxData as any, { fileName: name })
      return false
    }

    if (format === '.xml') {
      const csvParser = new Parser({ fields })
      const csv = csvParser.parse(data) || ''
      const xml = csvToXml(csv?.replaceAll(`"`, ''), {
        headerList: fields,
      })
      return '<table>' + xml + '</table>'
    }

    if (format === '.pdf') {
      const orientation = fields?.length > 3 ? 'l' : 'p'
      const pdf = new jsPDF(orientation, 'pt', 'a3')
      pdf.text('itBuzz Report', 235, 40)
      pdf.table(50, 55, data, fields, pdfTableConfig)
      pdf.save(name)
    }
  }

  generateAndDownloadFile(data, name, format, fields) {
    const content = this.parseData(data, format, fields, name)
    if (content) {
      this.downloadBlob(
        content,
        name + format,
        this.getContentTypeForFormat(format)
      )
    }
  }

  private downloadBlob(content, filename, contentType) {
    // Create a blob
    var blob = new Blob([content], { type: contentType })
    var url = URL.createObjectURL(blob)

    // Create a link to download it
    var pom = document.createElement('a')
    pom.href = url
    pom.setAttribute('download', filename)
    pom.click()
  }
}
