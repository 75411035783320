export const BooleanSort = (a, b, field) => {
  const aValue = a ? 1 : 0
  const bValue = b ? 1 : 0
  return aValue - bValue
}

export const NumberSort = (a, b, field) => {
  const aNumber = parseInt(a.replace(/\D/g, '').slice(0, 2))
  const bNumber = parseInt(b.replace(/\D/g, '').slice(0, 2))
  return aNumber - bNumber
}
export const ArrToNumberSort = (a, b, field) => {
  return NumberSort(String(a.length), String(b.length), null)
}
export const StringSort = (a, b, param) => {
  return a.localeCompare(b)
}
