import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'
import PollIcon from '@mui/icons-material/Poll'

import useProtectedPage from 'src/hooks/useProtectedPage'
import { AlertContext } from 'src/context/AlertContext'
import { EnrichmentForm } from './components/Form/Form'
import ConfirmCreationModal from './components/Modal/ConfirmCreationModal'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { Enrichment } from 'src/models/campaigns-model/enrichment-model'
import { createEnrichmentProfile, getEnrichmentProfileById } from 'src/service/enrichmentProfileService'
import { ROUTES } from 'src/routes/Router'
import { goToEnrichment } from 'src/routes/coordinator'

interface CreateEnrichmentProps {
  isViewMode?: boolean
  viewId?: any
}

export const CreateEnrichment = ({ isViewMode, viewId }: CreateEnrichmentProps) => {
  useProtectedPage()
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [data, setData] = useState<any>()
  const { createCRUDAlert } = useContext(AlertContext)
  const [enrichment, setEnrichment] = useState<Enrichment>()

  const getEnrichment = async () => {
    if (id || isViewMode) {
      const enrichment = await getEnrichmentProfileById(id || viewId)
      setEnrichment(enrichment)
    }
  }

  const onSubmit = async (data) => {
    setData(data)
    setIsModalOpen(true)
  }

  const onHandleCloseAndRejectCreationModal = () => {
    setIsModalOpen(false)
  }

  const onHandleCloseAndAcceptCreationModal = async () => {
    const enrichment = data
    enrichment.content.quiz = enrichment.content.quiz.map((item, index) => {
      const options = item.options.map((option, index) => {
        const obj = { ...option, id: Number(index) }
        return obj
      })
      item.options = options
      return { ...item, id: Number(index) }
    })
    const res = await createEnrichmentProfile({
      ...enrichment,
      contentType: 'enrichment'
    })
    if (res) {
      createCRUDAlert(ROUTES.ENRICHMENT, data.name, 'created')
      goToEnrichment(history)
    }
    setIsModalOpen(false)
  }

  useEffect(() => {
    getEnrichment()
  }, [])


  return (
    <Container
      sx={{
        mt: isViewMode ? '' : '96px',
      }}
    >
      <ConfirmCreationModal
        open={isModalOpen}
        onHandleCloseAndAccept={onHandleCloseAndAcceptCreationModal}
        onHandleCloseAndReject={onHandleCloseAndRejectCreationModal}
      />
      <AlertComponent isView={isViewMode} />
      <Box
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          mb: '15px'
        }}
      >
        <PollIcon />
        <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: '0.15px',
              color: ' #FF5E1E',
            }}
        >
          {t('enrichment.header')}
        </Typography>
      </Box>
      <EnrichmentForm data={enrichment} onSubmit={onSubmit} isViewMode={isViewMode} />
    </Container>
  )
}
