import { Box } from '@mui/material'

export const Gif = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        width: '159.07px',
        height: '160px',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundSize: '90px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
      }}
    >
      <img
        src={require('../../assets/icons/gif.gif')}
        alt="carregamento"
        style={{
          width: '79.5px',
          height: '80px',
        }}
      />
    </Box>
  )
}
