import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Divider, Link, Typography } from '@mui/material'

import InputRHF from '../../components/RHF/InputRHF'
import InputPasswordRHF from '../../components/RHF/InputRHFPassword'
import {
  userDefaultValues,
  userSchemaValidation,
} from './validators/userSchemaValidator'
import logoItBuzz from '../../assets/icons/ITBUZZ-MARCA.png'
import logoTecnoIt from '../../assets/icons/TECNO-IT-MARCA.png'
import { goToLoginNoAutorized } from '../../routes/coordinator'
interface Props {
  onSubmit: (data) => void
}
export const LoginForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation()
  const history = useNavigate()
  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: userDefaultValues,
    resolver: yupResolver(userSchemaValidation),
  })
  const { control, handleSubmit, formState } = form

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '24px',
        boxShadow:
          '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '16px',
      }}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      component="form"
    >
      <Box>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '29px',
            letterSpacing: '0.18px',
            color: '#FF5E1E',
          }}
          variant="h6"
          textAlign={'start'}
        >
          {t('login.title.welcome')}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '29px',
            letterSpacing: '0.18px',
            color: '#000000',
          }}
          variant="h6"
          textAlign={'start'}
        >
          {t('login.title.login_with_account')}
        </Typography>
      </Box>
      <InputRHF
        name="email"
        control={control}
        label="E-mail"
        sx={{
          width: '326px',
          height: '54px',
          mb: '24px',
        }}
      />
      <InputPasswordRHF
        name="password"
        label={t('login.label.input.password')}
        control={control}
        sx={{
          width: '326px',
          height: '54px',
          mb: '24px',
        }}
      />
      <Button
        variant="outlined"
        sx={{
          mt: 1,
          width: '328px',
          height: '40px',
          borderRadius: '4px',
          color: formState.isValid ? '#FF5E1E' : 'rgba(0, 0, 0, 0.38)',
          border: formState.isValid
            ? '1px solid #FF5E1E'
            : '1px solid rgba(0, 0, 0, 0.12)',
          ':hover': {
            backgroundColor: '#FF5E1E',
            color: '#ffff',
          },
        }}
        disabled={!formState.isValid}
        type={'submit'}
      >
        {t('login.button.acess')}
      </Button>

      <Link
        onClick={() => goToLoginNoAutorized(history)}
        href="#"
        underline="none"
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          color: 'rgba(0, 0, 0, 0.6)',
          ':hover': {
            color: '#FF5E1E',
          },
        }}
      >
        {t('login.button.not_acess')}
      </Link>

      <Divider sx={{ width: '336px' }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: 5,
        }}
      >
        <img alt="logo" src={logoItBuzz} style={{ width: '101.5px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: ' 16px',
              letterSpacing: '1.5px',
              color: 'rgba(0, 0, 0, 0.6)',
              mt: '2px',
            }}
          >
            {t('login.image.title.per')}
          </Typography>
          <img
            alt="logo"
            src={logoTecnoIt}
            style={{ width: '82.56', height: '24px' }}
          />
        </Box>
      </Box>
    </Box>
  )
}
