import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'

import { Card } from './Card'
interface Props {
  total: string | number
  sx?: any
}

export const TotalView = ({ total, sx }: Props) => {
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        minHeight: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        p: '16px',
        px: '31px',
        ...sx
      }}
    >
      <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6);' }}>
        {t('campaign.result.total.view.title')}
      </Typography>
      <Typography
        sx={{
          fontSize: '34px',
          color: 'rgba(0, 0, 0, 0.87)',
          mt: 1,
        }}
      >
        {total}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <AssessmentOutlinedIcon
          sx={{
            fontSize: '4rem',
            color: Number(total) <= 0 ? '#DBDBDB' : '#FF5E1E',
            mt: 1.5,
          }}
        />
      </Box>
    </Card>
  )
}
