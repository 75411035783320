import { createContext, ReactNode, useState } from 'react'

const defaultState = {
  open: false,
  handleOpen: () => {},
  handleClose: () => {},
}

interface ContextProviderProps {
  children: ReactNode
}

export const ModalCreatedCompanyContext = createContext<any>(defaultState)

function ModalIdCompanyProvider({ children }: ContextProviderProps) {
  const [open, setOpen] = useState<boolean>(false)
  const [page, setPage] = useState<string>()
  const [id, setId] = useState<string>()

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const openModalCompany = (page: string, id: string) => {
    setPage(page)
    setId(id)
    handleOpenModal()
  }

  const openModal = (route, id) => {
    openModalCompany(route, id)
  }

  return (
    <ModalCreatedCompanyContext.Provider
      value={{
        id,
        open,
        handleCloseModal,
        handleOpenModal,
        openModalCompany,
        openModal,
      }}
    >
      {children}
    </ModalCreatedCompanyContext.Provider>
  )
}

export default ModalIdCompanyProvider
