/* global google */
import * as React from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { createCustomEqual } from 'fast-equals'
import { isLatLngLiteral } from '@googlemaps/typescript-guards'
import activePointIcon from '../../../../assets/icons/ICON-COLOR-MAP-POINT-ACTIVE.svg'
import inactivePointIcon from '../../../../assets/icons/ICON-COLOR-MAP-POINT-INACTIVE.svg'
import HeatMapColor from './HeatMap'
import { getMapsApiKey } from 'src/domain/getEnvVarOrThrow'

export type Point = {
  latlng: google.maps.LatLng
  isActive: boolean
  totalAccess: number
  name: string
}

interface Props {
  points?: Array<Point>
  isColorMode?: boolean
  center?: google.maps.LatLng
}

const HeatMap = ({ points, isColorMode, center }: Props) => {
  const conections = points?.map(({ totalAccess }) => Number(totalAccess))
  const totalConections = HeatMapColor.getSumOfArray(conections)
  const arrPercentage = HeatMapColor.calculatePercentageArray(
    conections,
    totalConections
  )
  return (
    <Wrapper apiKey={getMapsApiKey()} render={render}>
      <Map
        minZoom={points?.length ? 12 : 0}
        maxZoom={16}
        styles={styles.silver}
        center={center}
        zoom={12}
        zoomControl={false}
        style={{
          height: '680px',
          with: '100%',
          position: 'relative',
        }}
        streetViewControl={false}
        mapTypeId="roadmap"
      >
        {isColorMode &&
          points.map(({ latlng, name }, i) => {
            return (
              <Marker
                key={`${latlng.lat()}y${latlng.lng()}-${isColorMode}`}
                position={latlng}
                title={name}
                icon={{
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 25,
                  fillColor: HeatMapColor.getColor(arrPercentage[i] || 1),
                  fillOpacity: 0.4,
                  strokeWeight: 0.0,
                }}
              />
            )
          })}
        {points.map(({ latlng, isActive, name }) => {
          const icon = isActive ? activePointIcon : inactivePointIcon
          return (
            <Marker
              key={`${latlng.lat()}x${latlng.lng()}-${isColorMode}`}
              position={latlng}
              icon={{
                url: icon,
                anchor: new google.maps.Point(12, 12),
              }}
              title={name}
            />
          )
        })}
      </Map>
    </Wrapper>
  )
}

const render = (status: Status) => {
  return <h1>{status}</h1>
}

const styles: Record<string, google.maps.MapTypeStyle[]> = {
  silver: [
    {
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#61616159' }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#c9c9c9' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
  ],
}

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string }
  onClick?: (e: google.maps.MapMouseEvent) => void
  onDblclick?: (e: google.maps.MapMouseEvent) => void
  onIdle?: (map: google.maps.Map) => void
  children?: React.ReactNode
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  onDblclick,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [map, setMap] = React.useState<google.maps.Map>()
  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
  }, [ref, map])

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  React.useEffect(() => {
    if (map) {
      ;['click', 'places_changed'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      )

      if (onClick) {
        map.addListener('click', onClick)
        map.setZoom(18)
      }

      if (onDblclick) {
        map.addListener('dblclick', onDblclick)
      }

      if (onIdle) {
        map.addListener('places_changed', () => onIdle(map))
      }
    }
  }, [map, onClick, onIdle, onDblclick])

  return (
    <div>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement<any>(child, { map })
        }
      })}
    </div>
  )
}

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
    }

    return deepEqual(a, b)
  }
)

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>()

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker())
      new google.maps.InfoWindow()
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  React.useEffect(() => {
    if (marker) {
      marker.setOptions({
        ...options,
      })
    }
  }, [marker, options])

  return null
}

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

export default HeatMap
