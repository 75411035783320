import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { Box } from '@mui/system'

interface Props {
  goTo?: any
  id?: string
  size?: string
  ml?: string
  mr?: string
  handleClose?: () => void
  handleOpenModal?: any
  name?: any
  showEditButton?: boolean
}

export const FooterBottonsView = ({
  goTo,
  id,
  size,
  ml,
  mr,
  handleClose,
  handleOpenModal,
  name,
  showEditButton = true,
}: Props) => {
  const history = useNavigate()
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        gap: size,
        ml: ml,
        mr: mr,
        mt: '13px',
      }}
    >
      <Button
        onClick={() => handleOpenModal(id, name)}
        variant="outlined"
        sx={{
          minWidth: '275px',
          height: '36px',
          p: '6px 6px 6px 8px',
          color: '#00000099',
          border: '1px solid #00000099',
          ':hover': {
            border: 'none',
            backgroundColor: '#00000099',
            color: '#ffff',
          },
        }}
      >
        {t('modal.button.delete')}
      </Button>
      <Button
        onClick={() => goTo(history, id)}
        variant="outlined"
        sx={{
          minWidth: '275px',
          height: '36px',
          p: '6px 6px 6px 8px',
          color: '#00000099',
          border: '1px solid #00000099',
          ':hover': {
            border: 'none',
            backgroundColor: '#00000099',
            color: '#ffff',
          },
        }}
        disabled={showEditButton}
      >
        {t('table.button.edit')}
      </Button>

      <Button
        onClick={handleClose}
        variant="outlined"
        sx={{
          minWidth: '570px',
          height: '36px',
          p: '6px 6px 6px 8px',
          ':hover': {
            backgroundColor: '#FF5E1E',
            color: '#ffff',
          },
        }}
      >
        {t('login.no_authorized.button')}
      </Button>
    </Box>
  )
}
