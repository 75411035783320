import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import * as yup from 'yup'

export const notificationDefaultValues: { title: string, content: string } = {
  title: '',
  content: '',
}

const mode: keyof ValidationMode = 'all'

export const notificationSchemaValidation = yup.object({
  title: yup.string().required('required'),
  content: yup.string().required('required'),
})

export const notificationUseFormArgs = {
  mode,
  defaultValues: notificationDefaultValues,
  resolver: yupResolver(notificationSchemaValidation),
}
