import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import { Box, CircularProgress, Typography } from '@mui/material'
import { t } from 'i18next'
import { ReactNode, useEffect, useState } from 'react'

import UploadFile from 'src/components/UploadFile/UploadFile'
import { uploadFileVideo } from 'src/service/uploadservice'

interface Props {
  onFileChange: (file, fileName) => void
  placeholder: string
  icon?: ReactNode
  value?: string
  accept?: string
  error?: boolean
  errorMessage?: string
  checkFileSizeCallback?: (size) => boolean
  defaultFile?: string
  acceptedFormats?: string
  isView?: any
  watch?: any
}
export const UploadVideo = ({
  onFileChange,
  placeholder,
  accept,
  error,
  errorMessage,
  checkFileSizeCallback,
  defaultFile,
  acceptedFormats,
  isView,
  watch
}: Props) => {
  const [hover, setHover] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(defaultFile)
  const [fileName, setFileName] = useState<string>('')

  const onReadFile = async (file) => {
    setIsLoading(true)
    if (accept === '.mp4') {
      const res = await uploadFileVideo(file, { params: { dir: 'training-video' } })
      const fileUrl = res.data.file
      onFileChange(fileUrl, file.name)
      setFileName(file.name)
      setIsLoading(false)
    }
  }

  useEffect(() => setFile(defaultFile), [defaultFile])

  const handleClick = () => {
    document.getElementById(placeholder).click()
  }

  const handleDownload = () => {
    if (isView) {
      const link = document.createElement('a')
      link.download = file
      link.href = file
      link.click()
    }
  }
  return (
    <Box sx={{ my: 3 }}>
      <Box
        onClick={() => {
          !file && handleClick()
          handleDownload()
        }}
        sx={{
          mb: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
          width: '100%',
          height: '90px',
          cursor: 'pointer',
          border: watch('videoUrl') && ' 1px solid #FF5E1E',
          backgroundImage:
            !watch('videoUrl') &&
            `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${
              hover
                ? error
                  ? 'rgba(129, 0, 0, 1)'
                  : 'rgb(255, 94, 30)'
                : error
                ? 'rgba(129, 0, 0, 1)'
                : 'rgba(0, 0, 0, 0.6)'
            }' stroke-width='4' stroke-dasharray='15%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`,
          borderBottom: watch('videoUrl') && 'solid 4px #FF5E1E',
          backgroundColor:
            watch('videoUrl') && 'rgba(255, 94, 30, 0.08)',

          gap: '10px',
          color: 'rgba(0, 0, 0, 0.6) ',
          ':hover': {
            color: !error && '#FF5E1E',
            borderColor: !error && '#FF5E1E',
          },
          borderRadius: watch('videoUrl') && '4px 4px 0px 0px',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <UploadFile
          onReadCallback={onReadFile}
          hidden
          type="file"
          accept={accept}
          id={placeholder}
          checkFileSizeCallback={checkFileSizeCallback}
        />

        <Box
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
          }}
        >
          {watch('videoUrl') || isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Box
                sx={{
                  width: 'auto',
                  minWidth: '230px',
                  minHeight: '80px',
                  wordBreak: 'break-all',
                  backgroundImage: watch('videoUrl')
                    ? `url(${watch('videoUrl')})`
                    : 'none',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  display: watch('videoUrl') ? 'none' : 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {isLoading && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '15px',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                    <Typography
                      sx={{
                        color: '#FF5E1E',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '16px',
                      }}
                    >
                      {t('campaign_loading')}
                    </Typography>
                  </div>
                )}
              </Box>
              {!isLoading && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                    color: '#FF5E1E',
                  }}
                >
                  {fileName}
                  <DeleteForeverIcon
                    sx={{
                      color: '#00000099',
                      cursor: 'pointer',
                      zIndex: 4,
                      display: isView ? 'none' : 'flex',
                    }}
                    onClick={() => {
                      onFileChange(undefined, undefined)
                      setFile(undefined)
                    }}
                  />
                </div>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <ImageOutlinedIcon
                  sx={{
                    width: '25px',
                    height: '25px',
                    color: error && 'rgba(129, 0, 0, 1)',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    color: error && 'rgba(129, 0, 0, 1)',
                  }}
                >
                  <label htmlFor={placeholder} style={{ cursor: 'pointer' }}>
                    {placeholder}
                  </label>
                  <br></br>
                  <label
                    htmlFor={acceptedFormats}
                    style={{ cursor: 'pointer', fontSize: '10px' }}
                  >
                    {acceptedFormats}
                  </label>
                </Typography>
              </Box>

              {error && (
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    color: error && 'rgba(129, 0, 0, 1)',
                    ml: '90px',
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
