import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CKEditorRHF from 'src/components/CKEditorRHF/CKEditorRHF'
import InputRHF from '../../../../components/RHF/InputRHF'
import { notificationDefaultValues, notificationSchemaValidation } from '../../validators/notificationSchemaValidator'

interface NotificationFormProps {
  onSubmit(data: any): void
}

export const NotificationForm = ({ onSubmit }: NotificationFormProps) => {
  const { t } = useTranslation()

  const form = useForm<any>({
    mode: 'all',
    defaultValues: notificationDefaultValues,
    resolver: yupResolver(notificationSchemaValidation),
  })
  const { control, handleSubmit, watch, } = form

  const formIsValid = () => Boolean(watch('title') && watch('content'))

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" sx={{ mb: 2 }}>
      <Box
        sx={{
          boxShadow: 'none',
          p: ' 0px 0px 16px 0px',
          borderRadius: '4px',
          mb: '24px',
          mt: '16px',
        }}
      >
        <InputRHF
          control={control}
          label={t('notification.form.input.label.title')}
          placeholder={t('notification.form.input.placeholder.title')}
          name="title"
          sx={{
            width: '100%',
            height: '56px',
            mb: 2,
          }}
        />

        <CKEditorRHF
          label={t('notification.form.input.label.description')}
          name="content"
          control={control}
          sx={{
            width: '100%',
            mt: 3,
            mb: 2,
          }}
          displayColorPicker={false}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
          }}
        >
          <Button
            type="submit"
            variant="outlined"
            disabled={!formIsValid()}
            sx={{
              p: 0,
              height: '36px',
              minWidth: '308px',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px',
              color: '#FF5E1E',
              borderColor: '#FF5E1E',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
              alignSelf: 'flex-end'
            }}
          >
            <AddIcon />
            {t('notification.button.create')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
