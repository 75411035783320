import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import { Box, Container, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { AlertContext } from 'src/context/AlertContext'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { FrequentlyAskedQuestion } from 'src/models/faqs-model'
import { ROUTES } from 'src/routes/Router'
import { goToFaqs } from 'src/routes/coordinator'
import { createFAQ, editFAQ, getFAQById } from 'src/service/faqsService'
import { FaqsForm } from './components/forms/FaqsForm'

interface CreateProps {
  faqId?: string
}

export function CreateFAQ ({ faqId }: CreateProps) {
  useProtectedPage()
  const history = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const { createCRUDAlert } = useContext(AlertContext)
  const [faq, setFaq] = useState<FrequentlyAskedQuestion>()

  const getFaq = async () => {
    if (id) {
      const faq = await getFAQById(id || faqId)
      setFaq(faq)
    }
  }

  const onSubmit = async (data: FrequentlyAskedQuestion) => {
    if (data?.id) {
      const { question, answer } = data
      const res = await editFAQ(id, { ...data, question, answer })
      if (res) {
        createCRUDAlert(ROUTES.FAQS, data.question, 'edited')
        goToFaqs(history)
      }
      return
    }
    const res = await createFAQ(data)
    if (res) {
      createCRUDAlert(ROUTES.FAQS, data.question, 'created')
      goToFaqs(history)
    }
  }

  useEffect(() => {
    getFaq()
  }, [])

  return (
    <Container sx={{ mt: '96px' }}>
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: '24px',
        }}
      >
        <QuestionAnswerRoundedIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
          }}
        >
          {t('faqs.title')}
        </Typography>
      </Box>
      <FaqsForm
        data={faq!}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
