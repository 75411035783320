import { importCsv } from './accesspointService'
import { getCompanyId } from './authService'

type AccessPoint = {
  id: string
  apName: string
  geoLatitude: string
  geoLongitude: string
  geoAddress: string
  ipAddress: string
  macAddress: string
  companyId: string
  created_at: string
  isActive: boolean
  deleted: boolean
  deleted_at: string | null
  company: {
    companyName: string
  }
}

export default class AccessPointCsvService {
  private data: AccessPoint[]

  constructor(data: AccessPoint[]) {
    this.data = data
  }

  private downloadCsv(csv: string, filename: string) {
    const csvBlob = new Blob([csv], { type: 'text/csv' })
    const url = URL.createObjectURL(csvBlob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.click()
  }

  private convertToCsv() {
    const headers = [
      'companyId',
      'geoAddress',
      'geoLatitude',
      'geoLongitude',
      'ipAddress',
      'macAddress',
      'name',
    ]
    const rows = this.getDataForCsv().map((ap) =>
      headers
        .map((header) =>
          header === 'geoAddress'
            ? `"${ap[header].replace(/"/g, '""')}"`
            : ap[header]
        )
        .join(';')
    )
    return [headers.join(';'), ...rows].join('\n')
  }

  private getDataForCsv() {
    return [
      {
        name: '',
        geoLatitude: '',
        geoLongitude: '',
        geoAddress: '',
        ipAddress: '',
        macAddress: '',
        companyId: getCompanyId() || 'companyId',
      },
    ]
  }

  public async exportCsv() {
    try {
      const csv = this.convertToCsv()
      this.downloadCsv(csv, 'access-points.csv')
      return true
    } catch (error) {
      return false
    }
  }

  public static async importCsv(file: File): Promise<any> {
    try {
      const formData = new FormData()
      formData.append('file', file, file.name)
      await importCsv(formData)
      return true
    } catch (error) {
      throw new Error('Erro on import csv')
    }
  }
}
