export const CircleIcon = () => {
  return (
    <svg
    width="43px"
    height="43px"
      viewBox="0 0 52 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0002 0.333008C10.0618 0.333008 0.333496 10.0613 0.333496 21.9997C0.333496 33.938 10.0618 43.6663 22.0002 43.6663C33.9385 43.6663 43.6668 33.938 43.6668 21.9997C43.6668 10.0613 33.9385 0.333008 22.0002 0.333008ZM22.0002 39.333C12.4452 39.333 4.66683 31.5547 4.66683 21.9997C4.66683 12.4447 12.4452 4.66634 22.0002 4.66634C31.5552 4.66634 39.3335 12.4447 39.3335 21.9997C39.3335 31.5547 31.5552 39.333 22.0002 39.333ZM28.5002 21.9997C28.5002 25.5963 25.5968 28.4997 22.0002 28.4997C18.4035 28.4997 15.5002 25.5963 15.5002 21.9997C15.5002 18.403 18.4035 15.4997 22.0002 15.4997C25.5968 15.4997 28.5002 18.403 28.5002 21.9997Z"
        fill="#DBDBDB"
      />
    </svg>
  )
}
