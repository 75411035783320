import { createContext, ReactNode, useEffect, useState } from 'react'

const defaultState = {}
interface ContextProviderProps {
  children: ReactNode
}
export const MapContext = createContext<any>(defaultState)

function MapProvider({ children }: ContextProviderProps) {
  const [latLng, setLatLng] = useState<google.maps.LatLng>(
    new google.maps.LatLng(undefined, undefined)
  )
  const [place, setPlace] = useState<{}>(false)

  const geocoder = new google.maps.Geocoder()

  const getMapAddres = async (latLng) => {
    if (!latLng.lat() || !latLng.lng()) return
    const res = await geocoder.geocode({ location: latLng })
    const address = res.results[0]
    setPlace({
      description: address.formatted_address as any,
      place_id: address.place_id,
    })
  }
  const resetLatLng = () => {
    setLatLng(new google.maps.LatLng(undefined, undefined))
    setPlace({
      description: '',
      place_id: undefined,
    })
  }
  useEffect(() => {
    getMapAddres(latLng)
  }, [latLng])

  return (
    <MapContext.Provider
      value={{
        latLng,
        setLatLng,
        place,
        resetLatLng,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

export default MapProvider
