import { Box, SxProps, Theme } from '@mui/material'
import InputRHF from './InputRHF'
import { Control, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface InputEmailRHFProps {
  name: string
  label: string
  disabled?: any
  isDuplicatedEmail: any
  control: Control<FieldValues>
  placeholder: string
  sx?: SxProps<Theme>
}

const InputEmailRHF = ({
  name,
  label,
  disabled,
  isDuplicatedEmail,
  control,
  placeholder,
  sx,
}: InputEmailRHFProps) => {
  const { t } = useTranslation()
  return (
    <Box
      className={isDuplicatedEmail ? 'errorduplicatemail' : ''}
      sx={{
        '.error-txt': {
          color: isDuplicatedEmail ? '#810000' : 'initial',
          display: isDuplicatedEmail ? 'grid' : 'none',
          fontSize: '12px',
          mt: '15px',
        },
        '.css-kgammo-MuiFormControl-root-MuiTextField-root': {
          width: '-webkit-fill-available',
        },
        ...sx,
      }}
    >
      <InputRHF
        disabled={disabled}
        control={control}
        label={label}
        name={name}
        placeholder={placeholder}
        sx={{
          '.MuiInputLabel-formControl': {
            color: isDuplicatedEmail ? '#810000' : '',
          },
          fieldset: {
            border: isDuplicatedEmail ? '1px solid #810000' : '',
          },
          input: {
            color: isDuplicatedEmail ? '#810000 !important' : '',
          },
          width: '100%',
          height: '48px',
          ...sx,
        }}
      />
      <p className="error-txt">{t('user.form.error.invalid.email')}</p>
    </Box>
  )
}

export default InputEmailRHF
