interface Props {
  onReadCallback: (fileToUpload: File) => void
  checkFileSizeCallback?: (size) => boolean
}
const UploadFile = ({ onReadCallback, ...props }) => {
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      try {
        const reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        const fileToUpload = event.target.files[0]
        if (props?.checkFileSizeCallback) {
          const sizeInMB = (fileToUpload.size / (1024 * 1024)).toFixed(2)
          const res = props?.checkFileSizeCallback(sizeInMB)
          if (!res) return
        }

        onReadCallback(fileToUpload)
        event.target.value = null
      } catch (error) {
        alert(error)
      }
    }
  }
  return <input {...props} onChange={handleChange} />
}

export default UploadFile
