import { VideoTraining } from 'src/models/training-video-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

interface CreateTrainingVideoInput {
  title: string
  description: string
  category: string
  videoUrl: string
}

export const createTrainingVideo = async (video: CreateTrainingVideoInput): Promise<VideoTraining | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/training-video`, video)
    return res.data
  } catch (error) {
    return false
  }
}

export const fetchTrainingVideos = async (options?: any): Promise<VideoTraining[] | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/training-video`, options)
    return res.data
  } catch (error) {}
}

export const getTrainingVideoById = async (id: string): Promise<VideoTraining | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/training-video/${id}`)
    return res.data
  } catch (error) {}
}

export const editTrainingVideo = async (id: string, trainingVideo: VideoTraining): Promise<VideoTraining | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/training-video/${id}`, trainingVideo)
    return res.data
  } catch (error) {
    return false
  }
}

export const deleteTrainingVideoById = async (id: string | number): Promise<VideoTraining | boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/training-video/${id}`)
    return true
  } catch (error) {
    return false
  }
}
