import axios from 'axios'
import { getToken, logout } from './authService'

export const instance = axios.create()

instance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status === 403) {
      logout()
      window.location.href = '/'
      return
    }
    return error
  }
)

export const client = () => {
  const defaultOptions = {
    headers: {
      'x-access-token': getToken() ? `${getToken()}` : '',
    },
  }

  return {
    get: (url: any, options = {}) =>
      instance.get(url, { ...defaultOptions, ...options }),
    post: (url: any, data: any, options = {}) =>
      instance.post(url, data, { ...defaultOptions, ...options }),
    put: (url: any, data: any, options = {}) =>
      instance.put(url, data, { ...defaultOptions, ...options }),
    patch: (url: any, data: any, options = {}) =>
      instance.patch(url, data, { ...defaultOptions, ...options }),
    delete: (url: any, options = {}) =>
      instance.delete(url, { ...defaultOptions, ...options }),
  }
}

export const downloadFile = (url: string, fileName: string) => {
  fetch(url, {
    method: 'POST',
    headers: {
      Authorization: getToken() ? `${getToken()}` : '',
    },
  })
    .then((res) => res.blob())
    .then((res) => {
      const aElement = document.createElement('a')
      aElement.setAttribute('download', fileName)
      const href = URL.createObjectURL(res)
      aElement.href = href
      aElement.setAttribute('target', '_blank')
      aElement.click()
      URL.revokeObjectURL(href)
    })
}
