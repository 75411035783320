import { ReactNode, useEffect, useState } from 'react'
import { t } from 'i18next'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Box, CircularProgress, Typography, SxProps } from '@mui/material'

import UploadFile from '../../../../components/UploadFile/UploadFile'
import { uploadFileImg } from 'src/service/uploadservice'

interface Props {
  onFileChange: (file) => void
  acceptedFormats: string
  placeholder: string
  icon: ReactNode
  value?: string
  isView?: boolean
  sx?: SxProps
}
export const UploadCompanyPhoto = ({
  onFileChange,
  placeholder,
  acceptedFormats,
  icon,
  value,
  isView,
  sx
}: Props) => {
  const [file, setFile] = useState<string>(value)
  const [isLoading, setIsLoading] = useState(false)

  const onReadFile = async (file) => {
    setIsLoading(true)
    const res = await uploadFileImg(file)
    const fileUrl = res.data.file
    onFileChange(fileUrl)
    setFile(fileUrl)
    setIsLoading(false)
  }

  useEffect(() => {
    setFile(value)
  }, [value])

  const handleClick = () => {
    document.getElementById(placeholder).click()
  }

  const handleDownload = () => {
    if (isView) {
      const link = document.createElement('a')
      link.download = file
      link.href = file
      link.click()
    }
  }

  return (
    <Box
      onClick={() => {
        !file && handleClick()
        handleDownload()
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        // width: '49%',
        height: isView ? '75px' : '120px',
        border: file
          ? ' 1px solid #FF5E1E'
          : ' 2.5px dashed rgba(0, 0, 0, 0.38)',
        borderBottom: file && 'solid 4px #FF5E1E',
        backgroundColor: file && 'rgba(255, 94, 30, 0.08)',
        mt: 1.5,
        gap: '10px',
        color: 'rgba(0, 0, 0, 0.6) ',
        ':hover': {
          color: '#FF5E1E',
          borderColor: '#FF5E1E',
        },
        borderRadius: '4px',
        cursor: 'pointer',
        ...sx
      }}
    >
      <UploadFile
        onReadCallback={onReadFile}
        hidden
        type="file"
        accept=".jpg,.png"
        id={placeholder}
        value={null}
      />

      <Box
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '16px',
        }}
      >
        {file || isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Box
              sx={{
                width: 'auto',
                minWidth: '230px',
                minHeight: '80px',
                wordBreak: 'break-all',
                backgroundImage: file ? `url(${file})` : 'none',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              {isLoading && (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <CircularProgress size={20} sx={{ ml: 1 }} />
                  <Typography sx={{ color: '#FF5E1E', fontWeight: 500 }}>
                    {t('company.form.input.placeholder.logo.loading')}
                  </Typography>
                </Box>
              )}
            </Box>
            {!isLoading && (
              <DeleteForeverIcon
                sx={{
                  color: '#00000099',
                  display: isView ? 'none' : 'flex',
                  cursor: 'pointer',
                  ':hover': {
                    color: '#ff5e1e',
                  },
                }}
                onClick={() => {
                  setFile(undefined)
                  onFileChange('')
                }}
              />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {icon}
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '16px',
              }}
            >
              <label htmlFor={placeholder} style={{ cursor: 'pointer' }}>
                {placeholder}
              </label>
              <br></br>
              <label
                htmlFor={acceptedFormats}
                style={{ cursor: 'pointer', fontSize: '10px' }}
              >
                {acceptedFormats}
              </label>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
