import * as React from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { InputRHFProps } from 'src/models/inputRHF-model'
import { MapContext } from 'src/context/MapContext'

const autocompleteService = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}
interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[]
}
interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
  place_id: string
  componentRestrictions: string
  fields: string
  striciBounds: boolean
}

interface AutocompleteMapProps extends InputRHFProps {}

export default function AutocompleteMap(props: AutocompleteMapProps) {
  const [value, setValue] = React.useState<PlaceType>()
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])
  const { t } = useTranslation()
  const { place, setLatLng, resetLatLng } = React.useContext(MapContext)

  const geocoder = new google.maps.Geocoder()

  React.useEffect(() => {
    if (value) {
      geocoder
        .geocode({ placeId: value.place_id })
        .then(({ results }) => {
          if (results[0].geometry.location) {
            setLatLng(results[0].geometry.location)
          }
        })
        .catch((e) => window.alert('Geocoder failed due to: ' + e))
    }
  }, [value])

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        200
      ),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService('', { location: true })
    }

    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }
        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      disabled={props.disabled}
      id="google-map-demo"
      sx={{ ...props.sx }}
      noOptionsText={t('point.form.no.options.address')}
      getOptionLabel={(option) => option.description || ''}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={place}
      popupIcon={<ArrowDropDownIcon sx={{ display: 'none' }} />}
      onChange={(_, value) => setValue(value as any)}
      onInputChange={(event, newInputValue) => {
        if (!newInputValue) resetLatLng()
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('point.form.input.label.address')}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings
        const parts = parse(
          option?.structured_formatting?.main_text,
          matches?.map((match: any) => [
            match?.offset || 0,
            match?.offset + match?.length || 0,
          ]) || []
        )
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part?.highlight ? 700 : 400,
                    }}
                  >
                    {part?.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
