import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Card } from './Card'
import { ToggableCard } from './ToggableCard'
import { RetryIcon } from 'src/components/Icons/RetryIcon'
import { useTranslation } from 'react-i18next'
interface Props {
  value?: number | string
}
export const ReconnectionsAverage = ({ value }: Props) => {
  const { t } = useTranslation()

  return (
    <ToggableCard viewkey="reconnectionsaverage">
      <Card
        sx={{
          minWidth: '222px',
          minHeight: '200px',
          width: '300px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          p: '16px',
          px: '31px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6);',
            whiteSpace: 'pre-wrap',
          }}
        >
          {t('painel.reconect')}
        </Typography>
        <Typography
          sx={{
            fontSize: '34px',
            color: 'rgba(0, 0, 0, 0.87)',
            mt: 1,
          }}
        >
          {value}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: 1,
          }}
        >
          <RetryIcon />
        </Box>
      </Card>
    </ToggableCard>
  )
}
