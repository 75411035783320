import { useEffect, useState } from 'react'
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import { Button, Tooltip, Typography } from '@mui/material'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import ListIcon from '@mui/icons-material/List'

import { CampaignSelect } from 'src/components/CampaignSelect/CampaignSelect'
import { getCompanyId } from 'src/service/authService'
import { options } from '../../validators/campaignSchemaValidator'
import InputRHF from 'src/components/RHF/InputRHF'
import MenuItemMap from 'src/components/ArrayIterators/MenuItemMap'
import { DefaultCampaignChip } from 'src/components/Chips/DefaultCampaignChip'
import { goToCampaigns } from 'src/routes/coordinator'
import { useNavigate } from 'react-router-dom'

interface Props {
  onCampaignChange?: (value: any) => void
  onCampaignTypeChange?: (value: any) => void
  control: Control<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  watch: any
  data?: any
}

const CampaignResultHeader = ({
  onCampaignChange,
  onCampaignTypeChange,
  control,
  watch,
  setValue,
  data,
}: Props) => {
  const history = useNavigate()
  const { t } = useTranslation()
  const [disableType, setDisableType] = useState(false)

  useEffect(() => {
    onCampaignChange && onCampaignChange(watch('campaign')?.id)
  }, [watch('campaign')])

  useEffect(() => {
    onCampaignTypeChange && onCampaignTypeChange(watch('contentType'))
  }, [watch('contentType')])

  const onChangeType = (value: any) => {
    if (value) {
      setValue('contentType', value)
      setDisableType(true)
      if (value === 'todas') {
        setValue('contentType', 'todas')
        setDisableType(false)
      }
    } else {
      setDisableType(false)
    }
  }
  useEffect(() => {
    defaultCampaign()
  }, []);

  const title = () => {
    if (watch('campaign')?.name !== 'Todas as campanhas') {
      return watch('campaign')?.name
    }
    if (watch('contentType') === 'text') {
      return 'Campanha de Texto'
    }
    if (watch('contentType') === 'media') {
      return 'Campanha de Mídia'
    }
    if (watch('contentType') === 'quix') {
      return 'Campanha de Pesquisa'
    }
    if (watch('contentType') === 'image') {
      return 'Campanha de Imagem'
    }
    if (watch('contentType') === 'todas') {
      return t('campaign.selected.all')
    }
  }
  const defaultCampaign: any = () => {
    if (watch('campaign')?.name && data?.defaultCampaign === true) {
      return <DefaultCampaignChip />
    }else{
     return undefined
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '20px',
        pr: 1,
        mt: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#FF5E1E',
        }}
      >
        <CampaignOutlinedIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            ml: 1,
          }}
        >
          {t('campaign.result.title')}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: '400',
              fontSize: '34px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              width: '100%',
            }}
          >
            {title()}
          </Typography>
          {defaultCampaign()}
        </Box>

        <Tooltip title={t('form.button.back_to_list')} placement="right">
          <Button
            onClick={() => goToCampaigns(history)}
            variant="outlined"
            sx={{
              p: 0,
              height: '36px',
              minWidth: '36px',
              borderColor: 'rgba(0, 0, 0, 0.6)',
              color: '#00000099',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderColor: 'rgba(0, 0, 0, 0.6)',
              },
            }}
          >
            <ListIcon />
          </Button>
        </Tooltip>
      </Box>

      <Box
        component="form"
        sx={{
          width: '100%',
          display: 'grid',
          gridColumn: 'span 12',
          gridTemplateColumns: 'repeat(12, 2fr)',
          rowGap: 2,
          columnGap: 2,
        }}
      >
        <CampaignSelect
          setValue={setValue}
          onChangeOption={(value) => onChangeType(value?.contentType)}
          companyId={getCompanyId() != 'null' && getCompanyId()}
          name="campaign"
          control={control}
          sx={{
            gridColumn: {
              xs: 'span 12',
              sm: 'span 12',
              md: 'span 8',
              lg: 'span 10',
            },
          }}
        />

        <InputRHF
          select
          name="contentType"
          control={control}
          disabled={disableType}
          label={t('campaign.type.select.label')}
          sx={{
            gridColumn: {
              xs: 'span 12',
              sm: 'span 12',
              md: 'span 4',
              lg: 'span 2',
            },
            mt: {
              sm: '4px',
              md: '0px',
            },
          }}
        >
          {options.map(MenuItemMap)}
        </InputRHF>
      </Box>
    </Box>
  )
}

export default CampaignResultHeader
