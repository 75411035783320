import { useFieldArray } from 'react-hook-form'
import { t } from 'i18next'
import { Box, IconButton, Tooltip } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import InputSelectRHF from '../RHF/InputSelectRHF'
import { OptionsSelect } from './OptionsSelect'
interface Props {
  onChange: (value) => void
  name: string
}
export const Question = ({
  onChange,
  name,
  onRemove,
  enrichment,
  formState: { control, setValue, watch },
  ...props
}: any) => {
  const optionsName = `${name}.options`

  const { fields, append, remove } = useFieldArray({
    control,
    name: optionsName,
  })

  const selectedOptions = (watch(name)?.options || []).map(
    (value) => value?.name
  )

  const removeSelectedOptions = (options) => {
    return (
      options?.filter((option) => !selectedOptions.includes(option?.name)) || []
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '50%',
          }}
        >
          <Tooltip title={t('campaign.button.segmentation.delete.button.tip')}>
            <IconButton sx={{ height: '55px' }} onClick={() => onRemove()}>
              <HighlightOffIcon />
            </IconButton>
          </Tooltip>

          <InputSelectRHF
            options={enrichment}
            placeholder={t('campaign.button.segmentation.question')}
            loading
            noOptionsText={t('noRowsLabel.general')}
            multiple={false}
            isOptionEqualToValueFn={(option: any, value: any) =>
              option.sku === value
            }
            getOptionLabel={(value: any) => value?.name || ''}
            onChangeAction={(value) => {
              setValue(optionsName, [])
              remove()
              append({})
            }}
            freeSolo
            sx={{
              width: '98%',
              height: '48px',
            }}
            control={control}
            name={name}
            {...props}
          />
        </Box>
        {watch(name) && (
          <Box
            sx={{
              height: '55px',
              display: 'flex',
              alignItems: 'center',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            <ArrowForwardIosIcon />
          </Box>
        )}
        <Box
          sx={{
            width: '45%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '25px',
          }}
        >
          {watch(name) &&
            fields.map((field, i) => (
              <OptionsSelect
                control={control}
                name={`${optionsName}[${i}]`}
                options={removeSelectedOptions(
                  watch(name)?.content?.quiz[0].options
                )}
                key={field?.id}
                onRemove={() => {
                  if (fields.length > 1) {
                    remove(i)
                  }
                  append({})
                }}
                onChangeAction={(value) => {
                  if (
                    value &&
                    fields.length < watch(name)?.content?.quiz[0].options.length
                  ) {
                    append({ name: '' })
                  }
                  if (value) {
                    setValue(`${optionsName}[${i}]`, value)
                  }
                }}
              />
            ))}
        </Box>
      </Box>
    </Box>
  )
}
