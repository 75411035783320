import { ReactElement } from 'react'
import { Tooltip } from '@mui/material'
import { Box } from '@mui/system'

interface Props {
  children: ReactElement
  content: string
}
export const StyledTooltip = ({ children, content }: Props) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'transparent',
            '& .MuiTooltip-arrow': {
              color: 'common.black',
            },
          },
        },
      }}
      title={
        <Box
          sx={{
            py: '4px',
            px: '12px',
            color: '#FF5E1E',
            border: '1px solid #FF5E1E',
            bgcolor: 'rgba(255, 94, 30, 0.04);',
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
          }}
        >
          {content}
        </Box>
      }
      placement="top"
    >
      {children}
    </Tooltip>
  )
}
