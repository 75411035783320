import { Box, Link, Typography } from '@mui/material'
import { t } from 'i18next'
import { useContext } from 'react'

import { ActivityContext } from 'src/context/ActivityContext'
import version from '../../../package.json'

export function Copyright(props) {
  const { openDrawer } = useContext(ActivityContext)

  return (
    <Box
      sx={{
        textAlign: !openDrawer && props.open ? 'justify' : 'center',
        backgroundColor: '#EAEAEA',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          flexDirection: !openDrawer && props.open ? 'none' : 'column',
          flexWrap: !openDrawer && props.open ? 'none' : 'wrap',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16px',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        <Link
          sx={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.6)',
            ml: !openDrawer && props.open ? 1 : 0,
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '16px',
          }}
          target={'https://tecnoit.com.br/'}
          href="https://tecnoit.com.br/"
        ></Link>
        <b>Tecno it</b>
        {!openDrawer && props.open
          ? ` - itBuzz ${version.version}`
          : `\ itBuzz\ V ${version.version}`}
      </Typography>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16px',
          color: 'rgba(0, 0, 0, 0.6)',
          ml: 1,
          textAlign: 'justify',
        }}
      >
        {!openDrawer && props.open
          ? `${t('copyrigth.message')} © ${new Date().getFullYear()}`
          : `© ${new Date().getFullYear()}`}
      </Typography>
    </Box>
  )
}
