import { t } from 'i18next'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { Box, Button, Tooltip } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded'
import InputSelectRHF from 'src/components/RHF/InputSelectRHF'
import { useContext, useEffect, useState } from 'react'
import { AlertContext } from 'src/context/AlertContext'
import { getAccessPoints } from 'src/service/accesspointService'

export const PointSearch = ({ onCenterMap, watch, onClean, ...props }: any) => {
  const { createAlert } = useContext(AlertContext)
  const [points, setPoints] = useState([])

  const getAllPointsByCompanyId = async (companyId: number) => {
    const data = await getAccessPoints({
      params: { size: 100000000, companyId },
    })
    if (!data?.listAccessPoints) {
      createAlert('/', t('form.pointgroup.notify.no.points'))
    }
    setPoints(data?.listAccessPoints || [])
  }
  const hasPointSelected = watch('point')?.apName
  useEffect(() => {
    if (watch('companyId')?.id) {
      getAllPointsByCompanyId(watch('companyId')?.id)
    }
  }, [watch('companyId')?.id])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'noWrap',
        mt: '4px',
        '.MuiAutocomplete-clearIndicator': {
          display: 'none',
        },
        '&:hover': {
          '.MuiAutocomplete-clearIndicator': {
            display: 'none',
          },
        },
      }}
    >
      <InputSelectRHF
        label={t('point.search.pointName.placeholder')}
        placeholder={t('form.pointgroup.select.placeholder.points')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.apName === value.apName
        }
        forcePopupIcon={false}
        getOptionLabel={(value: any) => value?.apName || ''}
        freeSolo
        sx={{
          width: hasPointSelected ? '32%' : '35%',
          height: '48px',
          '.MuiFormHelperText-root.Mui-error': {
            m: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px 0 0 4px',
            borderWidth: '1px 0.5px 1px 1px',
          },
        }}
        {...props}
        options={points}
        renderOption={(props, option: any, { inputValue }) => {
          const matches = match(option.apName, inputValue, {
            insideWords: true,
          })
          const parts = parse(option.apName, matches)

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? '#FF5E1E' : '#000000DE',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          )
        }}
      />
      <InputSelectRHF
        label={t('form.pointgroup.select.label.address')}
        placeholder={t('form.pointgroup.select.placeholder.address')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.geoAddress === value.geoAddress
        }
        forcePopupIcon={false}
        getOptionLabel={(value: any) => value?.geoAddress || ''}
        freeSolo
        sx={{
          width: hasPointSelected ? '32%' : '35%',
          height: '48px',
          '.MuiFormHelperText-root.Mui-error': {
            m: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '0px',
            borderWidth: '1px 1px 1px 1px',
          },
        }}
        {...props}
        options={points}
        renderOption={(props, option: any, { inputValue }) => {
          const matches = match(option.geoAddress, inputValue, {
            insideWords: true,
          })
          const parts = parse(option.geoAddress, matches)

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? '#FF5E1E' : '#000000DE',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          )
        }}
      />
      <InputSelectRHF
        label={t('point.form.input.label.latitude')}
        placeholder={t('point.form.input.label.latitude')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.geoLatitude === value.geoLatitude
        }
        forcePopupIcon={false}
        getOptionLabel={(value: any) => value?.geoLatitude || ''}
        freeSolo
        sx={{
          width: '15%',
          height: '48px',
          '.MuiFormHelperText-root.Mui-error': {
            m: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '0px',
            borderWidth: '1px 1px 1px 1px',
          },
        }}
        {...props}
        options={points}
        renderOption={(props, option: any, { inputValue }) => {
          const matches = match(option.geoLatitude, inputValue, {
            insideWords: true,
          })
          const parts = parse(option.geoLatitude, matches)

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? '#FF5E1E' : '#000000DE',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          )
        }}
      />
      <InputSelectRHF
        label={t('point.form.input.label.longitude')}
        placeholder={t('point.form.input.label.longitude')}
        loading
        noOptionsText={t('noRowsLabel.general')}
        multiple={false}
        isOptionEqualToValueFn={(option: any, value: any) =>
          option.geoLongitude === value.geoLongitude
        }
        forcePopupIcon={false}
        getOptionLabel={(value: any) => value?.geoLongitude || ''}
        freeSolo
        sx={{
          width: '15%',
          height: '48px',
          '.MuiFormHelperText-root.Mui-error': {
            m: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '0px 4px  4px 0',
            borderWidth: '1px 1px 1px 1px',
          },
        }}
        {...props}
        options={points}
        renderOption={(props, option: any, { inputValue }) => {
          const matches = match(option.geoLongitude, inputValue, {
            insideWords: true,
          })
          const parts = parse(option.geoLongitude, matches)

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? '#FF5E1E' : '#000000DE',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          )
        }}
      />
      {hasPointSelected && (
        <Box sx={{ display: 'flex', ml: 0.5, mt: 1, width: '6%' }}>
          <Tooltip title={t('pointGroups.button.toLocate')}>
            <Button
              sx={{
                minWidth: '25px',
                color: '#00000099',
                ':hover': {
                  color: ' #FF5E1E',
                },
              }}
              onClick={() => onCenterMap()}
            >
              <GpsFixedRoundedIcon sx={{ width: '20px' }} />
            </Button>
          </Tooltip>

          <Tooltip title={t('table.button.delete')}>
            <Button
              sx={{
                minWidth: '25px',
                color: '#00000099',
                ':hover': {
                  color: ' #FF5E1E',
                },
              }}
              onClick={() => {
                onClean()
              }}
            >
              <DeleteForeverIcon sx={{ width: '20px' }} />
            </Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}
