import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Avatar, Badge, Box, Button, IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

import { goToUsers } from 'src/routes/coordinator'
import {
  roleTypes,
  userManagerDefaultValues,
  userManagerSchemaValidation,
} from '../../validators/userManagerSchemaValidators'
import InputRHF from 'src/components/RHF/InputRHF'
import SwitchRHF from 'src/components/RHF/SwitchRHF'
import InputPasswordRHF from 'src/components/RHF/InputRHFPassword'
import { createUser, editUser } from 'src/service/userAdminsService'
import { getRoles } from 'src/service/roleService'
import { getCompanies } from 'src/service/companyService'
import { Company } from 'src/models/company-model'
import { UserManager } from 'src/models/user-model'
import MenuItemMap from 'src/components/ArrayIterators/MenuItemMap'
import UploadFile from 'src/components/UploadFile/UploadFile'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { CompanySelect } from 'src/components/CompanySelect/CompanySelect'
import InputMaskRHF from 'src/components/RHF/InputMaskRHF'
import { FooterBottons } from 'src/components/FormUtils/FooterButtons'
import InputSelectRHF from 'src/components/RHF/InputSelectRHF'
import { uploadFileImg } from 'src/service/uploadservice'
import InputEmailRHF from 'src/components/RHF/InputEmailRHF'
import { getRole } from 'src/service/authService'
import { RoleSelect } from 'src/components/RoleSelect/RoleSelect'
import { Role } from 'src/models/role-model'

const mockLanguage = [
  { id: 1, name: 'Português (pt-BR)' },
  { id: 2, name: 'Inglês (EN)' },
  { id: 3, name: 'Espanhol (ES)' },
]

interface UserManagerFormProps {
  data: UserManager
  isViewMode?: boolean
}

export const UserManagerForm = ({ data, isViewMode }: UserManagerFormProps) => {
  const { t } = useTranslation()
  const history = useNavigate()
  const form = useForm<any>({
    mode: 'all',
    defaultValues: userManagerDefaultValues,
    resolver: yupResolver(userManagerSchemaValidation),
  })
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState,
    setValue,
    watch,
  } = form
  const { id } = useParams()
  const clearForm = () => {
    reset({ name: '' })
    setValue('defaultLanguage', mockLanguage[0])
  }
  const [companies, setCompanies] = useState<Company[] | null>([])
  const [companyId, setCompanyId] = useState('')
  const [roles, setRoles] = useState<Role[] | null>([])
  const [roleId, setRoleId] = useState('')
  const [isDuplicatedEmail, setIsDuplicatedEmail] = useState(false)
  const type = watch('role')
  const img = watch('profileImage')
  const { createCRUDAlert } = useContext(AlertContext)

  const onSubmit = async () => {
    const data = getValues()
    console.log(data)
    const validData = {
      ...data,
      companyId: data?.companyId?.id,
      password: data?.password === '******' ? undefined : data?.password,
      role: getRole() === 'TECNOIT' ? data?.role : 'ADMIN',
      defaultLanguage: data?.defaultLanguage?.name,
      roleId: data?.roleId?.id
    }
    if (id) {
      const res = await editUser(id, { ...validData, id: null })
      if (res) {
        createCRUDAlert(ROUTES.USERMANAGER, validData.name, 'edited')
        goToUsers(history)
      }

      return
    }
    const res = await createUser(validData)
    if (res) {
      createCRUDAlert(ROUTES.USERMANAGER, validData.name, 'created')
      goToUsers(history)
    } else {
      setIsDuplicatedEmail(true)
    }
  }

  const onReadFile = async (fileToUpload) => {
    try {
      const resp = await uploadFileImg(fileToUpload)
      setValue('profileImage', resp.data.file)
    } catch (error) {
      alert(error)
    }
  }

  const handleDownload = () => {
    if (isViewMode) {
      const link = document.createElement('a')
      link.download = img
      link.href = img
      link.click()
    }
  }

  const onChangeCompany = (company: Company) => {
    setValue('companyId', company)
  }

  const onChangeRole = (role: Role) => {
    setValue('roleId', role)
  }

  const getCompanyList = async () => {
    const data = await getCompanies({ params: { size: 100000000 } })
    setCompanies(data.listCompanies)
  }

  const getRoleList = async () => {
    const data = await getRoles({ params: { size: 100000000 } })
    setRoles(data.listRoles)
  }

  useEffect(() => {
    setValue('defaultLanguage', mockLanguage[0])
  }, [])

  useEffect(() => {
    if (getRole() === 'TECNOIT') {
      getCompanyList()
    }
  }, [])

  useEffect(() => {
    getRoleList()
  }, [])
  
  useEffect(() => {
    const company = companies?.find(({ id }) => id === companyId)
    if (company) {
      setValue('companyId', company)
    }
  }, [companyId, companies])

  useEffect(() => {
    const role = roles?.find(({ id }) => id === roleId)
    if (role) {
      setValue('roleId', role)
    }
  }, [roleId, roles])

  useEffect(() => {
    if (data) {
      const {
        companyId,
        name,
        phone,
        role,
        profileImage,
        email,
        defaultLanguage,
        isActive,
        roleId,
        id,
      } = data
      reset({
        companyId,
        name,
        phone,
        role,
        profileImage,
        email,
        defaultLanguage,
        isActive,
        password: '******',
        roleId,
        id,
      })
      setCompanyId(companyId)
      setRoleId(roleId)
      setValue(
        'defaultLanguage',
        mockLanguage.find(({ name }) => name === defaultLanguage) ||
        mockLanguage[0]
      )
    }
  }, [data])

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      autoComplete={'off'}
      component="form"
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        {getRole() === 'TECNOIT' && (
          <InputRHF
            disabled={disableInput()}
            control={control}
            label={t('user.form.input.label.type')}
            name="role"
            select
            sx={{
              minHeight: '75px',
              gridColumn: 'span 11',
            }}
          >
            {roleTypes.map(MenuItemMap)}
          </InputRHF>
        )}
        {getRole() === 'TECNOIT' && type === 'ADMIN' && (
          <CompanySelect
            sx={{
              gridColumn: 'span 12',
            }}
            disabled={disableInput()}
            control={control}
            onChangeAction={onChangeCompany}
            label={t('user.form.input.label.name')}
          />
        )}

        <Box
          sx={{
            textAlign: 'center',
            gridColumn: {
              xs: 'span 12',
              sm: 'span 12',
              md: 'span 2',
              lg: 'span 2',
            },
          }}
        >
          <IconButton
            onClick={() => handleDownload()}
            color="primary"
            aria-label="upload picture"
            component="label"
            sx={{
              width: '80px',
              height: '80px',
              mt: 1,
              svg: {
                color: 'rgba(0, 0, 0, 0.38)',
              },
            }}
          >
            <UploadFile
              hidden
              accept="image/png, image/jpeg"
              type="file"
              onReadCallback={onReadFile}
            />
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <Button
                  onClick={() => setValue('profileImage', '')}
                  sx={{
                    borderRadius: '4px',
                    padding: '0px',
                    minWidth: '32px',
                    minHeight: '32px',
                    backgroundColor: '#fff',
                    p: img ? '3px' : '0px',
                    '.css-ccwtf3-MuiButtonBase-root-MuiIconButton-root': {
                      p: '3px',
                    },
                    ':hover': {
                      backgroundColor: '#fff',
                    },

                    svg: {
                      backgroundColor: '#fff',
                      borderRadius: '4px',
                      border: '1px solid rgba(0, 0, 0, 0.6)',
                      color: 'rgba(0, 0, 0, 0.6);',
                      minWidth: '28px',
                      minHeight: '28px',
                      padding: '3px',
                      ':hover': {
                        backgroundColor: '#fff',
                        color: '#ff5e1e',
                      },
                    },
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      display: img ? 'block' : 'none',
                    }}
                  />

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <UploadFile
                      hidden
                      accept="image/png, image/jpeg"
                      type="file"
                      onReadCallback={onReadFile}
                    />
                    <AddAPhotoIcon
                      sx={{
                        display: img ? 'none' : 'block',
                        // padding: '0px',
                      }}
                    />
                  </IconButton>
                </Button>
              }
            >
              <Avatar src={img} sx={{ width: '80px', height: '80px' }} />
            </Badge>
          </IconButton>
        </Box>

        <Box
          sx={{
            minHeight: '75px',
            display: 'grid',
            gridTemplateColumns: 'repeat(10, 1fr)',
            rowGap: 2,
            columnGap: 2,
            gridColumn: {
              xs: 'span 12',
              sm: 'span 6',
              md: 'span 10',
              lg: 'span 10',
            },
          }}
        >
          <InputRHF
            disabled={disableInput()}
            control={control}
            label={t('user.form.input.label.user')}
            name="name"
            placeholder={t('user.form.input.placeholder.user')}
            sx={{
              minHeight: '75px',
              gridColumn: 'span 9',
            }}
          />
          <SwitchRHF
            sx={{
              minHeight: '75px',
              gridColumn: 'span 1',
            }}
            disabled={disableInput()}
            name="isActive"
            control={control}
            onChangeAction={() => setValue('isActive', '')}
            labelNameOn="form.input.label.active.o"
            labelNameOff="form.input.label.inactive.o"
          />
          <InputSelectRHF
            disabled={disableInput()}
            options={mockLanguage}
            control={control}
            label={t('user.form.input.label.language')}
            name="defaultLanguage"
            sx={{
              minHeight: '75px',
              gridColumn: {
                xs: 'span 10',
                sm: 'span 5',
                md: 'span 5',
                lg: 'span 5',
              },
            }}
            isOptionEqualToValueFn={({ name }, { name: name2 }) =>
              name === name2
            }
            getOptionLabel={(option) => option?.name || ''}
          />

          <InputMaskRHF
            disabled={disableInput()}
            control={control}
            label={t('user.form.input.label.phone')}
            name="phone"
            placeholder={t('user.form.input.placeholder.phone')}
            mask="(99) 9 99999999"
            sx={{
              minHeight: '75px',
              gridColumn: {
                xs: 'span 10',
                sm: 'span 5',
                md: 'span 5',
                lg: 'span 5',
              },
            }}
          />

          <InputEmailRHF
            disabled={disableInput()}
            control={control}
            label="E-MAIL"
            name="email"
            placeholder={t('user.form.input.placeholder.email')}
            isDuplicatedEmail={isDuplicatedEmail}
            sx={{
              minHeight: '75px',
              gridColumn: {
                xs: 'span 10',
                sm: 'span 5',
                md: 'span 5',
                lg: 'span 5',
              },
            }}
          />

          <InputPasswordRHF
            disabled={disableInput()}
            name={'password'}
            autoComplete={'off'}
            label={t('user.form.input.label.password')}
            placeholder={t('user.form.input.placeholder.password')}
            control={control}
            sx={{
              minHeight: '75px',
              mt: 1,
              gridColumn: {
                xs: 'span 10',
                sm: 'span 5',
                md: 'span 5',
                lg: 'span 5',
              },
            }}
          />
          <RoleSelect
            sx={{
              gridColumn: 'span 10',
              mb: isViewMode ? '25px' : 0
            }}
            disabled={disableInput()}
            control={control}
            onChangeAction={onChangeRole}
            label={t('user.form.input.label.role')}
          />
        </Box>
      </Box>

      {!isViewMode && (
        <FooterBottons
          formIsValid={formState.isValid}
          isEdit={Boolean(id)}
          goTo={goToUsers}
          reset={clearForm}
          size="100%"
          buttonName="form.button.user"
        />
      )}
    </Box>
  )
}
