import AppsIcon from '@mui/icons-material/Apps'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import { Button, Chip, IconButton, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledTooltip } from 'src/components/Tooltip/StyledTooltip'
import { AlertContext } from 'src/context/AlertContext'
import { DashboardViewContext } from 'src/context/DashboardViewContext'
import { getPermissions } from 'src/service/authService'

interface Props {
  onPeriodChange?: (
    periodValue: any,
    periodName: any,
    initialDate: any,
    finalDate: any
  ) => void
  onReportDownload?: (periodValue: any, format: string) => Promise<any>
  onReportVisitorsDownload?: (period, format: string) => Promise<any>
  onEdit?: (editState: boolean) => void
  onChangeDateInitial?: (initialDate: any) => void
  onChangeDateFinal?: (initialDate: any) => void
}

const DashboardHeader = ({
  onPeriodChange,
  onReportDownload,
  onReportVisitorsDownload,
  onChangeDateInitial,
  onChangeDateFinal,
}: Props) => {
  const { t } = useTranslation()

  const periodOptions = [
    { value: '1d', name: t('date.label.today') },
    { value: '7d', name: `7 ${t('date.label.days')}` },
    { value: '30d', name: `30 ${t('date.label.days')}` },
    { value: '90d', name: t('date.label.quarter') },
    { value: '365d', name: t('date.label.year') },
    { value: 'custom', name: t('date.label.all') },
  ]
  const [period, setPeriod] = useState(periodOptions[0])
  const [initialDate, setInitialDate] = useState<string>(
    new Date().toDateString()
  )
  const [reportType, setReportType] = useState<'userLogin' | 'userData' | null>(
    null
  )
  const reportFormat = ['.csv', '.txt', '.xml', '.xlsx', '.pdf']
  const [finalDate, setFinalDate] = useState<string>(new Date().toDateString())
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const { isEditMode, handleToggleEdit, writeResult } =
    useContext(DashboardViewContext)
  const { createAlert } = useContext(AlertContext)
  const permissions = JSON.parse(getPermissions())

  const isDate1AfterDate2 = (date1, date2) => {
    return date1 > date2
  }
  const handleOpen = () => {
    setOpen(!open)
  }
  const handleDownload = async (format) => {
    if (reportType === 'userLogin') {
      await onReportDownload(period, format)
      return
    }

    await onReportVisitorsDownload(period, format)
  }

  return (
    <Box
      onClick={isEditMode ? handleToggleEdit : null}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '950px',
        width: '100%',
        justifyContent: 'center',
        gap: '20px',
        pr: 1,
        mt: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#FF5E1E',
        }}
      >
        <AppsIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            ml: '8px',
            mb: '-3px',
          }}
        >
          {t('painel.title')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: '400',
                fontSize: '34px',
                lineHeight: '160%',
                letterSpacing: ' 0.15px',
                width: '130px',
              }}
            >
              {period?.name}
            </Typography>
          </Box>
          <Box sx={{ ml: '80px' }}>
            {periodOptions.map(({ name, value }) => (
              <Chip
                key={value}
                sx={{
                  cursor: 'pointer',
                  ':hover': {
                    border: '1px solid #00000042',
                    color: '#00000099',
                    bgcolor: ' rgba(255, 94, 30, 0.08);',
                  },
                  borderColor: period.value === value ? '#FF5E1E' : '',
                  color: period.value === value ? '#FF5E1E' : '',
                  bgcolor:
                    period.value === value ? 'rgba(255, 94, 30, 0.08);' : '',
                  mr: '10px',
                }}
                onClick={() => {
                  /* Data Inicial */
                  let initialDate = new Date()
                  let endDate = new Date()
                  let endDateFormated =
                    ('0' + (initialDate.getMonth() + 1)).slice(-2) +
                    '/' +
                    ('0' + initialDate.getDate()).slice(-2) +
                    '/' +
                    initialDate.getUTCFullYear()
                  /* Data Final */
                  if (value === '1d') {
                    endDate = new Date(
                      new Date().setDate(initialDate.getDate() - 1)
                    )
                  } else if (value === '7d') {
                    endDate = new Date(
                      new Date().setDate(initialDate.getDate() - 7)
                    )
                  } else if (value === '30d') {
                    endDate = new Date(
                      new Date().setDate(initialDate.getDate() - 30)
                    )
                  } else if (value === '90d') {
                    endDate = new Date(
                      new Date().setDate(initialDate.getDate() - 90)
                    )
                  } else if (value === '365d') {
                    endDate = new Date(
                      new Date().setDate(initialDate.getDate() - 365)
                    )
                  } else {
                    endDate = new Date(
                      new Date().setDate(initialDate.getDate() - 1000)
                    )
                  }
                  let initialDateFormated =
                    ('0' + (endDate.getMonth() + 1)).slice(-2) +
                    '/' +
                    ('0' + endDate.getDate()).slice(-2) +
                    '/' +
                    endDate.getUTCFullYear()
                  setInitialDate(initialDateFormated)
                  setFinalDate(endDateFormated)
                  setPeriod({ value, name })
                  onPeriodChange &&
                    onPeriodChange(
                      value,
                      name,
                      initialDateFormated,
                      endDateFormated
                    )
                }}
                variant="outlined"
                label={name}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Box sx={{ height: '54px', display: 'flex', alignItems: 'flex-end' }}>
            {isEditMode && (
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => {
                  if (writeResult() === false) {
                    createAlert(
                      '/',
                      t('dasboard.card.notpossible.hide.cards'),
                      'error'
                    )
                    return
                  }
                  handleToggleEdit()
                }}
              >
                OK
              </Button>
            )}
          </Box>

          <Box
            sx={{
              '.btns-drop': {
                border: 'none',
                fontSize: '14px',
                fontWeight: '400',
                fontFamily: 'roboto',
                background: 'white',
                padding: '8px 16px',
                margin: '0 0 8px 0',
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left',
                '&:hover': {
                  backgroundColor: 'rgba(255, 94, 30, 0.04)',
                  color: '#FF5E1E',
                },
              },
            }}
          >
            <StyledTooltip content={t('dashboard.button.help')}>
              <Button
                onClick={() => {
                  handleToggleEdit()
                  setOpen(false)
                }}
                variant="outlined"
                sx={{
                  borderRadius: '4px 0px 0px 4px',
                  minWidth: '48px',
                  width: '48px',
                  height: '48px',
                  color: isEditMode ? '#FF5E1E' : 'rgba(0, 0, 0, 0.6)',
                  borderColor: isEditMode ? '#FF5E1E' : 'rgba(0, 0, 0, 0.12)',
                  backgroundColor: isEditMode ? 'rgba(255, 94, 30, 0.08);' : '',
                  ':hover': {
                    color: '#FF5E1E',
                  },
                }}
              >
                <RemoveRedEyeOutlinedIcon />
              </Button>
            </StyledTooltip>

            <StyledTooltip content={t('dashboard.button.help.download')}>
              <Button
                disabled={!permissions?.reports?.includes("reports:dashboard")}
                style={
                  open ? { color: '#FF5E1E', border: '1px solid #FF5E1E' } : {}
                }
                onClick={handleOpen}
                variant="outlined"
                sx={{
                  borderRadius: '0px 4px 4px 0px',
                  borderLeft: 'none',
                  minWidth: '48px',
                  width: '48px',
                  height: '48px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                  ':hover': {
                    color: '#FF5E1E',
                  },
                }}
              >
                {loading ? (
                  <HourglassBottomRoundedIcon />
                ) : (
                  <FileDownloadOutlinedIcon />
                )}
              </Button>
            </StyledTooltip>
            <div
              style={{
                position: 'relative',
              }}
            >
              {open && (
                <ul
                  style={{
                    position: 'absolute',
                    listStyleType: 'none',
                    margin: '5px 0',
                    padding: '0',
                    width: '180px',
                    boxShadow:
                      '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
                    borderRadius: 4,
                    right: '0px',
                    backgroundColor: '#ffff',
                    zIndex: 2,
                  }}
                >
                  <li
                    style={{
                      padding: ' 0px 16px',
                      marginBottom: '8px',
                      backgroundColor: 'white',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                      borderBottomColor: 'rgba(0, 0, 0, 0.12)',
                      marginTop: '15px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          width: '80%',
                        }}
                      >
                        <button
                          style={{
                            height: '100%',
                            textAlign: 'left',
                            background: 'none',
                            color: 'inherit',
                            border: 'none',
                            paddingLeft: 0,
                            paddingRight: 0,
                            margin: '0',
                            fontSize: 16,
                            fontWeight: 400,
                            fontFamily: 'roboto',
                            borderRadius: 4,
                          }}
                        >
                          {t('dashboard.export.title')}
                          {reportType && (
                            <p
                              style={{
                                width: '136px',
                                fontSize: 14,
                                height: 10,
                                color: 'rgba(0, 0, 0, 0.6)',
                              }}
                            >
                              {t(`dashboard.export.${reportType}`)}
                            </p>
                          )}
                        </button>
                      </div>
                      <div
                        style={{
                          width: '20%',
                        }}
                      >
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            handleOpen()
                            setReportType(null)
                          }}
                          sx={{
                            mb: reportType ? 5 : 0,
                            color: '#00000099',
                            ':hover': {
                              color: '#FF5E1E',
                              backgroundColor: 'rgba(255, 94, 30, 0.04)',
                            },
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </div>
                  </li>
                  {!reportType && (
                    <>
                      <li
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <button
                          disabled={!permissions?.reports?.includes("reports:visitors")}
                          className="btns-drop"
                          onClick={async () => {
                            setReportType('userLogin')
                          }}
                        >
                          {t('dashboard.export.userLogin')}
                        </button>
                      </li>
                      <li style={{ cursor: 'pointer' }}>
                        <button
                          disabled={!permissions?.reports?.includes("reports:visitors")}
                          className="btns-drop"
                          onClick={async () => {
                            setReportType('userData')
                          }}
                        >
                          {t('dashboard.export.userData')}
                        </button>
                      </li>
                    </>
                  )}
                  {reportType && (
                    <>
                      {reportFormat?.map((format) => (
                        <Box
                          component={'li'}
                          sx={{
                            cursor: 'pointer',
                            p: 0,
                            ':hover': {
                              color: '#FF5E1E',
                              backgroundColor: 'rgba(255, 94, 30, 0.04)',
                            },
                          }}
                          key={format}
                        >
                          <Box
                            sx={{
                              ml: 1,
                              p: 1,
                            }}
                            onClick={async () => {
                              setLoading(true)
                              await handleDownload(format)
                              setLoading(false)
                              setReportType(null)
                            }}
                          >
                            {format.toUpperCase()}
                          </Box>
                        </Box>
                      ))}
                    </>
                  )}
                </ul>
              )}
            </div>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mt: 2.5, gap: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t('date.initial')}
            value={initialDate}
            onChange={(dtInitial) => {
              let newInitialDate = new Date(dtInitial)
              let initialDateFormated =
                ('0' + (newInitialDate.getMonth() + 1)).slice(-2) +
                '/' +
                ('0' + newInitialDate.getDate()).slice(-2) +
                '/' +
                newInitialDate.getUTCFullYear()
              setInitialDate(initialDateFormated)
              onChangeDateInitial(initialDateFormated)
            }}
            inputFormat="DD/MM/YYYY"
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: 305,
                }}
              />
            )}
          />
          <DatePicker
            label={t('date.final')}
            value={finalDate}
            onChange={(dtFinal) => {
              let newFinalDate = new Date(dtFinal)
              let finalDateFormated =
                ('0' + (newFinalDate.getMonth() + 1)).slice(-2) +
                '/' +
                ('0' + newFinalDate.getDate()).slice(-2) +
                '/' +
                newFinalDate.getUTCFullYear()
              setFinalDate(finalDateFormated)
              onChangeDateFinal(finalDateFormated)
            }}
            inputFormat="DD/MM/YYYY"
            renderInput={(params) => (
              <TextField {...params} sx={{ width: 305 }} />
            )}
            shouldDisableDate={(date) => {
              // @ts-ignore
              const date1 = date?.$d
              return date1 < new Date(initialDate)
            }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  )
}

export default DashboardHeader
