import {
  Chart as ChartJS,
  ArcElement,
  PointElement,
  Tooltip,
  ChartOptions,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, PointElement, Tooltip)
interface Props {
  labels: string[]
  data: number[]
}

export const DoughnutChartColors = [
  '#BF3C08',
  '#FF5E1E',
  '#FF8D64',
  '#6F6F6F',
  '#1D1D1D',
]
export default function DoughnutChart2({ labels, data }: Props) {
  const dados = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: DoughnutChartColors,
        hoverBackgroundColor: DoughnutChartColors,
      },
    ],
  }

  const options: ChartOptions<'doughnut'> = {
    cutout: 68,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 100,
          padding: 20,
          usePointStyle: true,
          boxHeight: 10,
        },
      },
    },
  }

  return (
    <div>
      <Doughnut
        data={dados}
        options={options}
        style={{
          width: 320,
          height: 200,
          marginTop: '-75px',
          marginLeft: '-20px',
        }}
      />
    </div>
  )
}
