import { Campaign } from 'src/models/campaigns-model/campaign-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const getCampaigns = async (options?: any) => {
  try {
    const res = await client().get(`${BASE_URL}/campaign`, options)
    return res.data
  } catch (error) {}
}

export const getCampaignById = async (id: string): Promise<any | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/campaign/${id}`)
    return res.data
  } catch (error) {}
}

export const createCampaign = async (
  data: any
): Promise<Campaign | undefined> => {
  try {
    const res = await client().post(`${BASE_URL}/campaign`, data)
    return res.data
  } catch (error) {}
}

export const editCampaign = async (id: string, data: any): Promise<boolean> => {
  try {
    const result = await client().patch(`${BASE_URL}/campaign/${id}`, data)
    return result.status === 200
  } catch (error) {
    return false
  }
}

export const deleteCampaign = async (id: string | number): Promise<boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/campaign/${id}`)
    return true
  } catch (error) {
    return false
  }
}

export const recycleCampaignById = async (id: string): Promise<Campaign | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/campaign/${id}/enable`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const unarchiveCampaignById = async (id: string): Promise<Campaign | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/campaign/${id}/unarchive`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const getSystemEnrichments = async (options?): Promise<any> => {
  try {
    const res = await client().get(`${BASE_URL}/system/enrichment`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getCampaignResults = async (
  campaignId?: string,
  contentType?: string
): Promise<any> => {
  try {
    if (campaignId !== undefined) {
      const res = await client().post(
        `${BASE_URL}/reports/campaignsanalysis/${campaignId}`,
        {}
      )
      return res.data
    } else if (campaignId !== undefined && contentType !== undefined) {
      const res = await client().post(
        `${BASE_URL}/reports/campaignsanalysis/${campaignId}/${contentType}`,
        {}
      )
      return res.data
    } else {
      const res = await client().post(
        `${BASE_URL}/reports/campaignsanalysis/`,
        {}
      )
      return res.data
    }
  } catch (error) {
    return false
  }
}

export const disableDefaultCampaigns = async (companyId: string) => {
  await client().patch(`${BASE_URL}/has_active/campaign/${companyId}`, {})
}

export const getDefaultCampaign = async (companyId: string): Promise<any> => {
  try {
    const res = await client().get(
      `${BASE_URL}/has_active/campaign/${companyId}`
    )
    return res.data
  } catch (error) {
    console.log(error)
  }
}
