import { yupResolver } from '@hookform/resolvers/yup'
import { t } from 'i18next'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CampaignFormDefaultValues,
  CampaignFormResolver,
} from 'src/pages/Campaigns/components/Form/FormValidator'
import { goToCampaigns } from 'src/routes/coordinator'
import { ROUTES } from 'src/routes/Router'
import {
  getPayloadForImage,
  getPayloadForMedia,
  getPayloadForSearch,
  getPayloadForText,
} from 'src/service/campaignFormService'
import { createCampaign, editCampaign } from 'src/service/marketingCampaignService'
import { disableDefaultCampaigns } from '../service/marketingCampaignService'
import { AlertContext } from './AlertContext'

const defaultState = {
  contentType: null,
  setContentType: (value: contentTypeEnum) => {},
  formState: null,
  setFormState: (value) => {},
  handleSubmit: () => {},
  setCountContent: (value: number) => {},
  countContent: null,
}

interface ContextProviderProps {
  children: ReactNode
}

export enum contentTypeEnum {
  TEXT = 'text',
  MEDIA = 'media',
  SEARCH = 'quiz',
  IMAGE = 'image',
  NONE = '',
}
const payloadGetterForType = {
  [contentTypeEnum.IMAGE]: getPayloadForImage,
  [contentTypeEnum.TEXT]: getPayloadForText,
  [contentTypeEnum.MEDIA]: getPayloadForMedia,
  [contentTypeEnum.SEARCH]: getPayloadForSearch,
}

export const CampaignContext = createContext(defaultState)

function CampaignProvider({ children }: ContextProviderProps) {
  const [contentType, setContentType] = useState<contentTypeEnum>()
  const [countContent, setCountContent] = useState<number>(0)
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)
  const { id } = useParams()
  const isEdit = Boolean(id)

  const { ...form } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(CampaignFormResolver),
    defaultValues: CampaignFormDefaultValues,
  })

  const [formState, setFormState] = useState<any>(form)

  const handleSubmit = async () => {
    const payload = payloadGetterForType[contentType](formState?.getValues())
    const isDefault = payload?.defaultCampaign
    const titlePrefix = `${t('alert.campaign.form.campaign.with')} ${t(
      `table.campanha.${contentType}`
    )}`
    if (isDefault) {
      await disableDefaultCampaigns(payload?.companyId)
    }

    if (isEdit) {
      const result = await editCampaign(id, payload)

      if (result) {
        createCRUDAlert(
          ROUTES.CAMPAIGNS,
          `${titlePrefix} "${payload?.name}"`,
          'edited',
          false
        )
        goToCampaigns(history)
      }
      return
    }

    const result = await createCampaign(payload)
    if (result) {
      createCRUDAlert(
        ROUTES.CAMPAIGNS,
        `${titlePrefix} "${payload?.name}"`,
        'created'
      )
      goToCampaigns(history)
    }
  }

  useEffect(() => {
    if (contentType) {
      formState?.setValue('contentType', contentType)
      /* Calculando tempo de visualização */
      let viewTime = countContent
      formState?.setValue('countContent', viewTime)
    }
  }, [contentType, countContent])

  return (
    <CampaignContext.Provider
      value={{
        contentType,
        setContentType,
        formState,
        setFormState,
        handleSubmit,
        countContent,
        setCountContent,
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

export default CampaignProvider
