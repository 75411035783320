import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { GridColumns } from '@mui/x-data-grid'
import { Chip } from '@mui/material'

import { deleteUserById, editUser, getUsers, recycleUserById } from 'src/service/userAdminsService'
import { getPermissions } from 'src/service/authService'
import { Table } from 'src/components/Table/Table'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { goToEditUser } from 'src/routes/coordinator'
import CreaterUserManager from '../../CreateUserManager'

export default function UserTable() {
  const { t } = useTranslation()
  const [tableKey, setTableKey] = useState(0)
  const { createCRUDAlert } = useContext(AlertContext)
  const permissions = JSON.parse(getPermissions())

  const getData = async (options: any) => {
    const data = await getUsers(options)
    return { data: data.listAdmin, totalPages: data.page, size: data?.size }
  }

  const handleDelete = async (id, name) => {
    const res = await deleteUserById(id)
    if (res) createCRUDAlert(ROUTES.USERMANAGER, name, 'deleted')
    return
  }

  const handleRecycle = async (id, name) => {
    const res = await recycleUserById(id)
    if (res) {
      createCRUDAlert(ROUTES.USERMANAGER, name, 'edited')
    }
  }

  // const disableUser = async (data) => {
  //   const validData = {
  //     ...data,
  //     isActive: !data.isActive,
  //   }
  //   const result = await editUser(data.id, validData)

  //   setTableKey(tableKey + 1)
  //   if (result) createCRUDAlert(ROUTES.USERMANAGER, data.name, 'edited')
  //   return
  // }

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: t('table.header.managerName'),
      width: 125,
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'isActive',
      headerName: t('table.header.situation'),
      type: 'boolean',
      editable: false,
      renderCell: ({ row }) => {
        return row.isActive ? (
          <Chip
            sx={{
              background: 'rgba(255, 94, 30, 0.12)',
            }}
            size="small"
            label={t('table.status.active')}
            // clickable={true}
            // onClick={ () =>
            //   disableUser(row)
            // }
          />
        ) : (
          <Chip
            size="small"
            label={t('table.status.inactive')}
            // clickable={true}
            // onClick={ () =>
            //   disableUser(row)
            // }
          />
        )
      },
    },
    {
      field: 'companyName',
      headerName: t('table.header.company'),
      width: 240,
      editable: false,
      valueGetter: (params) => {
        if (params.row.role === 'TECNOIT') return 'Tecno it'
        return params.row.company?.companyName
          ? params.row.company?.companyName
          : ''
      },
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 230,
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
  ]

  return (
    <Box>
      <Table
        columns={columns}
        getData={getData}
        key={tableKey}
        handleDelete={handleDelete}
        titleKey="name"
        goTo={goToEditUser}
        defaultPageSize={15}
        model={'user'}
        Children={CreaterUserManager}
        size={'24px'}
        mr="25px"
        permissionUpdate={!permissions?.admin?.includes("admin:update")}
        permissionDelete={!permissions?.admin?.includes("admin:delete")}
        permissionView={!permissions?.admin?.includes("admin:view")}
        canShowRecycleButtons={true}
        permissionViewRecycle={permissions?.admin?.includes("admin:recycle:view")}
        permissionUpdateRecycle={permissions?.admin?.includes("admin:recycle:update")}
        handleRecycle={handleRecycle}
        canShowUnarchiveButtons={false}
      />
    </Box>
  )
}
