import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined'

import {
  pointAddDefaultValues,
  pointGroupsDefaultValues,
  pointGroupsSchemaValidation,
} from '../../validators/pointGroupsSchemaValidators'
import InputRHF from 'src/components/RHF/InputRHF'
import SwitchRHF from 'src/components/RHF/SwitchRHF'
import { getCompanyId, getPermissions, getRole } from 'src/service/authService'
import { CompanySelect } from 'src/components/CompanySelect/CompanySelect'
import { FooterBottons } from 'src/components/FormUtils/FooterButtons'
import { goToPointGroups } from 'src/routes/coordinator'
import MapComponet from '../../../../components/Map/RenderPointsMap'
import { getAccessPoints } from 'src/service/accesspointService'
import { AlertContext } from 'src/context/AlertContext'
import { OptionsPointSelect } from './OptionsPointSelect'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'
import { Company } from 'src/models/company-model'

interface PointGroupsFormProps {
  data: any
  onSubmit: (data: any) => void
  isViewMode?: boolean
}

export const PointGroupsForm = ({ data, onSubmit, isViewMode }: PointGroupsFormProps) => {
  const { t } = useTranslation()
  const { isGlobalAdminSelected, selectedCompanyData } = useGlobalSelectedCompany()
  const form = useForm<any>({
    mode: 'all',
    defaultValues: pointGroupsDefaultValues,
    resolver: yupResolver(pointGroupsSchemaValidation),
  })

  const {
    control,
    reset,
    formState,
    setValue,
    trigger,
    watch,
    handleSubmit,
    getValues,

  } = form

  const { id } = useParams()
  const { createAlert } = useContext(AlertContext)
  const [points, setPoints] = useState([])
  const [pointsLatLng, setPointsLatLng] = useState([])
  const [center, setCenter] = useState<any>()
  const [point, setPoint] = useState<any>()

  const permissions = JSON.parse(getPermissions())
  const hasArchiveUpdatePermission = permissions?.groups.includes('groups:archive:update')

  const { fields, append, remove } = useFieldArray({
    name: 'apList',
    control,
  })

  const removeSelectedOptions = (options) => {
    return points.filter(
      (point) => !options.find((option) => option.id === point.id)
    )
  }

  useEffect(() => {
    if (getRole() !== 'TECNOIT') {
      setValue('companyId', { id: getCompanyId() })
    }

    if (data) {
      reset({
        ...data,
        companyId: {
          companyName: data.company.companyName,
          id: data.companyId,
        },
        apList: data.accessPoint,
      })

      setPoints(data.accessPoint)
      const LatLngList = getValues('apList').map(
        (point) =>
          point?.geoLatitude &&
          new google.maps.LatLng(
            Number(point.geoLatitude),
            Number(point.geoLongitude)
          )
      )
      setPointsLatLng(LatLngList?.filter((point) => point) || [])
    }
  }, [data])

  useEffect(() => {
    if (watch('companyId')?.id) {
      getAllPointsByCompanyId(watch('companyId')?.id)
    } else {
      setPoints([])
    }
  }, [watch('companyId')?.id])

  useEffect(() => {
    getAllPointsByCompanyId(watch('companyId')?.id)
  }, [])

  useEffect(() => {
    if (!isGlobalAdminSelected()) {
      onChangeCompany(selectedCompanyData as Company)
    }
    if(!isGlobalAdminSelected && !selectedCompanyData) {
      setValue('companyId', undefined)
    }
  }, [selectedCompanyData])

  useEffect(() => {
    if (fields.length === 0) append({})
  }, [fields, watch, append])

  const getAllPointsByCompanyId = async (companyId: number) => {
    const data = await getAccessPoints({
      params: { size: 100000000, companyId },
    })

    if (!data?.listAccessPoints) {
      createAlert('/', t('form.pointgroup.notify.no.points'), 'info')
    }

    setPoints(data?.listAccessPoints || [])
  }

  const onCenter = (index) => {
    const selectedPoint = getValues(`apList[${index}]`)
    // Seta um center aleatorio, para atualizar o mapa
    setCenter(new google.maps.LatLng(666, 0))
    setPoint(selectedPoint)
  }

  useEffect(() => {
    // Identifica o center aleatorio e seta o center correto
    if (center?.lat() === 90 && point?.geoLatitude) {
      setCenter(new google.maps.LatLng(point?.geoLatitude, point?.geoLongitude))
    }
  }, [center, point])

  const onChangePoints = (value: any) => {
    trigger('apList')
    const LatLngList = getValues('apList').map(
      (point) =>
        point?.geoLatitude &&
        new google.maps.LatLng(
          Number(point.geoLatitude),
          Number(point.geoLongitude)
        )
    )
    setPointsLatLng(LatLngList?.filter((point) => point) || [])
  }

  const onChangeCompany = (company: Company) => {
    setValue('companyId', company)
    setValue('apList', [])
    setPoints([])
  }

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  console.log('### > FORMSTATE', formState)
  console.log('## > FormState.IsValid', formState.isValid)
  console.log(formState.errors)

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        {getRole() === 'TECNOIT' && (
          <CompanySelect
            sx={{
              gridColumn: 'span 12',
            }}
            disabled={disableInput()}
            control={control}
            onChangeAction={onChangeCompany}
          />
        )}

        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('pointGroups.form.input.label.name')}
          placeholder={t('pointGroups.form.input.placeholder.name')}
          name="groupName"
          sx={{
            gridColumn: hasArchiveUpdatePermission ? 'span 10' : 'span 11',
          }}
        />
        {
          hasArchiveUpdatePermission &&
          <SwitchRHF
            disabled={disableInput()}
            name='isArchived'
            control={control}
            onChangeAction={() => {
              trigger && trigger('isArchived')
              setValue && setValue('isArchived', '')
            }}
            labelNameOn='form.input.label.archived.o'
            labelNameOff='form.input.label.published.o'
          />
        }
        <SwitchRHF
          sx={{
            gridColumn: 'span 1',
          }}
          disabled={disableInput()}
          name="isActive"
          control={control}
          onChangeAction={() => setValue('isActive', '')}
          labelNameOn="form.input.label.active.o"
          labelNameOff="form.input.label.inactive.o"
        />

        <Box
          sx={{
            overflow: isViewMode ? 'auto' : '',
            height: isViewMode ? '20vh' : '',
            gridColumn: 'span 12',
          }}
        >
          {watch('apList') &&
            fields.map((field, i) => (
              <OptionsPointSelect
                isViewMode={isViewMode}
                disabled={disableInput()}
                key={field?.id}
                onCenterMap={onCenter}
                watch={watch}
                index={i}
                control={control}
                name={`apList[${i}]`}
                onChangeApList={onChangePoints}
                options={removeSelectedOptions(watch('apList'))}
                onChangeAction={(value) => {
                  if (value && fields.length < watch('apList').length) {
                    append({})
                  }
                  if (value) {
                    setValue(`${'apList'}[${i}]`, value)
                    const latlng = new google.maps.LatLng(
                      value?.geoLatitude,
                      value?.geoLongitude
                    )
                    setCenter(latlng)
                  }
                }}
                onRemove={() => {
                  if (fields.length > 0) {
                    remove(i)
                    onChangePoints(i)
                  }
                }}
              />
            ))}
        </Box>

        <Button
          disabled={disableInput()}
          onClick={() => append(pointAddDefaultValues)}
          variant="outlined"
          sx={{
            mt: isViewMode ? '0px' : 2,
            p: 0,
            height: '56px',
            borderRadius: '4px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            color: '#00000099',
            gridColumn: 'span 12',
            ':hover': {
              color: '#FF5E1E',
            },
          }}
        >
          <AddLocationOutlinedIcon
            sx={{
              height: '24px',
              minWidth: '24px',
            }}
          />
        </Button>

        <Box sx={{ gridColumn: 'span 12', mt: 1 }}>
          <MapComponet
            markerPoints={pointsLatLng}
            center={center}
            isViewMode={isViewMode}
          />
        </Box>
      </Box>
      {!isViewMode && (
        <FooterBottons
          isValid={!formState.isValid}
          formIsValid={formState.isValid}
          isEdit={Boolean(id)}
          goTo={goToPointGroups}
          reset={() => {
            setPointsLatLng([])
            if (getRole() !== 'TECNOIT') {
              reset({ companyId: { id: getCompanyId() } })
              return
            }
            reset()
          }}
          size="100%"
          buttonName="pointGroups.button.create"
          mb="16px"
        />
      )}
    </Box>
  )
}
