export const NewUserIcon = () => {
  return (
    <svg
      width="42"
      height="31"
      viewBox="0 0 42 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.909 8.10281C22.909 4.01556 19.4918 0.705078 15.2727 0.705078C11.0536 0.705078 7.6363 4.01556 7.6363 8.10281C7.6363 12.1901 11.0536 15.5005 15.2727 15.5005C19.4918 15.5005 22.909 12.1901 22.909 8.10281ZM-6.10352e-05 26.5971V30.296H30.5454V26.5971C30.5454 21.6776 20.3699 19.1994 15.2727 19.1994C10.1754 19.1994 -6.10352e-05 21.6776 -6.10352e-05 26.5971ZM36.2727 21.0488V15.5005H41.9999V11.8017H36.2727V6.25337H32.4545V11.8017H26.7272V15.5005H32.4545V21.0488H36.2727Z"
        fill="#FF5E1E"
      />
    </svg>
  )
}
