import { useEffect, useState } from 'react'
import { Box } from '@mui/system'

export const ColorPicker = ({ onColorChange, defaultColor, name, value, disabled }) => {
  const [color, setColor] = useState(defaultColor)

  useEffect(() => {
    onColorChange && onColorChange(color)
  }, [color])

  return (
    <>
      <Box
        component={'input'}
        name={name}
        sx={{
          width: '28px',
          height: '28px',
          m: 'none',
          p: 'none',
          borderRadius: '0px',
          backgroundColor: 'transparent',
          cursor: 'pointer',
          appearance: 'none',
          '-webkit-appearance': 'none',
          '-moz-appearance': 'none',
          border: 'none',
          ':focus': {
            outline: 'none',
          },
        }}
        type="color"
        value={value || defaultColor}
        onChange={({ target: { value } }) => setColor(value)}
        disabled={disabled}
      />
    </>
  )
}
