import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded'
import { Box, Container, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertComponent } from 'src/components/Alert/AlertComponent'

import { AlertContext } from 'src/context/AlertContext'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { VideoTraining } from 'src/models/training-video-model'
import { ROUTES } from 'src/routes/Router'
import { goToVideos } from 'src/routes/coordinator'
import { createTrainingVideo, editTrainingVideo, getTrainingVideoById } from 'src/service/trainingVideoService'
import { VideosForm } from './components/forms/VideosForm'

interface CreateVideoProps {
  videoId?: string
}

export function CreateVideo ({ videoId }: CreateVideoProps) {
  useProtectedPage()
  const history = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const { createCRUDAlert } = useContext(AlertContext)
  const [video, setVideo] = useState<VideoTraining>()

  const getTrainingVideo = async () => {
    if (id) {
      const data = await getTrainingVideoById(id || videoId)
      setVideo(data)
    }
  }

  const onSubmit = async (data: VideoTraining) => {
    if (data?.id) {
      const { title, description, videoUrl, category } = data
      const res = await editTrainingVideo(id, { ...data, title, description, videoUrl, category })
      if (res) {
        createCRUDAlert(ROUTES.TRAININGVIDEO, data.title, 'edited')
        goToVideos(history)
      }
      return
    }
    const res = await createTrainingVideo(data)
    if (res) {
      createCRUDAlert(ROUTES.TRAININGVIDEO, data.title, 'created')
      goToVideos(history)
    }
  }

  useEffect(() => {
    getTrainingVideo()
  }, [])

  return (
    <Container sx={{ mt: '96px' }}>
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: '24px',
        }}
      >
        <OndemandVideoRoundedIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
          }}
        >
          {t('training.video.title')}
        </Typography>
      </Box>
      <VideosForm
        data={video!}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
