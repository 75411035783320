import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'
import { Box, Container, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { Support } from 'src/models/support-model'
import { SupportForm } from './components/forms/SupportForm'

const SupportPage = () => {
  useProtectedPage()
  const { t } = useTranslation()
  const [support, setSupport] = useState<Support>()

  return (
    <Container
      sx={{
        mt: '96px',
      }}
    >
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '10px',
          justifyContent: 'flex-start',
          mb: '24px',
        }}
      >
        <ContactSupportOutlinedIcon
          sx={{
            position: 'relative',
            top: '3px',
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
          }}
        >
          {t('support.title')}
        </Typography>
      </Box>
      <SupportForm data={support!} />
    </Container>
  )
}
export default SupportPage
