import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Typography } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import AddIcon from '@mui/icons-material/Add'

import { goToCreateUser, goToRoles } from '../../routes/coordinator'
import UserTable from './components/Table/UserTable'
import ActivityHistory from '../../components/SideMenuAudit/ActivityHistory'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { getPermissions } from 'src/service/authService'

const UserManager = () => {
  const { t } = useTranslation()
  const history = useNavigate()
  const permissions = JSON.parse(getPermissions())

  return (
    <Container sx={{ mt: '96px' }}>
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <PersonOutlineOutlinedIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: '#FF5E1E',
              marginBottom: '-4px',
            }}
          >
            {t('user.title')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {
            permissions?.auditory?.includes('auditory:list') && <ActivityHistory path={'usuarios'} keySubtitle={'name'} />
          }
        </Box>
      </Box>

      <Button
        onClick={() => goToCreateUser(history)}
        disabled={!permissions.admin?.includes("admin:create")}
        variant="outlined"
        sx={{
          gap: '6px',
          width: '50%',
          height: '36px',
          mb: '40px',
          ':hover': {
            backgroundColor: '#FF5E1E',
            color: '#ffff',
          },
        }}
      >
        <AddIcon sx={{ width: '24px', height: '24px' }} />
        {t('form.button.user')}
      </Button>
      <Button
        onClick={() => goToRoles(history)}
        disabled={!permissions.role?.includes("role:create")}
        variant="outlined"
        sx={{
          gap: '6px',
          width: '45%',
          height: '36px',
          marginLeft: '5%',
          mb: '40px',
          color: 'rgba(0, 0, 0, 0.6)',
          borderColor: 'rgba(0, 0, 0, 0.6)',
          ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: '#ffff',
            borderColor: 'rgba(0, 0, 0, 0.6)',
          },
        }}
      >
        <PersonOutlineOutlinedIcon />
        {t('form.button.role')}
      </Button>

      <UserTable />
    </Container>
  )
}
export default UserManager
