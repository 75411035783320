import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import copy from 'copy-to-clipboard'
import { Box, IconButton, TextField, Tooltip } from '@mui/material'
import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined'
import { ContentCopy } from '@mui/icons-material'

import InputRHF from '../../../../components/RHF/InputRHF'
import {
  companyDefaultValues,
  companySchemaValidation,
} from '../../validators/companySchemaValidator'
import InputMaskRHF from '../../../../components/RHF/InputMaskRHF'
import SwitchRHF from 'src/components/RHF/SwitchRHF'
import { Company } from 'src/models/company-model'
import { UploadCompanyPhoto } from './UploadCompanyPhoto'
import { UploadCompanyLogoIcon } from 'src/components/Icons/UploadCompanyLogoIcon'
import { FooterBottons } from 'src/components/FormUtils/FooterButtons'
import { searchZipcode } from 'src/service/companyService'
import { goToCompany } from 'src/routes/coordinator'

interface CompanyFormProps {
  data: Company
  onSubmit: (data) => void
  isViewMode?: boolean
  viewId?: any
}

export const CompanyForm = ({
  data,
  onSubmit,
  isViewMode,
  viewId,
}: CompanyFormProps) => {
  const [companyLogo, setCompanyLogo] = useState<string>()
  const [companySmallLogo, setCompanySmallLogo] = useState<string>()
  const { t } = useTranslation()
  const { id } = useParams()

  const [cityName, setCityName] = useState<string>()
  const [stateName, setStateName] = useState<string>()
  const placeholder = t('company.form.input.placeholder.logo') + '\n' + 'a'
  const form = useForm<any>({
    mode: 'all',
    defaultValues: companyDefaultValues,
    resolver: yupResolver(companySchemaValidation),
  })
  const { control, handleSubmit, reset, formState, setValue, trigger, watch } =
    form

  const resetForm = () => {
    reset({ companyName: '' })
    setCompanyLogo('')
    setCompanySmallLogo('')
  }

  useEffect(() => {
    if (data) {
      reset(data)
      setCompanyLogo(data?.companyLogo)
      setCompanySmallLogo(data?.companyLogoSmall)
      setCityName(data?.companyCity)
      setValue('companyCity', data?.companyCity)
      setStateName(data?.companyState)
      setValue('companyState', data?.companyState)
    }
  }, [data])

  useEffect(() => {
    setValue('companyLogo', companyLogo)
    setValue('companyLogoSmall', companySmallLogo)
    setValue('companyCity', cityName)
    setValue('companyState', stateName)
  }, [companyLogo, companySmallLogo])
  const hideInput = () => {
    if (isViewMode) {
      return viewId
    }
    if (id) {
      return id
    }
  }
  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  const formIsValid = () =>
    Boolean(
      watch('companyName') &&
        watch('apName') &&
        watch('ipAddress') &&
        watch('macAddress') &&
        watch('geoAddress') &&
        watch('geoLongitude')
    )

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: isViewMode ? 0 : 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        {hideInput() && (
          <TextField
            value={`http://portal.itbuzz.com.br/${'<controladora>'}/${
              id || viewId
            }`}
            disabled
            sx={{
              fontSize: '14px',
              gridColumn: 'span 12',
              mb: isViewMode ? '4px' : '0px',
            }}
            InputProps={{
              endAdornment: (
                <Tooltip title={t('copy.id')} placement="left">
                  <IconButton
                    onClick={() =>
                      copy(
                        `http://portal.itbuzz.com.br/${'<controladora>'}/${
                          id || viewId
                        }`
                      )
                    }
                    sx={{
                      borderRadius: '4px',
                      minWidth: '32px',
                      color: '#00000099',
                      ':hover': {
                        minWidth: '32px',
                        color: 'white',
                        backgroundColor: '#00000099',
                      },
                    }}
                  >
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        )}

        <InputRHF
          required
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.name')}
          name="companyName"
          placeholder={t('company.form.input.placeholder.name')}
          sx={{
            gridColumn: 'span 11',
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <SwitchRHF
          disabled={disableInput()}
          name="isActive"
          control={control}
          onChangeAction={() => {
            trigger && trigger('isActive')
            setValue && setValue('isActive', '')
          }}
          sx={{
            gridColumn: 'span 1',
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputMaskRHF
          disabled={disableInput()}
          control={control}
          label="CNPJ"
          name="cnpj"
          placeholder={t('company.form.input.placeholder.cnpj')}
          mask="99.999.999/9999-99"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mt: 1,
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.contractNumber')}
          name="contractNumber"
          placeholder={t('company.form.input.placeholder.contractNumber')}
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.managerName')}
          placeholder={t('company.form.input.placeholder.managerName')}
          name="managerName"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.address')}
          placeholder={t('company.form.input.placeholder.address')}
          name="companyAddress"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 8',
              md: 'span 8',
              lg: 'span 8',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputMaskRHF
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.zipCode')}
          name="cep"
          placeholder={t('company.form.input.placeholder.zipCode')}
          mask="99999-999"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mt: 1,
            mb: isViewMode ? '4px' : '11px',
          }}
          onChangeAction={async (cep) => {
            const cepClear = cep.replace('_', '')
            if (cepClear.length === 9) {
              const res = await searchZipcode(cep)
              if (res.localidade !== undefined && res.uf !== undefined) {
                setCityName(res.localidade)
                setValue('companyCity', res.localidade)
                setStateName(res.uf)
                setValue('companyState', res.uf)
              } else {
                setCityName('')
                setStateName('')
                setValue('companyCity', '')
                setValue('companyState', '')
              }
            } else {
              setCityName('')
              setStateName('')
              setValue('companyCity', '')
              setValue('companyState', '')
            }
          }}
        />

        {watch('cep') && cityName && stateName && (
          <>
            <InputRHF
              disabled={true}
              control={control}
              label={t('company.form.input.label.companyState')}
              placeholder={t('company.form.input.placeholder.companyState')}
              name="companyState"
              value={stateName || ''}
              sx={{
                gridColumn: {
                  xs: 'span 10',
                  sm: 'span 4',
                  md: 'span 4',
                  lg: 'span 4',
                },
                mb: isViewMode ? '0px' : '11px',
              }}
            />

            <InputRHF
              disabled={true}
              control={control}
              label={t('company.form.input.label.companyCity')}
              placeholder={t('company.form.input.placeholder.companyCity')}
              name="companyCity"
              value={cityName || ''}
              sx={{
                gridColumn: {
                  xs: 'span 10',
                  sm: 'span 4',
                  md: 'span 8',
                  lg: 'span 8',
                },
                mb: isViewMode ? '0px' : '11px',
              }}
            />
          </>
        )}

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            rowGap: 1,
            columnGap: 2,
            gridColumn: 'span 12',
          }}
        >
          <UploadCompanyPhoto
            isView={isViewMode}
            icon={<UploadCompanyLogoIcon />}
            onFileChange={(file) => setCompanyLogo(file)}
            placeholder={t('company.form.input.placeholder.logo')}
            acceptedFormats={t('input.upload.file.acceptedformats')}
            value={companyLogo}
            sx={{
              gridColumn: {
                xs: 'span 10',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              },
              mb: '11px',
            }}
          />
          <UploadCompanyPhoto
            isView={isViewMode}
            icon={
              <BrandingWatermarkOutlinedIcon
                sx={{ transform: ' rotate(180deg)' }}
              />
            }
            onFileChange={(file) => setCompanySmallLogo(file)}
            placeholder={t('company.form.input.placeholder.smallLogo')}
            acceptedFormats={t('input.upload.file.acceptedformats')}
            value={companySmallLogo}
            sx={{
              gridColumn: {
                xs: 'span 10',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              },
              mb: '11px',
            }}
          />
        </Box>
      </Box>
      {!isViewMode && (
        <FooterBottons
          isValid={!formState.isValid}
          formIsValid={formState.isValid}
          isEdit={Boolean(id)}
          goTo={goToCompany}
          reset={() => resetForm()}
          size="100%"
          buttonName="company.button.create"
        />
      )}
    </Box>
  )
}
