import { useCallback, useContext, useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, Typography } from '@mui/material'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import InputRHF from 'src/components/RHF/InputRHF'
import { AnswersCampaign } from './AnswerCampaign'
import { FormHeader, checkHasLink } from '../FormHeader'
import { answerDefaultValues } from 'src/pages/Campaigns/validators/answerSchemaValidator'
import { questionDefaultValues } from 'src/pages/Campaigns/validators/questionSchemaValidator'
import {
  CampaignContext,
  contentTypeEnum,
} from 'src/context/CampaignFormContext'
import { Divider } from 'src/components/Dividers/Divider'
import { FormButtons } from '../../FormButtons'
import { PreviewModal, PreviewMode } from 'src/components/Modal/PreviewModal'
import { StepperContext } from 'src/context/StepperContext'
import { SearchCampaignPreview } from '../../../Preview/SearchCampaignPreview'
import { useParams } from 'react-router-dom'
import { goToCampaigns } from 'src/routes/coordinator'

interface Props {
  isViewMode?: boolean
  disabled?: any
}
export const SearchForm = ({ isViewMode, disabled }: Props) => {
  const [showPreview, setShowPreview] = useState(false)
  const [previewMode, setPreviewMode] = useState(PreviewMode.view)
  const { id } = useParams()
  const isEdit = Boolean(id)

  const { t } = useTranslation()

  const {
    setContentType,
    formState: { control, watch, getValues, setValue, reset },
  } = useContext(CampaignContext)
  const { nextStep } = useContext(StepperContext)
  const { fields, append, remove } = useFieldArray({
    name: 'content.quiz',
    control: control as any,
  })

  const clearForm = () => {
    setValue('content.title', '')
    setValue('content.description', '')
    setValue('content.quiz', [])
    setValue('content.mediaInterval', 0)
    setValue('content.hasMediaInterval', false)
    setValue('content.titleColor', undefined)
    setValue('content.descriptionColor', undefined)
    addQuiz()
  }
  const validateForm = useCallback(() => {
    const questions = watch('content.quiz') || []
    const questionsIsFilled = questions.map((question) => {
      return (
        !question?.options
          ?.map((option) => Boolean(option?.name))
          ?.includes(false) && Boolean(question?.question)
      )
    })

    const minimalRequiredQuestionsFilled =
      questions?.length >= 1 &&
      !questions
        ?.map((question) => Boolean(question?.options?.length >= 2))
        .includes(false)

    return (
      !questionsIsFilled.includes(false) &&
      fields.length > 0 &&
      Boolean(!checkHasLink(watch('content.description'))) &&
      minimalRequiredQuestionsFilled
    )
  }, [watch()])

  const formIsValid = validateForm()

  useEffect(() => {
    if (!isEdit && fields.length === 0) {
      append({
        ...questionDefaultValues,
        options: [answerDefaultValues, answerDefaultValues],
      })
    }
  }, [])

  const addQuiz = () => {
    append({
      ...questionDefaultValues,
      options: [answerDefaultValues, answerDefaultValues],
    })
  }

  const removeQuestion = (index: number) => {
    remove(index)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <PreviewModal
        handleClose={() => {
          setShowPreview(false)
        }}
        open={showPreview}
        title={`${t('campaign.preview.visualization.off')}\n${t(
          'campaign.search.title'
        )}`}
        mode={previewMode}
        onConfirm={() => {
          setShowPreview(false)
          nextStep()
        }}
        isSearch={true}
      >
        <SearchCampaignPreview />
      </PreviewModal>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <FormHeader title="campaign.search.title" isViewMode={isViewMode} />
        {fields.map((question: any, index) => {
          return (
            <Box
              key={question.id}
              sx={{
                justifyContent: 'center',
                borderRadius: '4px ',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                gap: '10px',
                padding: '16px 8px 16px 16px',
                mt: 0,
                width: '100%',
              }}
            >
              <Box
                sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    gap: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      alignContent: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      width: '54px',
                      height: '54.5px',
                      borderRadius: '4px',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: 'rgba(0, 0, 0, 0.38)',
                    }}
                  >
                    {index + 1}
                  </Typography>

                  <InputRHF
                    disabled={disabled}
                    name={`content.quiz[${index}].question`}
                    label={t('campaign.label.input.type_your_question')}
                    control={control as any}
                    sx={{
                      width: '100%',
                      height: '56px',
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            display: watch(`content.quiz[${index}].question`)
                              ? 'flex'
                              : 'none',
                            ':hover': {
                              color: '#FF5E1E',
                            },
                          }}
                          disabled={
                            disabled || fields.length === 1 ? true : false
                          }
                          onClick={() => removeQuestion(index)}
                        >
                          <DeleteForeverIcon sx={{ width: '20px' }} />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
                <Box
                  component={'input'}
                  placeholder={t('campaign.search.question.helperText')}
                  value={watch(`content.quiz[${index}].helperText`) || ''}
                  onChange={({ target: { value } }) =>
                    setValue(`content.quiz[${index}].helperText`, value)
                  }
                  sx={{
                    appearance: 'none',
                    '-webkit-appearance': 'none',
                    '-moz-appearance': 'none',
                    border: 'none',
                    ':focus': {
                      outline: 'none',
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    ml: 10,
                    fontFamily: 'Roboto',
                  }}
                />
              </Box>

              <AnswersCampaign
                watch={watch}
                {...{ control }}
                indexQuestion={index}
                disabled={disabled}
              />
              {index + 1 !== fields.length && (
                <Box sx={{ width: '100%', mt: 1.5 }}>
                  <Divider />
                </Box>
              )}
            </Box>
          )
        })}
        {fields.length < 3 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              disabled={disabled}
              onClick={addQuiz}
              variant="outlined"
              sx={{
                width: '100%',
                mt: 2,
                p: 0,
                height: '56px',
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                color: '#00000099',
                ':hover': {
                  color: '#FF5E1E',
                },
              }}
            >
              <AddBoxOutlinedIcon
                sx={{
                  height: '24px',
                  minWidth: '24px',
                }}
              />
              {t('campaign.search.add.question')}
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={{ width: '100%', p: 'none', mt: '16px' }}>
        {!isViewMode && (
          <FormButtons
            onClean={() => clearForm()}
            nextButtonEnabled={formIsValid}
            prevButtonEnabled={true}
            formIsValid={formIsValid}
            cleanButtonEnabled={formIsValid}
            previewButtonEnabled={formIsValid}
            prevButtonAction={() => {
              reset({})
              setContentType(contentTypeEnum.NONE)
            }}
            previewButtonAction={() => {
              setPreviewMode(PreviewMode.view)
              setShowPreview(true)
            }}
            nextButtonAction={() => {
              setPreviewMode(PreviewMode.confirm)
              setShowPreview(true)
            }}
            nextButtonCta="form.button.next"
            nextButtonArrow
            isEdit={isEdit}
            goTo={goToCampaigns}
          />
        )}
      </Box>
    </Box>
  )
}
