import { useEffect, useState } from 'react'
import { t } from 'i18next'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import { getCompanies } from 'src/service/companyService'
import InputSelectRHF from '../RHF/InputSelectRHF'

export const CompanySelect = (props: any) => {
  const [companies, setCompanies] = useState([])
  const getCompanyList = async () => {
    const data = await getCompanies({ params: { size: 100000000 } })
    setCompanies(data.listCompanies)
  }

  useEffect(() => {
    getCompanyList()
  }, [])

  return (
    <InputSelectRHF
      name="companyId"
      options={companies}
      label={t('company.input.label')}
      placeholder={t('user.form.input.placeholder.company')}
      loading
      noOptionsText={t('noRowsLabel.general')}
      multiple={false}
      isOptionEqualToValueFn={(option: any, value: any) =>
        option.companyName === value.companyName
      }
      getOptionLabel={(value: any) =>
        value.companyName ? value.companyName : ''
      }
      sx={{ ...props.sx, width: '100%', height: '48px' }}
      {...props}
      renderOption={(props, option: any, { inputValue }) => {
        const matches = match(option.companyName, inputValue, {
          insideWords: true,
        })
        const parts = parse(option.companyName, matches)

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    color: part.highlight ? '#FF5E1E' : '#000000DE',
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}
