import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form'
import { t } from 'i18next'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { SxProps, Theme } from '@mui/material'

import InputSelectRHF from '../RHF/InputSelectRHF'
import { getCampaigns } from 'src/service/marketingCampaignService'

interface Props {
  control: Control<FieldValues>
  companyId?: string
  name: string
  sx?: SxProps<Theme>
  onChangeOption?: (option: any) => void
  setValue?: UseFormSetValue<FieldValues>
}

export const CampaignSelect = ({
  control,
  companyId,
  name,
  sx,
  onChangeOption,
  setValue,
}: Props) => {
  const [campaigns, setCampaigns] = useState<any>([])
  const campaignsOption = [
    { name: 'Todas as campanhas', id: [], contentType: 'todas' },
    ...campaigns,
  ]

  const { id } = useParams()

  useEffect(() => {
    getCompanyList()
  }, [])

  useEffect(() => {
    if (id && campaigns) {
      const campaign = campaigns.find((campaign) => campaign.id === id)
      if (campaign) {
        const nameCampaing = {
          id: campaign.id,
          name: campaign.name,
          contentType: campaign.contentType,
        }
        setValue('campaign', nameCampaing)
        onChangeOption(nameCampaing)
      }
    }
    if (id === 'todas') {
      setValue('campaign', campaignsOption[0])
    }
  }, [id, campaigns])

  const getCompanyList = async () => {
    if (companyId) {
      const data = await getCampaigns({
        params: { size: 100000000, companyId: companyId },
      })

      setCampaigns(data?.listMarketingCampaign || [])
      return
    }

    const data = await getCampaigns({
      params: { size: 100000000 },
    })

    setCampaigns(data?.listMarketingCampaign || [])
  }

  return (
    <InputSelectRHF
      disableClearable
      name={name}
      control={control}
      defaultValue={campaignsOption[0]}
      options={campaignsOption}
      label={t('campaign.select.label')}
      loading
      noOptionsText={t('noRowsLabel.general')}
      multiple={false}
      isOptionEqualToValueFn={(option: any, value: any) =>
        option.name === value.name
      }
      getOptionLabel={(option: any) => (option.name ? option.name : '')}
      onChangeAction={(value) => {
        onChangeOption(value)
      }}
      sx={{
        width: '100%',
        height: '48px',
        ...sx,
      }}
      renderOption={(props, option: any, { inputValue }) => {
        const matches = match(option.name, inputValue, {
          insideWords: true,
        })
        const parts = parse(option.name, matches)

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    color: part.highlight ? '#FF5E1E' : '#000000DE',
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}
