import { useForm } from 'react-hook-form'
import { t } from 'i18next'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { PortalPreviewTemplate } from 'src/components/Preview/PortalPreviewTemplate'
import InputRHF from 'src/components/RHF/InputRHF'
interface Props {
  config?: any
}

export const PortalPreview = ({ config }: Props) => {
  const form = useForm<any>({})
  return (
    <PortalPreviewTemplate config={config}>
      <Box
        sx={{
          width: '280px',
          height: 'auto',
          backgroundColor: config?.containerBackgroundColor,
          boxShadow:
            '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);',
          borderRadius: '4px',
          padding: '18px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            color: config?.containerTextColor,
            fontWeight: 400,
            lineHeight: '29px',
            whiteSpace: 'pre-line',
            width: '100%',
            textAlign: 'left',
            letter: '-0.6 px',
          }}
        >
          {t('portal.peview.title.1')}
          {'\n'} {t('portal.peview.title.2')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <InputRHF placeholder="E-mail" name="aa" control={form.control} disabled />
          <Button
            disabled
            sx={{
              backgroundColor: config?.primaryColor,
              ':disabled': {
                color: config?.secondaryColor,
              },
            }}
          >
            {t('portal.peview.cta.join')}
          </Button>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                color: ' rgba(0, 0, 0, 0.6)',
                fontSize: '12px',
                fontFamily: 'Roboto',
                cursor: 'pointer',
              }}
            >
              {t('portal.peview.remove.account')}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                height: '1px',
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                width: '55px',
              }}
            />
            <Typography
              sx={{
                color: ' rgba(0, 0, 0, 0.6)',
                fontSize: '10px',
                fontFamily: 'Roboto',
                fontWeight: 500,
              }}
            >
              {t('portal.preview.dont.have.account')}
            </Typography>
            <Box
              sx={{
                height: '1px',
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                width: '55px',
              }}
            />
          </Box>
          <Button
            disabled
            sx={{
              backgroundColor: config?.secondaryColor,
              border: `1px solid ${config?.primaryColor}`,
              ':disabled': {
                color: config?.primaryColor,
              },
            }}
          >
            {t('portal.peview.cta.create.account')}
          </Button>
        </Box>
      </Box>
    </PortalPreviewTemplate>
  )
}
