import { SyntheticEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from "@mui/material"

import BusinessIcon from '@mui/icons-material/Business'
import EditIcon from '@mui/icons-material/Edit'
import KeyIcon from '@mui/icons-material/Key'

import useProtectedPage from "src/hooks/useProtectedPage"
import { ProfileModel } from "src/models/profile-model"
import { getUserProfile } from "src/service/userAdminsService"
import { CustomTabPanel } from "src/components/Tabs/CustomTabPanel"
import { a11yProps } from "src/components/Tabs/Helper"
import { goToEditUser } from "src/routes/coordinator"
import { ChangePasswordModal } from "./components/Modal/ChangePasswordModal"
import { AlertComponent } from "src/components/Alert/AlertComponent"

const mainButton: SxProps = {
  gap: '6px',
  width: '50%',
  height: '36px',
  color: 'rgba(0, 0, 0, 0.6)',
  borderColor: 'rgba(0, 0, 0, 0.6)',
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderColor: 'rgba(0, 0, 0, 0.6)',
    color: '#ffff',
  },
}

const secondaryButton: SxProps = {
  gap: '6px',
  width: '45%',
  height: '36px',
  marginLeft: 'auto',
  marginRight: 0,
  mb: '20px',
  float: 'right', // remover se colocar o outro botão
  ':hover': {
    backgroundColor: '#FF5E1E',
    color: '#ffff',
  },
}

export const ViewProfilePage = () => {
  useProtectedPage()
  const history = useNavigate()
  const [profile, setProfile] = useState<ProfileModel>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const { t } = useTranslation()

  const getUserData = async () => {
    const profile = await getUserProfile()
    setProfile(profile)
  }

  const getUserCompanyName = () => {
    if (profile?.company) {
      return profile.company.companyName
    } else {
      return (
        <>
          <span style={{ color: '#E65015' }}>it</span><span style={{ color: '#3D3D3D' }}>Buzz</span>
        </>
      )
    }
  }

  const handleSelectedTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleFormatDate = (dateString: string) => {
    const date = dateString ? new Date(dateString) : new Date()
    const formattedDate = Intl.DateTimeFormat('pt-BR', {
      dateStyle: 'full',
      timeStyle: 'long',
      timeZone: 'America/Sao_Paulo',
    }).format(date)

    return formattedDate.substring(0, 1).toUpperCase().concat(formattedDate.substring(1))
  }

  const onClose = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <Container
      sx={{
        mt: '96px',
      }}
    >
      <AlertComponent />
      <Grid container spacing={2} sx={{ mb: '20px' }}>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar sx={{ width: 200, height: 200 }} src={profile?.profileImage} />
        </Grid>
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography variant="h3">{profile?.name}</Typography>
          <Divider />
            <small style={{ display: 'flex', alignItems: 'flex-end', marginTop: '0' }}>
              <BusinessIcon sx={{ width: 50, height: 50, color: '#FF5E1E' }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginLeft: '10px' }}>
                {profile?.roleSystem?.roleName} @ {getUserCompanyName()}
              </Typography>
            </small>
        </Grid>
      </Grid>
      <Divider />
      <Tabs variant="fullWidth" value={selectedTab} onChange={handleSelectedTabChange} aria-label="select-tab">
        <Tab label={t("profile.tabs.personalDetails")} {...a11yProps(0)} />
        <Tab label={t("profile.tabs.loginHistory")} {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={selectedTab} index={0}>
       <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">{t("profile.table.fullName")}</TableCell>
                <TableCell component="th" scope="row">:</TableCell>
                <TableCell component="th" scope="row">{profile?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">{t("profile.table.email")}</TableCell>
                <TableCell component="th" scope="row">:</TableCell>
                <TableCell component="th" scope="row">{profile?.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">{t("profile.table.phone")}</TableCell>
                <TableCell component="th" scope="row">:</TableCell>
                <TableCell component="th" scope="row">{profile?.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">{t("profile.table.createdAt")}</TableCell>
                <TableCell component="th" scope="row">:</TableCell>
                <TableCell component="th" scope="row">{handleFormatDate(profile?.created_at)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">{t("profile.table.company")}</TableCell>
                <TableCell component="th" scope="row">:</TableCell>
                <TableCell component="th" scope="row">{getUserCompanyName()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">{t("profile.table.role")}</TableCell>
                <TableCell component="th" scope="row">:</TableCell>
                <TableCell component="th" scope="row">{profile?.roleSystem?.roleName}</TableCell>
              </TableRow><TableRow>
                <TableCell component="th" scope="row">{t("profile.table.defaultLanguage")}</TableCell>
                <TableCell component="th" scope="row">:</TableCell>
                <TableCell component="th" scope="row">{profile?.defaultLanguage}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {
                profile?.AdminLoginHistory && profile.AdminLoginHistory.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {handleFormatDate(row?.loginDate)}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </CustomTabPanel>
      {/* <Button
        onClick={() => goToEditUser(history, profile?.id)}
        variant="outlined"
        sx={mainButton}
      >
        <EditIcon sx={{ width: '24px', height: '24px' }} />
        {t('profile.button.edit.user')}
      </Button> */}
      <Button
        onClick={() => setIsModalOpen(true)}
        variant="outlined"
        sx={secondaryButton}
      >
        <KeyIcon />
        {t('profile.button.edit.password')}
      </Button>
      <ChangePasswordModal
        open={isModalOpen}
        handleClose={onClose}
      />
    </Container>
  )
}
