import { Role } from 'src/models/role-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const getRoles = async (options?) => {
  try {
    const res = await client().get(`${BASE_URL}/roles`, options)
    return res.data
  } catch (error) {
    return false
  }
}
export const getPermissions = async (options?) => {
  try {
    const res = await client().get(`${BASE_URL}/permissions`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getRoleById = async (
  id: string
): Promise<Role | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/roles/${id}`)
    return res.data
  } catch (error) {}
}

export const createRole = async (
  role: Role
): Promise<Role | boolean | { error: boolean; message: string }> => {
  const dataRole = {
    companyId: role.companyId,
    roleName: role.roleName,
    isTecnoitRole: role.isTecnoitRole,
    permissions: role.permissions
  }
  try {
    const res = await client().post(`${BASE_URL}/roles`, dataRole)
    const isDuplicatedEmail = (res as any)?.response?.status === 400
    const errorMessage = (res as any)?.response?.data // user.form.error.invalid.email ( tem no locale )
    // tem que retornar false para nao sair da pagina de formulario
    return res?.data ?? !isDuplicatedEmail
  } catch (error) {
    return false
  }
}

export const editRole = async (
  id: string,
  role: Role
): Promise<Role | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/roles/${id}`, role)
    return res.data
  } catch (error) {
    return false
  }
}

export const deleteRoleById = async (
  id: string | number
): Promise<Role | boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/roles/${id}`)
    return true
  } catch (error) {
    return false
  }
}
