import { Skeleton } from '@mui/material'
import { Accordion, AccordionDetails, AccordionSummary } from './FaqAccordionStyles'

export function FaqAccordionSkeleton () {
  return (
    <>
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Skeleton variant="text" height="32px" width="80%" />
        </AccordionSummary>
        <AccordionDetails>
          <Skeleton variant="rectangular" width="100%" height={118} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <Skeleton variant="text" height="32px" width="70%" />
        </AccordionSummary>
        <AccordionDetails>
          <Skeleton variant="rectangular" width="100%" height={118} />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
