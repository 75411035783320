import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import DoughnutChart from '../Charts/Doughnut'
import { Card } from './Card'
import { ToggableCard } from './ToggableCard'
interface Props {
  labels: string[]
  data: number[]
  total: number
}
export const PerGroupConnections = ({ labels, data, total }: Props) => {
  const { t } = useTranslation()

  return (
    <ToggableCard viewkey="pergroupconnections">
      <Card
        sx={{
          minWidth: '222px',
          minHeight: '200px',
          width: '300px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: '16px',
          px: '31px',
          pb: '20px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6);',
            whiteSpace: 'pre-line',
          }}
        >
          {t('painel.grupconnections')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <DoughnutChart labels={labels} data={data} total={total} />
        </Box>
      </Card>
    </ToggableCard>
  )
}
