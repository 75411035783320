import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColumns } from '@mui/x-data-grid'
import { Box, Chip, Typography } from '@mui/material'
import {
  deleteCampaign,
  editCampaign,
  getCampaigns,
  recycleCampaignById,
  unarchiveCampaignById,
} from 'src/service/marketingCampaignService'
import { getPermissions } from 'src/service/authService'
import { goToEditCampaign } from 'src/routes/coordinator'
import { formatDateAndTime } from 'src/util/formatDataAndTime'
import { ROUTES } from 'src/routes/Router'
import { AlertContext } from 'src/context/AlertContext'
import { Table } from 'src/components/Table/Table'
import { ArrToNumberSort } from 'src/components/Table/SortingFunctions'
import { DefaultCampaignChip } from 'src/components/Chips/DefaultCampaignChip'
import CreateCampaign from '../../CreateCampaign'

export default function CampaignTable() {
  const { t } = useTranslation()
  const [tableKey, setTableKey] = useState(0)
  const { createCRUDAlert } = useContext(AlertContext)
  const permissions = JSON.parse(getPermissions())

  const getData = async (options: any) => {
    const data = await getCampaigns(options)

    return {
      data: data.listMarketingCampaign,
      totalPages: data.page,
      size: data?.size,
    }
  }

  const handleDelete = async (id, name) => {
    const res = await deleteCampaign(id)
    if (res) createCRUDAlert(ROUTES.CAMPAIGNS, name, 'deleted')
    return
  }

  const handleRecycle = async (id, name) => {
    const res = await recycleCampaignById(id)
    if (res) {
      createCRUDAlert(ROUTES.CAMPAIGNS, name, 'edited')
    }
  }

  const handleUnarchive = async (id, name) => {
    const res = await unarchiveCampaignById(id)
    if (res) {
      createCRUDAlert(ROUTES.CAMPAIGNS, name, 'edited')
    }
  }

  const disableCampaign = async (data) => {
    const validData = {
      ...data,
      isActive: !data.isActive,
    }
    const result = await editCampaign(data.id, validData)

    setTableKey(tableKey + 1)
    if (result) createCRUDAlert(ROUTES.CAMPAIGNS, data.name, 'edited')
    return
  }

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: t('campaign.table.header.campaign'),
      width: 250,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
            }}
          >
            {row?.name?.slice(0, 18)}
          </Box>
        )
      },
    },
    {
      field: 'contentType',
      headerName: t('campaign.table.header.type'),
      width: 90,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (a) => t(`table.campanha.${a.value}`),
    },
    {
      field: 'isActive',
      headerName: t('table.header.situation'),
      type: 'boolean',
      editable: false,
      renderCell: ({ row }) => {
        if (row?.defaultCampaign)
          return (
            <Chip
              sx={{ background: '#FFA7261F' }}
              size="small"
              label={t('table.status.running')}
            />
          )
        if (new Date(row.initialDate) > new Date()) {
          if (row.isActive) {
            return (
              <Chip
                sx={{ background: '#FF5E1E1F' }}
                size="small"
                label={t('table.status.active')}
              />
            )
          } else {
            return (
              <Chip
                sx={{ background: '#00000014' }}
                size="small"
                label={t('table.status.inactive')}
              />
            )
          }
        }
        if (new Date() > new Date(row.finalDate)) {
          return (
            <Chip
              sx={{ background: '#2E7D321F' }}
              size="small"
              label={t('table.status.finished')}
            />
          )
        }
        if (
          new Date() < new Date(row.finalDate) &&
          new Date() > new Date(row.initialDate)
        ) {
          return (
            <Chip
              sx={{ background: '#FFA7261F' }}
              size="small"
              label={t('table.status.running')}
            />
          )
        }
      },
      width: 150,
    },
    {
      field: 'initialDate',
      headerName: t('campaign.table.header.duration'),
      type: 'dateTime',
      editable: false,
      headerAlign: 'center',
      align: 'left',
      minWidth: 170,
      renderCell: (params) => {
        if (params.row?.defaultCampaign) {
          return (
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <DefaultCampaignChip />
            </Box>
          )
        }
        return (
          <div>
            <Typography sx={{ paddingTop: '8px' }}>
              {formatDateAndTime(params.row.initialDate)} -
            </Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
              {' '}
              {formatDateAndTime(params.row.finalDate)}
            </Typography>
          </div>
        )
      },
    },
    {
      field: 'groupListName',
      headerName: t('campaign.table.header.groups'),
      width: 70,
      editable: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row: { groups } }) => groups?.length,
      sortComparator: ArrToNumberSort,
    },
    {
      field: 'segmentedActive',
      headerName: t('campaign.table.header.segmentation'),
      width: 110,
      type: 'boolean',
      editable: false,
      renderCell: ({ row }) => {
        return (
          <>
            {row.segmentedActive ? (
              <Typography>
                {t('campaign.table.header.segmentation.label.yes')}
              </Typography>
            ) : (
              <Typography>
                {t('campaign.table.header.segmentation.label.no')}
              </Typography>
            )}
          </>
        )
      },
    },
  ]

  return (
    <Table
      columns={columns}
      getData={getData}
      key={tableKey}
      handleDelete={handleDelete}
      titleKey="name"
      goTo={goToEditCampaign}
      defaultPageSize={15}
      resultButton
      model="campaign"
      Children={CreateCampaign}
      size="16px"
      permissionUpdate={!permissions?.campaign?.includes("campaign:update")}
      permissionDelete={!permissions?.campaign?.includes("campaign:delete")}
      permissionView={!permissions?.campaign?.includes("campaign:view")}
      canShowRecycleButtons={true}
      permissionViewRecycle={permissions?.campaign?.includes("campaign:recycle:view")}
      permissionUpdateRecycle={permissions?.campaign?.includes("campaign:recycle:update")}
      handleRecycle={handleRecycle}
      canShowUnarchiveButtons={true}
      permissionViewUnarchive={permissions?.campaign?.includes("campaign:archive:view")}
      permissionUpdateUnarchive={permissions?.campaign?.includes("campaign:archive:update")}
      handleUnarchive={handleUnarchive}
    />
  )
}
