import { Typography } from '@mui/material'
import { Box } from '@mui/system'

interface Props {
  title: string
}

export const TitleWithDivider = ({ title }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        my: 1,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Typography
        fontWeight={500}
        fontSize={10}
        color={'rgba(0, 0, 0, 0.6)'}
        letterSpacing={'1.5px'}
        sx={{ whiteSpace: 'nowrap', mr: 1 }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          minHeight: '2px',
          minWidth: '95%',
          height: '2px',
          bgcolor: 'rgba(0, 0, 0, 0.12);',
        }}
      ></Box>
    </Box>
  )
}
