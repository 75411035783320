export const EmpresaIcon = () => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.55883V0H0V16.0147H20V3.55883H10ZM4 14.2353H2V12.4558H4V14.2353ZM4 10.6765H2V8.89706H4V10.6765ZM4 7.11764H2V5.33823H4V7.11764ZM4 3.55883H2V1.77941H4V3.55883ZM8 14.2353H6V12.4558H8V14.2353ZM8 10.6765H6V8.89706H8V10.6765ZM8 7.11764H6V5.33823H8V7.11764ZM8 3.55883H6V1.77941H8V3.55883ZM18 14.2353H10V12.4558H12V10.6765H10V8.89706H12V7.11764H10V5.33823H18V14.2353ZM16 7.11764H14V8.89706H16V7.11764ZM16 10.6765H14V12.4558H16V10.6765Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  )
}
  