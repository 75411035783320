import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InputSelectRHF from 'src/components/RHF/InputSelectRHF'
import { getRole } from 'src/service/authService'
import { getCampaigns } from 'src/service/marketingCampaignService'

export const DependencyForm = ({ formState, ...props }) => {
  const { t } = useTranslation()
  const { control, setValue, getValues } = formState
  const [campaigns, setCampaigns] = useState([])
  const [campaignOptions, setCampaignOptions] = useState([])
  const [currentQuestionName, setCurrentQuestionName] = useState('')
  const [selectedCampaign, setSelectedCampaign] = useState<any>({})
  const [error, setError] = useState<string>('')

  const doesCampaignRequireAnythingElse = (): boolean => {
    const campaignContentType = selectedCampaign?.contentType
    if (campaignContentType !== 'quiz') {
      setCurrentQuestionName('')
      setCampaignOptions([])
      return false
    }
    else if (selectedCampaign.content.quiz.length !== 1) {
      setError('campaign.form.invalid.segmentation.question')
      setCurrentQuestionName('')
      setCampaignOptions([])
      return false
    }
    const currentOptions = selectedCampaign.content.quiz[0].options
    const question = selectedCampaign.content.quiz[0].question
    setCurrentQuestionName(question)
    setCampaignOptions(currentOptions)
    return true
  }

  const getCampaignsList = async () => {
    const additionalParams = getRole() === 'TECNOIT' ? { companyId: getValues('companyId') } : {}
    const data = await getCampaigns({ params: { size: 100000000, ...additionalParams }})
    setCampaigns(data.listMarketingCampaign)
  }

  useEffect(() => {
    getCampaignsList()
    const dependency = getValues('dependency')
    if (getValues('dependency')) {
      setSelectedCampaign(dependency.campaign)
      if (dependency?.campaign?.contentType === 'quiz') {
        setCurrentQuestionName(dependency.campaign.content.quiz[0].question)
        const options = dependency.campaign.content.quiz[0].options
        setValue('dependency.selectedOption', dependency.selectedOption)
        setCampaignOptions(options)
      }
    }
  }, [])

  const onChangeValue = async (value) => {
    setError('')
    setSelectedCampaign(value)
    setCurrentQuestionName('')
    setCampaignOptions([])
    setValue('dependency.selectedOption', {})
  }

  useEffect(() => {
    doesCampaignRequireAnythingElse()
  }, [selectedCampaign])

  return (
    <Box
      sx={{
        display: 'block',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        height: 'auto',
        gap: '25px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '50%',
          }}
        >
          <InputSelectRHF
            options={campaigns}
            placeholder={t('campaign.button.segmentation.question')}
            loading
            noOptionsText={t('noRowsLabel.general')}
            multiple={false}
            isOptionEqualToValueFn={(option: any, value: any) =>
              option.sku === value
            }
            getOptionLabel={(value: any) => value?.name || ''}
            onChangeAction={(value) => {
              onChangeValue(value)
            }}
            freeSolo
            sx={{
              width: '98%',
              height: '48px',
            }}
            control={control}
            name='dependency.campaign'
            {...props}
          />
        </Box>
        {campaignOptions.length > 0 && (
          <Box
            sx={{
              height: '55px',
              display: 'flex',
              alignItems: 'center',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            <ArrowForwardIosIcon />
          </Box>
        )}
        <Box
          sx={{
            width: '45%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '25px',
          }}
        >
          {error.length === 0 && campaignOptions.length > 0 &&
            <>
              <InputSelectRHF
                options={campaignOptions}
                placeholder={t('campaign.button.segmentation.campaign.option')}
                loading
                noOptionsText={t('noRowsLabel.general')}
                multiple={false}
                isOptionEqualToValueFn={(option: any, value: any) =>
                  option.name === value
                }
                getOptionLabel={(value: any) => value?.name || ''}
                freeSolo
                sx={{
                  width: '98%',
                  height: '48px',
                }}
                control={control}
                name='dependency.selectedOption'
                {...props}
              />
              <Typography variant='subtitle2'>{currentQuestionName}</Typography>
            </>
          }
        </Box>
      </Box>
      { error ? <Typography variant='subtitle2' color='red' marginTop='10px'>{t(error)}</Typography> : null }
    </Box>
  )
}
