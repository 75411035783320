import { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { t } from 'i18next'

import { FormHeader, checkHasLink } from '../FormHeader'
import {
  CampaignContext,
  contentTypeEnum,
} from 'src/context/CampaignFormContext'
import { StepperContext } from 'src/context/StepperContext'
import { FormButtons } from '../../FormButtons'
import { PreviewModal, PreviewMode } from 'src/components/Modal/PreviewModal'
import { TextCampaignPreview } from '../../../Preview/TextCampaignPreview'
import { MediaIntervalSelect } from '../MediaIntervalSelect'
import { useParams } from 'react-router-dom'
import { goToCampaigns } from 'src/routes/coordinator'
interface Props {
  isViewMode?: boolean
  disabled?: any
}
export const TextForm = ({ isViewMode, disabled }: Props) => {
  const [showPreview, setShowPreview] = useState(false)
  const [previewMode, setPreviewMode] = useState(PreviewMode.view)
  const { id } = useParams()
  const isEdit = Boolean(id)

  const {
    setContentType,
    formState: { setValue, watch, reset },
  } = useContext(CampaignContext)

  const { nextStep } = useContext(StepperContext)

  const formIsValid = Boolean(
    watch('content.description') &&
      watch('content.description').length < 2000 &&
      Boolean(!checkHasLink(watch('content.description')))
  )

  const clearForm = () => {
    setValue('content.title', '')
    setValue('content.description', '')
    setValue('content.mediaInterval', 0)
    setValue('content.hasMediaInterval', false)
    setValue('content.titleColor', undefined)
    setValue('content.descriptionColor', undefined)
  }

  useEffect(() => {
    setValue('content.mediaInterval', 0)
  }, [])

  return (
    <Box>
      <PreviewModal
        handleClose={() => setShowPreview(false)}
        open={showPreview}
        title={`${t('campaign.preview.visualization.off')}\n${t(
          'campaign.text.title'
        )}`}
        mode={previewMode}
        onConfirm={() => {
          setShowPreview(false)
          nextStep()
        }}
      >
        <TextCampaignPreview />
      </PreviewModal>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 1 }}
      >
        <FormHeader title={'campaign.text.title'} isViewMode={isViewMode} />
        <MediaIntervalSelect disabled={disabled} />
        <Box sx={{ width: '100%', p: 'none' }}>
          {!isViewMode && (
            <FormButtons
              onClean={() => clearForm()}
              nextButtonEnabled={formIsValid}
              prevButtonEnabled={true}
              formIsValid={formIsValid}
              cleanButtonEnabled={formIsValid}
              previewButtonEnabled={formIsValid}
              prevButtonAction={() => {
                reset({})
                setContentType(contentTypeEnum.NONE)
              }}
              previewButtonAction={() => {
                setPreviewMode(PreviewMode.view)
                setShowPreview(true)
              }}
              nextButtonAction={() => {
                setPreviewMode(PreviewMode.confirm)
                setShowPreview(true)
              }}
              nextButtonCta="form.button.next"
              nextButtonArrow
              isEdit={isEdit}
              goTo={goToCampaigns}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
