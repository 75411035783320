import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import { Box, CircularProgress, Typography } from '@mui/material'
import { t } from 'i18next'
import { ReactNode, useEffect, useState } from 'react'

import UploadFile from 'src/components/UploadFile/UploadFile'
import { uploadFileImg } from 'src/service/uploadservice'

interface Props {
  onFileChange: (file) => void
  placeholder: string
  icon?: ReactNode
  value?: string
}

export const UploadSupportPhoto = ({ onFileChange, placeholder, icon, value }: Props) => {
  const [file, setFile] = useState<string>(value)
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState<string>('');

  const onReadFile = async (file) => {
    setIsLoading(true);
    const res = await uploadFileImg(file);
    const fileUrl = res.data.file;
    onFileChange(fileUrl);
    setFile(fileUrl);
    setFileName(file.name);
    setIsLoading(false);
  };

  useEffect(() => {
    setFile(value)
  }, [value])

  const handleClick = () => {
    document.getElementById('file_upload').click()
  }

  return (
    <Box
      onClick={() => handleClick()}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        width: '47%',
        height: file ? 'none' : '50px',
        border: file
          ? ' 1px solid #FF5E1E'
          : ' 2.5px dashed rgba(0, 0, 0, 0.38)',
        borderBottom: file && 'solid 4px #FF5E1E',
        backgroundColor: file && 'rgba(255, 94, 30, 0.08)',
        mt: 1.5,
        gap: '10px',
        color: 'rgba(0, 0, 0, 0.6)',
        ':hover': {
          color: '#FF5E1E',
          borderColor: '#FF5E1E',
        },
        borderRadius: file && '4px 4px 0px 0px',
        cursor: 'pointer',
      }}
    >
      <UploadFile
        onReadCallback={onReadFile}
        hidden
        type="file"
        accept=".jpg,.png"
        id={'file_upload'}
      />

      <Box
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '16px',
        }}
      >
        {file || isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              color: 'rgba(0, 0, 0, 0.6)',
              ':hover': {
                color: '#FF5E1E',
              },
            }}
          >
            <Box
              sx={{
                width: 'auto',
                minWidth: '230px',
                minHeight: '80px',
                wordBreak: 'break-all',
                display: file ? 'none' : 'flex',
                backgroundSize: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              {isLoading && (
                <div style={{ display: 'flex', gap: 2 }}>
                  <CircularProgress size={20} sx={{ ml: 1 }} />
                  <Typography
                    sx={{
                      color: '#FF5E1E',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '16px',
                    }}
                  >
                    {t('company.form.input.placeholder.logo.loading')}
                  </Typography>
                </div>
              )}
            </Box>
            {!isLoading && (
              <div
                style={{
                  display: 'inline-block',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                  color: '#FF5E1E',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                <CheckCircleIcon
                  sx={{
                    width: '20px',
                    height: '20px'
                  }}
                />
                <Box
                  sx={{
                    display: 'inline',
                    ml: '10px',
                    mr: '30px',
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    color: '#FF5E1E',
                    position: 'relative',
                    top: '-6px',
                  }}
                >
                  {fileName}
                </Box>

                <DeleteForeverIcon
                  sx={{
                    color: '#00000099',
                    cursor: 'pointer',
                    zIndex: '9'
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setFile(undefined);
                  }}
                />
              </div>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <ImageOutlinedIcon />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '16px',
                display: 'flex',
              }}
            >
              <label htmlFor={placeholder} style={{ cursor: 'pointer' }}>
                {placeholder}
              </label>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
