export class ResultService {
  getTotalView = (data) => {
    return data?.fullReach || 0
  }

  getTotalVisualization = (data, type) => {
    const typeMap = {
      image: 'qtdImages',
      text: 'qtdTexts',
      media: 'qtdVideos',
      quiz: 'qtdResearches',
    }
    return data?.[typeMap[type]] || 0
  }
  getDevicesData = (data: any) => {
    const labels = Object.keys(data?.devices || {})
    const values = labels?.map((key) => data?.devices[key]) || []
    var formatedLabels = []

    values.map((index) => (formatedLabels = [...formatedLabels, index.name]))
    var formatedData = []

    values.map((index) => (formatedData = [...formatedData, index.quantity]))
    var total = values.reduce((acc, curr) => acc + curr.quantity, 0)
    return {
      labels: formatedLabels,
      data: formatedData,
      total: total,
    }
  }

  getGenderData = (data: any) => {
    const labels = Object.keys(data?.gender || {})
    const values = labels?.map((key) => data?.gender[key]) || []
    var formatedLabels = []

    values.map((index) => (formatedLabels = [...formatedLabels, index.name]))
    var formatedData = []

    values.map((index) => (formatedData = [...formatedData, index.quantity]))
    var total = values.reduce((acc, curr) => acc + curr.quantity, 0)
    return {
      labels: formatedLabels,
      data: formatedData,
      total: total,
    }
  }

  getAgeRangeData = (data: any) => {
    const labels = Object.keys(data?.ageRange || {})
    const values = labels?.map((key) => data?.ageRange[key]) || []
    var formatedLabels = []

    values.map((index) => (formatedLabels = [...formatedLabels, index.name]))
    var formatedData = []

    values.map((index) => (formatedData = [...formatedData, index.quantity]))
    var total = values.reduce((acc, curr) => acc + curr.quantity, 0)
    return {
      labels: formatedLabels,
      data: formatedData,
      total: total,
    }
  }

  getMaritalStatusData = (data: any) => {
    const labels = Object.keys(data?.maritalStatus || {})
    const values = labels?.map((key) => data?.maritalStatus[key]) || []
    var formatedLabels = []

    values.map((index) => (formatedLabels = [...formatedLabels, index.name]))
    var formatedData = []

    values.map((index) => (formatedData = [...formatedData, index.quantity]))
    var total = values.reduce((acc, curr) => acc + curr.quantity, 0)
    return {
      labels: formatedLabels,
      data: formatedData,
      total: total,
    }
  }

  getEducationData = (data: any) => {
    const labels = Object.keys(data?.education || {})
    const values = labels?.map((key) => data?.education[key]) || []
    var formatedLabels = []

    values.map((index) => (formatedLabels = [...formatedLabels, index.name]))
    var formatedData = []

    values.map((index) => (formatedData = [...formatedData, index.quantity]))
    var total = values.reduce((acc, curr) => acc + curr.quantity, 0)
    return {
      labels: formatedLabels,
      data: formatedData,
      total: total,
    }
  }
  getOccupationData = (data: any) => {
    const labels = Object.keys(data?.occupation || {})
    const values = labels?.map((key) => data?.occupation[key]) || []
    var formatedLabels = []

    values.map((index) => (formatedLabels = [...formatedLabels, index.name]))
    var formatedData = []

    values.map((index) => (formatedData = [...formatedData, index.quantity]))
    var total = values.reduce((acc, curr) => acc + curr.quantity, 0)
    return {
      labels: formatedLabels,
      data: formatedData,
      total: total,
    }
  }

  getNavegatorsData = (data: any) => {
    const labels = Object.keys(data?.browsers || {})
    const values = labels?.map((key) => data?.browsers[key]) || []
    var formatedLabels = []
    values.map((index) => (formatedLabels = [...formatedLabels, index.name]))
    var formatedData = []
    values.map((index) => (formatedData = [...formatedData, index.quantity]))
    var total = 0
    values.map((index) => (total = index?.quantity))
    return {
      labels: formatedLabels,
      data: formatedData,
      total: total,
    }
  }

  getActiveTotal = (data) => {
    return data?.campaigns?.active || 0
  }

  getInactiveTotal = (data) => {
    return data?.campaigns?.inactive || 0
  }

  getRanking = (data) => {
    const campaigns = Object.keys(data?.campaigns?.ranking || {})
    const formatedData =
      campaigns.map((name) => ({
        name: data?.campaigns?.ranking[name]?.name,
        value: data?.campaigns?.ranking[name]?.quantity,
      })) || []
    const desc = (a, b) => b.value - a.value
    return formatedData.sort(desc).slice(0, 10)
  }

  getDataCampaign = (data) => {
    if (data?.dataCampaign?.length) return data?.dataCampaign[0]
    return []
  }

}
