import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'

import { PointGroupsForm } from './components/Form/PointGroupsForm'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { PointGroups } from 'src/models/pointGroups-model'
import {
  createPointGroup,
  editPointGroup,
  getPointGroupById,
} from 'src/service/pointGroupsService'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { goToPointGroups } from 'src/routes/coordinator'
import { AlertComponent } from 'src/components/Alert/AlertComponent'

interface Props {
  isViewMode?: boolean
  viewId?: any
}

const CreatePointGroups = ({ isViewMode, viewId }: Props) => {
  useProtectedPage()
  const { t } = useTranslation()
  const { id } = useParams()
  const [pointGroup, setPointGroup] = useState<PointGroups>()
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)

  const getPointGroups = async () => {
    if (id || isViewMode) {
      const groups = await getPointGroupById(id || viewId)
      setPointGroup(groups)
    }
  }

  const onSubmit = async (data) => {
    const apList = data?.apList.filter(({ id }) => id)?.map(({ id }) => id)
    const validData = { ...data, apList, companyId: data?.companyId?.id }
    if (id) {
      const { groupName, apList, companyId, isActive, isArchived } = validData
      const res = await editPointGroup(id, {
        groupName,
        apList,
        newCompanyId: companyId,
        isActive,
        isArchived,
      })
      if (res) {
        createCRUDAlert(ROUTES.GROUPPOINT, groupName, 'edited')
        goToPointGroups(history)
      }

      return
    }
    const res = await createPointGroup(validData)
    if (res) {
      createCRUDAlert(ROUTES.GROUPPOINT, validData?.groupName, 'created')
      goToPointGroups(history)
    }
  }

  useEffect(() => {
    getPointGroups()
  }, [])

  return (
    <Container
      sx={{
        mt: isViewMode ? '' : '96px',
        ml: isViewMode ? '' : '30px',
        '.gm-style': {
          div: {
            cursor: 'default',
            ':active': {
              cursor: 'grabbing',
            },
          },
        },
      }}
    >
      <AlertComponent isView={isViewMode} />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          mb: isViewMode ? '10px' : '24px',
        }}
      >
        <WorkspacesOutlinedIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
          }}
        >
          {t('pointGroups.title')}
        </Typography>
      </Box>
      <PointGroupsForm
        isViewMode={isViewMode}
        data={pointGroup!}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
export default CreatePointGroups
