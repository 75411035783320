import { Box } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Hotjar from '@hotjar/browser';
import './i18n'

const siteId = 3417275;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
