import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import InputSelectRHF from 'src/components/RHF/InputSelectRHF'
import { Support } from 'src/models/support-model'
import { createSupport } from 'src/service/supportService'
import logoItBuzzWhite from '../../../../assets/icons/itBuzz.png'
import logoTecnoItWhite from '../../../../assets/icons/tecnoIT.png'
import InputRHF from '../../../../components/RHF/InputRHF'
import { supportDefaultValues, supportSchemaValidation } from '../../validators/supportSchema'
import { ModalSupport } from '../modal/ModalSupport'
import { UploadSupportPhoto } from '../ui/UploadSupportPhoto'

interface SupportFormProps {
  data?: Support
}

export const SupportForm = ({ data }: SupportFormProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const form = useForm<any>({
    mode: 'onBlur',
    defaultValues: supportDefaultValues,
    resolver: yupResolver(supportSchemaValidation),
  })
  const { control, handleSubmit, reset, getValues, setValue, watch } = form
  const [fileSupport, setFileSupport] = useState<string>()
  const [messageLength, setMessageLength] = useState<number>(0)

  const handleOpen = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
    resetForm()
  }

  const mockType = [
    { id: 1, name: t('support.type.technician') },
    { id: 2, name: t('support.type.financial') },
  ]

  const onSubmit = () => {
    const data = getValues()
    const validData = {
      ...data,
      ticketType: data?.ticketType?.name,
    }
    createSupport(validData)
      .then(() => {
        handleOpen()
      })
      .catch((error) => alert(error))
  }
  useEffect(() => {
    setValue('ticketType', mockType[0])
  }, [])

  useEffect(() => {
    setValue('fileUrl', fileSupport)
  }, [fileSupport])

  useEffect(() => {
    if (data) {
      reset(data)
      setFileSupport(data?.fileUrl)
    }
  }, [data])

  const resetForm = () => {
    reset({ message: '', ticketType: '' })
    setFileSupport('')
  }

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setMessageLength(value.length)
    setValue('message', value)
  }

  return (
    <Box
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      component="form"
      sx={{
        width: '100%',
        height: '255px',
        ml: '0px',
      }}
    >
      <InputRHF
        placeholder={t('suporte.request')}
        control={control}
        multiline
        label={t('support.message')}
        name="message"
        onChange={handleMessageChange}
        InputProps={{
          maxLength: 1000,
        }}
        sx={{
          width: '100%',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          p: {
            '.MuiFormHelperText-root.Mui-error': {
              m: 0,
              display: messageLength > 1000 ? 'block' : 'none',
            },
          },
        }}
      />

      <Box
        sx={{
          display: 'inline-flex',
          width: '50%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '2%',
          '.MuiTextField-root':{
            width: '100%',
            top: '-22px'
          },
        }}
      >
        <InputSelectRHF
          options={mockType}
          control={control}
          value={t('support.type.technician')}
          label={t('support.type')}
          name="ticketType"
          sx={{
            height: '48px',
            width: '100% !important',
          }}
          isOptionEqualToValueFn={(option, value) => option.id === parseInt(value)}
          getOptionLabel={(option) => option?.name || ''}
        />

      </Box>
      <Box
        className="upfoto"
        sx={{
          width: '50%',
          display: 'inline-block',
          mt: '4px',

          '.MuiBox-root': {
            height: '55.99px',
            width: '100%',
            borderRadius: '4px',
            ml: '2%',
          },
        }}
      >
        <UploadSupportPhoto
          onFileChange={(file) => setFileSupport(file)}
          placeholder={t('support.upload.attach')}
          value={fileSupport}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginRight: '0',
          gap: '10px',
          width: '100%',
          mt: 2.5,
        }}
      >
        <Tooltip title={t('support.button.clean')} placement="left">
          <Button
            onClick={resetForm}
            variant="outlined"
            sx={{
              display:
                watch('message') && watch('ticketType') ? 'flex' : 'none',
              p: 0,
              height: '36px',
              minWidth: '36px',
              borderColor: 'rgba(0, 0, 0, 0.6)',
              color: '#00000099',
              backgroundColor: !reset ? '#00000099' : '#ffff',
              ':hover': {
                color: '#ffff',
                backgroundColor: '#00000099',
                borderColor: 'rgba(0, 0, 0, 0.6)',
              },
            }}
          >
            <DeleteOutlineIcon />
          </Button>
        </Tooltip>
        <Button
          variant="outlined"
          type="submit"
          sx={{
            minWidth: '360px',
            height: '36px',
            fontSize: '14px',
            fontWeight: 500,
            LineHeight: '16px',
            padding: '6px 6px 6px 8px',
            width: '50%',
            pointerEvents: messageLength > 1000 ? 'none' : 'auto',
            ':hover': {
              backgroundColor: '#FF5E1E',
              color: '#ffff',
            },
          }}
        >
          <MailOutlineOutlinedIcon sx={{ width: '25px', height: '16px' }} />{' '}
          {t('support.button.submit')}
        </Button>
      </Box>
      <Divider sx={{ width: '100%', mb: 1, mt: 5 }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '16px',
          pl: 3.2,
          gap: '10px',
          width: '100%',
          height: '48px',
          background: ' #3D3D3D',
          borderRadius: '4px',
          textAlign: 'end',
        }}
      >
        <Box
          sx={{
            width: '50%',
            display: 'flex'
          }}
        >
          <img
            alt="logo"
            src={logoItBuzzWhite}
            style={{ width: '82.56px', height: '24px' }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              pl: 3,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '10px',
                lineHeight: ' 16px',
                letterSpacing: '1.5px',
                color: '#FFFFFF',
                mt: '2px',
              }}
            >
              {t('login.image.title.per')}
            </Typography>
            <img
              alt="logo"
              src={logoTecnoItWhite}
              style={{
                width: '93.5',
                height: '20px',
              }}
            />
          </Box>
        </Box>

        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '10px',
            lineHeight: '16px',
            letterSpacing: '1.5px',
            color: '#FFFFFF',
            pl: 29,
            width: '50%',
            textAlign: 'right',
          }}
        >
          SUPORTE@TECNOIT.COM.BR
        </Typography>
      </Box>
      <ModalSupport open={open} onHandleClose={handleClose} />
    </Box>
  )
}
