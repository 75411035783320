import AddIcon from '@mui/icons-material/Add'
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded'
import { Box, Button, Container, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import { t } from 'i18next'
import { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { AlertContext } from 'src/context/AlertContext'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { VideoTraining } from 'src/models/training-video-model'
import { ROUTES } from 'src/routes/Router'
import { goToCreateVideo } from 'src/routes/coordinator'
import { getPermissions } from 'src/service/authService'
import { deleteTrainingVideoById, fetchTrainingVideos } from 'src/service/trainingVideoService'
import { cleanHtmlString } from 'src/util/clean-html-string'
import { createMessageExcerpt } from 'src/util/generate-excerpt'
import { ConfirmDeleteModal } from './components/modal/ConfirmDeleteModal'
import { SelectedVideoCard } from './components/ui/SelectedVideoCard'
import { SelectedVideoSkeleton } from './components/ui/SelectedVideoSkeleton'
import { VideoListingSkeleton } from './components/ui/VideoListingSkeleton'

const formatVideoDescription = (message: string, maxLength: number): string => {
  const cleanMessage = cleanHtmlString(message)
  const excerpt = createMessageExcerpt(cleanMessage, maxLength)
  return excerpt
}

interface ObjectType {
  id: string
  title: string
}

export function VideoList () {
  useProtectedPage()
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)
  const [videos, setVideos] = useState<VideoTraining[]>([])
  const [sideVideos, setSideVideos] = useState<VideoTraining[]>([])
  const [selectedVideo, setSelectedVideo] = useState<VideoTraining>({} as VideoTraining)
  const [loading, setLoading] = useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [objectToBeDeleted, setObjectToBeDeleted] = useState<ObjectType>({} as ObjectType)

  const permissions = JSON.parse(getPermissions())
  const canCreateVideo: boolean = permissions?.['training-video']?.includes('training-video:create')
  const canUpdateVideo: boolean = permissions?.['training-video']?.includes('training-video:update')
  const canDeleteVideo: boolean = permissions?.['training-video']?.includes('training-video:delete')
  const canSeeItemMenu: boolean = canUpdateVideo || canDeleteVideo

  const loadTrainingVideos = async () => {
    const trainingVideos = await fetchTrainingVideos().catch(() => {
      return []
    })
    setVideos(trainingVideos)
    if (trainingVideos.length > 0) {
      const videoInExhibition = trainingVideos[0]
      setSelectedVideo(videoInExhibition)
      const listing = trainingVideos.filter(video => video.id !== videoInExhibition.id)
      setSideVideos(listing)
      setLoading(false)
    }
  }

  const handleSelectVideoInViewing = (videoId: string) => {
    const newVideo = videos.find(video => video.id === videoId)
    setSelectedVideo(newVideo)
    const newListing = videos.filter(video => video.id !== newVideo.id)
    setSideVideos(newListing)
  }

  const handleDelete = async (id: string, title: string) => {
    setIsModalOpen(true)
    setObjectToBeDeleted({ id, title })
  }

  const onAccept = async () => {
    const res = await deleteTrainingVideoById(objectToBeDeleted.id)
    if (res) createCRUDAlert(ROUTES.FAQS, objectToBeDeleted.title, 'deleted')
    setObjectToBeDeleted({} as ObjectType)
    setIsModalOpen(false)
  }

  const onReject = () => {
    setObjectToBeDeleted({} as ObjectType)
    setIsModalOpen(false)
  }

  useEffect(() => {
    loadTrainingVideos()
  }, [objectToBeDeleted])

  return (
    <Container sx={{ mt: '96px' }} >
      <ConfirmDeleteModal
        open={isModalOpen}
        onHandleCloseAndAccept={onAccept}
        onHandleCloseAndReject={onReject}
        objectName={objectToBeDeleted.title}
      />
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '10px',
          justifyContent: 'flex-start',
          mb: '24px',
        }}
      >
        <OndemandVideoRoundedIcon sx={{ position: 'relative', top: '3px' }}/>
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
          }}
        >
          {t('training.video.title')}
        </Typography>
      </Box>
      { canCreateVideo && (
        <Box sx={{ mb: '10px' }}>
          <Button
            onClick={() => goToCreateVideo(history)}
            variant="outlined"
            sx={{
              gap: '6px',
              width: '50%',
              height: '36px',
              mb: '40px',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
            }}
          >
            <AddIcon sx={{ width: '24px', height: '24px' }} />
            {t('training.video.button.create')}
          </Button>
        </Box>
        )
      }
      <Box sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '32px',
        '@media screen and (max-width: 1250px)': {
          flexDirection: 'column'
        }
      }}>
        <Box>
          { loading ? <SelectedVideoSkeleton /> : (
            <>
              <ReactPlayer
                url={selectedVideo?.videoUrl}
                config={{ file: {
                  attributes: {
                    controlsList: 'nodownload'
                  }
                }}}
                controls
              />
              <SelectedVideoCard
                currentVideo={selectedVideo}
                canSeeMenu={canSeeItemMenu}
                canEditVideo={canUpdateVideo}
                canRemoveVideo={canDeleteVideo}
                handleDelete={handleDelete}
              />
            </>
          )}
        </Box>
        <Box display='flex' flexDirection='column'>
          { loading ? <VideoListingSkeleton /> : (
            sideVideos.map((video) => {
              return (
                <Link
                  key={video.id}
                  underline='hover'
                  onClick={() => handleSelectVideoInViewing(video.id)}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    mb: '10px',
                    color: 'inherit',
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: 'rgba(255, 94, 30, 0.06)'
                    }
                  }}
                >
                  <ReactPlayer url={video?.videoUrl} width='128px' height='74px' style={{ minWidth: '128px' }} />
                  <div style={{ marginLeft: '6px' }}>
                    <Typography variant='body1' color='primary' fontWeight='500' >{formatVideoDescription(video.title, 40)}</Typography>
                    <Typography fontSize='14px'>{formatVideoDescription(video.description, 70)}</Typography>
                  </div>
                </Link>
              )
            }))
          }
        </Box>
      </Box>
    </Container>
  )
}
