import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColumns } from '@mui/x-data-grid'
import { Chip } from '@mui/material'

import { deleteCompanyById, getCompanies, recycleCompanyById } from 'src/service/companyService'
import { getPermissions } from 'src/service/authService'
import { Table } from 'src/components/Table/Table'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { goToEditCompany } from 'src/routes/coordinator'
import { ArrToNumberSort, NumberSort } from 'src/components/Table/SortingFunctions'
import CreateCompany from '../../CreateCompany'


export default function CompanyTable() {
  const { t } = useTranslation()
  const { createCRUDAlert } = useContext(AlertContext)
  const [tableKey, setTableKey] = useState(0)
  const permissions = JSON.parse(getPermissions())

  const getData = async (options: any) => {
    const data = await getCompanies(options)
    return { data: data.listCompanies, totalPages: data.page, size: data?.size }
  }
  const handleDelete = async (id, name) => {
    const res = await deleteCompanyById(id)
    if (res) createCRUDAlert(ROUTES.COMPANY, name, 'deleted')
    return
  }

  const handleRecycle = async (id, name) => {
    const res = await recycleCompanyById(id)
    if (res) {
      createCRUDAlert(ROUTES.COMPANY, name, 'edited')
    }
  }

  const columns: GridColumns = [
    {
      field: 'companyName',
      headerName: t('table.header.company'),
      width: 222,
      editable: false,
    },
    {
      field: 'isActive',
      headerName: t('table.header.situation'),
      width: 95,
      type: 'boolean',
      editable: false,
      renderCell: ({ row }) => {
        return row.isActive ? (
          <Chip
            sx={{
              background: 'rgba(255, 94, 30, 0.12)',
            }}
            size="small"
            label={t('table.status.active')}
          />
        ) : (
          <Chip size="small" label={t('table.status.inactive')} />
        )
      },
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 150,
      type: 'number',
      editable: false,
      headerAlign: 'left',
      align: 'left',
      sortComparator: NumberSort,
    },
    {
      field: 'contractNumber',
      headerName: t('company.table.header.contractNumber'),
      type: 'number',
      width: 110,
      editable: false,
      headerAlign: 'left',
      align: 'left',
      sortComparator: ArrToNumberSort,
    },
    {
      field: 'managerName',
      headerName: t('table.header.managerName'),
      width: 140,
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
  ]
  return (
    <Table
      columns={columns}
      getData={getData}
      key={tableKey}
      handleDelete={handleDelete}
      titleKey="companyName"
      goTo={goToEditCompany}
      defaultPageSize={5}
      model={'company'}
      Children={CreateCompany}
      size={'16px'}
      mr="25px"
      permissionUpdate={!permissions?.company?.includes("company:update")}
      permissionDelete={!permissions?.company?.includes("company:delete")}
      permissionView={!permissions?.company?.includes("company:view")}
      canShowRecycleButtons={true}
      permissionViewRecycle={permissions?.company?.includes("company:recycle:view")}
      permissionUpdateRecycle={permissions?.company?.includes("company:recycle:update")}
      handleRecycle={handleRecycle}
      canShowUnarchiveButtons={false}
    />
  )
}
