import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { Box, CircularProgress, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import { theme } from './constants/theme'
import Router, { ROUTES } from './routes/Router'
import AlertProvider from './context/AlertContext'
import ActivityProvider from './context/ActivityContext'
import { useAxiosLoader } from './hooks/globalLoader'
import ModalIdCompanyProvider from './context/ModalCreatedCompanyContext'
import ModalPortalIsActiveProvider from './context/ModalPortalIsActiveContext'
import ModalChangePasswordProvider from './context/ModalChangePasswordContext'
import { GlobalSelectedCompanyProvider } from './context/GlobalSelectedCompanyContext'

const App = () => {
  const google = window?.google
  return (
    <ThemeProvider theme={theme}>
      <GlobalSelectedCompanyProvider>
        <ActivityProvider>
          <AlertProvider>
            <ModalIdCompanyProvider>
              <ModalPortalIsActiveProvider>
                <ModalChangePasswordProvider>
                  <CssBaseline />
                  <BrowserRouter>
                    <Box sx={{ display: 'flex' }}>
                      <Router />
                    </Box>
                    <Loading />
                  </BrowserRouter>
                </ModalChangePasswordProvider>
              </ModalPortalIsActiveProvider>
            </ModalIdCompanyProvider>
          </AlertProvider>
        </ActivityProvider>
      </GlobalSelectedCompanyProvider>
    </ThemeProvider>
  )
}

const Loading = () => {
  const [loading] = useAxiosLoader()
  return (
    <>
      {/* {loading && (
        <Box
          className="loadingComponent"
          sx={{
            minWidth: '100vw',
            width: '100%',
            minHeight: '100vh',
            height: '100%',
            bgcolor: 'rgba(255, 255, 255, 0.424)',
            position: 'absolute',
            top: 0,
            zIndex: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {window.location.pathname === ROUTES.DASHBOARD && (
            <CircularProgress />
          )}
        </Box>
      )} */}
    </>
  )
}

export default App
