import { useTranslation } from 'react-i18next'
import { Box, Container, Typography } from '@mui/material'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'

import useProtectedPage from 'src/hooks/useProtectedPage'
import { CampaignForm } from './components/Form/CampaignForm'
import CampaignProvider from 'src/context/CampaignFormContext'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
interface Props {
  isViewMode?: boolean
  viewId?: any
}
export const CreateCampaign = ({ isViewMode, viewId }: Props) => {
  useProtectedPage()
  const { t } = useTranslation()

  return (
    <Container
      sx={{
        mt: isViewMode ? '' : '96px',
      }}
    >
      <AlertComponent isView={isViewMode} />
      <Box
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <CampaignOutlinedIcon />
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '160%',
            mb: '-4px',
          }}
          variant="h6"
        >
          {t('campaign.header')}
        </Typography>
      </Box>
      <Box>
        <CampaignProvider>
          <CampaignForm isViewMode={isViewMode} viewId={viewId} />
        </CampaignProvider>
      </Box>
    </Container>
  )
}
export default CreateCampaign
