import { getCampaignById } from "./marketingCampaignService"

export interface DependencyType {
  campaign: any
  selectedOption?: {
    id: string
    name: string
  }
}

export interface CampaignDependency {
  campaignId: string
  campaignType: 'quiz' | 'media' | 'image' | 'text'
  selectedOption?: {
    id: string
    answer: string
  }
}

export const getPayloadForImage = (data: any) => {
  const groups = data?.groups?.map((group) => group.id)
  const segmentedRules = questionTosegmentedRules(data?.questions)
  const segmentedCampaignDependency = dependencyToRules(data?.dependency)
  return {
    companyId: data?.company?.id || data?.companyId,
    groups,
    contentType: data?.contentType,
    name: data?.name,
    initialDate: data?.initialDate,
    finalDate: data?.finalDate,
    isActive: Boolean(data?.isActive),
    isArchived: Boolean(data?.isArchived),
    defaultCampaign: data?.defaultCampaign || false,
    segmentedRules: data?.segmentedActive ? segmentedRules : [],
    segmentedActive: Boolean(data?.segmentedActive),
    segmentedCampaign: Boolean(data?.segmentedCampaign),
    segmentedCampaignDependency : data?.segmentedCampaign ? segmentedCampaignDependency : {},
    content: {
      ...data?.content,
      mediaInterval: data?.content?.hasMediaInterval
        ? data?.content?.mediaInterval
        : 0,
    },
  }
}

export const getPayloadForMedia = (data: any) => {
  const groups = data?.groups?.map((group) => group.id)
  const segmentedRules = questionTosegmentedRules(data?.questions)
  const segmentedCampaignDependency = dependencyToRules(data?.dependency)
  return {
    companyId: data?.company?.id || data?.companyId,
    groups,
    contentType: data?.contentType,
    name: data?.name,
    initialDate: data?.initialDate,
    finalDate: data?.finalDate,
    isActive: Boolean(data?.isActive),
    isArchived: Boolean(data?.isArchived),
    segmentedRules: data?.segmentedActive ? segmentedRules : [],
    segmentedActive: Boolean(data?.segmentedActive),
    defaultCampaign: data?.defaultCampaign || false,
    segmentedCampaign: Boolean(data?.segmentedCampaign),
    segmentedCampaignDependency : data?.segmentedCampaign ? segmentedCampaignDependency : {},
    content: {
      ...data?.content,
      mediaInterval: data?.content?.hasMediaInterval
        ? data?.content?.mediaInterval
        : 0,
    },
  }
}
export const getPayloadForSearch = (data: any) => {
  const groups = data?.groups?.map((group) => group.id)
  const segmentedRules = questionTosegmentedRules(data?.questions)
  const segmentedCampaignDependency = dependencyToRules(data?.dependency)
  return {
    companyId: data?.company?.id || data?.companyId,
    groups,
    contentType: data?.contentType,
    name: data?.name,
    initialDate: data?.initialDate,
    finalDate: data?.finalDate,
    isActive: Boolean(data?.isActive),
    isArchived: Boolean(data?.isArchived),
    segmentedRules: data?.segmentedActive ? segmentedRules : [],
    segmentedActive: Boolean(data?.segmentedActive),
    segmentedCampaign: Boolean(data?.segmentedCampaign),
    segmentedCampaignDependency : data?.segmentedCampaign ? segmentedCampaignDependency : {},
    content: data?.content,
    defaultCampaign: false,
  }
}

export const getPayloadForText = (data: any) => {
  const groups = data?.groups?.map((group) => group.id)
  const segmentedRules = questionTosegmentedRules(data?.questions)
  const segmentedCampaignDependency = dependencyToRules(data?.dependency)
  return {
    companyId: data?.company?.id || data?.companyId,
    groups,
    contentType: data?.contentType,
    name: data?.name,
    defaultCampaign: data?.defaultCampaign || false,
    initialDate: data?.initialDate,
    finalDate: data?.finalDate,
    isActive: Boolean(data?.isActive),
    isArchived: Boolean(data?.isArchived),
    segmentedRules: data?.segmentedActive ? segmentedRules : [],
    segmentedActive: Boolean(data?.segmentedActive),
    segmentedCampaign: Boolean(data?.segmentedCampaign),
    segmentedCampaignDependency : data?.segmentedCampaign ? segmentedCampaignDependency : {},
    content: {
      ...data?.content,
      mediaInterval: data?.content?.hasMediaInterval
        ? data?.content?.mediaInterval
        : 0,
    },
  }
}

export const questionTosegmentedRules = (questions = []) => {
  const segmentedRules = questions?.map((question) => {
    const answerList = question?.options
      ?.map((option) => option.name)
      .filter((option) => Boolean(option))
    const questionSku = question?.sku
    return { questionSku, answerList }
  })
  return segmentedRules
}

export const dependencyToRules = (dependency: DependencyType) => {
  const dependencyObject = dependency || {}
  if (Object.keys(dependencyObject).length === 0) return {}
  const campaignId = dependency?.campaign?.id
  const contentType = dependency?.campaign?.contentType
  const selectedOption = {
    id: dependency?.selectedOption?.id,
    answer: dependency?.selectedOption?.name
  }
  if (contentType === 'quiz') {
    return {
      campaignId,
      contentType,
      selectedOption
    }
  }
  return {
    campaignId,
    contentType
  }
}

export const segmentedRulesToQuestions = (segmentedRules, enrichments) => {
  const result = segmentedRules?.map((q) => {
    const question = enrichments.find(({ sku }) => sku === q?.questionSku)
    const options = question?.content?.quiz[0]?.options?.filter(({ name }) =>
      q.answerList.includes(name)
    )
    return { ...question, options }
  })
  return result
}

export const dependencyToFrontend = async (segmentation: CampaignDependency) => {
  if (Object.keys(segmentation).length === 0) return {}
  const dependencyCampaign = await getCampaignById(segmentation.campaignId)
  const frontOption = {
    id: segmentation?.selectedOption?.id,
    name: segmentation?.selectedOption?.answer
  }
  return {
    campaign: dependencyCampaign,
    selectedOption: frontOption ?? null
  }
}
