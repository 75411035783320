import * as yup from 'yup'
import { Enrichment, EnrichmentContent } from 'src/models/campaigns-model/enrichment-model'
import { Answer } from 'src/models/campaigns-model/answer-model'
import { Question } from 'src/models/campaigns-model/question-model'

const enrichmentContentDefaultValues: EnrichmentContent = {
  title: '',
  description: '',
  quiz: [],
}

export const enrichmentQuestionDefaultValues: Question = {
  id: '',
  question: '',
  options: [],
}

export const enrichmentAnswerDefaultValues: Answer = {
  id: '',
  name: '',
}

export const enrichmentDefaultValues: Enrichment = {
  name: '',
  isActive: true,
  contentType: 'enrichment',
  sku: '',
  content: enrichmentContentDefaultValues,
}

export const enrichmentAnswerSchemaValidation = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required('required'),
})

export const enrichmentQuestionSchemaValidation = yup.object().shape({
  id: yup.string().nullable(),
  question: yup.string().required('required'),
  options: yup.array().of(enrichmentAnswerSchemaValidation)
})

export const enrichmentSchemaValidation = yup.object({
  name: yup.string().required('required'),
  isActive: yup.boolean(),
  contentType: yup.string().required('required').default('enrichment'),
  sku: yup.string().required('required'),
  content: yup.object({
    title: yup.string().required('required'),
    description: yup.string().required('required'),
    quiz: yup.array().of(enrichmentQuestionSchemaValidation)
  })
})
